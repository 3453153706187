import React from "react";
import styled from "styled-components";
import axios from "axios";
import {SmartToaster, toast} from "react-smart-toaster";

// import DatePicker from "react-date-picker/dist/entry.nostyle";
import {Form, Field, FieldArray, Formik} from "formik";
import "../Common/datetimepicker.css";

import approved from "../Common/approvedbtn.png";
import notapproved from "../Common/notapprovedbtn.png";
import Allapprove from "../Common/Allapprove.svg";

// api URL
import {apiURL, createBuffer, generateSHA2} from "../../utils/util";

const FullWrap = styled.div`
  width: 950px;
  /* border: 1px solid black; */
  display: flex;
  flex-wrap: wrap;
  margin-top: 2em;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.23);
  background-color: #ffffff;
  margin-left: 1em;
  border-radius: 6px;
`;
const TwoFieldWrap = styled.div`
  display: flex;
  max-width: 700px;
`;
const ThreeFieldWrap = styled.div`
  display: flex;
`;

const LableInput = styled.div`
  margin: 20px;
  width: ${props => (props.full ? 640 : 270)}px;
`;
const ImageInput = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
  width: 200px;
`;

const Label = styled.label`
  font-size: 12px;
  color: #323c47;
`;
const ImgLabelWrap = styled.div`
  margin-top: 10px;
  display: flex;
`;

const ApproveAllImg = styled.img`
  padding-right: 1em;
  height: 35px;
`;

const Img = styled.img`
  padding-right: 1em;
`;
const ImgLabel = styled.label`
  width: 80px;
  text-align: center;
  /* border: 1px solid black; */
  padding-top: 3px;
`;

const FormField = styled(Field)`
  margin-top: 10px;
  position: ${props => (props.absolute ? "absolute" : "")};
  width: 100%;
  // border: solid 1px #e9eff4;
  border: ${props => (props.active ? "1px solid #d0d0d0" : "none")};
  text-indent: 15px;
  padding: 10px;
  font-size: 17px;
  border-radius: 4px;
  background-color: #ffffff;
  opacity: 0.5;
  ::-webkit-input-placeholder {
    font-family: Ubuntu;
    /* font-size: 20px; */
    font-weight: 300;
    color: #0d3868;
    opacity: 0.5;
    padding: 15px;
    /* text-align: left; */
  }
`;

const DoneButton = styled.button`
  width: 100px;
  height: 40px;
  border-radius: 5px;
  background-color: #085db0;
  color: white;
  cursor: pointer;
  margin-left: 20px;
`;

const ConfirmWrap = styled.div`
  display: flex;
  margin: 20px;
  max-width: 600px;
  justify-content: space-between;
`;
const ConfirmButton = styled.button`
  background-color: #ffffff;
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 17px;
  cursor: pointer;
  /* width: 150px; */
  width: ${props => (props.notapproved ? 170 : 150)}px;
  margin-right: 20px;
  height: 40px;
  text-decoration: ${props => (props.underline ? "underline" : "")};
  color: ${props => (props.underline ? "#39a1dd" : "")};
`;

// const EditButton = styled.button`
//   margin-left: 865px;
//   margin-top: 20px;
//   width: 100px;
//   height: 40px;
//   border-radius: 5px;
//   background-color: #085db0;
//   color: white;
//   font-size: large;
// `;

const EditRemoveButtonWrap = styled.div`
  width: 100px;
  display: flex;
  justify-content: space-between;
  margin-left: 800px;
  margin-top: 20px;
`;

const SaveButton = styled.button`
  border: none;
  color: #39a1dd;
  font-size: 20px;
  outline: 0;
  cursor: pointer;
`;

const EditButton = styled.button`
  border: none;
  color: #d0021b;
  font-size: 20px;
  outline: 0;
  cursor: pointer;
`;

const FormWrap = styled.div`
  width: 900px;
  display: flex;
  flex-wrap: wrap;
`;

const CommentTextBox = styled.textarea`
  /* width: 450px; */
  width: 100%;
  margin: 20px;
  height: 100px;
  font-size: 15px;
  /* margin-left: 120px; */
`;

const ApprovedMsgDiv = styled.div`
  margin-left: 20px;
`;

const ApprovedMsg = styled.label`
  color: #39a1dd;
`;

const ConfirmFullWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

const email = window.sessionStorage.getItem("email");
const password = window.sessionStorage.getItem("token");

export default class ServiceChargesForm extends React.Component {
  state = {
    isNotOkClicked: false,
    categoryNameDropDown: [],
    expertID: sessionStorage.getItem("expertID"),
    isFieldEnable: false,
    currentEditing: -1,
    admin_comment: "",
    isOkClicked: false,
    notokclicked: false,
    isFieldActive: false,
    isApproveAllClicked: false,
    personal_info: {},
    category: [
      {
        cat_id: "",
        cat_name: "",
        sub_category: "",
        charges_per_hour: null,
        charges_per_minute: null,
        expert_id: "",
      },
    ],

    category_list: [
      {
        id: "",
        name: "",
      },
    ],
  };

  componentDidMount() {
    this.getParticularExpertDetails();
    this.getCategoryList();
  }

  getParticularExpertDetails = () => {
    const {expertID} = this.state;

    const buffer = createBuffer({
      email,
      docid: expertID,
    });
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/expert/getParticularExpert`,
        {
          json_string: JSON.stringify({
            email,
            docid: expertID,
          }),
        },
        {headers: {signature, email, channel: "web"}},
      )

      // axios
      //   .post(`${apiURL}/expert/getParticularExpert`, {
      //     docid: expertID,
      //   })
      .then(response => {
        if (response.status === 200 && response.data) {
          const {data} = response;
          const updatedState = {
            personal_info: data.personal_info,
            isNotOkClicked: false,
          };

          if (
            data.category &&
            Array.isArray(data.category) &&
            data.category.length !== 0
          ) {
            updatedState.category = data.category;
          }
          this.setState(updatedState);
          // console.log(data);
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };

  getCategoryList = () => {
    const buffer = createBuffer({
      email,
    });
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/expert/getCategoryList`,
        {
          json_string: JSON.stringify({
            email,
          }),
        },
        {headers: {signature, email, channel: "web"}},
      )

      // axios
      //   .post(`${apiURL}/expert/getCategoryList`, {})

      .then(response => {
        // console.log("Category List", response);

        if (response.status === 200 && response.data) {
          const {data} = response;
          const catListDropDown = [];

          data.category_list.forEach(item => {
            const tempObj = {};

            tempObj.value = item.id;
            tempObj.label = item.name;

            catListDropDown.push(tempObj);
          });
          this.setState({
            category_list: data.category_list,
            categoryNameDropDown: catListDropDown,
          });
          // console.log("Category_list", data);
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };

  updateServiceChargesInfo = () => {
    const {category} = this.state;
    // console.log("Updated Charges", category);
    // console.log(typeof category);

    const buffer = createBuffer({
      email,
      data: category,
    });
    const signature = generateSHA2(buffer, password);
    // console.log("category", category);
    axios
      .post(
        `${apiURL}/expert/UpdateServiceCharges`,
        {
          json_string: JSON.stringify({
            email,
            data: category,
          }),
        },
        {headers: {signature, email, channel: "web"}},
      )

      .then(response => {
        // console.log(response);

        this.getParticularExpertDetails();
      })

      .catch(error => {
        // console.log(error);
      });
    this.setState({isFieldActive: false});
  };

  handleOptionSelectedChange = id => {
    this.setState({id});
  };

  handleStartChange = startDate => {
    this.setState({startDate});
  };

  handleEndChange = endDate => {
    this.setState({endDate});
  };

  // handleOnButtonClick = index => {
  //   this.setState({currentEditing: index});
  // };

  handleOnButtonClick = () => {
    this.setState({isFieldEnable: true, isFieldActive: true});
  };

  handleOnChange = (e, index) => {
    const {category} = this.state;

    const {name} = e.target.dataset;
    const {value} = e.target;

    // console.log("Name and value", name, value);
    // console.log("VALUE", value);

    // console.log("Category list INDEX ", category[index]);
    // console.log("index", index);
    // console.log("TempObj".tempObj);

    const tempobj = {
      [name]: value,
    };

    const arrayData = Object.assign(category[index], tempobj);

    category[index] = arrayData;

    this.setState({
      category,
    });
  };

  onOkClicked = () => {
    this.setState({
      isOkClicked: true,
    });
  };

  onNotOkClicked = () => {
    this.setState({
      notokclicked: true,
    });
  };

  handleOnAdminChange = e => {
    this.setState({admin_comment: e.target.value});
  };

  onApproveAllClicked = () => {
    this.setState({
      isApproveAllClicked: true,
    });
  };

  handleExpertApproval = () => {
    this.setState({
      isOkClicked: true,
    });

    // this.setState({});

    setTimeout(() => {
      const {
        expertID,
        isOkClicked,
        isNotOkClicked,
        admin_comment,
        isApproveAllClicked,
      } = this.state;
      const {expertApprovalObj} = this.props;

      let expertStatus = expertApprovalObj.service_charges_status;

      expertApprovalObj.service_charges_comment = admin_comment;

      // console.log("ok clicked", isOkClicked);

      // expertStatus = isOkClicked
      //   ? "accepted"
      //   : isNotOkClicked
      //   ? "rejected"
      //   : null;

      if (isOkClicked) {
        if (
          expertStatus === "submitted" ||
          expertStatus === "rejected" ||
          expertStatus === null
        ) {
          expertStatus = "accepted";
        }

        // console.log("Is Ok Clicked called", expertStatus);
      } else {
        // console.log("Is Ok Clicked called", isOkClicked);
      }

      if (isNotOkClicked) {
        expertStatus = "rejected";
        // console.log("Is Not Ok Clicked called", expertStatus);
      }

      expertApprovalObj.service_charges_status = expertStatus;

      if (isApproveAllClicked) {
        expertStatus = "accepted";
        expertApprovalObj.personal_info_status = expertStatus;
        expertApprovalObj.experience_status = expertStatus;
        expertApprovalObj.education_status = expertStatus;
        expertApprovalObj.service_charges_status = expertStatus;
        expertApprovalObj.skills_status = expertStatus;
        expertApprovalObj.personal_info_comment = null;
        expertApprovalObj.experience_comment = null;
        expertApprovalObj.education_comment = null;
        expertApprovalObj.service_charges_comment = null;
        expertApprovalObj.skills_comment = null;
      }

      // console.log("After Api Call", expertApprovalObj);
      // axios
      //   .post(`${apiURL}/expert/ExpertApproval`, {
      //     expert_id: expertID,
      //     expert_approve: expertApprovalObj,
      //   })

      const buffer = createBuffer({
        email,
        expert_id: expertID,
        expert_approve: expertApprovalObj,
      });
      const signature = generateSHA2(buffer, password);

      axios
        .post(
          `${apiURL}/expert/ExpertApproval`,
          {
            json_string: JSON.stringify({
              email,
              expert_id: expertID,
              expert_approve: expertApprovalObj,
            }),
          },
          {headers: {signature, email, channel: "web"}},
        )

        .then(response => {
          this.getParticularExpertDetails();
          // toast.info("Service Charges Information Approved!");
          // console.log("API res", response);
        });
    }, 0);
    // console.log("After Api Call", expertApprovalObj);
  };

  expertApproval = () => {
    const {
      expertID,
      isOkClicked,
      notokclicked,
      isNotOkClicked,
      admin_comment,
      // expertApprovalObj,
    } = this.state;
    const {expertApprovalObj} = this.props;
    const admincomment = admin_comment;
    // console.log("admin comment", admin_comment);

    let status = expertApprovalObj.service_charges_status;

    status = isOkClicked ? "accepted" : notokclicked ? "rejected" : null;

    expertApprovalObj.service_charges_status = status;

    // console.log("Expert Approval", expertApprovalObj);

    if (status === "accepted" || status === "rejected") {
      const buffer = createBuffer({
        email,
        expert_id: expertID,
        expert_approve: expertApprovalObj,
      });
      const signature = generateSHA2(buffer, password);

      axios
        .post(
          `${apiURL}/expert/ExpertApproval`,
          {
            json_string: JSON.stringify({
              email,
              expert_id: expertID,
              expert_approve: expertApprovalObj,
            }),
          },
          {headers: {signature, email, channel: "web"}},
        )

        // axios
        //   .post(`${apiURL}/expert/ExpertApproval`, {
        //     expert_id: expertID,
        //     expert_approve: expertApprovalObj,
        //   })

        .then(response => {
          // console.log(response);
        });
      // console.log("approval details", expertID);
    }
  };

  // handleOnChange = (e, cat_id, index) => {
  //   // get the intial state
  //   const {category} = this.state;
  //   // get particular education details from index
  //   const particularCategoryDetails = category[index];
  //   console.log("Education from state", particularCategoryDetails);

  //   // get the value from changed input
  //   const {value} = e.target;

  //   // get the name of the field
  //   const {name} = e.target.dataset;
  //   console.log("Data Name", name);
  //   console.log("Name and Value", name, value);

  //   // create the temporary object from updated data
  //   const tempObj = {
  //     [name]: value,
  //   };
  //   console.log("Updated data", tempObj);

  //   // update the particularEducationDetails data
  //   Object.assign(particularCategoryDetails.data, tempObj);

  //   console.log("Updated Edu Details", particularCategoryDetails);

  //   // update the intial array
  //   category[index] = particularCategoryDetails;

  //   console.log("Updated Education Details", category);
  //   this.setState({
  //     category,
  //   });
  // };

  // handleOnChange = (e, education_id, index) => {
  //   const {education, educationDet} = this.state;
  //   console.log(education);
  //   const {name, value} = e.target;
  //   const tempObj = {
  //     [name]: value,
  //   };

  //   const copyobj = Object.assign(education, tempObj);
  //   console.log(copyobj);
  //   if (education.includes === education_id) {
  //     educationDet.push(education_id[index]);
  //   }
  //   console.log("pushed Edu Details : ", educationDet);
  //   console.log("pushed edu id ", education_id[index]);
  //   this.setState({education});
  // };

  notOkClicked = () => {
    this.setState({
      isNotOkClicked: true,
    });
  };

  checkIfNoCategories = () => {
    const {category} = this.state;

    if (category.length === 1) {
      const data = category[0];

      if (
        data.cat_id === "" &&
        data.cat_name === "" &&
        data.sub_category === "" &&
        data.charges_per_hour === null &&
        data.charges_per_minute === null &&
        data.expert_id === ""
      ) {
        return true;
      }

      return false;
    }

    return false;
  };

  render() {
    const {
      category,
      isFieldEnable,
      personal_info,
      isNotOkClicked,
      isFieldActive,
    } = this.state;
    const {expertApprovalObj} = this.props;
    const counter = 1;

    console.log(category);

    return (
      <div>
        <SmartToaster store={toast} lightBackground position="top_center" />
        {this.checkIfNoCategories() ? (
          <div>No Data Available</div>
        ) : (
          <Formik
            enableReinitialize
            initialValues={{serviceChargesDetails: category}}
            // initialValues={{
            //   serviceChargesDetails: [
            //     {
            //       servicecharges_id: "dgdfhdhtrhtrj",
            //       data: {
            //         intrerest: "Computer Science",
            //         subcategory: "C++/C",
            //         chargespmin: "20",
            //         chargesphour: "40",
            //       },
            //     },
            //   ],
            // }}
            render={({values}) => (
              <Form>
                <EditRemoveButtonWrap>
                  <EditButton
                    type="button"
                    onClick={this.handleOnButtonClick}
                    // onClick={(e, cat_id) => {
                    //   this.handleOnButtonClick(e, cat_id, index);
                    // }}

                    // onClick={() => {
                    //   // this.handleOnButtonClick(index);
                    // }}
                  >
                    Edit
                  </EditButton>
                  <SaveButton
                    onClick={() => {
                      this.updateServiceChargesInfo();
                      toast.info(" Service Charges Updated!");
                    }}
                  >
                    Save
                  </SaveButton>
                </EditRemoveButtonWrap>
                <FieldArray
                  name="serviceChargesDetails"
                  render={arrayHelpers => (
                    <React.Fragment>
                      {values.serviceChargesDetails.map(
                        (serviceDetail, index) => (
                          <FullWrap key={serviceDetail.cat_id}>
                            {/* {console.log(values)} */}
                            {/* <EditButton
                            type="button"
                            // onClick={(e, cat_id) => {
                            //   this.handleOnButtonClick(e, cat_id, index);
                            // }}

                            onClick={() => {
                              this.handleOnButtonClick(index);
                            }}
                          >
                            Edit
                          </EditButton> */}

                            <TwoFieldWrap>
                              <LableInput>
                                <Label>CATEGORY</Label>
                                <FormField
                                  type="text"
                                  placeholder="Write Here.."
                                  // active={isFieldEnable ? isFieldActive : null}
                                  value={category[index].category_name}
                                  name={`serviceChargesDetails[${index}].category_name`}
                                  disabled
                                  // onChange={
                                  //   index === currentEditing
                                  //     ? e => {
                                  //         this.handleOnChange(e);
                                  //       }
                                  //     : null
                                  // }
                                  data-name="category_name"
                                  data-index={index}
                                  onChange={
                                    isFieldEnable
                                      ? e => {
                                          this.handleOnChange(e, index);
                                        }
                                      : null
                                  }
                                />
                              </LableInput>
                              {/* 
                            <LableInput>
                              <Label>INTEREST</Label>
                              <Select
                                isMulti
                                name=""
                                options={categoryNameDropDown}
                                defaultValue={category_list[0].name}
                                // value={options.find(
                                //   option =>
                                //     option.value === category[index].cat_name,
                                // )}

                                // onChange={this.handleOptionSelectedChange}
                                // onChange={
                                //   isFieldEnable
                                //     ? e => {
                                //         this.handleOnChange(e);
                                //       }
                                //     : null
                                // }

                                disabled={index !== currentEditing}
                                onChange={
                                  index === currentEditing
                                    ? (e, cat_id) =>
                                        this.handleOnChange(e, cat_id, index)
                                    : null
                                }
                                styles={{
                                  control: () => ({
                                    marginTop: "10px",
                                    display: "flex",
                                    borderRadius: "4px",
                                    border: "1px solid #e9eff4",
                                  }),
                                }}
                              />
                            </LableInput> */}

                              <LableInput>
                                <Label>SUB CATEGORY</Label>
                                <FormField
                                  type="text"
                                  placeholder="Write Here.."
                                  // active={isFieldEnable ? isFieldActive : null}
                                  value={
                                    category[index].sub_category_name_english
                                  }
                                  name={`serviceChargesDetails[${index}].sub_category_name_english`}
                                  data-name="sub_category_name_english"
                                  disabled
                                  // disabled={index !== currentEditing}
                                  // onChange={
                                  //   index === currentEditing
                                  //     ? e => {
                                  //         this.handleOnChange(e);
                                  //       }
                                  //     : null
                                  // }
                                  onChange={
                                    isFieldEnable
                                      ? e => {
                                          this.handleOnChange(e, index);
                                        }
                                      : null
                                  }
                                />
                              </LableInput>

                              {/* <LableInput>
                              <Label>SUB CATEGORY</Label>
                              <Select
                                isMulti
                                // name="sub_category"
                                options={SubCategoryValues}
                                // value={SubCategoryValues[0]}
                                // value={options.find(
                                //   option =>
                                //     option.value ===
                                //     category[index].sub_category,
                                // )}

                                // onChange={this.handleOptionSelectedChange}

                                // onChange={
                                //   isFieldEnable
                                //     ? e => {
                                //         this.handleOnChange(e);
                                //       }
                                //     : null
                                // }
                                disabled={index !== currentEditing}
                                onChange={
                                  index === currentEditing
                                    ? (e, cat_id) =>
                                        this.handleOnChange(e, cat_id, index)
                                    : null
                                }
                                styles={{
                                  control: () => ({
                                    marginTop: "10px",
                                    display: "flex",
                                    borderRadius: "4px",
                                    border: "1px solid #e9eff4",
                                  }),
                                }}
                              />
                            </LableInput> */}
                            </TwoFieldWrap>

                            <ThreeFieldWrap>
                              <LableInput>
                                <Label>CHARGE/MIN</Label>
                                <FormField
                                  type="number"
                                  placeholder="Write here..."
                                  active={isFieldEnable ? isFieldActive : null}
                                  value={category[index].charges_per_minute}
                                  name={`serviceChargesDetails[${index}].charges_per_minute`}
                                  // disabled={!index !== currentEditing}
                                  // onChange={
                                  //   index === currentEditing
                                  //     ? (e, cat_id) => {
                                  //         this.handleOnChange(e, cat_id, index);
                                  //       }
                                  //     : null
                                  // }
                                  data-name="charges_per_minute"
                                  onChange={
                                    isFieldEnable
                                      ? e => {
                                          this.handleOnChange(e, index);
                                        }
                                      : null
                                  }
                                />
                              </LableInput>

                              <LableInput>
                                <Label>CHARGE/HOUR</Label>
                                <FormField
                                  type="number"
                                  placeholder="Write here..."
                                  active={isFieldEnable ? isFieldActive : null}
                                  value={category[index].charges_per_hour}
                                  name={`serviceChargesDetails[${index}].charges_per_hour`}
                                  // disabled={!index === currentEditing}
                                  // onChange={
                                  //   index === currentEditing
                                  //     ? (e, cat_id) => {
                                  //         this.handleOnChange(e, cat_id, index);
                                  //       }
                                  //     : null
                                  // }
                                  data-name="charges_per_hour"
                                  onChange={
                                    isFieldEnable
                                      ? e => {
                                          this.handleOnChange(e, index);
                                        }
                                      : null
                                  }
                                />
                              </LableInput>
                            </ThreeFieldWrap>

                            {expertApprovalObj.service_charges_status ===
                            "submitted" ? (
                              <LableInput>
                                <Label>Status</Label>
                                <FormField
                                  type="text"
                                  placeholder="Write here..."
                                  value="Expert needs to be approved.."
                                />
                              </LableInput>
                            ) : expertApprovalObj.service_charges_status ===
                              "rejected" ? (
                              <LableInput>
                                <Label>Status</Label>
                                <FormField
                                  type="text"
                                  placeholder="Write here..."
                                  value="Expert is not approved.."
                                />
                              </LableInput>
                            ) : null}

                            {expertApprovalObj.service_charges_status ===
                            "submitted" ? (
                              <LableInput full>
                                <Label>Not Approved Reason</Label>
                                <FormField
                                  placeholder="Write here..."
                                  component="textarea"
                                  name="not_approved_reason"
                                  // active={isFieldEnable ? isFieldActive : null}
                                  value={personal_info.service_charges_comment}
                                  // onChange={
                                  //   isFieldEnable
                                  //     ? e => {
                                  //         this.handleOnChange(e);
                                  //       }
                                  //     : null
                                  // }
                                />
                              </LableInput>
                            ) : null}
                          </FullWrap>
                        ),
                      )}
                    </React.Fragment>
                  )}
                />
              </Form>
            )}
          />
        )}
        {this.checkIfNoCategories() ? null : (
          <ConfirmFullWrap>
            {expertApprovalObj.service_charges_status === "accepted" ? (
              <ApprovedMsgDiv>
                <ApprovedMsg>
                  Service Charges Info has been approved
                </ApprovedMsg>
              </ApprovedMsgDiv>
            ) : expertApprovalObj.service_charges_status === "submitted" ? (
              <ApprovedMsgDiv />
            ) : null}
            <ConfirmWrap>
              <ConfirmButton
                type="button"
                disabled={
                  expertApprovalObj.service_charges_status === "accepted"
                }
                onClick={
                  this.handleExpertApproval
                  // this.expertApproval();
                  // toast.info(" Service Charges details Approved!");
                }
              >
                <Img src={approved} />
                Approved
              </ConfirmButton>
              <ConfirmButton
                red
                notapproved
                type="button"
                onClick={this.notOkClicked}
              >
                <Img src={notapproved} />
                Not Approved
              </ConfirmButton>
              <ConfirmButton
                red
                notapproved
                type="button"
                disabled={
                  expertApprovalObj.personal_info_status === "accepted" &&
                  expertApprovalObj.experience_status === "accepted" &&
                  expertApprovalObj.education_status === "accepted" &&
                  expertApprovalObj.service_charges_status === "accepted" &&
                  expertApprovalObj.skills_status === "accepted"
                }
                onClick={() => {
                  this.onApproveAllClicked();
                  this.handleExpertApproval();
                }}
              >
                <ApproveAllImg src={Allapprove} />
                Approve All
              </ConfirmButton>
            </ConfirmWrap>
          </ConfirmFullWrap>
        )}
        {isNotOkClicked ? (
          // <ConfirmButton underline>
          //   +Add Comment
          // </ConfirmButton>
          <div>
            <CommentTextBox
              type="text"
              onChange={e => this.handleOnAdminChange(e)}
              placeholder="Add Comments Here.."
            />
            <DoneButton
              type="button"
              onClick={() => {
                this.handleExpertApproval();
                this.getParticularExpertDetails();
              }}
            >
              Done!
            </DoneButton>
          </div>
        ) : null}
      </div>
    );
  }
}
