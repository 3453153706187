import React from "react";
import styled from "styled-components";
import Select from "react-select";

const FilterOptionsWrap = styled.div`
  margin-left: 5em;
  width: 300px;
  border: 1px solid black;
  margin-top: 0em;
  position: absolute;
  background-color: white;
  /* display:flex; */
`;

const SelectWrap = styled.div`
  margin: 20px;
  width: 250px;
`;

// const MonthDropDown = styled.div`
//   border: 1px solid black;
// `;

// const Dropdownbtn = styled.button`
//   /* background: none; */
//   color: "#53bfb5";
//   padding-left: 0em;
//   font-size: 16px;
//   border: 1px solid grey;
//   height: 60px;
//   text-decoration: none;
//   text-align: right;
//   min-width: 170px;
//   max-height: 50px;
//   outline: none;
//   display: flex;
//   justify-content: flex-end;
//   align-items: center;
//   font-family: "Ubuntu";
//   font-size: 1.1em;
//   /* padding-bottom: 0.5em; */
//   /* padding-top: 0.5em; */
//   cursor: pointer;

//   @media (max-width: 900px) {
//     font-size: 0.8em;
//     height: 50px;
//   }
// `;

// const Dropdown = styled.div``;

// const DropdownContent = styled.ul`
//   align-items: center;
//   /* display: ${props => {
//     if (props.active) {
//       return "none";
//     }
//     return "block";
//   }}; */
//   background-color: none;
//   min-width: 120px;
//   max-height: 100%;
//   bottom: 610px;
//   padding-top: 0em;
//   padding-right: 1.5em;
//   border-right: "2px solid #53bfb5";
//   & :hover {
//     background-color: none;
//   }
// `;

const Months = [
  {value: "January", label: "January"},
  {value: "February", label: "February"},
  {value: "March", label: "March"},
  {value: "April", label: "April"},
  {value: "May", label: "May"},
  {value: "June", label: "June"},
  {value: "July", label: "July"},
  {value: "August", label: "August"},
  {value: "September", label: "September"},
  {value: "October", label: "October"},
  {value: "November", label: "November"},
  {value: "December", label: "December"},
];

// const Year = [{value: "2018", label: "2018"}];

const newYear = new Date();
const Year = newYear.getFullYear();
const allYear = [];
// for (let i = 2019; i <= Year; i + 1) {
//   [
//     {
//       value: "i",
//       label: "i",
//     },
//   ];
//   allYear.push();
// }

class FilterOptions extends React.Component {
  state = {};

  handleMonthChange = selectedMonthOption => {
    this.setState({selectedMonthOption});
  };

  handleYearChange = selectedYearOption => {
    this.setState({selectedYearOption});
  };

  render() {
    const {selectedMonthOption, selectedYearOption} = this.state;
    return (
      <FilterOptionsWrap>
        <SelectWrap>
          <Select
            value={selectedMonthOption}
            onChange={this.handleMonthChange}
            options={Months}
            placeholder="Select Month..."
          />
          <Select
            value={selectedYearOption}
            onChange={this.handleYearChange}
            placeholder="Select Year..."
            options={allYear}
          />
        </SelectWrap>
      </FilterOptionsWrap>
    );
  }
}

export default FilterOptions;
