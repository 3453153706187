import React from "react";
import styled from "styled-components";
import axios from "axios";
import NavigationBar from "../NavigationBar/NavigationBar";
import SideBar from "../NavigationBar/SideBar";
import Ecards from "./Ecards";
import FullForm from "../Common/FullForm";
import ExpertSideBar from "./ExpertSideBar";
import ExperienceForm from "./ExperienceForm";
import ServiceChargesForm from "./ServiceChargesForm";
import SkillsForm from "./SkillsForm";
import EducationForm from "./EducationForm";
import ExpertReactTable from "./ExpertReactTable";
import FinancialForm from "./FinancialForm";
import ReviewsAndRatings from "./ReviewsAndRatings";

import NotAccessiblePNG from "../Common/NotAccessiblePNG.png";

// api URL
import {apiURL, createBuffer, generateSHA2} from "../../utils/util";
import moment from "moment";

const FullSection = styled.section`
  background-color: #fafbfc;
  display: grid;
  grid-template-rows: 1fr auto;
  max-height: 100%;

  /* grid-template-columns: auto auto; */
`;
const LabelInputWrap = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 4.5em;
  margin-left: 4.5em;
  margin-top: 0.5em;
`;

const ExpertListLabel = styled.h2`
  font-size: 17px;
  color: #1a173b;
  /* margin-left: 23px; */
`;

const PageOneContentWrap = styled.div``;
const PageTwoContentWrap = styled.div``;
const PageTwoContent = styled.div`
  display: flex;
  border-radius: 3.8px;
  border: solid 1px #ebedf8;
  background-color: #ffffff;
  margin: 0.5em 0 2em 6em;
  height: 100%;
`;

const TitleLabel = styled.label`
  font-size: 15px;
  padding-right: 10px;
`;

const FullCardWrap = styled.div`
  display: flex;
  margin-left: 70px;
  justify-content: space-between;
  max-width: 750px;
`;
const CardTitleWrap = styled.div`
  padding-top: 20px;
`;

const CardContentWrap = styled.div`
  /* padding-top: 20px;
  padding-left: 20px; */
  padding: 10px 20px 70px 10px;
`;
const LableWrap = styled.div`
  padding-top: ${props => (props.padding ? "10" : "")}px;
  /* padding: 10px 20px 50px 10px; */
`;
const NumberLabel = styled.label`
  /* padding-left: 10px; */
  padding-right: 5px;
  font-size: 20px;
  color: #4da1ff;
`;
const NumberLabelReview = styled(NumberLabel)`
  color: #ff6d4a;
`;

const ActiveExpertsList = styled.li`
  list-style: none;
  color: #417505;
`;

const NotAccessibleDiv = styled.div`
  /* margin-left: 100px; */
  /* margin-top: 100px; */
  padding-top: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  height: 100%;
  width: 100%;
`;

const NotAccessibleImg = styled.img``;

const NotAccessibleLabel = styled.h2``;

const email = window.sessionStorage.getItem("email");
const password = window.sessionStorage.getItem("token");

// const docid = {
//   docid: this.state.docid,
// };
class Experts extends React.Component {
  state = {
    loading: true,
    isPageLoad: true,
    isOptionClicked: false,
    expertsList: [],
    particularExpertDetails: [],
    expertAnalytics: {
      expert: [],
    },
    typeofuser: ["superadmin", "superreader", "approvalmanager"],
    isAuthourized: false,
    expertID: sessionStorage.getItem("expertID"),
    isPersonalDetailsClicked: true,
    isEducationDetailsClicked: false,
    isServiceChargesDetailsClicked: false,
    isSkillsDetailsClicked: false,
    isExperienceDetailsClicked: false,
    isFinancialDetailsClicked: false,
    isReviewsAndRatingsClicked: false,

    isPersonalDetailsActive: true,
    isEducationDetailsActive: false,
    isServiceChargesDetailsActive: false,
    isSkillsDetailsActive: false,
    isExperienceDetailsActive: false,
    isFinancialDetailsActive: false,
    isReviewsAndRatingsActive: false,

    expertApprovalList: [],

    expertApprovalObj: {
      personal_info_status: null,
      personal_info_comment: null,
      experience_status: null,
      experience_comment: null,
      education_status: null,
      education_comment: null,
      service_charges_status: null,
      service_charges_comment: null,
      skills_status: null,
      skills_comment: null,
      expert_id: "",
    },
  };

  // lifecycle methods

  componentDidMount() {
    this.userData();
    this.getExpertAnalyticsData();
    this.getExpertDetails();
    this.getExpertStatus();
  }

  // api calls

  getExpertDetails = () => {
    console.log("fetching experts");
    const buffer = createBuffer({email});
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/expert/getExpertList`,
        {
          json_string: JSON.stringify({email}),
        },
        {headers: {signature, email, channel: "web"}},
      )

      // .post(`${apiURL}/expert/getExpertList`, {})

      .then(response => {
        // console.log(response);

        if (response.status === 200 && response.data) {
          // console.log(response.data);
          let {data} = response;
          data.map(item => {
            item.registration_date = moment(item.created_at).format(
              "DD-MM-YYYY",
            );
          });
          this.setState({expertsList: data, loading: false});
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };

  getExpertAnalyticsData = async () => {
    const buffer = createBuffer({email});
    const signature = generateSHA2(buffer, password);

    await axios
      .post(
        `${apiURL}/expert/ExpertAnalyticsData`,
        {
          json_string: JSON.stringify({email}),
        },
        {headers: {signature, email, channel: "web"}},
      )

      // .post(`${apiURL}/expert/ExpertAnalyticsData`, {})

      .then(response => {
        // console.log(response);

        if (response.status === 200 && response.data) {
          // console.log(response.data);
          const {data} = response;
          // console.log("analytics", data);
          this.setState({expertAnalytics: data.content, loading: false});
          // console.log("expertssss", data.content.expert);
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };

  getExpertStatus = async () => {
    const buffer = createBuffer({email});
    const signature = generateSHA2(buffer, password);

    await axios
      .post(
        `${apiURL}/expert/GetAllExpertStatus`,
        {
          json_string: JSON.stringify({email}),
        },
        {headers: {signature, email, channel: "web"}},
      )

      // .post(`${apiURL}/expert/GetAllExpertStatus`, {})

      .then(response => {
        // console.log(response);

        if (response.status === 200 && response.data) {
          // console.log(response.data);
          const {data} = response;
          // console.log("analytics", data);
          this.setState({expertApprovalList: data.content});
          // console.log("expert status", data.content);
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };

  // below api Not To be included

  // getParticularExpertDetails = () => {
  //   const {expertID} = this.state;
  //   axios
  //     .post(`${apiURL}/expert/getParticularExpert`, {
  //       docid: expertID,
  //     })
  //     // .post(`${apiURL}/expert/getParticularExpert`, {}, {params: {}})
  //     .then(response => {
  //       console.log(response);
  //       if (response.status === 200 && response.data) {
  //         const {particularexpertdata} = response;
  //         this.setState({particularExpertDetails: particularexpertdata});
  //       }
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // };

  // event handling methods

  handleReview = event => {
    let {expertApprovalList, expertApprovalObj} = this.state;
    // console.log(event.target.dataset.expertid);
    // console.log("event target", event.target);
    const expertId = event.target.dataset.expertid;

    expertApprovalObj = expertApprovalList.filter(
      item => item.expert_id === expertId,
    )[0];
    // console.log("eexpertApprovalObjx", expertApprovalObj);
    sessionStorage.setItem("expertID", expertId);

    this.setState({
      expertApprovalObj,
      isPageLoad: false,
    });
  };

  optionClicked = () => {
    this.setState({
      isOptionClicked: true,
    });
  };

  personalDetailsClicked = () => {
    this.setState({
      isPersonalDetailsClicked: true,
      isPersonalDetailsActive: true,
      isEducationDetailsActive: false,
      isServiceChargesDetailsActive: false,
      isSkillsDetailsActive: false,
      isExperienceDetailsActive: false,
    });
  };

  educationDetailsClicked = () => {
    // console.log(event.target);
    // console.log("Education Details button clicked");
    this.setState({
      isPersonalDetailsClicked: false,
      isEducationDetailsClicked: true,
      isPersonalDetailsActive: false,
      isEducationDetailsActive: true,
      isServiceChargesDetailsActive: false,
      isSkillsDetailsActive: false,
      isExperienceDetailsActive: false,
      isFinancialDetailsActive: false,
      isReviewsAndRatingsActive: false,
    });
  };

  serviceChargesDetailsClicked = () => {
    this.setState({
      isPersonalDetailsClicked: false,
      isEducationDetailsClicked: false,
      isServiceChargesDetailsClicked: true,

      isPersonalDetailsActive: false,
      isEducationDetailsActive: false,
      isServiceChargesDetailsActive: true,
      isSkillsDetailsActive: false,
      isExperienceDetailsActive: false,
      isFinancialDetailsActive: false,
      isReviewsAndRatingsActive: false,
    });
  };

  skillsDetailsClicked = () => {
    this.setState({
      isPersonalDetailsClicked: false,
      isEducationDetailsClicked: false,
      isServiceChargesDetailsClicked: false,
      isSkillsDetailsClicked: true,

      isPersonalDetailsActive: false,
      isEducationDetailsActive: false,
      isServiceChargesDetailsActive: false,
      isSkillsDetailsActive: true,
      isExperienceDetailsActive: false,
      isFinancialDetailsActive: false,
      isReviewsAndRatingsActive: false,
    });
  };

  experienceDetailsClicked = () => {
    this.setState({
      isPersonalDetailsClicked: false,
      isEducationDetailsClicked: false,
      isServiceChargesDetailsClicked: false,
      isSkillsDetailsClicked: false,
      isExperienceDetailsClicked: true,

      isPersonalDetailsActive: false,
      isEducationDetailsActive: false,
      isServiceChargesDetailsActive: false,
      isSkillsDetailsActive: false,
      isExperienceDetailsActive: true,
      isFinancialDetailsActive: false,
      isReviewsAndRatingsActive: false,
    });
  };

  financialDetailsClicked = () => {
    this.setState({
      isPersonalDetailsClicked: false,
      isEducationDetailsClicked: false,
      isServiceChargesDetailsClicked: false,
      isSkillsDetailsClicked: false,
      isExperienceDetailsClicked: false,
      isFinancialDetailsClicked: true,

      isPersonalDetailsActive: false,
      isEducationDetailsActive: false,
      isServiceChargesDetailsActive: false,
      isSkillsDetailsActive: false,
      isExperienceDetailsActive: false,
      isFinancialDetailsActive: true,
      isReviewsAndRatingsActive: false,
    });
  };

  reviewsAndRatingsDetailsClicked = () => {
    this.setState({
      isPersonalDetailsClicked: false,
      isEducationDetailsClicked: false,
      isServiceChargesDetailsClicked: false,
      isSkillsDetailsClicked: false,
      isExperienceDetailsClicked: false,
      isFinancialDetailsClicked: false,
      isReviewsAndRatingsClicked: true,

      isPersonalDetailsActive: false,
      isEducationDetailsActive: false,
      isServiceChargesDetailsActive: false,
      isSkillsDetailsActive: false,
      isExperienceDetailsActive: false,
      isFinancialDetailsActive: false,
      isReviewsAndRatingsActive: true,
    });
  };

  backToExpertClicked = () => {
    // isBackToExpertListClicked: true,
    // browserHistory.push("/experts");
  };

  userData = () => {
    let {isAuthourized, typeofuser} = this.state;
    const role = sessionStorage.getItem("typeofuser");
    isAuthourized = typeofuser.includes(role);
    this.setState({isAuthourized});
  };

  render() {
    const {match} = this.props;
    // console.log("Expert Path", match.path);
    // console.log("Expert Props", this.props);
    // console.log("Param ID", match.params.id);
    const {
      isPageLoad,
      isOptionClicked,
      expertsList,
      expertID,
      isAuthourized,
      particularExpertDetails,
      isPersonalDetailsClicked,
      isEducationDetailsClicked,
      isServiceChargesDetailsClicked,
      isSkillsDetailsClicked,
      isExperienceDetailsClicked,
      isFinancialDetailsClicked,
      isReviewsAndRatingsClicked,
      isPersonalDetailsActive,
      isEducationDetailsActive,
      isServiceChargesDetailsActive,
      isSkillsDetailsActive,
      isExperienceDetailsActive,
      isFinancialDetailsActive,
      isReviewsAndRatingsActive,
      // isBackToExpertListClicked,
      // typeofuser,
      expertApprovalObj,
      loading,
      expertAnalytics,
      expertApprovalList,
    } = this.state;
    const role = sessionStorage.getItem("typeofuser");
    // const {isPersonalInfoClicked, isEducationInfoClicked} = this.props;

    return (
      <div>
        <FullSection>
          {/* {console.log("type of user", sessionStorage.getItem("typeofuser"))} */}

          <NavigationBar />
          <SideBar />

          {isAuthourized ? (
            <div>
              {isPageLoad ? (
                <PageOneContentWrap>
                  <LabelInputWrap>
                    <ExpertListLabel>Experts Data Management</ExpertListLabel>
                    {/* <AddExpert>Add Expert</AddExpert> */}
                  </LabelInputWrap>
                  <FullCardWrap>
                    <Ecards>
                      {/* <CardContentWrap>
           <TitleLabel>Number of experts in Total</TitleLabel>
           <FilterOptionButton>
             <FilterOption
               src={optionfilter}
               onClick={this.optionClicked}
             />
           </FilterOptionButton>
           {isOptionClicked ? <FilterOptions /> : null}
           <LableWrap>
             <NumberLabel>200</NumberLabel>
             <MonthLabel>This month</MonthLabel>
           </LableWrap>
         </CardContentWrap> */}
                      <CardContentWrap>
                        <TitleLabel>
                          <LableWrap>
                            <NumberLabel>
                              {expertAnalytics.total_expert_registered}
                            </NumberLabel>
                          </LableWrap>
                          Experts registered this Month
                          {/* <NumberLabel>200</NumberLabel> */}
                          {/* <MonthLabel>June</MonthLabel>
             <YearLabel>2018</YearLabel> */}
                        </TitleLabel>
                        {/* <FilterOptionButton>
             <FilterOption
               src={optionfilter}
               onClick={this.optionClicked}
             />
           </FilterOptionButton> */}
                      </CardContentWrap>
                    </Ecards>

                    <Ecards>
                      <CardContentWrap>
                        <TitleLabel>
                          <LableWrap>
                            <NumberLabelReview>
                              {expertAnalytics.total_expert_to_be_review}
                            </NumberLabelReview>
                          </LableWrap>
                          Experts to be reviewed this Month
                          {/* <NumberLabel>200</NumberLabel> */}
                          {/* <MonthLabel>June</MonthLabel>
             <YearLabel>2018</YearLabel> */}
                        </TitleLabel>
                        {/* <FilterOptionButton>
             <FilterOption
               src={optionfilter}
               onClick={this.optionClicked}
             />
           </FilterOptionButton> */}
                      </CardContentWrap>
                    </Ecards>

                    {/* <Ecards>
         <CardContentWrap>
           <LableWrap>
             <NumberLabelReview>10</NumberLabelReview>
           </LableWrap>
           <TitleLabel>Number of experts to review</TitleLabel>
           <FilterOptionButton>
             <FilterOption
               src={optionfilter}
               onClick={this.optionClicked}
             />
           </FilterOptionButton>
           <LableWrap>
             <NumberLabelReview>10</NumberLabelReview>
             <MonthLabel>This month</MonthLabel>
           </LableWrap>
         </CardContentWrap>
       </Ecards> */}
                    <Ecards>
                      <CardContentWrap>
                        <CardTitleWrap>
                          <TitleLabel>Hightest number of </TitleLabel>
                          <LableWrap>
                            <TitleLabel>Completed chats by Experts</TitleLabel>
                          </LableWrap>
                        </CardTitleWrap>
                        <LableWrap padding>
                          {/* {console.log("Exprt", expertAnalytics.expert)} */}
                          {expertAnalytics.expert.map((item, i) => {
                            return (
                              <ActiveExpertsList key={i}>
                                {item}
                              </ActiveExpertsList>
                            );
                          })}
                          {/* {expertAnalytics.expert.map((item, i) => (
                            <ActiveExpertsList key={i} />
                          ))} */}

                          {/* <ActiveExpertsList>Salma khatib</ActiveExpertsList> */}
                          {/* <ActiveExpertsList>
                            Salim Abed al Kader
                          </ActiveExpertsList> */}
                        </LableWrap>
                      </CardContentWrap>
                    </Ecards>
                  </FullCardWrap>
                  {/* <ExpertTable
       handlereview={this.handleReview}
       expertsList={expertsList}
     /> */}
                  <ExpertReactTable
                    handlereview={this.handleReview}
                    expertsList={expertsList}
                    getExperts={this.getExpertDetails}
                    expertApprovalList={expertApprovalList}
                    loading={loading}
                    expertApprovalObj={expertApprovalObj}
                  />
                </PageOneContentWrap>
              ) : (
                <PageTwoContentWrap>
                  <LabelInputWrap>
                    <ExpertListLabel>Expert Review</ExpertListLabel>
                    {/* 
       <BacktoExpertButton onClick={this.backToExpertClicked}>
         Back To Expert List
       </BacktoExpertButton>
       {isBackToExpertListClicked ? <Experts /> : null} */}
                  </LabelInputWrap>
                  <PageTwoContent>
                    <ExpertSideBar
                      personalInfoClicked={this.personalDetailsClicked}
                      isPersonalInfoActive={isPersonalDetailsActive}
                      // educationInfoClicked={this.educationDetailsClicked}
                      // isEducationInfoActive={isEducationDetailsActive}
                      serviceChargesInfoClicked={
                        this.serviceChargesDetailsClicked
                      }
                      isServiceChargesInfoActive={isServiceChargesDetailsActive}
                      // skillsInfoClicked={this.skillsDetailsClicked}
                      // isSkillsInfoActive={isSkillsDetailsActive}
                      experienceInfoClicked={this.experienceDetailsClicked}
                      isExperienceInfoActive={isExperienceDetailsActive}
                      financialInfoClicked={this.financialDetailsClicked}
                      isfinancialInfoActive={isFinancialDetailsActive}
                      reviewsAndratingsClicked={
                        this.reviewsAndRatingsDetailsClicked
                      }
                      isreviewsAndratingsActive={isReviewsAndRatingsActive}
                    />
                    {/* if(isPersonalDetailsClicked)
       {<FullForm onClick={this.personalDetailsClicked} />}
       else if(isEducationDetailsClicked)
       {
         <QualificationDegreeForm
           onClick={this.educationDetailsClicked}
         />
       } */}

                    {isPersonalDetailsClicked ? (
                      <FullForm
                        // particularExpertDetails={particularExpertDetails}
                        expertApprovalObj={expertApprovalObj}
                      />
                    ) : isEducationDetailsClicked ? (
                      // <QualificationDegreeForm
                      //   particularExpertDetails={particularExpertDetails}
                      // />
                      <EducationForm expertApprovalObj={expertApprovalObj} />
                    ) : isServiceChargesDetailsClicked ? (
                      <ServiceChargesForm
                        expertApprovalObj={expertApprovalObj}
                      />
                    ) : isSkillsDetailsClicked ? (
                      <SkillsForm expertApprovalObj={expertApprovalObj} />
                    ) : isExperienceDetailsClicked ? (
                      <ExperienceForm expertApprovalObj={expertApprovalObj} />
                    ) : isFinancialDetailsClicked ? (
                      <FinancialForm />
                    ) : isReviewsAndRatingsClicked ? (
                      <ReviewsAndRatings />
                    ) : null}
                  </PageTwoContent>
                </PageTwoContentWrap>
              )}
            </div>
          ) : (
            // "Not Accessible"
            <NotAccessibleDiv>
              <NotAccessibleLabel>
                Sorry you’re a{" "}
                {role === "financialmanager"
                  ? "Financial Manager"
                  : role === "contentmanager"
                  ? "Content Manager"
                  : null}
                . Please contact the Super Admin to access this area.
              </NotAccessibleLabel>
              <NotAccessibleImg alt="Not accessible" src={NotAccessiblePNG} />
            </NotAccessibleDiv>
          )}
        </FullSection>
      </div>
    );
  }
}

export default Experts;
