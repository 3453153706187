import React from "react";
import styled from "styled-components";
import axios from "axios";
import {withFormik, Form, Field} from "formik";
import Select from "react-select";
import makeAnimated from "react-select/lib/animated";
import {SmartToaster, toast} from "react-smart-toaster";
import approved from "../Common/approvedbtn.png";
import notapproved from "../Common/notapprovedbtn.png";
import Allapprove from "../Common/Allapprove.svg";

import {apiURL, createBuffer, generateSHA2} from "../../utils/util";

const FullWrap = styled.div`
  width: 900px;
  /* border: 1px solid black; */
  display: flex;
  flex-wrap: wrap;
  margin-top: 2em;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.23);
  background-color: #ffffff;
  margin-left: 1em;
  border-radius: 6px;
`;

const EditRemoveButtonWrap = styled.div`
  width: 100px;
  display: flex;
  justify-content: space-between;
  margin-left: 800px;
  margin-top: 20px;
`;

const SaveButton = styled.button`
  border: none;
  color: #39a1dd;
  font-size: 20px;
  cursor: pointer;
  outline: 0;
`;

const EditButton = styled.button`
  border: none;
  color: #d0021b;
  font-size: 20px;
  cursor: pointer;
  outline: 0;
`;

const LableInput = styled.div`
  margin: 20px;
  width: ${props => (props.full ? 640 : 300)}px;
`;

const Label = styled.label`
  font-size: 12px;
`;

const ApproveAllImg = styled.img`
  padding-right: 1em;
  height: 35px;
`;

const Img = styled.img`
  padding-right: 1em;
`;

const ConfirmWrap = styled.div`
  display: flex;
  margin: 20px;
  max-width: 700px;
  justify-content: space-between;
`;
const ConfirmButton = styled.button`
  background-color: #ffffff;
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 17px;
  /* width: 150px; */
  cursor: pointer;
  width: ${props => (props.notapproved ? 170 : 150)}px;
  margin-right: 20px;
  height: 40px;
  text-decoration: ${props => (props.underline ? "underline" : "")};
  color: ${props => (props.underline ? "#39a1dd" : "")};
`;

// const EditButton = styled.button`
//   margin-left: 500px;
//   margin-top: 20px;
//   width: 100px;
//   height: 40px;
//   border-radius: 5px;
//   background-color: #085db0;
//   color: white;
//   font-size: large;
// `;
const FormWrap = styled.div`
  width: 900px;
  display: flex;
  flex-wrap: wrap;
`;

const DoneButton = styled.button`
  width: 100px;
  height: 40px;
  border-radius: 5px;
  background-color: #085db0;
  color: white;
  cursor: pointer;
  margin-left: 20px;
`;

const CommentTextBox = styled.textarea`
  /* width: 450px; */
  width: 100%;
  margin: 20px;
  height: 100px;
  font-size: 15px;
  /* margin-left: 120px; */
`;

const FormField = styled(Field)`
  margin-top: 10px;
  position: ${props => (props.absolute ? "absolute" : "")};
  width: 100%;
  // border: solid 1px #e9eff4;
  border: ${props => (props.active ? "1px solid #d0d0d0" : "none")};
  text-indent: 15px;
  padding: 10px;
  font-size: 17px;
  border-radius: 4px;
  background-color: #ffffff;
  opacity: 0.5;
  ::-webkit-input-placeholder {
    font-family: Ubuntu;
    /* font-size: 20px; */
    font-weight: 300;
    color: #0d3868;
    opacity: 0.5;
    padding: 15px;
    /* text-align: left; */
  }
`;

const ApprovedMsgDiv = styled.div`
  margin-left: 20px;
`;

const ApprovedMsg = styled.label`
  color: #39a1dd;
`;

const ConfirmFullWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

const email = window.sessionStorage.getItem("email");
const password = window.sessionStorage.getItem("token");

class SkillsForm extends React.Component {
  state = {
    isNotOkClicked: false,
    expertID: sessionStorage.getItem("expertID"),
    personal_info: {
      getSkillList: [],
    },
    updatedArraySkill: [],
    mainDropdownData: [],
    skills: [],
    admin_comment: "",
    isOkClicked: false,
    notokclicked: false,
    isApproveAllClicked: false,
    isFieldEnable: false,
  };

  componentDidMount() {
    this.getParticularExpertDetails();
  }

  handleOnButtonClick = () => {
    this.setState({isFieldEnable: true, isFieldActive: true});
  };

  handleOptionSelectedChange = updatedArraySkill => {
    this.setState({updatedArraySkill});
  };

  getParticularExpertDetails = () => {
    const {expertID, personal_info} = this.state;

    const buffer = createBuffer({
      email,
      docid: expertID,
    });
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/expert/getParticularExpert`,
        {
          json_string: JSON.stringify({
            email,
            docid: expertID,
          }),
        },
        {headers: {signature, email, channel: "web"}},
      )
      // axios
      //   .post(`${apiURL}/expert/getParticularExpert`, {
      //     docid: expertID,
      //   })
      // .post(`${apiURL}/expert/getParticularExpert`, {})
      // .post(`${apiURL}/expert/getParticularExpert`, {}, {params: {}})
      .then(response => {
        // console.log(response);
        if (response.status === 200 && response.data) {
          const {data} = response;
          // console.log("skills", data.personal_info.skills);
          const tempObj = {
            getSkillList: data.personal_info.skills,
            personal_info: data.personal_info,
          };
          // console.log("tempobj", tempObj);
          const tempassign = Object.assign(personal_info, tempObj);
          // console.log("tempassogn", tempassign);
          //  const personal_info = Object.assign(personal_info, tempObj);
          // console.log("skill list", data.personal_info.skills);
          this.setState({personal_info, isNotOkClicked: false});
          this.skillsDetails();
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };

  updateSkillsInfo = () => {
    const {
      personal_info,
      expertID,
      updatedArraySkill,
      mainDropdownData,
    } = this.state;
    // console.log("Updated Skils", personal_info);
    // console.log(typeof category);

    const newUpdatedArray = updatedArraySkill.map(item => item.value);

    // console.log("new updated", newUpdatedArray);

    // axios
    //   .post(`${apiURL}/expert/UpdateSkills`, {
    //     skills: newUpdatedArray,
    //     expert_id: expertID,
    //   })

    const buffer = createBuffer({
      email,
      skills: newUpdatedArray,
      expert_id: expertID,
    });
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/expert/UpdateSkills`,
        {
          json_string: JSON.stringify({
            email,
            skills: newUpdatedArray,
            expert_id: expertID,
          }),
        },
        {headers: {signature, email, channel: "web"}},
      )

      .then(response => {
        // console.log(response);
        // alert("Data Updated Successfully.....");
        this.getParticularExpertDetails();
      })

      .catch(error => {
        // console.log(error);
      });
  };

  onOkClicked = () => {
    this.setState({
      isOkClicked: true,
    });
  };

  onNotOkClicked = () => {
    this.setState({
      notokclicked: true,
    });
  };

  handleOnAdminChange = e => {
    this.setState({admin_comment: e.target.value});
  };

  onApproveAllClicked = () => {
    this.setState({
      isApproveAllClicked: true,
    });
  };

  handleExpertApproval = () => {
    this.setState({
      isOkClicked: true,
    });

    // this.setState({});

    setTimeout(() => {
      const {
        expertID,
        isOkClicked,
        isNotOkClicked,
        admin_comment,
        isApproveAllClicked,
      } = this.state;
      const {expertApprovalObj} = this.props;

      let expertStatus = expertApprovalObj.skills_status;
      expertApprovalObj.skills_comment = admin_comment;

      // console.log("ok clicked", isOkClicked);

      // expertStatus = isOkClicked
      //   ? "accepted"
      //   : isNotOkClicked
      //   ? "rejected"
      //   : null;

      if (isOkClicked) {
        if (
          expertStatus === "submitted" ||
          expertStatus === "rejected" ||
          expertStatus === null
        ) {
          expertStatus = "accepted";
        }

        // console.log("Is Ok Clicked called", expertStatus);
      } else {
        // console.log("Is Ok Clicked called", isOkClicked);
      }

      if (isNotOkClicked) {
        expertStatus = "rejected";
        // console.log("Is Not Ok Clicked called", expertStatus);
      }

      expertApprovalObj.skills_status = expertStatus;

      if (isApproveAllClicked) {
        expertStatus = "accepted";
        expertApprovalObj.personal_info_status = expertStatus;
        expertApprovalObj.experience_status = expertStatus;
        expertApprovalObj.education_status = expertStatus;
        expertApprovalObj.service_charges_status = expertStatus;
        expertApprovalObj.skills_status = expertStatus;
        expertApprovalObj.personal_info_comment = null;
        expertApprovalObj.experience_comment = null;
        expertApprovalObj.education_comment = null;
        expertApprovalObj.service_charges_comment = null;
        expertApprovalObj.skills_comment = null;
      }

      // console.log("After Api Call", expertApprovalObj);
      // axios
      //   .post(`${apiURL}/expert/ExpertApproval`, {
      //     expert_id: expertID,
      //     expert_approve: expertApprovalObj,
      //   })

      const buffer = createBuffer({
        email,
        expert_id: expertID,
        expert_approve: expertApprovalObj,
      });
      const signature = generateSHA2(buffer, password);

      axios
        .post(
          `${apiURL}/expert/ExpertApproval`,
          {
            json_string: JSON.stringify({
              email,
              expert_id: expertID,
              expert_approve: expertApprovalObj,
            }),
          },
          {headers: {signature, email, channel: "web"}},
        )

        .then(response => {
          this.getParticularExpertDetails();
          // toast.info("Education Information Approved!");
          // console.log(response);
        });
    }, 0);
    // console.log("After Api Call", expertApprovalObj);
  };

  expertApproval = () => {
    const {
      expertID,

      isOkClicked,
      notokclicked,
      isNotOkClicked,
      admin_comment,
    } = this.state;

    // console.log("admin comment", admin_comment);
    const status = isOkClicked
      ? "accepted"
      : notokclicked
      ? "rejected"
      : "null";
    if (status === "accepted" || status === "rejected") {
      const buffer = createBuffer({
        email,
        expert_id: expertID,
        status,
        admin_comment,
        label: "skills",
      });
      const signature = generateSHA2(buffer, password);

      axios
        .post(
          `${apiURL}/expert/ExpertApproval`,
          {
            json_string: JSON.stringify({
              email,
              expert_id: expertID,
              status,
              admin_comment,
              label: "skills",
            }),
          },
          {headers: {signature, email, channel: "web"}},
        )
        // axios
        //   .post(`${apiURL}/expert/ExpertApproval`, {
        //     expert_id: expertID,
        //     status,
        //     admin_comment,
        //     label: "skills",
        //   })

        .then(response => {
          // console.log(response);
        });
      // console.log("approval details", expertID, status, admin_comment);
    }
  };

  // getSkillDetails = () => {
  //   axios
  //     .post(`${apiURL}/aboutshawer/GetExpertProfile`, {})

  //     .then(response => {
  //       if (response.status === 200 && response.data) {
  //         const {data} = response;
  //         console.log(data);
  //         console.log("skillssssssssssss", data.content.list_skills);
  //         this.setState({
  //           skills: data.content.list_skills,
  //         });
  //       }
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // };

  skillsDetails = () => {
    const {personal_info, getSkillList, skills, mainDropdownData} = this.state;

    // console.log("dsfds", personal_info.getSkillList);
    const updatedArraySkill = personal_info.getSkillList.map(i => ({
      label: i,
      value: i,
    }));
    // console.log("updatedArraySkill", updatedArraySkill);
    this.setState({
      updatedArraySkill,
      mainDropdownData: updatedArraySkill,
    });

    // console.log("Updated array skills", mainDropdownData);
  };

  notOkClicked = () => {
    this.setState({
      isNotOkClicked: true,
    });
  };

  render() {
    const {
      getSkillList,
      personal_info,
      isNotOkClicked,
      updatedArraySkill,
      mainDropdownData,
      isFieldEnable,
      isFieldActive,
    } = this.state;
    const {expertApprovalObj} = this.props;
    return (
      <Form>
        <SmartToaster store={toast} lightBackground position="top_center" />
        <EditRemoveButtonWrap>
          <SaveButton
            onClick={() => {
              this.handleOnButtonClick();
            }}
          >
            Edit
          </SaveButton>

          <EditButton
            onClick={() => {
              this.updateSkillsInfo();
              // toast.info("Personal Information Updated!");
            }}
          >
            Save
          </EditButton>
        </EditRemoveButtonWrap>
        <FullWrap>
          {/* <EditButton>Edit</EditButton> */}
          <FormWrap>
            <LableInput full>
              <Label>Skills</Label>
              {/* {console.log([...updatedArraySkill])} */}
              <Select
                closeMenuOnSelect={false}
                components={makeAnimated()}
                isMulti
                options={mainDropdownData}
                value={updatedArraySkill}
                // value={options.find(
                //   option => option.value === getSkillList.skills,
                // )}
                // onChange={() => {
                //   this.setState({
                //     updatedArraySkill,
                //   });
                // }}
                onChange={
                  isFieldEnable
                    ? e => {
                        this.handleOptionSelectedChange(e);
                      }
                    : null
                }
              />
            </LableInput>
            {expertApprovalObj.skills_status === "submitted" ? (
              <LableInput full>
                <Label>Not Approved Reason</Label>
                <FormField
                  active={isFieldEnable ? isFieldActive : null}
                  placeholder="Write here..."
                  component="textarea"
                  name="not_approved_reason"
                  // active={isFieldEnable ? isFieldActive : null}
                  value={personal_info.skills_comment}
                  onChange={
                    isFieldEnable
                      ? e => {
                          this.handleOnChange(e);
                        }
                      : null
                  }
                />
              </LableInput>
            ) : null}

            <ConfirmFullWrap>
              {expertApprovalObj.skills_status === "submitted" ? (
                <LableInput>
                  <Label>Status</Label>
                  <FormField
                    type="text"
                    placeholder="Write here..."
                    value="Expert needs to be approved.."
                  />
                </LableInput>
              ) : expertApprovalObj.skills_status === "rejected" ? (
                <LableInput>
                  <Label>Status</Label>
                  <FormField
                    type="text"
                    placeholder="Write here..."
                    value="Expert is not approved.."
                  />
                </LableInput>
              ) : null}

              {expertApprovalObj.skills_status === "accepted" ? (
                <ApprovedMsgDiv>
                  <ApprovedMsg>Skills Info has been approved</ApprovedMsg>
                </ApprovedMsgDiv>
              ) : null}

              <ConfirmWrap>
                <ConfirmButton
                  type="button"
                  disabled={expertApprovalObj.skills_status === "accepted"}
                  onClick={
                    this.handleExpertApproval
                    // toast.info(" Skills details Approved!");
                  }
                >
                  <Img src={approved} />
                  Approved
                </ConfirmButton>
                <ConfirmButton
                  red
                  notapproved
                  type="button"
                  onClick={this.notOkClicked}
                >
                  <Img src={notapproved} />
                  Not Approved
                </ConfirmButton>
                <ConfirmButton
                  red
                  notapproved
                  type="button"
                  disabled={
                    expertApprovalObj.personal_info_status === "accepted" &&
                    expertApprovalObj.experience_status === "accepted" &&
                    expertApprovalObj.education_status === "accepted" &&
                    expertApprovalObj.service_charges_status === "accepted" &&
                    expertApprovalObj.skills_status === "accepted"
                  }
                  onClick={() => {
                    this.onApproveAllClicked();
                    this.handleExpertApproval();
                  }}
                >
                  <ApproveAllImg src={Allapprove} />
                  Approve All
                </ConfirmButton>
              </ConfirmWrap>
            </ConfirmFullWrap>
            {isNotOkClicked ? (
              // <ConfirmButton underline>
              //   +Add Comment
              // </ConfirmButton>
              <div>
                <CommentTextBox
                  type="text"
                  onChange={e => this.handleOnAdminChange(e)}
                  placeholder="Add Comments Here.."
                />
                <DoneButton
                  type="button"
                  onClick={() => {
                    this.handleExpertApproval();
                    this.getParticularExpertDetails();
                  }}
                >
                  Done!
                </DoneButton>
              </div>
            ) : null}
          </FormWrap>
        </FullWrap>
      </Form>
    );
  }
}
const FormikFullForm = withFormik({})(SkillsForm);

export default FormikFullForm;
