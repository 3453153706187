import React from "react";
import styled from "styled-components";
import axios from "axios";
import Select from "react-select";
import {SmartToaster, toast} from "react-smart-toaster";

import Modal from "react-modal";
import NavigationBar from "../NavigationBar/NavigationBar";
import SideBar from "../NavigationBar/SideBar";
import closebtn from "../AboutShawer/closebtn.png";
import AdminSettingsReactTable from "./AdminSettingsReactTable";
import NotAccessiblePNG from "../Common/NotAccessiblePNG.png";

// api URL
import {apiURL, generateSHA2, createBuffer, Spinner} from "../../utils/util";

const FullSection = styled.section`
  background-color: #fafbfc;
  display: grid;
  grid-template-rows: 1fr auto;
  max-height: 100%;

  /* grid-template-columns: auto auto; */
`;
const LabelInputWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 4.5em;
  margin-left: 4.5em;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  width: 1050px;
`;

const ChatListLabel = styled.label`
  font-size: 17px;
  color: #1a173b;
`;

const ColumnOneContentWrap = styled.div``;
const ColumnTwoContentWrap = styled.div`
  width: 250px;
`;
const ColumnWrap = styled.div`
  display: flex;
  width: 100%;
`;

const AddButton = styled.button`
  width: 150px;
  height: 40px;
  border-radius: 5px;
  background-color: #e54666;
  color: #fff;
  outline: none;
  cursor: pointer;
`;

// add user Popup CSS

const customStyles = {
  content: {
    height: "80%",
    width: "38%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    // border: "0px",
  },
};

const PopupWrap = styled.div`
  margin-left: 60px;
  display: flex;
  flex-direction: column;
`;

const PopupTitle = styled.h3`
  margin-bottom: 30px;
  margin-top: 0px;
`;

const LabelInputPopupWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 60px; */
  height: ${props => (props.height ? "280" : "60")}px;
  /* margin-bottom: 25px; */
  margin-bottom: ${props => (props.margin ? "5" : "25")}px;
`;

const Label = styled.label`
  font-size: 10px;
`;

const Input = styled.input`
  color: #323c47;
  border-radius: 4px;
  border: solid 1px #e9eff4;
  width: 350px;
  height: 40px;
`;

const RadioButtonInput = styled.input`
  font-size: 10px;
  margin-right: 15px;
  padding-bottom: 0px;
`;

const RadioButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  margin-top: 15px;
  margin-bottom: 15px;
`;

const AdminRoleLabel = styled.label`
  color: #323c47;
  font-size: 13px;
  /* padding-left: 15px; */
  display: flex;
  align-items: center;
  padding: 20px 0px 20px 0px;
  /* margin-bottom: 10px; */
  /* padding-right: 10px; */
`;

const DoneButton = styled.button`
  width: 100px;
  color: #fff;
  height: 30px;
  border-radius: 5px;
  background-color: #085db0;
  outline: 0;
  cursor: pointer;
`;

const CloseButton = styled.button`
  outline: 0;
  border: none;
  cursor: pointer;
  margin-left: 400px;
  margin-top: -10px;
`;

const CloseButtonImg = styled.img`
  height: 30px;
  width: 30px;
`;

const LabelPassword = styled.label`
  font-size: 10px;
  color: #d0d0d0;
  margin-bottom: 20px;
`;

const NotAccessibleDiv = styled.div`
  /* margin-left: 100px; */
  /* margin-top: 100px; */
  padding-top: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  height: 100%;
  width: 100%;
`;

const NotAccessibleImg = styled.img``;

const NotAccessibleLabel = styled.h2``;

const Authemail = window.sessionStorage.getItem("email");
const password = window.sessionStorage.getItem("token");

class AdminSettings extends React.Component {
  state = {
    open: false,
    email: " ",
    name: " ",
    isLoading: false,
    superAdminClicked: false,
    superReaderClicked: false,
    contentManagerClicked: false,
    approvalManagerClicked: false,
    financialManagerClicked: false,
    typeofuser: ["superadmin"],
    isAuthourized: false,
  };

  // lifecycle methods

  // api calls

  openPopup = this.openPopup.bind(this);

  closePopup = this.closePopup.bind(this);

  componentDidMount() {
    this.userData();
  }

  onSuperAdminClicked = () => {
    this.setState({
      superAdminClicked: true,
      superReaderClicked: false,
      contentManagerClicked: false,
      approvalManagerClicked: false,
      financialManagerClicked: false,
    });
  };

  onSuperReaderClicked = () => {
    this.setState({
      superReaderClicked: true,
      superAdminClicked: false,

      contentManagerClicked: false,
      approvalManagerClicked: false,
      financialManagerClicked: false,
    });
  };

  onContentManagerClicked = () => {
    this.setState({
      contentManagerClicked: true,
      superAdminClicked: false,
      superReaderClicked: false,

      approvalManagerClicked: false,
      financialManagerClicked: false,
    });
  };

  onApprovalManagerClicked = () => {
    this.setState({
      approvalManagerClicked: true,
      superAdminClicked: false,
      superReaderClicked: false,
      contentManagerClicked: false,

      financialManagerClicked: false,
    });
  };

  onFinancialManagerClicked = () => {
    this.setState({
      financialManagerClicked: true,
      superAdminClicked: false,
      superReaderClicked: false,
      contentManagerClicked: false,
      approvalManagerClicked: false,
    });
  };

  handleOnEmailChange = e => {
    this.setState({email: e.target.value});
  };

  handleOnNameChange = e => {
    this.setState({name: e.target.value});
  };

  createAdmin = () => {
    const {
      name,
      email,
      typeofuser,
      approvalManagerClicked,
      contentManagerClicked,
      superAdminClicked,
      superReaderClicked,
      financialManagerClicked,
      isAuthourized,
      isLoading,
    } = this.state;

    this.setState({isLoading: true});

    const type_of_user = superAdminClicked
      ? "superadmin"
      : superReaderClicked
      ? "superreader"
      : contentManagerClicked
      ? "contentmanager"
      : approvalManagerClicked
      ? "approvalmanager"
      : financialManagerClicked
      ? "financialmanager"
      : null;

    const buffer = createBuffer({email, name, type_of_user});
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/user/CreateAdmin`,
        {
          json_string: JSON.stringify({
            email,
            name,
            type_of_user,
          }),
        },
        {headers: {signature, email: Authemail, channel: "web"}},
      )

      .then(response => {
        if (response.status === 200 && response.data) {
          this.closePopup();
          toast.info("Admin has been added!");
          this.setState({isLoading: false});
        }
      })
      .catch(error => {
        // console.log("error", error.response.data.content);
        this.setState({isLoading: false});
        alert(error.response.data.content);
      });
  };

  userData = () => {
    let {isAuthourized, typeofuser} = this.state;
    const role = sessionStorage.getItem("typeofuser");
    isAuthourized = typeofuser.includes(role);
    this.setState({isAuthourized});
  };

  openPopup() {
    this.setState({open: true});
  }

  closePopup() {
    this.setState({open: false});
  }

  render() {
    const {
      open,
      isLoading,
      isAuthourized,
      superAdminClicked,
      contentManagerClicked,
      approvalManagerClicked,
      financialManagerClicked,
    } = this.state;
    const role = sessionStorage.getItem("typeofuser");
    return (
      <FullSection>
        <SmartToaster store={toast} lightBackground position="top_center" />
        <NavigationBar />
        <SideBar />
        {isAuthourized ? (
          <ColumnWrap>
            <ColumnOneContentWrap>
              <LabelInputWrap>
                <ChatListLabel>My Settings</ChatListLabel>
                <AddButton onClick={this.openPopup}>Add Admin</AddButton>

                <Modal
                  isOpen={open}
                  onRequestClose={this.closePopup}
                  style={customStyles}
                >
                  <PopupWrap>
                    <CloseButton more onClick={this.closePopup}>
                      <CloseButtonImg src={closebtn} />
                    </CloseButton>
                    <PopupTitle>Register a New Admin</PopupTitle>
                    <LabelInputPopupWrap>
                      <Label>FULL NAME</Label>
                      <Input
                        placeholder="Write here"
                        onChange={e => this.handleOnNameChange(e)}
                      />
                    </LabelInputPopupWrap>

                    <LabelInputPopupWrap margin>
                      <Label>EMAIL ADDRESS</Label>
                      <Input
                        placeholder="Write here"
                        onChange={e => this.handleOnEmailChange(e)}
                      />
                    </LabelInputPopupWrap>

                    <LabelPassword>
                      Password would be send to this email id
                    </LabelPassword>

                    <LabelInputPopupWrap height>
                      <Label>SELECT ADMIN ROLE</Label>

                      <RadioButtonWrap>
                        <AdminRoleLabel>
                          <RadioButtonInput
                            id="superadmin"
                            value="superadmin"
                            type="radio"
                            checked={superAdminClicked}
                            onClick={() => this.onSuperAdminClicked()}
                          />
                          Super Admin
                        </AdminRoleLabel>

                        {/* <AdminRoleLabel>
                          <RadioButtonInput
                            id="superreader"
                            value="superreader"
                            type="radio"
                            checked={superReaderClicked}
                            onClick={() => this.onSuperReaderClicked()}
                          />
                          Super Reader
                        </AdminRoleLabel> */}

                        <AdminRoleLabel>
                          <RadioButtonInput
                            id="approvemanagement"
                            value="approvemanagement"
                            checked={approvalManagerClicked}
                            type="radio"
                            onClick={() => this.onApprovalManagerClicked()}
                          />
                          Approval Manager
                        </AdminRoleLabel>

                        <AdminRoleLabel>
                          <RadioButtonInput
                            id="financialmanagement"
                            value="financialmanagement"
                            checked={financialManagerClicked}
                            type="radio"
                            onClick={() => this.onFinancialManagerClicked()}
                          />
                          Financial Manager
                        </AdminRoleLabel>

                        <AdminRoleLabel>
                          <RadioButtonInput
                            id="contentmanagement"
                            value="contentmanagement"
                            type="radio"
                            checked={contentManagerClicked}
                            onClick={() => this.onContentManagerClicked()}
                          />
                          Content Manager
                        </AdminRoleLabel>
                      </RadioButtonWrap>
                    </LabelInputPopupWrap>

                    <LabelInputPopupWrap>
                      <DoneButton
                        onClick={() => {
                          this.createAdmin();
                        }}
                      >
                        Done {isLoading ? <Spinner /> : null}
                      </DoneButton>
                    </LabelInputPopupWrap>
                  </PopupWrap>
                </Modal>
              </LabelInputWrap>
              <AdminSettingsReactTable />
            </ColumnOneContentWrap>
          </ColumnWrap>
        ) : (
          <NotAccessibleDiv>
            <NotAccessibleLabel>
              Sorry you’re a{" "}
              {role === "contentmanager"
                ? "Content Manager"
                : role === "approvalmanager"
                ? "Approval Manager"
                : role === "financialmanager"
                ? "Financial Manager"
                : role === "superreader"
                ? "Super Reader"
                : null}
              . Please contact the Super Admin to access this area.
            </NotAccessibleLabel>
            <NotAccessibleImg alt="Not accessible" src={NotAccessiblePNG} />
          </NotAccessibleDiv>
        )}
      </FullSection>
    );
  }
}

export default AdminSettings;
