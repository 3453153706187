import React from "react";
import styled from "styled-components";
import ReactTable from "react-table";
import Modal from "react-modal";
import "react-table/react-table.css";
import {searchInTable, searchInTableExactMatch} from "../../utils/util";
// import styledComponentsCjs from "styled-components";
import {apiURL, createBuffer, generateSHA2} from "../../utils/util";
import axios from "axios";

const FullTableWrap = styled.div`
  margin-left: 4em;
  margin-right: 4em;
  margin-top: 2em;
  max-height: 100%;

  background-color: #ffffff;
`;

const ReviewButton = styled.button`
  width: 100px;
  height: 30px;
  // background-color: #d0021b;
  background-color: #0052a9;
  /* background-color: #004389; */
  border-radius: 4px;
  color: white;
  font-size: 15px;
  cursor: pointer;
  outline: 0;
`;

const ViewButton = styled.button`
  width: 100px;
  height: 30px;

  background-color: rgba(0, 207, 49, 1);

  border-radius: 4px;
  color: white;
  font-size: 15px;
  cursor: pointer;
  outline: 0;

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
    /* background-color: rgba(0, 207, 49, 0.); */
  }
`;

const EmailButton = styled.a`
  width: 100px;
  height: 30px;
  background-color: #fafbfc;
  /* background-color: #0052a9; */
  color: #c0c5d2;
  font-size: 15px;
  border-radius: 4px;
  border: solid 0.5px #c0c5d2;
  cursor: pointer;
  outline: 0;
  padding: 5px;
  text-decoration: none;
`;

const FilterInput = styled.input`
  ::-webkit-input-placeholder {
    font-size: 10px;
  }
`;

const TableHeaderDiv = styled.div`
  text-align: left;
  padding-top: ${props => (props.padding ? "" : "8")}px;
  /* padding-top: 8px; */
`;

const StyledCheckbox = styled.input`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const customStyles = {
  content: {
    height: "auto",
    width: "80%",
    // position: "absolute",
    top: "50%",
    left: "50%",
    // right: "auto",
    // bottom: "auto",
    // marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const email = window.sessionStorage.getItem("email");
const password = window.sessionStorage.getItem("token");

class ExpertReactTable extends React.Component {
  state = {isPaymentModalOpen: false, paymentModalExpertId: null};

  render() {
    const {handlereview, expertsList, loading} = this.props;
    // console.log(expertsList);

    const closePaymentModal = () => {
      this.setState({isPaymentModalOpen: false});
    };

    const openPaymentModal = props => {
      // console.log(props.original);
      this.setState({isPaymentModalOpen: true});
      this.setState({paymentModalExpertId: props.original.id});
    };

    const handlePromotions = ({id, is_promotion_enabled}, e) => {
      e.persist();
      // console.log(email, id, !is_promotion_enabled);
      const buffer = createBuffer({
        expert_id: id,
        is_promotion_enabled: !is_promotion_enabled,
      });
      const signature = generateSHA2(buffer, password);
      // console.log(signature);

      console.log({
        json_string: JSON.stringify({
          expert_id: id,
          is_promotion_enabled: e.target.checked,
        }),
      });

      axios
        .post(
          `${apiURL}/expert/UpdateExpertDiscountStatus`,
          {
            json_string: JSON.stringify({
              expert_id: id,
              is_promotion_enabled: e.target.checked,
            }),
          },
          {headers: {signature, email, channel: "web"}},
        )
        .then(response => {
          console.log(response);
          if (response.status === 200 && response.data) {
            console.log(response.data);
            this.props.getExperts();
          }
        })
        .catch(error => {
          if (error.status === 404) {
            console.log(error.data);
            e.target.checked = !e.target.checked;
          }
        });
    };

    const columns = [
      {
        Header: () => (
          <TableHeaderDiv>
            <b>Name</b>
          </TableHeaderDiv>
        ),

        id: "name",
        accessor: d => d.name,
        style: {color: "#4D4F5C"},
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search Name.."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        filterMethod: (filter, row) => searchInTable(filter, row),
      },
      {
        id: "date",
        Header: () => (
          <TableHeaderDiv>
            <b>Registration Date</b>
          </TableHeaderDiv>
        ),
        style: {color: "#4D4F5C"},
        accessor: d => d.registration_date,
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search Registration Date..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        filterMethod: (filter, row) => searchInTable(filter, row),
        sortMethod: (a, b) => {
          const splitdate1 = a.split("-");
          const splitdate2 = b.split("-");
          const date1 = new Date(
            splitdate1[2],
            splitdate1[1],
            splitdate1[0],
          ).getTime();
          const date2 = new Date(
            splitdate2[2],
            splitdate2[1],
            splitdate2[0],
          ).getTime();
          if (date1 > date2) return 1;
          return -1;
        },
      },
      {
        Header: () => (
          <TableHeaderDiv padding>
            <b>
              No. of
              <br />
              Online Chats
            </b>
          </TableHeaderDiv>
        ),
        id: "onlinechats",
        accessor: d => d.onlineCount,
        style: {textAlign: "center", color: "#4D4F5C"},
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search No. of Online Chats.."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        // filterMethod: (filter, row) => searchInTableForNumber(filter, row),
      },
      {
        Header: () => (
          <TableHeaderDiv padding>
            <b>
              No. of
              <br />
              Offline Chats
            </b>
          </TableHeaderDiv>
        ),

        id: "offlinechats",
        accessor: d => d.offlineCount,
        style: {textAlign: "center", color: "#4D4F5C"},
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search No. of Offline Chats.."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        // filterMethod: (filter, row) => searchInTableForNumber(filter, row),
      },
      {
        Header: () => (
          <TableHeaderDiv padding>
            <b>
              No. of
              <br />
              Appointments
            </b>
          </TableHeaderDiv>
        ),
        id: "appointments",

        accessor: d => d.scheduleCount,
        style: {textAlign: "center", color: "#4D4F5C"},
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search No. of Appointments"
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        // filterMethod: (filter, row) => searchInTableForNumber(filter, row),
      },
      {
        Header: () => (
          <TableHeaderDiv>
            <b>Support</b>
          </TableHeaderDiv>
        ),
        accessor: d => "",
        id: "support",
        Cell: props => {
          return (
            <EmailButton href="mailto:noreply@shawer.sa">Email</EmailButton>
          );
        },
        style: {textAlign: "center"},
        filterable: false,
      },
      {
        Header: () => (
          <TableHeaderDiv>
            <b>Review</b>
          </TableHeaderDiv>
        ),
        accessor: d => (d.expert_status ? "Reviewed" : "Review"),
        // accessor: d => d.id,
        id: "buttonid",
        // Header: "Review",
        Cell: props => {
          return (
            <>
              {props.original.expert_status ? (
                <ViewButton
                  onClick={handlereview}
                  data-expertid={props.original.id}
                >
                  Reviewed
                </ViewButton>
              ) : (
                <ReviewButton
                  onClick={handlereview}
                  data-expertid={props.original.id}
                >
                  Review
                </ReviewButton>
              )}
            </>
          );
        },
        style: {textAlign: "center"},
        filterMethod: (filter, row) => searchInTableExactMatch(filter, row),
        Filter: ({filter, onChange}) => {
          return (
            <FilterInput
              type="text"
              placeholder="Search Review Status..."
              value={filter ? filter.value : ""}
              onChange={event => onChange(event.target.value)}
            />
          );
        },
      },
      {
        Header: () => (
          <TableHeaderDiv>
            <b>View Payment</b>
          </TableHeaderDiv>
        ),
        id: "viewPayment",
        accessor: () => "",
        style: {color: "#4D4F5C"},
        Cell: props => {
          return (
            <>
              <ViewButton
                onClick={() => openPaymentModal(props)}
                disabled={!props.original.expert_status}
              >
                View
              </ViewButton>
            </>
          );
        },
      },
      {
        Header: () => (
          <TableHeaderDiv>
            <b>Promotions</b>
          </TableHeaderDiv>
        ),
        id: "promotions",
        accessor: d => d.is_promotion_enabled,
        style: {color: "#4D4F5C"},
        Cell: ({original}) => {
          return (
            <StyledCheckbox
              disabled={!original.expert_status}
              type="checkbox"
              checked={original.is_promotion_enabled}
              onChange={e => handlePromotions(original, e)}
            />
          );
        },
      },
    ];

    return (
      <>
        <FullTableWrap>
          <ReactTable
            noDataText={loading ? "" : "No Rows Found"}
            loading={loading}
            loadingText="Loading..."
            filterable
            columns={columns}
            data={expertsList}
            defaultPageSize={10}
            sortable={true}
          />
        </FullTableWrap>

        {this.state.isPaymentModalOpen ? (
          <TransactionHistory
            isPaymentModalOpen={this.state.isPaymentModalOpen}
            onRequestClose={closePaymentModal}
            selectedExpertId={this.state.paymentModalExpertId}
          />
        ) : null}
      </>
    );
  }
}

class TransactionHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {expertTransactions: [], selectedTransactions: []};
  }

  componentDidMount() {
    const buffer = createBuffer({
      expert_id: this.props.selectedExpertId,
    });
    const signature = generateSHA2(buffer, password);
    // console.log(signature);

    console.log({
      json_string: JSON.stringify({
        expert_id: this.props.selectedExpertId,
      }),
    });

    axios
      .post(
        `${apiURL}/chathistory/ListDiscountChatsByExpert`,
        {
          json_string: JSON.stringify({
            expert_id: this.props.selectedExpertId,
          }),
        },
        {headers: {signature, email, channel: "web"}},
      )
      .then(response => {
        console.log(response);
        if (response.status === 200 && response.data) {
          console.log(response.data.content);
          this.setState({
            ...this.state,
            expertTransactions: response.data.content,
          });
        }
      })
      .catch(error => {
        if (error.status === 404 && error.data) {
          console.log(error.data);
        }
      });
  }

  handlePayments = () => {
    console.log(this.state.selectedTransactions);
    const buffer = createBuffer({
      discount_chat_ids: this.state.selectedTransactions,
    });
    const signature = generateSHA2(buffer, password);
    // console.log(signature);

    console.log({
      json_string: JSON.stringify({
        discount_chat_ids: this.state.selectedTransactions,
      }),
    });

    axios
      .post(
        `${apiURL}/chathistory/PayDiscountChatsByExpert`,
        {
          json_string: JSON.stringify({
            discount_chat_ids: this.state.selectedTransactions,
          }),
        },
        {headers: {signature, email, channel: "web"}},
      )
      .then(response => {
        console.log(response);
        if (response.status === 200) {
          console.log(response);
          this.props.onRequestClose();
        }
      })
      .catch(error => {
        if (error.status === 404 && error.data) {
          console.log(error.data);
        }
      });
  };

  render() {
    const {isPaymentModalOpen, onRequestClose} = this.props;
    // console.log("newRender", this.state.selectedTransactions);

    const displayTransactionHistory = () => {
      const transactionColumns = [
        {
          Header: () => (
            <TableHeaderDiv>
              <b>Name</b>
            </TableHeaderDiv>
          ),

          id: "transactionName",
          accessor: d => d.user_name,
          style: {color: "#4D4F5C"},
          Filter: ({filter, onChange}) => (
            <FilterInput
              type="text"
              placeholder="Search Name.."
              value={filter ? filter.value : ""}
              onChange={event => onChange(event.target.value)}
            />
          ),
          filterMethod: (filter, row) => searchInTable(filter, row),
        },
        {
          Header: () => (
            <TableHeaderDiv>
              <b>Chat Type</b>
            </TableHeaderDiv>
          ),
          style: {color: "#4D4F5C"},
          id: "chatType",
          accessor: d => d.chat_type,
          Filter: ({filter, onChange}) => (
            <FilterInput
              type="text"
              placeholder="Search Registration Date..."
              value={filter ? filter.value : ""}
              onChange={event => onChange(event.target.value)}
            />
          ),
          filterMethod: (filter, row) => searchInTable(filter, row),
          sortMethod: (a, b) => {
            const splitdate1 = a.split("-");
            const splitdate2 = b.split("-");
            const date1 = new Date(
              splitdate1[2],
              splitdate1[1],
              splitdate1[0],
            ).getTime();
            const date2 = new Date(
              splitdate2[2],
              splitdate2[1],
              splitdate2[0],
            ).getTime();
            if (date1 > date2) return 1;
            return -1;
          },
        },
        {
          Header: () => (
            <TableHeaderDiv>
              <b>Time (min)</b>
            </TableHeaderDiv>
          ),

          id: "time",
          accessor: d => {
            let time = (d.end_time - d.start_time) / 1000 / 60;
            return time.toFixed(2);
          },
          style: {color: "#4D4F5C"},
          Filter: ({filter, onChange}) => (
            <FilterInput
              type="text"
              placeholder="Search Name.."
              value={filter ? filter.value : ""}
              onChange={event => onChange(event.target.value)}
            />
          ),
          filterMethod: (filter, row) => searchInTable(filter, row),
        },
        {
          Header: () => (
            <TableHeaderDiv>
              <b>Charge/min</b>
            </TableHeaderDiv>
          ),

          id: "chargePerMinute",
          accessor: d => d.expert_rate_min,
          style: {color: "#4D4F5C"},
          Filter: ({filter, onChange}) => (
            <FilterInput
              type="text"
              placeholder="Search Name.."
              value={filter ? filter.value : ""}
              onChange={event => onChange(event.target.value)}
            />
          ),
          filterMethod: (filter, row) => searchInTable(filter, row),
        },
        {
          Header: () => (
            <TableHeaderDiv>
              <b>Amount (currency)</b>
            </TableHeaderDiv>
          ),

          id: "amount",
          accessor: d => d.total_amount.toFixed(2),
          style: {color: "#4D4F5C"},
          Filter: ({filter, onChange}) => (
            <FilterInput
              type="text"
              placeholder="Search Name.."
              value={filter ? filter.value : ""}
              onChange={event => onChange(event.target.value)}
            />
          ),
          filterMethod: (filter, row) => searchInTable(filter, row),
        },
        {
          Header: () => (
            <TableHeaderDiv>
              <b>Approve</b>
            </TableHeaderDiv>
          ),
          id: "approve",
          accessor: () => "",
          style: {color: "#4D4F5C"},
          Cell: ({original}) => {
            return (
              <StyledCheckbox
                type="checkbox"
                checked={this.state.selectedTransactions.includes(original.id)}
                disabled={original.is_approved_by_admin}
                onChange={e => {
                  console.log(e.target.checked);
                  let selected = e.target.checked;
                  if (selected) {
                    this.setState({
                      ...this.state,
                      selectedTransactions: [
                        ...this.state.selectedTransactions,
                        original.id,
                      ],
                    });
                    // console.log("added", this.state.selectedTransactions);
                  } else {
                    let updatedSelectedTransactions = this.state.selectedTransactions.filter(
                      id => id !== original.id,
                    );
                    this.setState({
                      ...this.state,
                      selectedTransactions: updatedSelectedTransactions,
                    });
                    // console.log("removed", this.state.selectedTransactions);
                  }
                }}
              />
            );
          },
        },
        {
          Header: () => (
            <TableHeaderDiv>
              <b>Status</b>
            </TableHeaderDiv>
          ),
          id: "status",
          accessor: () => "",
          style: {color: "#4D4F5C"},
          Cell: ({original}) =>
            original.is_approved_by_admin ? (
              <ViewButton data-expertid={original.id}>Approved</ViewButton>
            ) : (
              <ViewButton
                style={{background: "coral"}}
                data-expertid={original.id}
              >
                Pending
              </ViewButton>
            ),
        },
      ];

      const dummyList = [
        {
          transaction_name: "Akshay",
          transaction_date: "08-06-21",
          time: "10:00",
          rate: "$5.00",
          amount: "$50.00",
        },
        {
          transaction_name: "Shreyash",
          transaction_date: "08-06-21",
          time: "20:00",
          rate: "$5.00",
          amount: "$100.00",
        },
        {
          transaction_name: "John",
          transaction_date: "08-06-21",
          time: "30:00",
          rate: "$5.00",
          amount: "$150.00",
        },
      ];

      return (
        <ReactTable
          noDataText={"Not found"}
          loading={false}
          loadingText="Loading..."
          filterable
          columns={transactionColumns}
          data={this.state.expertTransactions}
          defaultPageSize={10}
          sortable={true}
        />
      );
    };

    return (
      <Modal
        isOpen={isPaymentModalOpen}
        onRequestClose={onRequestClose}
        style={customStyles}
        ariaHideApp={false}
      >
        <button onClick={onRequestClose}>Close</button>
        <ViewButton style={{marginLeft: "10px"}} onClick={this.handlePayments}>
          Approve
        </ViewButton>
        <div>Transaction History</div>
        <div>{displayTransactionHistory()}</div>
      </Modal>
    );
  }
}

export default ExpertReactTable;
