import React from "react";
import styled from "styled-components";

import ReactTable from "react-table";
import "react-table/react-table.css";
import {SmartToaster, toast} from "react-smart-toaster";
import StarRatingComponent from "react-star-rating-component";

import axios from "axios";

import {apiURL, createBuffer, generateSHA2} from "../../utils/util";

const FullTableWrap = styled.div`
  /* margin-left: 4.5em; */

  margin-top: 0.6em;
  max-height: 100%;
  width: 1100px;
  background-color: #ffffff;
`;

const TableWrap = styled.div`
  margin-left: 20px;
  width: 1300px;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 35px;
  width: 170px;
`;

const AcceptButton = styled.button`
  // background-color: #0052a9;
  // color: #fff;
  background-color: solid 0.5px #c0c5d2;
  color: #c0c5d2;
  cursor: pointer;
  border-radius: 4px;
  outline: none;
  padding: 5px;
`;

const RejectButton = styled.button`
  background-color: solid 0.5px #c0c5d2;
  color: #c0c5d2;
  cursor: pointer;
  border-radius: 4px;
  padding: 5px;
  outline: none;
`;

const TableHeaderDiv = styled.div`
  /* text-align: left; */
  text-align: ${props => (props.center ? "center" : "left")};
`;

const email = window.sessionStorage.getItem("email");
const password = window.sessionStorage.getItem("token");

class ReviewManagementReactTable extends React.Component {
  state = {
    checked: false,
    loading: true,
    rating: 1,
    isNotOkClicked: false,
    openReasonMore: false,
    getListOfPendingReview: [],
    reviewApproval: {},

    // data: makeData(),
  };

  // lifecycle methods

  componentDidMount() {
    this.getReviewManagementDetails();
  }

  onSwitchHandleChange(checked) {
    this.setState({checked});
  }

  onStarClick(nextValue, prevValue, name) {
    this.setState({rating: nextValue});
  }

  // api calls

  getReviewManagementDetails = () => {
    // axios
    //   .post(`${apiURL}/review/ListOfPendingReview`, {})

    const buffer = createBuffer({email});
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/review/ListOfPendingReview`,
        {
          json_string: JSON.stringify({email}),
        },
        {headers: {signature, email, channel: "web"}},
      )

      .then(response => {
        // console.log(response);

        if (response.status === 200 && response.data) {
          //  console.log(response.data);
          const {data} = response;
          //  console.log("Data", data);

          this.setState({getListOfPendingReview: data.content, loading: false});
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };

  updateWithdrawalManagementDetails = (e, btntype) => {
    const {getListOfPendingReview} = this.state;
    const {index} = e.target.dataset;

    // console.log("update review api called");
    // console.log("indexxxxx", index);
    // console.log("sdfsdafds", getListOfPendingReview[index].approved_by_admin);
    // console.log("Button Type is", btntype);

    const docid = getListOfPendingReview[index].id;
    const expert = getListOfPendingReview[index].expert_name;
    const user = getListOfPendingReview[index].user_name;
    const ratings = getListOfPendingReview[index].rating;
    const reviewReasons = getListOfPendingReview[index].reasons;
    // Object.assign(reviewApproval, docid);
    // Object.assign(reviewApproval, expert);
    const tempObj = {
      id: getListOfPendingReview[index].id,
      expert_name: getListOfPendingReview[index].expert_name,
      user_name: getListOfPendingReview[index].user_name,
      rating: getListOfPendingReview[index].rating,
      reasons: getListOfPendingReview[index].reasons,
      expert_id: getListOfPendingReview[index].expert_id,
      user_id: getListOfPendingReview[index].user_id,
      chat_session_id: getListOfPendingReview[index].chat_session_id,
      type: getListOfPendingReview[index].type,
    };
    // Object.assign(user, reviewApproval);
    // Object.assign(ratings, reviewApproval);
    // Object.assign(reviewReasons, reviewApproval);
    // this.setState({reviewApproval: tempObj});
    // console.log("review approval", tempObj);

    // console.log("docid", docid);
    const status = btntype === "accept";

    const buffer = createBuffer({email, chat_feedback_id: docid, status});
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/review/UpdateReviewStatus`,
        {
          json_string: JSON.stringify({email, chat_feedback_id: docid, status}),
        },
        {headers: {signature, email, channel: "web"}},
      )

      .then(response => {
        this.getReviewManagementDetails();
      })
      .catch(error => {
        // console.log(error);
      });
  };

  notOkClicked = () => {
    this.setState({
      isNotOkClicked: true,
    });
  };

  render() {
    const {loading, getListOfPendingReview} = this.state;
    const columns = [
      {
        id: "date",

        Header: () => (
          <TableHeaderDiv center>
            <b>Date</b>
          </TableHeaderDiv>
        ),

        accessor: d => d.date,
        sortMethod: (a, b) => {
          const splitdate1 = a.split("-");
          const splitdate2 = b.split("-");
          const date1 = new Date(
            splitdate1[2],
            splitdate1[1],
            splitdate1[0],
          ).getTime();
          const date2 = new Date(
            splitdate2[2],
            splitdate2[1],
            splitdate2[0],
          ).getTime();

          if (date1 > date2) return 1;
          return -1;
        },
        style: {textAlign: "center", color: "#4D4F5C"},
      },
      {
        id: "expert",
        Header: () => (
          <TableHeaderDiv center>
            <b>Expert</b>
          </TableHeaderDiv>
        ),
        accessor: d => d.expert_name,
        style: {textAlign: "center", color: "#4D4F5C"},
      },

      {
        id: "user",
        Header: () => (
          <TableHeaderDiv center>
            <b>User</b>
          </TableHeaderDiv>
        ),
        accessor: d => d.user_name,
        style: {textAlign: "center", color: "#4D4F5C"},
      },

      {
        id: "rating",
        Header: () => (
          <TableHeaderDiv center>
            <b>Rating</b>
          </TableHeaderDiv>
        ),

        accessor: d => d.rating,
        style: {textAlign: "center", color: "#4D4F5C"},
        Cell: props => {
          return (
            <div>
              <StarRatingComponent
                name="rate1"
                starCount={5}
                value={props.value}
                onStarClick={this.onStarClick}
                starColor="#5fd6ff"
              />
            </div>
          );
        },
      },
      {
        // Header: "Review",
        // accessor: "review",
        id: "reason",
        Header: () => (
          <TableHeaderDiv center>
            <b>Review Reason</b>
          </TableHeaderDiv>
        ),
        accessor: d =>
          d.reasons ? d.reasons : d.reasons.length === 0 ? "-" : "--",
        width: 350,
        // Cell: props => {
        //   const minifiedString = props.value.substring(0, 20);
        //   // console.log(minifiedString);
        //   return (
        //     <div>
        //       <div>
        //         {`${minifiedString}`}
        //         <lable>... </lable>
        //         <MoreReasonButton
        //           onClick={this.openReasonMorePopup}
        //           data-index={props.index}
        //         >
        //           More
        //         </MoreReasonButton>
        //       </div>
        //     </div>
        //   );
        // },
        style: {color: "#4D4F5C"},
      },

      // {
      //   Header: () => (
      //     <TableHeaderDiv center>
      //       <b>Status</b>
      //     </TableHeaderDiv>
      //   ),

      //   accessor: d => d.approved_by_admin,

      //   id: "approved_by_admin",
      //   // Header: "Status",
      //   // accessor: "status",
      //   Cell: props => {
      //     console.log(props);
      //     return `${props.value}`;
      //   },
      // },
      {
        Header: () => (
          <TableHeaderDiv center>
            <b>Approve</b>
          </TableHeaderDiv>
        ),
        // Header: "Approve",
        Cell: props => {
          // console.log("original props", props.original);
          // console.log("original props!!!!!", props.original.approved_by_admin);
          return (
            <ButtonWrap>
              <AcceptButton
                data-index={props.index}
                data-id={props.original.id}
                data-status={props.original.approved_by_admin}
                onClick={(e, btnType = "accept") => {
                  this.updateWithdrawalManagementDetails(e, btnType);
                  toast.info("Expert Feedback Approved!");
                }}
              >
                Approved
              </AcceptButton>

              <RejectButton
                data-index={props.index}
                data-id={props.original.id}
                data-status={props.original.approved_by_admin}
                onClick={(e, btnType = "reject") => {
                  this.updateWithdrawalManagementDetails(e, btnType);
                  toast.info("Expert Feedback not approved!");
                }}
              >
                Not Approved
              </RejectButton>
            </ButtonWrap>
          );
        },
        width: 250,
        style: {textAlign: "center"},
        filterable: false,
      },
      // {
      //   Header: "Approve",
      //   Cell: props => {
      //     return (
      //       <ArrowButton>
      //         <ArrowImg src={arrowimg} />
      //       </ArrowButton>
      //     );
      //   },
      //   style: {textAlign: "center"},
      //   filterable: false,
      // },
    ];
    return (
      <FullTableWrap>
        <SmartToaster store={toast} lightBackground position="top_center" />
        {/* <TitleDiv>
          <Title>January 2018</Title>
          <FilterImage src={filterimg} />
        </TitleDiv> */}
        <TableWrap>
          <ReactTable
            noDataText={loading ? "" : "No Rows Found"}
            loading={loading}
            loadingText="Loading..."
            filterable
            columns={columns}
            data={getListOfPendingReview}
            defaultPageSize={10}
            defaultSorted={[
              {
                id: "date",
                desc: true,
              },
            ]}
            // expanded={expanded}
            // showPaginationTop
            // showPaginationBottom={false}
          />
        </TableWrap>
      </FullTableWrap>
    );
  }
}

export default ReviewManagementReactTable;
