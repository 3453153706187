import React, {Component} from "react";
import styled from "styled-components";
import axios from "axios";
import Modal from "react-modal";

import NavigationBar from "../NavigationBar/NavigationBar";
import SideBar from "../NavigationBar/SideBar";
import NotAccessiblePNG from "../Common/NotAccessiblePNG.png";
import {apiURL, createBuffer, generateSHA2} from "../../utils/util";

import CouponsReactTable from "./CouponsReactTable";
import GenerateCoupon from "./GenerateCoupon";

const FullSection = styled.section`
  background-color: #fafbfc;
  display: grid;
  grid-template-rows: 1fr auto;
  max-height: 100%;
`;

const LabelInputWrap = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 4.5em;
  margin-left: 4.5em;
  margin-top: 0.5em;
`;

const CouponsListLabel = styled.h2`
  font-size: 17px;
  color: #1a173b;
`;

const ColumnOneContentWrap = styled.div``;

const ColumnWrap = styled.div`
  display: flex;
  width: 100%;
`;

const GenerateCouponModalButton = styled.button`
  width: 150px;
  height: 40px;
  border-radius: 5px;
  background-color: #e54666;
  color: #fff;
  outline: none;
  border: none;
  cursor: pointer;
`;

const customStyles = {
  content: {
    minHeight: 500,
    height: "auto",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "0 20px",
  },
};

const NotAccessibleDiv = styled.div`
  padding-top: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  height: 100%;
  width: 100%;
`;

const NotAccessibleImg = styled.img``;

const NotAccessibleLabel = styled.h2``;

const email = window.sessionStorage.getItem("email");
const password = window.sessionStorage.getItem("token");

class Coupons extends Component {
  constructor() {
    super();
    this.state = {
      isAuthourized: false,
      loading: false,
      typeofuser: ["superadmin", "superreader"],
      isGenerateCouponModalOpen: false,
      couponsList: [],
    };
  }

  componentDidMount() {
    this.userData();
    this.getCoupons();
  }

  getCoupons = () => {
    const buffer = createBuffer({email});
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/coupons/GetCoupons`,
        {
          json_string: JSON.stringify({email}),
        },
        {headers: {signature, email, channel: "web"}},
      )
      .then(response => {
        if (response.status === 200 && response.data) {
          const {data} = response;
          this.setState({
            ...this.state,
            couponsList: data.content,
            loading: false,
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  userData = () => {
    let {isAuthourized, typeofuser} = this.state;
    const role = sessionStorage.getItem("typeofuser");
    isAuthourized = typeofuser.includes(role);
    this.setState({isAuthourized});
  };

  openGenerateCouponModal = () => {
    this.setState({...this.state, isGenerateCouponModalOpen: true});
  };

  closeGenerateCouponModal = () => {
    this.setState({...this.state, isGenerateCouponModalOpen: false});
  };

  render() {
    const {isAuthourized, loading} = this.state;
    const role = sessionStorage.getItem("typeofuser");

    return (
      <FullSection>
        <NavigationBar />
        <SideBar />
        {isAuthourized ? (
          <>
            <ColumnWrap>
              <ColumnOneContentWrap>
                <LabelInputWrap>
                  <CouponsListLabel>Coupons</CouponsListLabel>
                  <GenerateCouponModalButton
                    onClick={this.openGenerateCouponModal}
                  >
                    Generate Coupon
                  </GenerateCouponModalButton>
                </LabelInputWrap>
                <CouponsReactTable
                  loading={loading}
                  couponsList={this.state.couponsList}
                  getCoupons={this.getCoupons}
                />
              </ColumnOneContentWrap>
            </ColumnWrap>
            <Modal
              isOpen={this.state.isGenerateCouponModalOpen}
              onRequestClose={this.closeGenerateCouponModal}
              style={customStyles}
              ariaHideApp={false}
            >
              <GenerateCoupon
                closeGenerateCouponModal={this.closeGenerateCouponModal}
                getCoupons={this.getCoupons}
              />
            </Modal>
          </>
        ) : (
          <NotAccessibleDiv>
            <NotAccessibleLabel>
              Sorry you’re a{" "}
              {role === "contentmanager"
                ? "Content Manager"
                : role === "approvalmanager"
                ? "Approval Manager"
                : role === "financialmanager"
                ? "Financial Manager"
                : null}
              . Please contact the Super Admin to access this area.
            </NotAccessibleLabel>
            <NotAccessibleImg alt="Not accessible" src={NotAccessiblePNG} />
          </NotAccessibleDiv>
        )}
      </FullSection>
    );
  }
}

export default Coupons;
