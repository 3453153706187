import React from "react";
import styled from "styled-components";
import ReactTable from "react-table";
import "react-table/react-table.css";
import axios from "axios";

import {searchInTable} from "../../utils/util";
import {apiURL, createBuffer, generateSHA2} from "../../utils/util";

const FullTableWrap = styled.div`
  margin-left: 4em;
  margin-right: 4em;
  margin-top: 2em;
  max-height: 100%;
  background-color: #ffffff;
`;

const ActionButton = styled.button`
  width: 100px;
  height: 30px;
  border-radius: 4px;
  color: white;
  font-size: 15px;
  cursor: pointer;
  outline: 0;
  border: none;
  background-color: rgba(0, 207, 49, 1);

  &[color="danger"] {
    background-color: #e54666;
  }
`;

const EmailButton = styled.a`
  width: 100px;
  height: 30px;
  background-color: #fafbfc;
  color: #c0c5d2;
  font-size: 15px;
  border-radius: 4px;
  border: solid 0.5px #c0c5d2;
  cursor: pointer;
  outline: 0;
  padding: 5px;
  text-decoration: none;
`;

const FilterInput = styled.input`
  ::-webkit-input-placeholder {
    font-size: 10px;
  }
`;

const TableHeaderDiv = styled.div`
  text-align: left;
  padding-top: ${props => (props.padding ? "" : "8")}px;
`;

const StyledCheckbox = styled.input`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const customStyles = {
  content: {
    height: "auto",
    width: "80%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
};

const email = window.sessionStorage.getItem("email");
const password = window.sessionStorage.getItem("token");

class CouponsReactTable extends React.Component {
  constructor() {
    super();
  }

  updateCouponStatus = (couponId, newStatus) => {
    console.log(couponId, newStatus);
    const updateCouponStatusBody = {
      coupon_id: couponId,
      status: newStatus,
    };

    const buffer = createBuffer({email, ...updateCouponStatusBody});
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/coupons/UpdateCouponStatus`,
        {
          json_string: JSON.stringify({email, ...updateCouponStatusBody}),
        },
        {headers: {signature, email, channel: "web"}},
      )
      .then(response => {
        if (response.status === 200 && response.data) {
          console.log(response);
          this.props.getCoupons();
        }
      })
      .catch(error => {
        if (error.response.data.code === 409) {
          alert(error.response.data.err);
        } else {
          alert("Something went wrong! Try again later.");
        }
      });
  };

  render() {
    const {couponsList, loading} = this.props;
    const columns = [
      {
        Header: () => (
          <TableHeaderDiv>
            <b>Coupon Code</b>
          </TableHeaderDiv>
        ),
        id: "couponCode",
        accessor: d => d.coupon_code,
        style: {color: "#4D4F5C"},
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search Code..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        filterMethod: (filter, row) => searchInTable(filter, row),
        minWidth: 150,
      },
      {
        Header: () => (
          <TableHeaderDiv>
            <b>Coupon Name</b>
          </TableHeaderDiv>
        ),
        id: "couponName",
        style: {color: "#4D4F5C"},
        accessor: d => d.coupon_name,
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search Coupon..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        filterMethod: (filter, row) => searchInTable(filter, row),
        minWidth: 150,
      },
      {
        Header: () => (
          <TableHeaderDiv>
            <b>Coupon Type</b>
          </TableHeaderDiv>
        ),
        id: "couponType",
        style: {color: "#4D4F5C"},
        accessor: d => d.coupon_type,
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search Type..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        filterMethod: (filter, row) => searchInTable(filter, row),
        minWidth: 120,
      },
      {
        Header: () => (
          <TableHeaderDiv>
            <b>Amount</b>
          </TableHeaderDiv>
        ),
        id: "amount",
        style: {color: "#4D4F5C"},
        accessor: d => d.coupon_flat_amount ? d.coupon_flat_amount : '-',
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search Coupon..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        filterMethod: (filter, row) => searchInTable(filter, row),
        minWidth: 120,
      },
      {
        Header: () => (
          <TableHeaderDiv>
            <b>Percentage</b>
          </TableHeaderDiv>
        ),
        id: "percentage",
        style: {color: "#4D4F5C"},
        accessor: d => (d.coupon_percentage ? d.coupon_percentage : "-"),
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search Percentage..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        filterMethod: (filter, row) => searchInTable(filter, row),
        minWidth: 120,
      },
      {
        Header: () => (
          <TableHeaderDiv>
            <b>Max Amount (for %)</b>
          </TableHeaderDiv>
        ),
        id: "maxPercentageAmount",
        style: {color: "#4D4F5C"},
        accessor: d =>
          d.coupon_max_percentage_amount ? d.coupon_max_percentage_amount : "-",
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search Percentage amount..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        filterMethod: (filter, row) => searchInTable(filter, row),
        minWidth: 150,
      },
      {
        Header: () => (
          <TableHeaderDiv>
            <b>Min Value</b>
          </TableHeaderDiv>
        ),
        id: "minValue",
        style: {color: "#4D4F5C"},
        accessor: d => d.coupon_min_value,
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search Min Value..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        filterMethod: (filter, row) => searchInTable(filter, row),
        minWidth: 120,
      },
      {
        Header: () => (
          <TableHeaderDiv>
            <b>Max Value</b>
          </TableHeaderDiv>
        ),
        id: "maxValue",
        style: {color: "#4D4F5C"},
        accessor: d => d.coupon_max_value,
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search Max Value..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        filterMethod: (filter, row) => searchInTable(filter, row),
        minWidth: 120,
      },
      {
        Header: () => (
          <TableHeaderDiv>
            <b>Per User Limit</b>
          </TableHeaderDiv>
        ),
        id: "perUserLimit",
        style: {color: "#4D4F5C"},
        accessor: d => d.per_usage_limit,
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search User Limit..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        filterMethod: (filter, row) => searchInTable(filter, row),
        minWidth: 120,
      },
      {
        Header: () => (
          <TableHeaderDiv>
            <b>Usage Limit</b>
          </TableHeaderDiv>
        ),
        id: "usageLimit",
        style: {color: "#4D4F5C"},
        accessor: d => d.usage_limit,
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search usage limit..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        filterMethod: (filter, row) => searchInTable(filter, row),
        minWidth: 120,
      },
      {
        Header: () => (
          <TableHeaderDiv>
            <b>Status</b>
          </TableHeaderDiv>
        ),
        id: "status",
        style: {color: "#4D4F5C"},
        minWidth: 160,
        accessor: d => d.status,
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        filterMethod: (filter, row) => searchInTable(filter, row),
        Cell: ({row: {status}, original: {id}}) => {
          return (
            <>
              {status ? (
                <ActionButton
                  color="danger"
                  onClick={() => this.updateCouponStatus(id, false)}
                >
                  Deactivate
                </ActionButton>
              ) : (
                <ActionButton onClick={() => this.updateCouponStatus(id, true)}>
                  Activate
                </ActionButton>
              )}
            </>
          );
        },
      },
    ];

    return (
      <>
        <FullTableWrap>
          <ReactTable
            noDataText={loading ? "" : "No Rows Found"}
            loading={loading}
            loadingText="Loading..."
            filterable
            columns={columns}
            data={couponsList}
            defaultPageSize={10}
            sortable={true}
          />
        </FullTableWrap>
      </>
    );
  }
}

export default CouponsReactTable;
