import React, {Component} from "react";
import styled from "styled-components";
import Select from "react-select";
import axios from "axios";

import {apiURL, createBuffer, generateSHA2} from "../../utils/util";

const AddCouponForm = styled.form``;

const ModalHeader = styled.div`
  position: sticky;
  top: 0;
  background: white;
  padding-top: 20px;
`;

const GenerateCouponButton = styled.button`
  height: 30px;
  background-color: rgba(0, 207, 49, 1);
  border-radius: 5px;
  color: white;
  font-size: 15px;
  cursor: pointer;
  outline: 0;
  border: none;
`;

const CloseButton = styled.button`
  height: 30px;
  border-radius: 5px;
  background-color: #e54666;
  color: #fff;
  outline: none;
  cursor: pointer;
  font-size: 15px;
  border: none;
`;

const GenerateCouponWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
`;

const StyledLabel = styled.label`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  .label {
    min-width: 150px;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  margin-left: 20px;
  border: 1px solid black;
  padding: 0 10px;
  color: #000;
`;

const StyledSelect = styled(Select)`
  width: 100%;
  margin-left: 20px;
`;

const email = window.sessionStorage.getItem("email");
const password = window.sessionStorage.getItem("token");

class GenerateCoupon extends Component {
  constructor() {
    super();
    this.state = {
      couponCode: "",
      couponName: "",
      // Please keep this consistent with CDM selected Options
      selectedCouponType: {label: "Percentage", value: "percentage"},
      amount: 0,
      minValue: 0,
      maxValue: 0,
      perUserLimit: 0,
      usageLimit: 0,
      percentage: 0,
      maxAmount: 0,
      couponTypeOptions: [],
      couponOptionsLoading: true,
    };
  }

  toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  getCouponTypes() {
    const buffer = createBuffer({email});
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/coupons/GetCouponTypes`,
        {
          json_string: JSON.stringify({email}),
        },
        {headers: {signature, email, channel: "web"}},
      )
      .then(response => {
        if (response.status === 200 && response.data) {
          const {data} = response;
          const couponTypeOptions = data.content.map(type => {
            return {
              value: type.coupon_type,
              label: this.toTitleCase(type.coupon_type),
              id: type.id,
            };
          });
          // console.log(couponTypeOptions);
          this.setState({
            ...this.state,
            couponTypeOptions,
            selectedCouponType: couponTypeOptions[0],
            couponOptionsLoading: false,
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  addCoupon = () => {
    const {
      couponCode,
      couponName,
      amount,
      minValue,
      maxValue,
      perUserLimit,
      usageLimit,
      percentage,
      maxAmount,
      selectedCouponType,
    } = this.state;

    const addCouponBody = {
      coupon_code: couponCode,
      coupon_name: couponName,
      coupon_type_id: selectedCouponType.id,
      ...(selectedCouponType.value === "flat" && {
        coupon_flat_amount: amount,
        coupon_percentage: null,
        coupon_max_percentage_amount: null,
      }),
      ...(selectedCouponType.value === "percentage" && {
        coupon_flat_amount: null,
        coupon_percentage: percentage,
        coupon_max_percentage_amount: maxAmount,
      }),
      coupon_min_value: minValue,
      coupon_max_value: maxValue,
      per_usage_limit: perUserLimit,
      usage_limit: usageLimit,
    };

    const buffer = createBuffer({email, ...addCouponBody});
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/coupons/AddCoupon`,
        {
          json_string: JSON.stringify({email, ...addCouponBody}),
        },
        {headers: {signature, email, channel: "web"}},
      )
      .then(response => {
        if (response.status === 200 && response.data) {
          console.log(response);
          this.props.closeGenerateCouponModal();
          this.props.getCoupons();
        }
      })
      .catch(error => {
        if (error.response.data.code === 409) {
          alert(error.response.data.err);
        } else {
          alert("Something went wrong! Try again later.");
        }
      });
  };

  componentDidMount() {
    this.getCouponTypes();
  }

  render() {
    const {
      couponCode,
      couponName,
      selectedCouponType,
      amount,
      minValue,
      maxValue,
      perUserLimit,
      usageLimit,
      percentage,
      maxAmount,
      couponOptionsLoading,
      couponTypeOptions,
    } = this.state;
    const {closeGenerateCouponModal} = this.props;

    const handleInput = ({target: {name, value}}) => {
      this.setState({...this.state, [name]: value});
    };

    const handleSelect = couponType => {
      this.setState({...this.state, selectedCouponType: couponType});
    };

    return (
      <AddCouponForm
        onSubmit={e => {
          e.preventDefault();
          this.addCoupon();
        }}
      >
        <ModalHeader>
          <CloseButton onClick={closeGenerateCouponModal}>Close</CloseButton>
          <GenerateCouponButton style={{marginLeft: "10px"}} type="submit">
            Generate Coupon
          </GenerateCouponButton>
          <p>
            <b>Coupon Management</b>
          </p>
        </ModalHeader>
        <GenerateCouponWrapper>
          <StyledLabel>
            <span className="label">Coupon Code*</span>
            <StyledInput
              type="text"
              onChange={handleInput}
              value={couponCode}
              name="couponCode"
              placeholder="Eg. Shawer30"
              required
            />
          </StyledLabel>
          <StyledLabel>
            <span className="label">Coupon Name*</span>
            <StyledInput
              type="text"
              onChange={handleInput}
              value={couponName}
              name="couponName"
              placeholder="Eg. 30% Cashback"
              required
            />
          </StyledLabel>
          <StyledLabel>
            <span className="label">Coupon Type*</span>
            <StyledSelect
              isLoading={couponOptionsLoading}
              options={couponTypeOptions}
              value={selectedCouponType}
              name="couponType"
              styles={{
                control: () => ({
                  display: "flex",
                  border: "1px solid black",
                }),
              }}
              onChange={handleSelect}
              isSearchable={false}
              required
            />
          </StyledLabel>
          {selectedCouponType && selectedCouponType.value === "flat" && (
            <StyledLabel>
              <span className="label">Amount*</span>
              <StyledInput
                type="number"
                onChange={handleInput}
                value={amount}
                name="amount"
                required
              />
            </StyledLabel>
          )}
          {selectedCouponType && selectedCouponType.value === "percentage" && (
            <>
              <StyledLabel>
                <span className="label">Percentage*</span>
                <StyledInput
                  type="number"
                  onChange={handleInput}
                  value={percentage}
                  name="percentage"
                  required
                />
              </StyledLabel>
              <StyledLabel>
                <span className="label">Max Amount*</span>
                <StyledInput
                  type="number"
                  onChange={handleInput}
                  value={maxAmount}
                  name="maxAmount"
                  required
                />
              </StyledLabel>
            </>
          )}
          <StyledLabel>
            <span className="label">Min Value*</span>
            <StyledInput
              type="number"
              onChange={handleInput}
              value={minValue}
              name="minValue"
              required
            />
          </StyledLabel>
          <StyledLabel>
            <span className="label">Max Value*</span>
            <StyledInput
              type="number"
              onChange={handleInput}
              value={maxValue}
              name="maxValue"
              required
            />
          </StyledLabel>
          <StyledLabel>
            <span className="label">Per User Limit*</span>
            <StyledInput
              type="number"
              onChange={handleInput}
              value={perUserLimit}
              name="perUserLimit"
              required
            />
          </StyledLabel>
          <StyledLabel>
            <span className="label">Usage Limit*</span>
            <StyledInput
              type="number"
              onChange={handleInput}
              value={usageLimit}
              name="usageLimit"
              required
            />
          </StyledLabel>
        </GenerateCouponWrapper>
      </AddCouponForm>
    );
  }
}

export default GenerateCoupon;
