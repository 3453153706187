import React from "react";
import styled from "styled-components";
import foldericon from "./foldericon.png";
import meetingicon from "./meetingicon.png";
import option from "./option.png";
import QualificationDegreeForm from "./QualificationDegreeForm";
import FullForm from "../Common/FullForm";

const SideBarWrap = styled.div`
  /* height: 90vh; */
  max-height: 100%;
  margin-left: 2em;
  margin-top: 2em;
  border-radius: 6px;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
`;
const SideBarLabel = styled.label`
  font-size: 10px;
  margin-left: 2.3em;
`;
const ButtonIcon = styled.img`
  height: 30px;
`;

const ButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4em;
  margin-left: 0.1em;
  margin-right: 0.1em;
`;
const Button = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1em 2em 1em 2em;
  padding: 1.5em;
  width: 250px;
  border-radius: 5px;
  border: solid 1px #e9eff4;
  outline: 0px;
  cursor: pointer;
  /* background-color: #ffffff; */
  /* color: #323c47; */
  color: ${props => (props.active ? "#fff" : "#323c47")};
  background-color: ${props => (props.active ? "#39a1dd" : "#ffffff")};
`;
const OptionIcon = styled.img`
  height: 20px;
`;
class ExpertSideBar extends React.Component {
  state = {
    // isPersonalInfoActive: true,
    // isPersonalInfoClicked: false,
    // isEducationInfoClicked: false,
  };

  // onPersonalDetailsClick = () => {
  //   this.setState({
  //     isPersonalInfoClicked: true,
  //   });
  // };

  // onEducationDetailsClick = () => {
  //   this.setState({
  //     isPersonalInfoClicked: false,
  //     isEducationInfoClicked: true,
  //   });
  // };

  render() {
    // const {isPersonalInfoClicked, isEducationInfoClicked} = this.state;
    const {
      personalInfoClicked,
      educationInfoClicked,
      serviceChargesInfoClicked,
      skillsInfoClicked,
      experienceInfoClicked,
      financialInfoClicked,
      reviewsAndratingsClicked,

      isPersonalInfoActive,
      isEducationInfoActive,
      isServiceChargesInfoActive,
      isSkillsInfoActive,
      isExperienceInfoActive,
      isfinancialInfoActive,
      isreviewsAndratingsActive,
    } = this.props;

    // const {isPersonalInfoActive} = this.state;

    return (
      <SideBarWrap>
        <ButtonWrap>
          <SideBarLabel>DOCUMENTS</SideBarLabel>
          {/* <Button onClick={this.onPersonalDetailsClick}> */}
          <Button onClick={personalInfoClicked} active={isPersonalInfoActive}>
            <ButtonIcon src={foldericon} />
            Personal Information
            <OptionIcon src={option} />
          </Button>
          {/* {isPersonalInfoClicked ? <FullForm /> : null} */}
          {/* <Button onClick={this.onEducationDetailsClick}> */}
          {/* <Button onClick={educationInfoClicked} active={isEducationInfoActive}>
            <ButtonIcon src={foldericon} />
            Education Details
            <OptionIcon src={option} />
          </Button> */}
          {/* {isEducationInfoClicked ? <QualificationDegreeForm /> : null} */}
          <Button
            onClick={serviceChargesInfoClicked}
            active={isServiceChargesInfoActive}
          >
            <ButtonIcon src={foldericon} />
            Service Charges Details
            <OptionIcon src={option} />
          </Button>
          {/* <Button onClick={skillsInfoClicked} active={isSkillsInfoActive}>
            <ButtonIcon src={foldericon} />
            Skill Details
            <OptionIcon src={option} />
          </Button> */}
          <Button
            onClick={experienceInfoClicked}
            active={isExperienceInfoActive}
          >
            <ButtonIcon src={foldericon} />
            Experience
            <OptionIcon src={option} />
          </Button>
          <Button onClick={financialInfoClicked} active={isfinancialInfoActive}>
            <ButtonIcon src={foldericon} />
            Finacial Information
            <OptionIcon src={option} />
          </Button>
          <Button
            onClick={reviewsAndratingsClicked}
            active={isreviewsAndratingsActive}
          >
            <ButtonIcon src={foldericon} />
            Reviews and Ratings
            <OptionIcon src={option} />
          </Button>
        </ButtonWrap>
      </SideBarWrap>
    );
  }
}
// const ExpertSideBar = () => (

// );

export default ExpertSideBar;
