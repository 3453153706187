import React from "react";
import NavigationBar from "../NavigationBar/NavigationBar";
import SideBar from "../NavigationBar/SideBar";
import styled from "styled-components";
import axios from "axios";
import Select from "react-select";
import {SmartToaster, toast} from "react-smart-toaster";
import {apiURL, generateSHA2, createBuffer} from "../../utils/util";

const FullSection = styled.section`
  background-color: #fafbfc;
  display: grid;
  grid-template-rows: 1fr auto;
  max-height: 100%;

  /* grid-template-columns: auto auto; */
`;

const ColumnOneContentWrap = styled.div``;
const ColumnTwoContentWrap = styled.div`
  width: 250px;
`;
const ColumnWrap = styled.div`
  display: flex;
  width: 100%;
`;

const ChatListLabel = styled.label`
  font-size: 17px;
  color: #1a173b;
`;

const LabelInputWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 4.5em;
  margin-left: 4.5em;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  width: 1050px;
`;

const LabelInputWrap1 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 4.5em;
  margin-left: 4.5em;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  width: 500px;
`;

const LableInput = styled.div`
  margin: 20px;
  width: ${props => (props.full ? 840 : 400)}px;
`;

const Label = styled.label`
  font-size: 16px;
  color: #323c47;
`;

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 5rem 2rem;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  margin-top: 25px;
  font-size: 16px;
`;

const options = [
    {
      value: true,
      label: "Yes",
    },
    {
      value: false,
      label: "No",
    },
  ];

class ScheduleChatSettings extends React.Component {

    state = {
        value: false,
        optionSelected: null
      };
    
      componentDidMount() {
        this.getScheduleChatSetting();
        this.handleOptions();
      }
    
      getScheduleChatSetting = async () => {
        const email = window.sessionStorage.getItem("email");
        const password = window.sessionStorage.getItem("token");
        try {
          const buffer = createBuffer({email});
          const signature = generateSHA2(buffer, password);
          axios
            .post(
              `${apiURL}/schedulechatsetting/ScheduleChatSetting`,
              {
                json_string: JSON.stringify({
                  email,
                }),
              },
              {headers: {signature, email, channel: "web"}},
            )
            .then(response => {
              if (
                response.status >= 200 &&
                response.status < 300 &&
                response.data
              ) {
                const {content} = response.data;
                console.log("DATA",content.physical_meeting)
                this.setState({
                  value: content.physical_meeting,
                  optionSelected: content.physical_meeting
                },
                () => this.handleOptions()
                );
              }
              else {
                toast.error("Couldn't get the data");
              }
            });
        } catch (error) {
          toast.error("Couldn't get the data");
        }
      };

      handleOptionSelectedChange = optionSelected => {
        this.setState({optionSelected});
      };

      handleOptions = () => {
        let {optionSelected, value} = this.state;
        // console.log("language", personal_info);
        if (value !== null) {
            if(value == false) {
                optionSelected = {
                    value: value,
                    label: "No",
                };
            } else {
                optionSelected = {
                    value: true,
                    label: "Yes",
                };
            }

          this.setState({optionSelected});
        }
      };

      updatePercentage = async () => {
        const email = window.sessionStorage.getItem("email");
        const password = window.sessionStorage.getItem("token");
    
        const {optionSelected} = this.state;
    
        try {
          const buffer = createBuffer({email, physical_meeting: optionSelected.value});
          const signature = generateSHA2(buffer, password);
    
          axios
            .post(
              `${apiURL}/schedulechatsetting/UpdateScheduleChatSetting`,
              {
                json_string: JSON.stringify({
                  email,
                  physical_meeting: optionSelected.value,
                }),
              },
              {headers: {signature, email, channel: "web"}},
            )
            .then(response => {
              if (response.status >= 200 && response.status < 300) {
                toast.success("Successfully updated");
                this.getScheduleChatSetting();
              } else {
                toast.error("Couldn't update the data");
              }
            });
        } catch (error) {
          toast.error("Couldn't update the data");
        }
      };

      handleSubmit = e => {
          console.log("SUMIT BUTTON PRESSED")
          e.preventDefault();
          this.updatePercentage();
      }

    render() {
        const {
            value,
            optionSelected
          } = this.state;
      return (
        <FullSection>
            <SmartToaster store={toast} lightBackground position="top_center" />
            <NavigationBar />
            <SideBar />
            <ColumnWrap>
                <ColumnOneContentWrap>
                <Form onSubmit={this.handleSubmit}>
                    <LabelInputWrap>
                    <ChatListLabel>Schedule Chat Settings</ChatListLabel>
                    </LabelInputWrap>
                    <LabelInputWrap1>
                        <LableInput>
                            <Label>Show Physical Meeting</Label>
                            <Select
                                name="physical_meeting"
                                value={optionSelected}
                                onChange={
                                    e => {
                                        this.handleOptionSelectedChange(e)
                                    }
                                }
                                options={options}
                                styles={{
                                control: () => ({
                                    marginTop: "10px",
                                    display: "flex",
                                    borderRadius: "4px",
                                    border: "1px solid #e9eff4",
                                }),
                                }}
                            />
                        </LableInput>
                    </LabelInputWrap1>
                    <Button type="submit">Save</Button>
                    </Form>
                </ColumnOneContentWrap>
            </ColumnWrap>
        </FullSection>
      )
    }
  }

export default ScheduleChatSettings