import React from "react";
import styled from "styled-components";

import img from "./login-img.svg";
import logo from "./shawerlogo.png";
import "../../styles/global.css";

import LoginBoxAuth from "./LogibBoxAuth";

const FullSection = styled.div`
  background: url(${img});
  height: 100vh;
  /* background-size: contain; */
  background-size: cover;
`;

const LoginboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 10em;
`;
const ContentHolder = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  flex-grow: 1;
`;
const ShawerAdminLabel = styled.label`
  font-size: 23px;
  color: #ffffff;
  padding: 35px;
`;

const ShawerAdminLabel2 = styled.label`
  opacity: 0.7;

  font-size: 20px;
  color: #ffffff;
  text-align: center;
`;

const ShawerLogo = styled.img`
  width: 200px;
`;

const Login = ({history, match}) => (
  <FullSection>
    <LoginboxContainer>
      <ContentHolder>
        <ShawerLogo src={logo} />
        <ShawerAdminLabel>Welcome to Shawer admin portal</ShawerAdminLabel>
        <ShawerAdminLabel2>
          Thousands of Experts ready to answer <br /> & help your business.
        </ShawerAdminLabel2>
      </ContentHolder>

      <LoginBoxAuth history={history} />
    </LoginboxContainer>
  </FullSection>
);
export default Login;
