import React from "react";
import styled from "styled-components";
import axios from "axios";
import shortid from "shortid";
import NavigationBar from "../NavigationBar/NavigationBar";
import SideBar from "../NavigationBar/SideBar";
import Ecards from "../Experts/Ecards";
import DashboardChartData from "./DashboardChartData";
import ExpertBarChart from "./ExpertBarChart";
import UserBarChart from "./UserBarChart";
import DoughnutChart from "./DoughnutChart";
import DashboardBarChart from "./DashboardBarChart";
import MixChart from "./MixChart";
import CircularProgressbar from "./CircularProgressBar";
import PieChart from "./PieChart";
// api URL
import {apiURL, createBuffer, generateSHA2} from "../../utils/util";

// import DashboardTable from "./DashboardTable";
// import FullForm from "../Common/FullForm";

const FullSection = styled.div`
  background-color: #fafbfc;
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
`;
const MixChartWrap = styled.div`
  /* border: 1px solid #fafbfc; */
  padding: 30px;
  border: 1px solid #f0f0f0;
`;

const PieChartWrap = styled.div`
  padding: 30px;
`;

const MixChartLabel = styled.div`
  padding-left: 30px;
  padding-bottom: 30px;
  font-size: 20px;
  color: #004d9e;
`;

const RowWrap = styled.div`
  max-width: 1270px;
  margin-top: 30px;
  margin-left: 100px;
  margin-right: 50px;
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.02);
  background-color: #ffffff;
`;
const RowOneWrap = styled.div``;
const RowTwoWrap = styled.div`
  display: flex;
  /* padding-top: 50px;
  padding-left: 50px; */
`;
const TitleFilterWrap = styled.div`
  margin-top: 1.2em;
  margin-left: 4.5em;
`;
const TitleLabel = styled.label`
  font-size: 17px;
  color: #1a173b;
  margin-left: 24px;
`;

const CardLabel = styled.label`
  color: #354052;
  font-size: 15px;
  padding-left: 30px;
  margin-top: 30px;
  margin-bottom: 70px;
`;

const PieChartLabelWrap = styled.div`
  /* display: flex;
  justify-items: center; */
  margin-left: 150px;
`;

const NumberLabel = styled.label`
  margin-right: 3px;
  font-size: 20px;
  /* color: #004d9e; */
  color: ${props => (props.green ? "#00B936" : "#004d9e")};
`;
const MonthLabel = styled.label`
  font-size: 15px;
`;
const YearLabel = styled.label`
  font-size: 15px;
`;

const EcardWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 3em;
  padding-top: 1em;
`;

const CardContentWrap = styled.div`
  width: 100%;
  border: 1px solid #f0f0f0;
  padding-left: 3em;
  padding-right: 3em;
  display: flex;
  flex-direction: column;
  justify-content: ${props => (props.transaction ? "flex-start" : "center")};
`;
const CategoryLabel = styled.label`
  padding-right: 40px;

  /* color: #999999; */
  color: ${props => (props.transaction ? "#000000" : "#999999")};
  /* font-size: 13px; */
  font-size: ${props => (props.transaction ? 17 : 13)}px;
`;
const CategoryLabelCount = styled.label`
  color: #000000;
  color: ${props => (props.transaction ? "#999999" : "#000000")};
  font-size: 15px;
  text-align: right;
`;
const HighlightTitle = styled.div`
  padding-left: 17px;
  padding-bottom: 20px;
  font-size: 18px;
  /* color: #004d9e; */
  color: ${props => (props.green ? "#25bb80" : "#004d9e")};
`;
const CategoryContentWrap = styled.div`
  display: flex;
  padding: 1em;
  justify-content: space-between;
`;

// const email = "shahjanushi239@gmail.com";

// const password = "523d14437dd0c3c76d8f501544313059";

class Dashboard extends React.Component {
  state = {
    id: "",
    getHighestNoofExpertReg: [],
    typeofchats: {},
    highlightDet: {},
    expertBarchartDet: {},
    userBarchartDet: {},
  };

  componentDidMount() {
    this.getOverviewDetails();
    this.getPieChartDetails();
    this.getExpertBarChartDetails();
    this.getHighlightsDetails();
    this.getUserBarChartDetails();
  }

  getPieChartDetails = () => {
    const email = window.sessionStorage.getItem("email");
    const password = window.sessionStorage.getItem("token");
    const buffer = createBuffer({email});
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/overview/NoOfTypeOfChats`,
        {
          json_string: JSON.stringify({email}),
        },
        {headers: {signature, email, channel: "web"}},
      )

      // .post(`${apiURL}/overview/NoOfTypeOfChats`, {})

      .then(response => {
        // console.log(response);

        if (response.status === 200 && response.data) {
          // console.log(" pie chart Data", response.data);
          const {data} = response;
          // console.log(" pie chart Data.....", data.content);

          this.setState({typeofchats: data.content});
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };

  getOverviewDetails = () => {
    const email = window.sessionStorage.getItem("email");
    const password = window.sessionStorage.getItem("token");
    const buffer = createBuffer({email});
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/overview/HighestNumberofExpertRegisteredByCat`,
        {
          json_string: JSON.stringify({email}),
        },
        {headers: {signature, email, channel: "web"}},
      )

      // .post(`${apiURL}/overview/HighestNumberofExpertRegisteredByCat`, {})

      .then(response => {
        // console.log(response);

        if (response.status === 200 && response.data) {
          // console.log(response.data);
          const {data} = response;
          // console.log("Data getHighestNoofExpertReg", data.content);

          this.setState({getHighestNoofExpertReg: data.content});
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };

  getExpertBarChartDetails = () => {
    const email = window.sessionStorage.getItem("email");
    const password = window.sessionStorage.getItem("token");
    const buffer = createBuffer({email});
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/overview/ExpertBarchart`,
        {
          json_string: JSON.stringify({email}),
        },
        {headers: {signature, email, channel: "web"}},
      )

      // .post(`${apiURL}/overview/ExpertBarchart`, {})

      .then(response => {
        // console.log(response);

        if (response.status === 200 && response.data) {
          // console.log(response.data);
          const {data} = response;
          // console.log("Expert Data", data.content);

          this.setState({expertBarchartDet: data.content});
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };

  getUserBarChartDetails = () => {
    const email = window.sessionStorage.getItem("email");
    const password = window.sessionStorage.getItem("token");
    const buffer = createBuffer({email});
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/overview/UserBarchart`,
        {
          json_string: JSON.stringify({email}),
        },
        {headers: {signature, email, channel: "web"}},
      )

      //  .post(`${apiURL}/overview/UserBarchart`, {})

      .then(response => {
        // console.log(response);

        if (response.status === 200 && response.data) {
          // console.log(response.data);
          const {data} = response;
          // console.log("User Data", data.content);

          this.setState({userBarchartDet: data.content});
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };

  getHighlightsDetails = () => {
    const email = window.sessionStorage.getItem("email");
    const password = window.sessionStorage.getItem("token");
    const buffer = createBuffer({email});
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/overview/HighLight`,
        {
          json_string: JSON.stringify({email}),
        },
        {headers: {signature, email, channel: "web"}},
      )

      // .post(`${apiURL}/overview/HighLight`, {})

      .then(response => {
        // console.log(response);

        if (response.status === 200 && response.data) {
          // console.log(response.data);
          const {data} = response;
          // console.log("Highlight Data", data.content);

          this.setState({highlightDet: data.content});
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };

  render() {
    const {
      getHighestNoofExpertReg,
      typeofchats,
      highlightDet,
      expertBarchartDet,
      userBarchartDet,
    } = this.state;
    return (
      <FullSection>
        <NavigationBar />
        <SideBar />

        <TitleFilterWrap>
          <TitleLabel>Data Overview</TitleLabel>
        </TitleFilterWrap>
        <RowWrap>
          <RowOneWrap>
            <Ecards>
              {/* <EcardWrap>
            <CardLabel>Number of users registered</CardLabel>
            <NumMonthWrap>
              <NumberLabel>200</NumberLabel>
              <MonthLabel>June 2018</MonthLabel>
            </NumMonthWrap>
            <BarChart />
          </EcardWrap> */}

              <EcardWrap>
                <CardLabel>
                  <NumberLabel>{userBarchartDet.total_no_of_user} </NumberLabel>{" "}
                  users registered to-date
                  {/* <MonthLabel>June</MonthLabel>
              <YearLabel>2018</YearLabel> */}
                </CardLabel>
                {/* <NumMonthWrap>
              <MonthLabel>2018</MonthLabel>
            </NumMonthWrap> */}
                <UserBarChart userBarchartDet={userBarchartDet} />
              </EcardWrap>

              {/* <CircularProgressbar
            squareSize={squareSize}
            strokeWidth={strokeWidth}
            percentage={percentage}
          /> */}

              {/* <EcardWrap>
            <CardLabel>Number of experts registered</CardLabel>
            <NumMonthWrap>
              <NumberLabel>90</NumberLabel>
              <MonthLabel>June 2018</MonthLabel>
            </NumMonthWrap>
            <BarChart />
          </EcardWrap> */}

              <EcardWrap>
                <CardLabel>
                  <NumberLabel green>
                    {expertBarchartDet.total_no_of_expert}
                  </NumberLabel>
                  experts registered to-date
                  {/* <MonthLabel>June</MonthLabel>
              <YearLabel>2018</YearLabel> */}
                </CardLabel>
                {/* <NumMonthWrap>
              <MonthLabel>June 2018</MonthLabel>
            </NumMonthWrap> */}
                {/* {console.log("expertBarchartDet", expertBarchartDet)} */}
                <ExpertBarChart expertBarchartDet={expertBarchartDet} />
              </EcardWrap>

              {/* <CircularProgressbar
            squareSize={squareSize}
            strokeWidth={strokeWidth}
            percentage={percentage}
          /> */}
              <CardContentWrap>
                <HighlightTitle>
                  Highest number of experts registered by Categories
                </HighlightTitle>
                {getHighestNoofExpertReg.map(index => (
                  <CategoryContentWrap key={shortid.generate()}>
                    <CategoryLabel>{index.category_name}</CategoryLabel>
                    <CategoryLabelCount>
                      {index.no_of_expert_registered}
                    </CategoryLabelCount>
                  </CategoryContentWrap>
                ))}

                {/* 
{getCategoryList.map(index => (
  <CategoryInputWrap key={shortid.generate()}>
    <CategoryLabel> {index.category_name}</CategoryLabel>
    <EditRemoveButtonWrap>
      <EditButton>Edit</EditButton>
      <RemoveButton>Remove</RemoveButton>
    </EditRemoveButtonWrap>
  </CategoryInputWrap>
))} */}

                {/* <CategoryContentWrap>
                  <CategoryLabel>
                    {getHighestNoofExpertReg.category_name}
                  </CategoryLabel>
                  <CategoryLabelCount>
                    {getHighestNoofExpertReg.no_of_expert_registered}
                  </CategoryLabelCount>
                </CategoryContentWrap>
                <CategoryContentWrap>
                  <CategoryLabel>Home & Leisure</CategoryLabel>
                  <CategoryLabelCount>1</CategoryLabelCount>
                </CategoryContentWrap>
                <CategoryContentWrap>
                  <CategoryLabel>Computers & Programming</CategoryLabel>
                  <CategoryLabelCount>16</CategoryLabelCount>
                </CategoryContentWrap>
                <CategoryContentWrap>
                  <CategoryLabel>Spirituality & Religion</CategoryLabel>
                  <CategoryLabelCount>16</CategoryLabelCount>
                </CategoryContentWrap> */}
              </CardContentWrap>
            </Ecards>
          </RowOneWrap>
          <RowTwoWrap>
            <PieChartWrap>
              <PieChartLabelWrap>
                <NumberLabel>{typeofchats.total_chat}</NumberLabel> total number
                of chats to-date
              </PieChartLabelWrap>
              <PieChart typeofchats={typeofchats} />
            </PieChartWrap>
            {/* <MixChartWrap>
          <MixChartLabel>Transaction</MixChartLabel>
          <CardLabel>Total Amount Received</CardLabel>
          <MixChart />
        </MixChartWrap> */}
            <CardContentWrap>
              <HighlightTitle>Highlights</HighlightTitle>
              <CategoryContentWrap>
                <CategoryLabel transaction>Most Active Expert</CategoryLabel>
                <CategoryLabelCount transaction>
                  {highlightDet.most_active_expert}
                </CategoryLabelCount>
              </CategoryContentWrap>

              <CategoryContentWrap>
                <CategoryLabel transaction>No of Refund Cases</CategoryLabel>
                <CategoryLabelCount transaction>
                  {highlightDet.total_refund_cases}
                </CategoryLabelCount>
              </CategoryContentWrap>
              <CategoryContentWrap>
                <CategoryLabel transaction>No of Off-line Chats </CategoryLabel>
                <CategoryLabelCount transaction>
                  {highlightDet.total_offline_chat}
                </CategoryLabelCount>
              </CategoryContentWrap>
              <CategoryContentWrap>
                <CategoryLabel transaction>No of On-line Chats </CategoryLabel>
                <CategoryLabelCount transaction>
                  {highlightDet.total_online_chat}
                </CategoryLabelCount>
              </CategoryContentWrap>
            </CardContentWrap>
          </RowTwoWrap>
        </RowWrap>

        {/* <DashboardTable /> */}
        {/* <FullForm /> */}
      </FullSection>
    );
  }
}

// const Dashboard = () => (
//   <FullSection>
//     <NavigationBar />
//     <SideBar />

//     <TitleFilterWrap>
//       <TitleLabel>Data Overview</TitleLabel>
//     </TitleFilterWrap>
//     <RowWrap>
//       <RowOneWrap>
//         <Ecards>
//           {/* <EcardWrap>
//             <CardLabel>Number of users registered</CardLabel>
//             <NumMonthWrap>
//               <NumberLabel>200</NumberLabel>
//               <MonthLabel>June 2018</MonthLabel>
//             </NumMonthWrap>
//             <BarChart />
//           </EcardWrap> */}

//           <EcardWrap>
//             <CardLabel>
//               <NumberLabel>200</NumberLabel> users registered to-date
//               {/* <MonthLabel>June</MonthLabel>
//               <YearLabel>2018</YearLabel> */}
//             </CardLabel>
//             {/* <NumMonthWrap>
//               <MonthLabel>2018</MonthLabel>
//             </NumMonthWrap> */}
//             <UserBarChart />
//           </EcardWrap>

//           {/* <CircularProgressbar
//             squareSize={squareSize}
//             strokeWidth={strokeWidth}
//             percentage={percentage}
//           /> */}

//           {/* <EcardWrap>
//             <CardLabel>Number of experts registered</CardLabel>
//             <NumMonthWrap>
//               <NumberLabel>90</NumberLabel>
//               <MonthLabel>June 2018</MonthLabel>
//             </NumMonthWrap>
//             <BarChart />
//           </EcardWrap> */}

//           <EcardWrap>
//             <CardLabel>
//               <NumberLabel green>90</NumberLabel> experts registered to-date
//               {/* <MonthLabel>June</MonthLabel>
//               <YearLabel>2018</YearLabel> */}
//             </CardLabel>
//             {/* <NumMonthWrap>
//               <MonthLabel>June 2018</MonthLabel>
//             </NumMonthWrap> */}
//             <ExpertBarChart />
//           </EcardWrap>

//           {/* <CircularProgressbar
//             squareSize={squareSize}
//             strokeWidth={strokeWidth}
//             percentage={percentage}
//           /> */}
//           <CardContentWrap>
//             <HighlightTitle>
//               Highest number of experts registered by Categories
//             </HighlightTitle>
//             <CategoryContentWrap>
//               <CategoryLabel>Home & Leisure</CategoryLabel>
//               <CategoryLabelCount>16</CategoryLabelCount>
//             </CategoryContentWrap>
//             <CategoryContentWrap>
//               <CategoryLabel>Computers & Programming</CategoryLabel>
//               <CategoryLabelCount>16</CategoryLabelCount>
//             </CategoryContentWrap>
//             <CategoryContentWrap>
//               <CategoryLabel>Spirituality & Religion</CategoryLabel>
//               <CategoryLabelCount>16</CategoryLabelCount>
//             </CategoryContentWrap>
//             <CategoryContentWrap>
//               <CategoryLabel>Shopping & Style</CategoryLabel>
//               <CategoryLabelCount>16</CategoryLabelCount>
//             </CategoryContentWrap>
//           </CardContentWrap>
//         </Ecards>
//       </RowOneWrap>
//       <RowTwoWrap>
//         <PieChartWrap>
//           <PieChart />
//         </PieChartWrap>
//         {/* <MixChartWrap>
//           <MixChartLabel>Transaction</MixChartLabel>
//           <CardLabel>Total Amount Received</CardLabel>
//           <MixChart />
//         </MixChartWrap> */}
//         <CardContentWrap>
//           <HighlightTitle>Highlights</HighlightTitle>
//           <CategoryContentWrap>
//             <CategoryLabel transaction>Most Active Expert</CategoryLabel>
//             <CategoryLabelCount transaction> Omar Khalife</CategoryLabelCount>
//           </CategoryContentWrap>
//           <CategoryContentWrap>
//             <CategoryLabel transaction>Most Active User</CategoryLabel>
//             <CategoryLabelCount transaction> Nour Ballane</CategoryLabelCount>
//           </CategoryContentWrap>
//           <CategoryContentWrap>
//             <CategoryLabel transaction>No of Refund Cases</CategoryLabel>
//             <CategoryLabelCount transaction> 23</CategoryLabelCount>
//           </CategoryContentWrap>
//           <CategoryContentWrap>
//             <CategoryLabel transaction>No of Off-line Chats </CategoryLabel>
//             <CategoryLabelCount transaction> 23</CategoryLabelCount>
//           </CategoryContentWrap>
//           <CategoryContentWrap>
//             <CategoryLabel transaction>No of On-line Chats </CategoryLabel>
//             <CategoryLabelCount transaction> 23</CategoryLabelCount>
//           </CategoryContentWrap>
//         </CardContentWrap>
//       </RowTwoWrap>
//     </RowWrap>

//     {/* <DashboardTable /> */}
//     {/* <FullForm /> */}
//   </FullSection>
// );
export default Dashboard;
