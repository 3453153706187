import React from "react";
import styled from "styled-components";
import Modal from "react-modal";
import ReactTable from "react-table";
import "react-table/react-table.css";

import axios from "axios";
import NumberFormat from "react-number-format";
import {SmartToaster, toast} from "react-smart-toaster";
import moment from "moment";

import closebtn from "../AboutShawer/closebtn.png";
import {apiURL, createBuffer, generateSHA2} from "../../utils/util";

const FullTableWrap = styled.div`
  margin-top: 0.6em;
  max-height: 100%;
  width: 1100px;
  background-color: #ffffff;
`;

const TableWrap = styled.div`
  margin-left: 20px;
  width: 1300px;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 25px;
  width: 150px;
`;

const AcceptButton = styled.button`
  background-color: solid 0.5px #c0c5d2;
  color: #c0c5d2;
  // background-color: #00cf31;
  // color: #fff;
  cursor: pointer;
  border-radius: 4px;
  padding: 5px;
  width: 70px;
`;

const RejectButton = styled.button`
  background-color: solid 0.5px #c0c5d2;
  color: #c0c5d2;
  cursor: pointer;
  border-radius: 4px;
  padding: 5px;
  width: 70px;
`;

// Popup css

// const TypeOfRefundRadio = styled.radio``;

const CloseButton = styled.button`
  outline: 0;
  border: none;
  cursor: pointer;
  margin-left: 410px;
  margin-top: -10px;
`;

const CloseButtonImg = styled.img`
  height: 30px;
  width: 30px;
`;

// Reason popup css

const MoreReasonTitle = styled.h2`
  margin-top: 0px;
`;

const DetailsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 190px; */
`;
const DetailsContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between;
  align-items: center; */
`;

const DetailTitle = styled.h5`
  margin: 5px;

  color: #8a96a0;
`;

const DetailContent = styled.span``;

const MoreReasonPopupDiv = styled.div``;

const ButtonTextArea = styled.textarea`
  outline: none;
  border-radius: 4px;
  border: solid 1px #e9eff4;
  /* border: none; */
  width: 450px;
  height: 100px;
  ::-webkit-input-placeholder {
    font-weight: 300;
    color: #0d3868;
    opacity: 0.5;
    padding: 10px;
  }
`;

const Button = styled.button`
  width: 100px;
  border-radius: 5px;
  padding: 5px;
  margin-top: 10px;
  cursor: pointer;
  background-color: #085db0;
  color: #fff;
  height: 30px;
  outline: 0;
`;

const Bold = styled.b`
  font-weight: bolder;
  color: black;
`;

const Label = styled.label``;

const customStylesMore = {
  content: {
    height: "30%",
    width: "30%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    // border: "0px",
  },
};

const customStylesAccept = {
  content: {
    height: "38%",
    width: "34%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    // border: "0px",
  },
};

const customStylesReject = {
  content: {
    height: "38%",
    width: "34%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    // border: "0px",
  },
};

const email = window.sessionStorage.getItem("email");
const password = window.sessionStorage.getItem("token");

const TableHeaderDiv = styled.div`
  /* text-align: left; */
  text-align: ${props => (props.center ? "center" : "left")};
`;

class WithdrawalManagementReactTable extends React.Component {
  state = {
    openReject: false,

    isNotOkClicked: false,
    openReasonMore: false,
    getWithdrawalRequest: [],
    selectedBankDetails: null,
    withdrawal_comment: "",

    loading: true,
    btnClicked: false,
    id: "",
  };

  openPopup = this.openPopup.bind(this);

  closePopup = this.closePopup.bind(this);

  openRejectPopup = this.openRejectPopup.bind(this);

  closeRejectPopup = this.closeRejectPopup.bind(this);

  closeReasonMorePopup = this.closeReasonMorePopup.bind(this);

  // lifecycle methods

  componentDidMount() {
    this.getWithdrawalManagementDetails();
  }

  notOkClicked = () => {
    this.setState({
      isNotOkClicked: true,
    });
  };

  // api calls

  getWithdrawalManagementDetails = () => {
    // axios
    //   .post(`${apiURL}/withdrawal/GetWithdrawalRequest`, {})

    const buffer = createBuffer({email});
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/withdrawal/GetWithdrawalRequest`,
        {
          json_string: JSON.stringify({email}),
        },
        {headers: {signature, email, channel: "web"}},
      )

      .then(response => {
        // console.log(response);

        if (response.status === 200 && response.data) {
          // console.log(response.data);
          const {data} = response;
          // console.log("Data.....", data.content);

          this.setState({getWithdrawalRequest: data.content, loading: false});
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };

  updateWithdrawalManagementDetails = () => {
    const {id, withdrawal_comment, btnClicked} = this.state;

    // console.log("Comment", withdrawal_comment);
    // console.log("ID...", id);

    const status = btnClicked ? "done" : "rejected";

    const buffer = createBuffer({
      email,
      withdrawal_id: id,
      withdrawal_comment,
      withdrawal_status: status,
    });
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/withdrawal/UpdateWithdrawalRequest`,
        {
          json_string: JSON.stringify({
            email,
            withdrawal_id: id,
            withdrawal_comment,
            withdrawal_status: status,
          }),
        },
        {headers: {signature, email, channel: "web"}},
      )

      // axios
      //   .post(`${apiURL}/withdrawal/UpdateWithdrawalRequest`, {
      // withdrawal_id: id,
      // withdrawal_comment,
      // withdrawal_status: status,
      //   })

      .then(response => {
        // console.log(response);

        this.getWithdrawalManagementDetails();
      })
      .catch(error => {
        // console.log(error);
      });
  };

  // Reason More.. methods

  handleOnChange = e => {
    this.setState({withdrawal_comment: e.target.value});
  };

  handleOnRejectChange = e => {
    this.setState({withdrawal_comment: e.target.value});
  };

  openReasonMorePopup = e => {
    const {index} = e.target.dataset;
    this.setState({openReasonMore: true, selectedBankDetails: index});
  };

  modalrender = () => {
    const {selectedBankDetails, getWithdrawalRequest} = this.state;

    if (selectedBankDetails !== null) {
      const bankDetails = getWithdrawalRequest[selectedBankDetails];

      return (
        <div>
          <CloseButton more onClick={this.closeReasonMorePopup}>
            <CloseButtonImg src={closebtn} />
          </CloseButton>
          <MoreReasonTitle>Bank Details</MoreReasonTitle>
          <DetailsContentWrap>
            <DetailsWrap>
              <DetailTitle>Full Name</DetailTitle>
              <DetailContent>
                {bankDetails.bank_details[0].full_name}
              </DetailContent>
            </DetailsWrap>
            <DetailsWrap>
              <DetailTitle>Nick Name</DetailTitle>
              <DetailContent>
                {bankDetails.bank_details[0].nick_name}
              </DetailContent>
            </DetailsWrap>

            <DetailsWrap>
              <DetailTitle>IBAN No.</DetailTitle>
              <DetailContent>{bankDetails.bank_details[0].iban}</DetailContent>
            </DetailsWrap>
            <DetailsWrap>
              <DetailTitle>Bank Name</DetailTitle>
              <DetailContent>
                {bankDetails.bank_details[0].bank_name}
              </DetailContent>
            </DetailsWrap>
          </DetailsContentWrap>
        </div>
      );
    }
    return null;
  };

  onDoneAcceptButtonClick = () => {};

  closeReasonMorePopup() {
    this.setState({openReasonMore: false});
  }

  openPopup(e) {
    const {getWithdrawalRequest} = this.state;
    const {index} = e.target.dataset;

    this.setState({
      openAccept: true,
      btnClicked: true,
      id: getWithdrawalRequest[index].id,
    });
  }

  closePopup() {
    this.setState({openAccept: false, btnClicked: false});
  }

  openRejectPopup(e) {
    const {getWithdrawalRequest} = this.state;
    const {index} = e.target.dataset;

    this.setState({
      openReject: true,
      btnClicked: false,
      id: getWithdrawalRequest[index].id,
    });
  }

  closeRejectPopup() {
    this.setState({openReject: false});
  }

  render() {
    const {
      openAccept,
      getWithdrawalRequest,
      withdrawal_comment,
      openReasonMore,
      openReject,

      loading,
    } = this.state;
    const columns = [
      {
        id: "date",

        Header: () => (
          <TableHeaderDiv center>
            <b>Date</b>
          </TableHeaderDiv>
        ),
        width: 170,
        accessor: d => {
          return moment(d.withdrawal_initiated_date)
            .local()
            .format("DD-MM-YYYY");
        },
        sortMethod: (a, b) => {
          const splitdate1 = a.split("-");
          const splitdate2 = b.split("-");
          const date1 = new Date(
            splitdate1[2],
            splitdate1[1],
            splitdate1[0],
          ).getTime();
          const date2 = new Date(
            splitdate2[2],
            splitdate2[1],
            splitdate2[0],
          ).getTime();
          // console.log("value of date", date1);

          // const date2
          if (date1 > date2) return 1;
          return -1;
        },

        style: {textAlign: "center", color: "#4D4F5C"},
      },
      {
        id: "expert",
        width: 220,
        Header: () => (
          <TableHeaderDiv center>
            <b>Expert Name</b>
          </TableHeaderDiv>
        ),

        accessor: d => d.expert_name,
        style: {color: "#4D4F5C"},
      },

      {
        id: "bankdetails",

        Header: () => (
          <TableHeaderDiv center>
            <b>Bank Details</b>
          </TableHeaderDiv>
        ),

        Cell: props => {
          return (
            <div>
              <Label>
                <Bold>Bank Name :</Bold>{" "}
                {props.original.bank_details[0].bank_name}
              </Label>
              <br />
              <Label>
                <Bold>Iban No. : </Bold>
                {props.original.bank_details[0].iban}
              </Label>
              <br />
              <Label>
                <Bold>Full Name : </Bold>{" "}
                {props.original.bank_details[0].full_name}
              </Label>
              <br />
              <Label>
                <Bold> Nick Name : </Bold>{" "}
                {props.original.bank_details[0].nick_name}
              </Label>
            </div>
          );
        },

        // Cell: props => {
        //   // console.log(props);
        //   if (props.value !== "-") {
        //     // console.log("Props", props.value);
        //     const ibannumber = props.value[0].iban;
        //     // console.log(ibannumber);
        //     const minifiedString = ibannumber.substring(0, 12);
        //     // console.log(props);
        //     // console.log(minifiedString);
        //     return (
        //       <div>
        //         {props.value ? (
        //           <div>
        //             {/* {`${ibannumber}`} */}
        //             {`${minifiedString}`}
        //             <label>... </label>
        //             <MoreReasonButton
        //               onClick={e => this.openReasonMorePopup(e)}
        //               data-index={props.index}
        //             >
        //               {/* {console.log("index", props)} */}
        //               More
        //             </MoreReasonButton>
        //           </div>
        //         ) : (
        //           "-"
        //         )}
        //       </div>
        //     );
        //   }
        //   return props.value;
        // },
        style: {color: "#4D4F5C"},
      },

      {
        id: "amount",
        width: 100,
        Header: () => (
          <TableHeaderDiv center>
            <b>Amount</b>
          </TableHeaderDiv>
        ),
        // accessor: "amount",
        accessor: d => d.withdrawal_amount,
        style: {textAlign: "center", color: "#4D4F5C"},
        Cell: props => {
          // console.log(props);
          return (
            <span>
              {props.value ? (
                <NumberFormat
                  value={props.value}
                  decimalScale={2}
                  displayType="text"
                />
              ) : (
                0
              )}
            </span>
          );
        },
      },

      {
        id: "status",
        width: 150,
        Header: () => (
          <TableHeaderDiv center>
            <b>Status</b>
          </TableHeaderDiv>
        ),
        // Header: "Status",
        // accessor: "status",
        accessor: d =>
          d.withdrawal_status === "initiated" ? "Initiated" : " ",
        style: {color: "#4D4F5C", textAlign: "center"},
      },
      {
        Header: () => (
          <TableHeaderDiv center>
            <b>Approve</b>
          </TableHeaderDiv>
        ),
        width: 200,
        Cell: props => {
          return (
            <ButtonWrap>
              <AcceptButton onClick={this.openPopup} data-index={props.index}>
                Accept
              </AcceptButton>
              <RejectButton
                onClick={this.openRejectPopup}
                data-index={props.index}
              >
                Reject
              </RejectButton>
            </ButtonWrap>
          );
        },
        style: {textAlign: "center", color: "#4D4F5C"},
        filterable: false,
      },
    ];
    return (
      <FullTableWrap>
        <SmartToaster store={toast} lightBackground position="top_center" />
        {/* <TitleDiv>
          <Title>January 2018</Title>
          <FilterImage src={filterimg} />
        </TitleDiv> */}
        <TableWrap>
          <ReactTable
            noDataText={loading ? "" : "No Rows Found"}
            loading={loading}
            loadingText="Loading..."
            filterable
            columns={columns}
            data={getWithdrawalRequest}
            defaultPageSize={10}
            defaultSorted={[
              {
                id: "date",
                desc: true,
              },
            ]}
            // expanded={expanded}
            // showPaginationTop
            // showPaginationBottom={false}
          />

          <Modal
            isOpen={openReasonMore}
            onRequestClose={this.closeReasonMorePopup}
            style={customStylesMore}
            ariaHideApp={false}
          >
            <MoreReasonPopupDiv>
              <div>{this.modalrender()}</div>
            </MoreReasonPopupDiv>
          </Modal>

          {/* ---- Accept button Popup ----  */}

          <Modal
            isOpen={openAccept}
            onRequestClose={this.closePopup}
            style={customStylesAccept}
            ariaHideApp={false}
          >
            <MoreReasonPopupDiv>
              {/* <div>{this.modalrender()}</div> */}
              <CloseButton more onClick={this.closePopup}>
                <CloseButtonImg src={closebtn} />
              </CloseButton>
              <h3>Withdrawal Acceptance</h3>
              <h5>Comment</h5>
              <ButtonTextArea
                value={withdrawal_comment}
                name="withdrawal_comment"
                onChange={e => this.handleOnChange(e)}
                placeholder="if you have any comments about withdrwaral please, write here"
              />
              <Button
                onClick={() => {
                  this.updateWithdrawalManagementDetails();
                  this.closePopup();
                  toast.info("Withdrawal Accepted!");
                }}
              >
                Accept
              </Button>
            </MoreReasonPopupDiv>
          </Modal>

          {/* ---- Reject button Popup ----  */}

          <Modal
            isOpen={openReject}
            onRequestClose={this.closeRejectPopup}
            style={customStylesReject}
            ariaHideApp={false}
          >
            <MoreReasonPopupDiv>
              {/* <div>{this.modalrender()}</div> */}
              <CloseButton more onClick={this.closeRejectPopup}>
                <CloseButtonImg src={closebtn} />
              </CloseButton>
              <h3>Withdrawal Rejection</h3>
              <h5>Comment</h5>
              <ButtonTextArea
                value={withdrawal_comment}
                name="withdrawal_comment"
                placeholder="if you have any comments about withdrwaral please, write here"
                onChange={e => this.handleOnRejectChange(e)}
              />
              <Button
                onClick={() => {
                  this.updateWithdrawalManagementDetails();
                  this.closeRejectPopup();
                  toast.info("Withrawal Rejected!");
                }}
              >
                Reject
              </Button>
            </MoreReasonPopupDiv>
          </Modal>
        </TableWrap>
      </FullTableWrap>
    );
  }
}

export default WithdrawalManagementReactTable;
