import React from "react";
import styled from "styled-components";
import DatePicker from "react-date-picker";
import axios from "axios";
import {SmartToaster, toast} from "react-smart-toaster";

// import DatePicker from "react-date-picker/dist/entry.nostyle";
import "../Common/datetimepicker.css";
import {withFormik, Form, Field, FieldArray, Formik} from "formik";
import fileicon from "../Common/fileicon.png";
import approved from "../Common/approvedbtn.png";
import notapproved from "../Common/notapprovedbtn.png";
import Allapprove from "../Common/Allapprove.svg";

// import DatePicker from "react-date-picker/dist/entry.nostyle";

// api URL
import {apiURL, generateSHA2, createBuffer} from "../../utils/util";

// import "../../styles/global.css";

const FullWrap = styled.div`
  width: 950px;
  /* border: 1px solid black; */
  display: flex;
  flex-wrap: wrap;
  margin-top: 2em;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.23);
  background-color: #ffffff;
  margin-left: 1em;
  border-radius: 6px;
`;
const TwoFieldWrap = styled.div`
  display: flex;
  max-width: 700px;
`;
const ThreeFieldWrap = styled.div`
  display: flex;
`;

const LableInput = styled.div`
  margin: 20px;
  width: ${props => (props.full ? 640 : 270)}px;
`;
const ImageInput = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
  width: 200px;
`;

const Label = styled.label`
  font-size: 12px;
  color: #323c47;
`;
const ImgLabelWrap = styled.div`
  margin-top: 10px;
  display: flex;
`;

const ApproveAllImg = styled.img`
  padding-right: 1em;
  height: 35px;
`;

const Img = styled.img`
  padding-right: 1em;
`;
const ImgLabel = styled.label`
  width: 80px;
  text-align: center;
  /* border: 1px solid black; */
  padding-top: 3px;
`;

const FormField = styled(Field)`
  margin-top: 10px;
  position: ${props => (props.absolute ? "absolute" : "")};
  width: 100%;
  // border: solid 1px #e9eff4;
  border: ${props => (props.active ? "1px solid #d0d0d0" : "none")};
  text-indent: 15px;
  padding: 10px;
  font-size: 17px;
  border-radius: 4px;
  background-color: #ffffff;
  opacity: 0.5;
  ::-webkit-input-placeholder {
    font-family: Ubuntu;
    /* font-size: 20px; */
    font-weight: 300;
    color: #0d3868;
    opacity: 0.5;
    padding: 15px;
    /* text-align: left; */
  }
`;

const ConfirmWrap = styled.div`
  display: flex;
  margin: 20px;
  max-width: 600px;
  justify-content: space-between;
`;
const ConfirmButton = styled.button`
  background-color: #ffffff;
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 17px;
  /* width: 150px; */
  cursor: pointer;
  width: ${props => (props.notapproved ? 170 : 150)}px;
  margin-right: 20px;
  height: 40px;
  text-decoration: ${props => (props.underline ? "underline" : "")};
  color: ${props => (props.underline ? "#39a1dd" : "")};
`;

// const EditButton = styled.button`
//   margin-left: 865px;
//   margin-top: 20px;
//   width: 100px;
//   height: 40px;
//   border-radius: 5px;
//   background-color: #085db0;
//   color: white;
//   font-size: large;
// `;

const EditRemoveButtonWrap = styled.div`
  width: 100px;
  display: flex;
  justify-content: space-between;
  margin-left: 800px;
  margin-top: 20px;
`;

const SaveButton = styled.button`
  border: none;
  color: #39a1dd;
  font-size: 20px;
  outline: 0;
  cursor: pointer;
`;

const EditButton = styled.button`
  border: none;
  color: #d0021b;
  font-size: 20px;
  outline: 0;
  cursor: pointer;
`;

const FormWrap = styled.div`
  width: 900px;
  display: flex;
  flex-wrap: wrap;
`;

const CommentTextBox = styled.textarea`
  /* width: 450px; */
  width: 100%;
  margin: 20px;
  height: 100px;
  font-size: 15px;
  /* margin-left: 120px; */
`;

const DoneButton = styled.button`
  width: 100px;
  height: 40px;
  border-radius: 5px;
  background-color: #085db0;
  color: white;
  cursor: pointer;
  margin-left: 20px;
`;

const ApprovedMsgDiv = styled.div`
  margin-left: 20px;
`;

const ApprovedMsg = styled.label`
  color: #39a1dd;
`;
const ConfirmFullWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

const email = window.sessionStorage.getItem("email");
const password = window.sessionStorage.getItem("token");

export default class ExperienceForm extends React.Component {
  state = {
    expertID: sessionStorage.getItem("expertID"),
    isFieldEnable: false,
    isNotOkClicked: false,
    startDate: new Date(),
    endDate: new Date(),
    experienceDet: [],
    currentEditing: -1,
    admin_comment: "",
    isOkClicked: false,
    notokclicked: false,
    isFieldActive: false,
    isApproveAllClicked: false,
    personal_info: {},
    experience_details:"",
    experience: [
      {
        experience_id: "",
        data: {
          company: "",
          fileList: [],
          from_year: "",
          location: "",
          title: "",
          to_year: "",
        },
      },
    ],
    expertApprovalObj: {
      personal_info_status: null,
      personal_info_comment: null,
      experience_status: null,
      experience_comment: null,
      education_status: null,
      education_comment: null,
      service_charges_status: null,
      service_charges_comment: null,
      skills_status: null,
      skills_comment: null,
    },
    // education: [],
  };

  componentDidMount() {
    this.getParticularExpertDetails();
  }

  getParticularExpertDetails = () => {
    const {expertID} = this.state;

    const buffer = createBuffer({
      email,
      docid: expertID,
    });
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/expert/getParticularExpert`,
        {
          json_string: JSON.stringify({
            email,
            docid: expertID,
          }),
        },
        {headers: {signature, email, channel: "web"}},
      )

      // axios
      //   .post(`${apiURL}/expert/getParticularExpert`, {
      //     docid: expertID,
      //   })
      .then(response => {
        // console.log(response);
        if (response.status === 200 && response.data) {
          const {data} = response;
          this.setState({
            experience: data.experience,
            personal_info: data.personal_info,
            experience_details: data.personal_info.experience_details,
            isNotOkClicked: false,
          });
          // console.log(data);
          // if (this.state.experience) {
          //   this.setState({apiLoading: false});
          // }
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };

  updateExperienceInfo = () => {
    const {expertID,experience_details} = this.state;
    // axios
    //   .post(`${apiURL}/expert/UpdateExperienceDetails`, {
    //     data: experience,
    //   })

    const buffer = createBuffer({
      email,
      expert_id: expertID,
      data: experience_details,
    });
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/expert/UpdateExperienceDetails`,
        {
          json_string: JSON.stringify({
            email,
            expert_id: expertID,
            data: experience_details,
          }),
        },
        {headers: {signature, email, channel: "web"}},
      )

      .then(response => {
        // console.log(response);

        this.getParticularExpertDetails();
      });
    this.setState({isFieldActive: false});
  };

  handleStartChange = startDate => {
    this.setState({startDate});
  };

  handleEndChange = endDate => {
    this.setState({endDate});
  };

  // handleOnButtonClick = index => {
  //   this.setState({currentEditing: index, isFieldActive: true});
  // };

  notOkClicked = () => {
    this.setState({
      isNotOkClicked: true,
    });
  };

  onApproveAllClicked = () => {
    this.setState({
      isApproveAllClicked: true,
    });
  };

  handleOnButtonClick = () => {
    this.setState({isFieldEnable: true, isFieldActive: true});
  };

  handleOnExpChange = (e) => {
    var {experience_details} = this.state;
    console.log(experience_details)
    const {name, value} = e.target;
    experience_details = value
    this.setState({experience_details})
    // const tempObj = {
    //   [name]: value,
    // };

    // const personalInfoObj = experience_details;
    // const copyobj = Object.assign(personalInfoObj, tempObj);

    // this.setState({experience_details});  
  }

  handleOnChange = (e, index) => {
    const {experience} = this.state;

    const editData = experience[index].data;

    // console.log(editData);
    const {value} = e.target;
    const {name} = e.target.dataset;
    const tempObj = {
      [name]: value,
    };

    const updatedObject = Object.assign(editData, tempObj);

    const tempArrayData = {
      data: updatedObject,
    };

    const updatedArrayData = Object.assign(experience[index], tempArrayData);

    experience[index] = updatedArrayData;

    // console.log("Updated Education", experience);

    this.setState({
      experience,
    });
  };

  onOkClicked = () => {
    this.setState({
      isOkClicked: true,
    });
  };

  onNotOkClicked = () => {
    this.setState({
      notokclicked: true,
    });
  };

  handleOnAdminChange = e => {
    this.setState({admin_comment: e.target.value});
  };

  handleExpertApproval = () => {
    this.setState({
      isOkClicked: true,
    });

    // this.setState({});

    setTimeout(() => {
      const {
        expertID,
        isOkClicked,
        isNotOkClicked,
        admin_comment,
        isApproveAllClicked,
      } = this.state;
      const {expertApprovalObj} = this.props;

      let expertStatus = expertApprovalObj.experience_status;

      expertApprovalObj.experience_comment = admin_comment;

      // console.log("ok clicked", isOkClicked);

      // expertStatus = isOkClicked
      //   ? "accepted"
      //   : isNotOkClicked
      //   ? "rejected"
      //   : null;

      if (isOkClicked) {
        if (
          expertStatus === "submitted" ||
          expertStatus === "rejected" ||
          expertStatus === null
        ) {
          expertStatus = "accepted";
        }

        // console.log("Is Ok Clicked called", expertStatus);
      } else {
        // console.log("Is Ok Clicked called", isOkClicked);
      }

      if (isNotOkClicked) {
        expertStatus = "rejected";
        // console.log("Is Not Ok Clicked called", expertStatus);
      }
      expertApprovalObj.experience_status = expertStatus;

      if (isApproveAllClicked) {
        expertStatus = "accepted";
        expertApprovalObj.personal_info_status = expertStatus;
        expertApprovalObj.experience_status = expertStatus;
        expertApprovalObj.education_status = expertStatus;
        expertApprovalObj.service_charges_status = expertStatus;
        expertApprovalObj.skills_status = expertStatus;
        expertApprovalObj.personal_info_comment = null;
        expertApprovalObj.experience_comment = null;
        expertApprovalObj.education_comment = null;
        expertApprovalObj.service_charges_comment = null;
        expertApprovalObj.skills_comment = null;
      }

      // console.log("After Api Call", expertApprovalObj);
      // axios
      //   .post(`${apiURL}/expert/ExpertApproval`, {
      //     expert_id: expertID,
      //     expert_approve: expertApprovalObj,
      //   })

      const buffer = createBuffer({
        email,
        expert_id: expertID,
        expert_approve: expertApprovalObj,
      });
      const signature = generateSHA2(buffer, password);

      axios
        .post(
          `${apiURL}/expert/ExpertApproval`,
          {
            json_string: JSON.stringify({
              email,
              expert_id: expertID,
              expert_approve: expertApprovalObj,
            }),
          },
          {headers: {signature, email, channel: "web"}},
        )

        .then(response => {
          this.getParticularExpertDetails();
          // toast.info("Education Information Approved!");
          // console.log(response);
        });
    }, 0);
    // console.log("After Api Call", expertApprovalObj);
  };

  expertApproval = () => {
    const {
      expertID,
      isOkClicked,
      notokclicked,
      isNotOkClicked,
      admin_comment,
      personal_info,
      expertApprovalObj,
    } = this.state;
    const admincomment = admin_comment;
    // console.log("admin comment", admin_comment);

    let status = expertApprovalObj.experience_status;

    status = isOkClicked ? "accepted" : notokclicked ? "rejected" : null;

    expertApprovalObj.experience_status = status;

    // console.log("Expert Approval", expertApprovalObj);

    if (status === "accepted" || status === "rejected") {
      // axios
      //   .post(`${apiURL}/expert/ExpertApproval`, {
      //     expert_id: expertID,
      //     expert_approve: expertApprovalObj,
      //   })

      const buffer = createBuffer({
        email,
        expert_id: expertID,
        expert_approve: expertApprovalObj,
      });
      const signature = generateSHA2(buffer, password);

      axios
        .post(
          `${apiURL}/expert/ExpertApproval`,
          {
            json_string: JSON.stringify({
              email,
              expert_id: expertID,
              expert_approve: expertApprovalObj,
            }),
          },
          {headers: {signature, email, channel: "web"}},
        )

        .then(response => {
          // console.log(response);
        });
      // console.log("approval details", expertID);
    }
  };

  // expertApproval = () => {
  //   const {
  //     expertID,

  //     isOkClicked,
  //     notokclicked,
  //     isNotOkClicked,
  //     admin_comment,
  //   } = this.state;

  //   console.log("admin comment", admin_comment);
  //   const status = isOkClicked
  //     ? "accepted"
  //     : notokclicked
  //     ? "rejected"
  //     : "null";
  //   if (status === "accepted" || status === "rejected") {
  //     axios
  //       .post(`${apiURL}/expert/ExpertApproval`, {
  //         expert_id: expertID,
  //         status,
  //         admin_comment,
  //         label: "experience_details",
  //       })

  //       .then(response => {
  //         console.log(response);
  //       });
  //     console.log("approval details", expertID, status, admin_comment);
  //   }
  // };

  // handleOnChange = e => {
  //   const {experience} = this.state;
  //   console.log(experience);
  //   const {name, value} = e.target;
  //   const tempObj = {
  //     [name]: value,
  //   };
  //   const copyobj = Object.assign(experience, tempObj);
  //   console.log(copyobj);
  //   this.setState({experience});
  // };

  // handleOnChange = (e, experience_id, index) => {
  //   // get the intial state
  //   const {experience} = this.state;
  //   // get particular education details from index
  //   const particularExperienceDetails = experience[index];
  //   console.log("Experience from state", particularExperienceDetails);

  //   // get the value from changed input
  //   const {value} = e.target;

  //   // get the name of the field
  //   const {name} = e.target.dataset;
  //   console.log("Data Name", name);
  //   console.log("Name and Value", name, value);

  //   // create the temporary object from updated data
  //   const tempObj = {
  //     [name]: value,
  //   };
  //   console.log("Updated data", tempObj);

  //   // update the particularEducationDetails data
  //   Object.assign(particularExperienceDetails.data, tempObj);

  //   console.log("Updated Experience Details", particularExperienceDetails);

  //   // update the intial array
  //   experience[index] = particularExperienceDetails;

  //   console.log("Updated Experience  Details", experience);
  //   this.setState({
  //     experience,
  //   });
  // };

  render() {
    const {
      experience,
      currentEditing,
      isNotOkClicked,
      isFieldEnable,
      isFieldActive,
      personal_info,
      startDate,
      endDate,
      experience_details
    } = this.state;
    const {expertApprovalObj} = this.props;
    return (
      <div>
        <SmartToaster store={toast} lightBackground position="top_center" />
        <Formik
          enableReinitialize
          // initialValues={{experienceDetails: experience}} 
          initialValues={{experience_details: experience_details}} 

          // initialValues={{
          //   experienceDetails: [
          //     {
          //       experience_id: "DRhwaP87qJcK37PsEppb",
          //       data: {
          //         company: "Saudi Electricity Co",
          //         fileList: [],
          //         from_year: "",
          //         location: "Haradh",
          //         title: "Android Developer",
          //         to_year: "",
          //       },
          //     },

          //     {
          //       experience_id: "LFOCp8ZznGQmQF9JpjFG",
          //       data: {
          //         company: "Saudi Telecom Co (STC)",
          //         fileList: [],
          //         from_year: "",
          //         location: "Granada Mall Parking",
          //         title: "Android Developer",
          //         to_year: "",
          //       },
          //     },
          //   ],
          // }}
          render={({values}) => (
            <Form onSub>
              <FullWrap>
              <EditRemoveButtonWrap>
                <EditButton
                  type="button"
                  onClick={this.handleOnButtonClick}
                  // onClick={(e, education_id) => {
                  //   this.handleOnButtonClick(e, education_id, index);
                  // }}

                  // onClick={() => {
                  //   // this.handleOnButtonClick(index);
                  // }}
                >
                  Edit
                </EditButton>
                <SaveButton
                  onClick={() => {
                    this.updateExperienceInfo();
                    toast.info(" Experience Details Updated!");
                  }}
                >
                  Save
                </SaveButton>
              </EditRemoveButtonWrap>
              {/* <FullWrap> */}
              {/* <FieldArray
                name="experienceDetails"
                render={() => (
                  <React.Fragment>
                    {values.experienceDetails.map((expDetail, index) => (
                      // <div key={expDetail.experience_id}>
                      // {console.log(values)} */}
                        <FormWrap>
                        {/* <TwoFieldWrap> */}
                          <LableInput full>
                            <Label>EXPERIENCE</Label>
                            <FormField
                              type="text"
                              placeholder="Write here..."
                              active={isFieldEnable ? isFieldActive ? 1 : 0 : 0}
                              value={personal_info.experience_status != "accepted" ? personal_info.new_experience_details : experience_details}
                              // value={experience[index].data.company}
                              // name={`personal_info.experience_details`}
                              name="experience_details"
                              // disabled={index !== currentEditing}
                              onChange={
                                isFieldEnable
                                  ? (e) => {
                                      this.handleOnExpChange(e);
                                    }
                                  : null
                              }
                            />
                          </LableInput>

                          {/* <LableInput>
                            <Label>TITLE</Label>
                            <FormField
                              type="text"
                              placeholder="Write here..."
                              active={isFieldEnable ? isFieldActive : null}
                              value={experience[index].data.title}
                              name={`experienceDetails[${index}].data.title`}
                              data-name="title"
                              onChange={
                                isFieldEnable
                                  ? e => {
                                      this.handleOnChange(e, index);
                                    }
                                  : null
                              }
                              // disabled={index !== currentEditing}
                              // onChange={
                              //   index === currentEditing
                              //     ? (e, experience_id) =>
                              //         this.handleOnChange(
                              //           e,
                              //           experience_id,
                              //           index,
                              //         )
                              //     : null
                              // }
                            />
                          </LableInput>
                        </TwoFieldWrap>

                        <ThreeFieldWrap>
                          <LableInput>
                            <Label>LOCATION</Label>
                            <FormField
                              type="text"
                              placeholder="Write here..."
                              active={isFieldEnable ? isFieldActive : null}
                              value={experience[index].data.location}
                              name={`experienceDetails[${index}].data.location`}
                              data-name="location"
                              // disabled={index !== currentEditing}
                              // onChange={
                              //   index === currentEditing
                              //     ? (e, experience_id) =>
                              //         this.handleOnChange(
                              //           e,
                              //           experience_id,
                              //           index,
                              //         )
                              //     : null
                              // }
                              onChange={
                                isFieldEnable
                                  ? e => {
                                      this.handleOnChange(e, index);
                                    }
                                  : null
                              }
                            />
                          </LableInput>

                          <LableInput>
                            <Label>DURATION(FROM)</Label>
                            <FormField
                              type="text"
                              placeholder="Write Here.."
                              active={isFieldEnable ? isFieldActive : null}
                              value={experience[index].data.from_year}
                              name={`experienceDetails[${index}].data.from_year`}
                              data-name="from_year"
                              // disabled={index !== currentEditing}
                              // onChange={
                              //   index === currentEditing
                              //     ? (e, experience_id) =>
                              //         this.handleOnChange(
                              //           e,
                              //           experience_id,
                              //           index,
                              //         )
                              //     : null
                              // }
                              onChange={
                                isFieldEnable
                                  ? e => {
                                      this.handleOnChange(e, index);
                                    }
                                  : null
                              }
                            />
                          </LableInput>

                          <LableInput>
                            <Label>DURATION(TO)</Label>
                            <FormField
                              type="text"
                              placeholder="Write Here.."
                              active={isFieldEnable ? isFieldActive : null}
                              value={experience[index].data.to_year}
                              name={`experienceDetails[${index}].data.to_year`}
                              data-name="to_year"
                              // disabled={index !== currentEditing}
                              // onChange={
                              //   index === currentEditing
                              //     ? (e, experience_id) =>
                              //         this.handleOnChange(
                              //           e,
                              //           experience_id,
                              //           index,
                              //         )
                              //     : null
                              // }
                              onChange={
                                isFieldEnable
                                  ? e => {
                                      this.handleOnChange(e, index);
                                    }
                                  : null
                              }
                            />
                          </LableInput> */}
                        {/* </ThreeFieldWrap> */}

                        {expertApprovalObj.experience_status === "submitted" ? (
                          <LableInput>
                            <Label>Status</Label>
                            <FormField
                              type="text"
                              placeholder="Write here..."
                              value="Expert needs to be approved.."
                            />
                          </LableInput>
                        ) : expertApprovalObj.experience_status ===
                          "rejected" ? (
                          <LableInput>
                            <Label>Status</Label>
                            <FormField
                              type="text"
                              placeholder="Write here..."
                              value="Expert is not approved"
                            />
                          </LableInput>
                        ) : null}

                        {expertApprovalObj.experience_status === "submitted" ? (
                          <LableInput full>
                            <Label>Not Approved Reason</Label>
                            <FormField
                              placeholder="Write here..."
                              component="textarea"
                              name="not_approved_reason"
                              // active={isFieldEnable ? isFieldActive : null}
                              value={personal_info.experience_comment}
                              // onChange={
                              //   isFieldEnable
                              //     ? e => {
                              //         this.handleOnChange(e);
                              //       }
                              //     : null
                              // }
                            />
                          </LableInput>
                        ) : null}
                        </FormWrap>
                      </FullWrap>
                    {/* ))}
                  </React.Fragment>
                )} */}
              {/* /> */}
              {/* </FullWrap> */}
            </Form>
          )}
        />

        <ConfirmFullWrap>
          {expertApprovalObj.experience_status === "accepted" ? (
            <ApprovedMsgDiv>
              <ApprovedMsg>Experience Info has been approved</ApprovedMsg>
            </ApprovedMsgDiv>
          ) : null}

          <ConfirmWrap>
            <ConfirmButton
              type="button"
              disabled={expertApprovalObj.experience_status === "accepted"}
              onClick={
                this.handleExpertApproval
                // toast.info(" Experience Details details Approved!");
              }
            >
              <Img src={approved} />
              Approved
            </ConfirmButton>
            <ConfirmButton
              red
              notapproved
              type="button"
              onClick={this.notOkClicked}
            >
              <Img src={notapproved} />
              Not Approved
            </ConfirmButton>
            <ConfirmButton
              red
              notapproved
              type="button"
              disabled={
                expertApprovalObj.personal_info_status === "accepted" &&
                expertApprovalObj.experience_status === "accepted" &&
                expertApprovalObj.education_status === "accepted" &&
                expertApprovalObj.service_charges_status === "accepted" &&
                expertApprovalObj.skills_status === "accepted"
              }
              onClick={() => {
                this.onApproveAllClicked();
                this.handleExpertApproval();
              }}
            >
              <ApproveAllImg src={Allapprove} />
              Approve All
            </ConfirmButton>
          </ConfirmWrap>
        </ConfirmFullWrap>
        {isNotOkClicked ? (
          // <ConfirmButton underline>
          //   +Add Comment
          // </ConfirmButton>
          <div>
            <CommentTextBox
              type="text"
              onChange={e => this.handleOnAdminChange(e)}
              placeholder="Add Comments Here.."
            />
            <DoneButton
              type="button"
              onClick={() => {
                this.handleExpertApproval();
                this.getParticularExpertDetails();
              }}
            >
              Done!
            </DoneButton>
          </div>
        ) : null}
      </div>
    );
  }
}
