import React from "react";
import styled from "styled-components";

const MySVG = styled.svg`
  position: absolute;
  top: 50px;
  left: 50px;
  padding-bottom: ${props => (props.forgotpwd ? 10 : 0)}px;
`;

const LoginIcons = ({password}) => {
  if (password) {
    return (
      <MySVG
        xmlns="http://www.w3.org/2000/svg"
        width="31"
        height="32"
        viewBox="0 0 31 32"
      >
        <g fill="none" fillRule="evenodd" stroke="#0D3868" strokeWidth="2">
          <path d="M1.084 14.5h28v16h-28zM6.084 13.5V9.882c0-4.622 4.038-8.382 9-8.382 4.963 0 9 3.76 9 8.382V13.5" />
          <path strokeLinecap="square" d="M15.084 21.654v3.705" />
        </g>
      </MySVG>
    );
  }
  return (
    <MySVG
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="27"
      viewBox="0 0 36 27"
    >
      <g fill="none" fillRule="evenodd" stroke="#0D3868" strokeWidth="2">
        <path d="M1 2h33.594v23.5H1z" />
        <path d="M35.328 1l-17.22 14.633L.89 1" />
      </g>
    </MySVG>
  );
};

export default LoginIcons;
