import React from "react";
import {Bar} from "react-chartjs-2";
import styled from "styled-components";

const MixChartWrap = styled.div`
  width: 650px;
  padding: 20px;
  /* border: 1px solid #fafbfc;
  border: 1px solid black; */
`;

const data = {
  // labels: ["January", "February", "March", "April", "May", "June", "July"],
  datasets: [
    {
      label: "",
      type: "line",
      borderWidth: 1,
      data: [551, 265, 140, 249, 360, 237, 140, 240, 249, 360, 237, 140],
      fill: false,
      borderColor: "#E54666",
      backgroundColor: "#E54666",
      pointBorderColor: "#E54666",
      pointBackgroundColor: "#E54666",
      pointHoverBackgroundColor: "#E54666",
      pointHoverBorderColor: "#E54666",
      yAxisID: "y-axis-2",
    },
    {
      type: "bar",
      label: "",
      data: [200, 185, 590, 599, 250, 400, 195, 590, 599, 250, 400, 195],
      fill: false,
      backgroundColor: "#004d9e",
      borderColor: "#71B37C",
      hoverBackgroundColor: "#004d9e",
      hoverBorderColor: "#71B37C",
      // yAxisID: "y-axis-1",
    },
  ],
};

const options = {
  legend: {
    display: false,
  },
  responsive: true,
  tooltips: {
    mode: "label",
  },
  elements: {
    line: {
      fill: false,
    },
    // point: {
    //   radius: 0,
    // },
  },
  scales: {
    xAxes: [
      {
        display: true,
        gridLines: {
          display: false,
        },
        barPercentage: 0.5,
        // labels: {
        //   show: true,
        // },
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
    ],
    yAxes: [
      {
        type: "linear",
        display: true,
        position: "left",
        id: "y-axis-1",
        gridLines: {
          display: false,
        },
        labels: {
          show: true,
        },
      },
      {
        type: "linear",
        display: true,
        position: "right",
        id: "y-axis-2",
        gridLines: {
          display: false,
        },
        labels: {
          show: true,
        },
      },
    ],
  },
};

// const plugins = [{
//     afterDraw: (chartInstance, easing) => {
//         const ctx = chartInstance.chart.ctx;
//         ctx.fillText("This text drawn by a plugin", 100, 100);
//     }
// }];

// export default React.createClass({
//   displayName: 'MixExample',
const MixChart = () => (
  <MixChartWrap>
    <Bar
      data={data}
      options={options}
      // plugins={plugins}
    />
  </MixChartWrap>
);

export default MixChart;
