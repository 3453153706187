import React from "react";
import styled from "styled-components";
import foldericon from "../Experts/foldericon.png";
import option from "../Experts/option.png";

const SideBarWrap = styled.div`
  /* height: 90vh; */
  max-height: 100%;
  margin-left: 2em;
  margin-top: 2em;
  border-radius: 6px;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
`;
const SideBarLabel = styled.label`
  font-size: 10px;
  margin-left: 2.3em;
`;
const ButtonIcon = styled.img`
  height: 30px;
`;

const ButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4em;
  margin-left: 0.1em;
  margin-right: 0.1em;
`;
const Button = styled.button`
  outline: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1em 2em 1em 2em;
  padding: 1.5em;
  width: 250px;
  border-radius: 5px;
  border: solid 1px #e9eff4;
  /* background-color: #ffffff;
  color: #323c47; */
  color: ${props => (props.active ? "#fff" : "#323c47")};
  background-color: ${props => (props.active ? "#39a1dd" : "#ffffff")};
  cursor: pointer;
`;
const OptionIcon = styled.img`
  height: 20px;
`;
class ExpertSideBar extends React.Component {
  state = {};

  render() {
    const {
      transactionsClicked,
      transactionsActive,
      shawerCategoriesClicked,
      ratingsClicked,
      expertProfileInfoClicked,
      contactUsInfoClicked,
      termOfUseInfoClicked,
      privacyPolicyInfoClicked,
      shawerCategoriesActive,
      ratingsActive,
      expertProfileInfoActive,
      contactUsInfoActive,
      termOfUseInfoActive,
      privacyPolicyInfoActive,
    } = this.props;

    return (
      <SideBarWrap>
        <ButtonWrap>
          <SideBarLabel>DOCUMENTS</SideBarLabel>
          {/* <Button onClick={this.onPersonalDetailsClick}> */}
          <Button
            onClick={shawerCategoriesClicked}
            active={shawerCategoriesActive}
          >
            <ButtonIcon src={foldericon} />
            Shawer Categories
            <OptionIcon src={option} />
          </Button>
          {/* {isPersonalInfoClicked ? <FullForm /> : null} */}
          {/* <Button onClick={this.onEducationDetailsClick}> */}
          <Button onClick={ratingsClicked} active={ratingsActive}>
            <ButtonIcon src={foldericon} />
            Ratings
            <OptionIcon src={option} />
          </Button>
          {/* {isEducationInfoClicked ? <QualificationDegreeForm /> : null} */}
          <Button
            onClick={expertProfileInfoClicked}
            active={expertProfileInfoActive}
          >
            <ButtonIcon src={foldericon} />
            Expert Profile Details
            <OptionIcon src={option} />
          </Button>
          <Button onClick={contactUsInfoClicked} active={contactUsInfoActive}>
            <ButtonIcon src={foldericon} />
            Contact Us
            <OptionIcon src={option} />
          </Button>
          <Button onClick={termOfUseInfoClicked} active={termOfUseInfoActive}>
            <ButtonIcon src={foldericon} />
            Terms of Use
            <OptionIcon src={option} />
          </Button>
          <Button
            onClick={privacyPolicyInfoClicked}
            active={privacyPolicyInfoActive}
          >
            <ButtonIcon src={foldericon} />
            Privacy Policy
            <OptionIcon src={option} />
          </Button>
          <Button onClick={transactionsClicked} active={transactionsActive}>
            <ButtonIcon src={foldericon} />
            Transaction Fee
            <OptionIcon src={option} />
          </Button>
        </ButtonWrap>
      </SideBarWrap>
    );
  }
}

export default ExpertSideBar;
