import React from "react";
import styled from "styled-components";
import axios from "axios";
import * as Yup from "yup";

import {SmartToaster, toast} from "react-smart-toaster";

import {Form, Field, FieldArray, Formik} from "formik";

// api URL

import {apiURL, generateSHA2, createBuffer} from "../../utils/util";

const InputButtonWrap = styled.form`
  margin-top: 20px;
  width: 530px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AddInput = styled(Field)`
  width: 400px;
  height: 35px;
  border-radius: 4px;
  border: solid 1px #e9eff4;
  border-bottom: solid 1px #e9eff4;
`;

const AddError = styled.span`
  font-size: 12px;
  color: red;
`;

const InputErrorSpan = styled.div`
  display: flex;
  flex-direction: column;
`;

const AddReasonButton = styled.button`
  width: 100px;
  height: 30px;
  border-radius: 2px;
  border: solid 1px rgba(77, 161, 255, 0.7);
  background-color: #39a1dd;
  color: #fff;
`;

const QualificationDetailsDiv = styled.div``;

const SearchEditWrap = styled.div`
  width: 550px;
  border: 1px solid #e9eff4;
  border-radius: 4px;
  margin-top: 20px;
  border-bottom: none;
`;

const SearchWrap = styled.div``;

const Wrap = styled.div`
  padding: 20px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid #e9eff4;
`;

const EditRemoveButtonWrap = styled.div`
  width: 130px;
  display: flex;
  justify-content: space-between;
`;

const EditButton = styled.button`
  border: none;
  color: #39a1dd;
  font-size: 14px;
  cursor: pointer;
  outline: 0;
`;

const RemoveButton = styled.button`
  border: none;
  color: #d0021b;
  font-size: 14px;
  cursor: pointer;
  outline: 0;
`;

const FormField = styled(Field)`
  position: ${props => (props.absolute ? "absolute" : "")};
  width: 100%;
  /* border: solid 1px #d0d0d0; */
  border: none;
  text-indent: 15px;
  padding: 10px 10px 10px 5px;
  font-size: 17px;
  border-radius: 4px;
  background-color: #ffffff;
  opacity: 0.5;
  outline: none;
  ::-webkit-input-placeholder {
    font-family: Ubuntu;
    /* font-size: 20px; */
    font-weight: 300;
    color: #0d3868;
    opacity: 0.5;
    padding: 15px;
    /* text-align: left; */
  }
`;

const email = window.sessionStorage.getItem("email");
const password = window.sessionStorage.getItem("token");

class SchoolDetails extends React.Component {
  state = {
    getProfileList: [],
    qualification: [],
    fieldOfStudy: [],
    list_schools: [],
    currentEditing: -1,
  };

  // lifecycle methods

  componentDidMount() {
    this.getSchoolDetails();
  }

  handleOnChange = (e, index) => {
    const {list_schools} = this.state;

    const editData = list_schools[index];
    const {value} = e.target;
    const {name} = e.target.dataset;
    list_schools[index] = value;

    // arrayHelpers.replace(index, qualification[index])
    this.setState({
      list_schools,
    });
  };

  // Adding value to Array

  onChangeValue = e => {
    this.setState({value: e.target.value});
  };

  clearState = () => {
    this.setState({value: ""});
  };

  onClickAdd = values => {
    const {list_schools} = this.state;
    const {schoolName} = values;
    list_schools.push(schoolName);
    this.setState({list_schools});
    this.updateSchoolInfo();
  };

  // updating value to array

  updateSchoolInfo = async index => {
    const {list_schools} = this.state;

    // await axios
    //   .post(`${apiURL}/aboutshawer/UpdateSchool`, {
    //     list_schools,
    //     label: "list_schools",
    //   })

    const buffer = createBuffer({
      email,
      list_schools,
      label: "list_schools",
    });
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/aboutshawer/UpdateSchool`,
        {
          json_string: JSON.stringify({
            email,
            list_schools,
            label: "list_schools",
          }),
        },
        {headers: {signature, email, channel: "web"}},
      )

      .then(response => {
        this.clearState();
      })
      .catch(error => {});
    this.setState({currentEditing: -1});
  };

  handleOnButtonClick = index => {
    this.setState({currentEditing: index});
  };

  deleteElementFromArray = e => {
    const {index} = e.target.dataset;

    const {list_schools} = this.state;

    list_schools.splice(index, 1);

    this.updateSchoolInfo();

    this.getSchoolDetails();
  };

  // api call

  getSchoolDetails = () => {
    const buffer = createBuffer({email});
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/aboutshawer/GetExpertProfile`,
        {
          json_string: JSON.stringify({email}),
        },
        {headers: {signature, email, channel: "web"}},
      )

      // axios
      //   .post(`${apiURL}/aboutshawer/GetExpertProfile`, {})

      .then(response => {
        if (response.status === 200 && response.data) {
          const {data} = response;

          this.setState({
            qualification: data.content.list_qualification_degree,
            fieldOfStudy: data.content.list_fields_of_study,
            list_schools: data.content.list_schools,
          });
        }
      })
      .catch(error => {});
  };

  render() {
    const {
      qualification,
      fieldOfStudy,
      list_schools,
      currentEditing,
    } = this.state;

    return (
      <QualificationDetailsDiv>
        <SmartToaster store={toast} lightBackground position="top_center" />
        {/* <InputButtonWrap>
          <AddInput
            onChange={this.onChangeValue}
            placeholder="  Write a School"
          />
          <AddReasonButton onClick={this.onClickAdd}>Add</AddReasonButton>
        </InputButtonWrap> */}

        <Formik
          initialValues={{schoolName: ""}}
          validationSchema={Yup.object().shape({
            schoolName: Yup.string()
              .required("Please Enter School")
              .matches(
                /^((?!(\*\/|\/\*|--|<|>)).)*$/,
                "Should not contain following patterns - '/*','*/', '--', '>','<'",
              )
              .matches(/\S+/, "Please Enter Valid Input"),
          })}
          onSubmit={values => {
            this.onClickAdd(values);
          }}
        >
          {props => {
            const {touched, errors, handleSubmit} = props;
            return (
              <InputButtonWrap onSubmit={handleSubmit}>
                <InputErrorSpan>
                  <AddInput
                    id="schoolName"
                    name="schoolName"
                    placeholder="Enter School"
                    type="text"
                  />
                  {/* {console.log("props", props)} */}
                  {errors.schoolName && touched.schoolName && (
                    <AddError>{errors.schoolName}</AddError>
                  )}
                </InputErrorSpan>
                <AddReasonButton type="submit">Add</AddReasonButton>
              </InputButtonWrap>
            );
          }}
        </Formik>

        <SearchEditWrap>
          <SearchWrap>
            {/* <SerachInput placeholder="Search School" /> */}
          </SearchWrap>

          {/* ---------Formik----------- */}
          <div>
            <Formik
              enableReinitialize
              initialValues={{schoolDetails: list_schools}}
              render={({values}) => (
                <Form>
                  {console.log("Formil Val", values)}
                  <FieldArray
                    name="schoolDetails"
                    render={({arrayHelpers, remove}) => (
                      <React.Fragment>
                        {values.schoolDetails.map((schooldet, index) => (
                          <Wrap key={index}>
                            <FormField
                              type="text"
                              placeholder="Write here..."
                              // value={schooldet}
                              name={`schoolDetails[${index}]`}
                              data-name={schooldet}
                              data-index={index}
                              disabled={index !== currentEditing}
                              onChange={
                                index === currentEditing
                                  ? e => this.handleOnChange(e, index)
                                  : null
                              }
                            />
                            <EditRemoveButtonWrap>
                              <EditButton
                                type="button"
                                data-index={index}
                                onClick={() => {
                                  this.handleOnButtonClick(index);
                                }}
                              >
                                Edit
                              </EditButton>
                              <RemoveButton
                                type="button"
                                data-index={index}
                                onClick={() => {
                                  this.updateSchoolInfo(index);
                                  toast.info("Data Updated!");
                                }}
                              >
                                Save
                              </RemoveButton>
                              <RemoveButton
                                type="button"
                                data-index={index}
                                // onClick={() => remove(index)}
                                onClick={e => {
                                  this.deleteElementFromArray(e);
                                  toast.info("Data Deleted!");
                                }}
                                // onClick={() =>
                                //   this.updateSchoolInfo(index)
                                // }
                              >
                                Remove
                              </RemoveButton>
                            </EditRemoveButtonWrap>
                          </Wrap>
                        ))}
                      </React.Fragment>
                    )}
                  />
                </Form>
              )}
            />
          </div>

          {/* --------- //Formik----------- */}
        </SearchEditWrap>
      </QualificationDetailsDiv>
    );
  }
}

export default SchoolDetails;
