import React from "react";
import styled from "styled-components";
import Modal from "react-modal";
import ReactTable from "react-table";
import axios from "axios";
import NumberFormat from "react-number-format";
import {SmartToaster, toast} from "react-smart-toaster";
import "react-table/react-table.css";
import StarRatingComponent from "react-star-rating-component";

import arrowimg from "../Chats/arrow.png";

import closebtn from "../AboutShawer/closebtn.png";
import {apiURL, createBuffer, generateSHA2} from "../../utils/util";

const FullTableWrap = styled.div`
  /* margin-left: 4.5em; */

  margin-top: 0.6em;
  max-height: 100%;
  width: 1100px;
  background-color: #ffffff;
`;

const TableWrap = styled.div`
  margin-left: 20px;
  width: 1300px;
`;

const TitleDiv = styled.div`
  /* padding-top: 10px; */
  padding-bottom: 10px;
  padding-left: 20px;
`;

const ArrowButton = styled.button`
  border: none;
  cursor: pointer;
  border: none;
`;
const ArrowImg = styled.img`
  pointer-events: none;
`;

// Popup css
const ChatSummaryFullWrap = styled.div`
  margin: 30px;
`;
const ChatSummaryTitle = styled.h1`
  color: #1a173b;
  margin-top: 0px;
  margin-left: 13px;
`;
const ChatSummaryContentLabelWrap = styled.div`
  display: flex;
  flex-direction: column;
`;
const ColumnOneLabelContentWrap = styled.div`
  margin: 0px 20px 0px 20px;
  border-right: 1px solid #b4bac6;
  width: 400px;
  height: 200px;
`;
const ColumnTwoLabelContentWrap = styled.div`
  width: 400px;
`;
const ChatSummaryLabel = styled.label`
  color: #b4bac6;
  font-size: 12px;
  padding-right: 40px;
  max-width: 400px;
  padding-bottom: ${props => (props.bottom ? 15 : 0)}px;
  /* padding-bottom: 20px; */
`;
const ChatSummaryContent = styled.p`
  color: #000000;
  font-size: 14px;
`;
const RatingStars = styled.div`
  width: 100px;
`;
const DateAmountDiv = styled.div`
  display: flex;
  width: 970px;
  /* border-bottom: 1px solid black; */
`;
const DateAmountLabelDiv = styled.div`
  padding-right: 60px;
  padding-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LabelContentWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 100px;
  margin-bottom: 15px;
`;
const LabelContentReviewWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`;

const ButtonContentWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ColumnsWrap = styled.div`
  display: flex;
  width: 100%;
  border-top: 1px solid #b4bac6;
  border-bottom: 1px solid #b4bac6;
  padding: 20px 0px 20px 0px;
  margin: 20px 0px 20px 0px;
`;

const ExportDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 20%;
  align-items: center;
  margin-left: 20px;
`;

const RefundReqWrap = styled.div``;

const RefundReasonDiv = styled.div`
  margin-left: 45px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 65%;
  height: 22%;
`;

const CommentsWrap = styled.div`
  margin-left: 45px;
  margin-top: 10px;
`;

const TypeOfRefund = styled.div`
  margin-left: 45px;
  margin-top: 10px;
`;

const RadioLabelFullWrap = styled.div`
  margin-top: 5px;
  display: flex;
  width: 650px;
  justify-content: space-between;
`;

const RadioLabelWrap = styled.div`
  display: flex;
  width: 130px;
  justify-content: space-between;
  margin: 15px 0px 15px 0px;
`;

const TypeOfRefundRadio = styled.input``;

const RadioLabel = styled.label``;

const RefundReasonTextArea = styled.textarea`
  /* border: none; */
  border: solid 1px #e9eff4;
  border-radius: 4px;
  height: 70px;
  font-size: 15px;
`;

const ChatHistoryList = styled.textarea`
  /* border: none; */
  border: solid 1px #e9eff4;
  border-radius: 4px;
  height: 200px;
  font-size: 15px;
  width: 80%;
  overflow: scroll;
`;

const ChatHistoryLink = styled.a`
  cursor: pointer;
  color: #085db0;
  text-decoration: underline;
  padding-bottom: 15px;
`;

const CommentTextBoxWrap = styled.div`
  display: flex;
  flex-direction: column;
  /* height: 500px; */
  margin-left: 40px;
`;

const CommentTextWrap = styled.div`
  display: flex;
`;

const CommentTextBox = styled.textarea`
  /* width: 450px; */
  width: 45%;
  /* height: 22%; */
  margin: 20px 20px 20px 0px;
  font-size: 15px;
  border: solid 1px #e9eff4;
  border-radius: 5px;
  outline: none;
  /* margin-left: 120px; */
`;

const DoneButton = styled.button`
  border-radius: 5px;
  background-color: #085db0;
  height: 30px;
  color: #fff;
  padding-left: 10px;
  padding-right: 10px;
  width: 100px;
  cursor: pointer;
`;

const CloseButton = styled.button`
  outline: 0;
  border: none;
  margin-left: ${props => (props.more ? 700 : 890)}px;
  /* margin-left: 1000px; */
  margin-top: -10px;
  cursor: pointer;
`;

const CloseButtonImg = styled.img`
  height: 30px;
  width: 30px;
`;

// Reason popup css

const MoreReasonTitle = styled.h1`
  margin-top: 0px;
`;

const MoreReasonPopupDiv = styled.div``;

const RefundInputLabelWrap = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  width: 500px;
  align-items: center;
`;

const RefundInput = styled.input`
  width: 300px;
  border-radius: 4px;
  border: solid 1px #d0d0d0;
  background-color: #ffffff;
  height: 30px;
`;

const RefundLabel = styled.label`
  color: #0052a9;
  width: 400px;
  margin-left: 20px;
`;

const TableHeaderDiv = styled.div`
  /* text-align: left; */
  text-align: ${props => (props.center ? "center" : "left")};
`;

const DetailsContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between;
  align-items: center; */
`;

const customStylesChatDetails = {
  content: {
    // height: "80%",
    width: "55%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "scroll",
    // border: "0px",
  },
};

const customStyles = {
  content: {
    height: "80%",
    width: "70%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    // border: "0px",
  },
};

const ChatHistoryWrap = styled.div`
  max-width: 700px;
  display: flex;
  justify-content: flex-start;
`;

const ChatHistoryFullWrap = styled.div`
  height: 400px;
  overflow: scroll;
`;

const ChatUserName = styled.label`
  color: #085db0;
  font-size: 13px;
  width: 100px;
  margin-right: 10px;
`;

const LabelMessageWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ChatMessage = styled.p`
  font-size: 13px;
  max-width: 600px;
`;

const ChatAudioImageUrl = styled.a`
  font-size: 13px;
  padding: 7px 7px 7px 0px;
`;

const email = window.sessionStorage.getItem("email");
const password = window.sessionStorage.getItem("token");

class ChatRefundReactTable extends React.Component {
  state = {
    checked: false,
    open: false,
    rating: 1,
    isNotOkClicked: false,
    openChatDetails: false,
    getChatRefundRequest: [],
    selectedRefundReq: null,
    selectedUpadteRefundReq: null,
    admin_comment: "",
    admin_refund_comment_for_expert: "",
    fullrefundbtn: false,
    noRefundbtn: false,
    percentage: 0,
    refund_amount: 0,
    partialRefundbtn: false,
    loading: true,
  };

  onSwitchHandleChange = this.onSwitchHandleChange.bind(this);

  closePopup = this.closePopup.bind(this);

  closeChatDetailsPopup = this.closeChatDetailsPopup.bind(this);

  // lifecycle methods

  componentDidMount() {
    this.getDisputeManagementDetails();
  }

  onSwitchHandleChange(checked) {
    this.setState({checked});
  }

  // onStarClick(nextValue, prevValue, name) {
  //   this.setState({rating: nextValue});
  // }

  notOkClicked = () => {
    this.setState({
      isNotOkClicked: true,
    });
  };

  onFullRefundbtnClicked = () => {
    this.setState({
      fullrefundbtn: true,
      noRefundbtn: false,
      partialRefundbtn: false,
    });
  };

  onNoRefundbtnClicked = () => {
    this.setState({
      noRefundbtn: true,
      fullrefundbtn: false,
      partialRefundbtn: false,
    });
  };

  onPartialbtnClicked = () => {
    this.setState({
      partialRefundbtn: true,
      fullrefundbtn: false,
      noRefundbtn: false,
    });
  };

  clearState = () => {
    this.setState({
      admin_comment: "",
      admin_refund_comment_for_expert: "",
      fullrefundbtn: false,
      noRefundbtn: false,
      percentage: 0,
      refund_amount: 0,
      partialRefundbtn: false,
      selectedRefundReq: null,
    });
  };

  // api calls

  getDisputeManagementDetails = () => {
    const buffer = createBuffer({email});
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/dispute/ChatRefundRequest`,
        {
          json_string: JSON.stringify({email}),
        },
        {headers: {signature, email, channel: "web"}},
      )

      // axios
      //   .post(`${apiURL}/dispute/ChatRefundRequest`, {})

      .then(response => {
        if (response.status === 200 && response.data) {
          const {data} = response;
          this.setState({getChatRefundRequest: data.content, loading: false});
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };

  updateWithdrawalManagementDetails = () => {
    const {
      selectedRefundReq,
      getChatRefundRequest,
      admin_comment,
      fullrefundbtn,
      refund_amount,
      noRefundbtn,
      partialRefundbtn,
      percentage,
      admin_refund_comment_for_expert,
    } = this.state;

    // console.log(" refund req Details", getChatRefundRequest);

    if (selectedRefundReq !== null) {
      const upadteChatRefundDetails = getChatRefundRequest[selectedRefundReq];
      // console.log("INDEX IS", selectedRefundReq);
      // console.log("Selectded Bank Details is", upadteChatRefundDetails);

      // console.log("Comment");

      // console.log("ADMIN COMMENT", admin_comment);
      const chatID = upadteChatRefundDetails.chat_id;

      const refundAmt = upadteChatRefundDetails.total_amount;

      const refundAmtttt = fullrefundbtn
        ? refundAmt
        : noRefundbtn
        ? 0
        : partialRefundbtn
        ? (refundAmt * percentage) / 100
        : null;
      const status = fullrefundbtn
        ? "fully_done"
        : noRefundbtn
        ? "rejected"
        : partialRefundbtn
        ? "partially_done"
        : null;

      const buffer = createBuffer({
        email,
        chat_id: chatID,
        admin_refund_comment: admin_comment,
        admin_refund_comment_for_expert,
        admin_refund_amount: refundAmtttt,
        refund_status: status,
      });
      const signature = generateSHA2(buffer, password);

      axios
        .post(
          `${apiURL}/dispute/UpdateRefundRequest`,
          {
            json_string: JSON.stringify({
              email,
              chat_id: chatID,
              admin_refund_comment: admin_comment,
              admin_refund_comment_for_expert,
              admin_refund_amount: refundAmtttt,
              refund_status: status,
            }),
          },
          {headers: {signature, email, channel: "web"}},
        )

        // axios
        //   .post(`${apiURL}/dispute/UpdateRefundRequest`, {
        // chat_id: chatID,
        // admin_refund_comment: admin_comment,
        // admin_refund_comment_for_expert,
        // admin_refund_amount: refundAmtttt,
        // refund_status: status,
        //   })

        .then(response => {
          // console.log("update res", response);
          toast.info("Refund Updated!");
          this.closePopup();
          this.getDisputeManagementDetails();
        })
        .catch(error => {
          // console.log(error);
        });
    }
  };

  openChatDetailsPopup = e => {
    // console.log("Target", e.target);
    const {index} = e.target.dataset;
    this.setState({
      openChatDetails: true,
    });
  };

  handleOnChange = e => {
    this.setState({admin_comment: e.target.value});
  };

  handleOnExpertCommentChange = e => {
    this.setState({admin_refund_comment_for_expert: e.target.value});
  };

  handleOnAmountChange = e => {
    const {refund_amount, percentage} = this.state;

    this.setState({percentage: e.target.value, refund_amount});
  };

  modalRenderChatDetails = () => {
    const {selectedRefundReq, getChatRefundRequest} = this.state;

    if (selectedRefundReq !== null) {
      const chatRefundDetails = getChatRefundRequest[selectedRefundReq];

      return (
        <div>
          <CloseButton more onClick={this.closeChatDetailsPopup}>
            <CloseButtonImg src={closebtn} />
          </CloseButton>
          <MoreReasonTitle>Chat History</MoreReasonTitle>
          <ChatHistoryFullWrap>
            {chatRefundDetails.chat_history.map(index => (
              <ChatHistoryWrap key>
                <LabelMessageWrap>
                  <ChatUserName>
                    {index.user_name ? index.user_name : index.expert_name}
                  </ChatUserName>
                  {index.message ? (
                    <ChatMessage>{index.message}</ChatMessage>
                  ) : index.audio_url ? (
                    <ChatAudioImageUrl
                      target="_blank"
                      rel="noopener noreferrer"
                      href={index.audio_url}
                    >
                      {" "}
                      Audio File
                    </ChatAudioImageUrl>
                  ) : index.image_url ? (
                    <ChatAudioImageUrl
                      target="_blank"
                      rel="noopener noreferrer"
                      href={index.image_url}
                    >
                      {" "}
                      Image File
                    </ChatAudioImageUrl>
                  ) : null}

                  {/* <ChatMessage>
                  {index.message
                    ? index.message
                    : index.audio_url
                    ? index.audio_url
                    : index.image_url}
                </ChatMessage> */}
                </LabelMessageWrap>
              </ChatHistoryWrap>
            ))}
          </ChatHistoryFullWrap>
        </div>
      );
    }
    return null;
  };

  modalrender = () => {
    // console.log("render called");
    const {
      selectedRefundReq,
      getChatRefundRequest,
      fullrefundbtn,
      noRefundbtn,
      partialRefundbtn,
      percentage,
    } = this.state;

    // console.log(" refund req Details", getChatRefundRequest);

    if (selectedRefundReq && getChatRefundRequest.length > 0) {
      // console.log("Index is", selectedRefundReq);
      const chatRefundDetails = getChatRefundRequest[selectedRefundReq];

      return (
        <div>
          <ChatSummaryFullWrap>
            <CloseButton onClick={this.closePopup}>
              <CloseButtonImg src={closebtn} />
            </CloseButton>
            <ChatSummaryTitle>Refund</ChatSummaryTitle>
            <ChatSummaryContentLabelWrap>
              <DateAmountDiv>
                <DateAmountLabelDiv>
                  <ChatSummaryLabel>DATE OF CHAT</ChatSummaryLabel>
                  <ChatSummaryContent>
                    {chatRefundDetails.refund_date}
                  </ChatSummaryContent>
                </DateAmountLabelDiv>
                <DateAmountLabelDiv>
                  <ChatSummaryLabel>TOTAL AMOUNT</ChatSummaryLabel>
                  {chatRefundDetails.total_amount ? (
                    <div>
                      <ChatSummaryContent>
                        <NumberFormat
                          decimalScale={2}
                          value={chatRefundDetails.total_amount}
                          displayType="text"
                        />
                      </ChatSummaryContent>
                    </div>
                  ) : (
                    "0"
                  )}
                </DateAmountLabelDiv>
                <DateAmountLabelDiv>
                  <ChatSummaryLabel>TYPE OF CHAT </ChatSummaryLabel>
                  <ChatSummaryContent>
                    {chatRefundDetails.chat_type}
                  </ChatSummaryContent>
                </DateAmountLabelDiv>
                <DateAmountLabelDiv>
                  <ChatSummaryLabel>DURATION</ChatSummaryLabel>
                  <ChatSummaryContent>
                    0{chatRefundDetails.hr}:{chatRefundDetails.min}:
                    {chatRefundDetails.sec}
                  </ChatSummaryContent>
                </DateAmountLabelDiv>
              </DateAmountDiv>

              <ColumnsWrap>
                <ColumnOneLabelContentWrap>
                  <LabelContentWrap>
                    <ChatSummaryLabel>EXPERT</ChatSummaryLabel>
                    <ButtonContentWrap>
                      <ChatSummaryContent>
                        {chatRefundDetails.expert_name}
                      </ChatSummaryContent>
                      {/* <SupportButton>Email</SupportButton> */}
                    </ButtonContentWrap>
                  </LabelContentWrap>
                  <LabelContentWrap>
                    <ChatSummaryLabel>EXPERT RATING</ChatSummaryLabel>
                    {chatRefundDetails.expert_review ? (
                      <RatingStars>
                        {chatRefundDetails.expert_review.rating ? (
                          <StarRatingComponent
                            name="rate1"
                            starCount={5}
                            value={chatRefundDetails.expert_review.rating}
                            onStarClick={this.onStarClick}
                            starColor="#5fd6ff"
                            // style={{backgroundColor: "blue"}}
                          />
                        ) : (
                          " Not available"
                        )}
                      </RatingStars>
                    ) : (
                      " Not available"
                    )}
                  </LabelContentWrap>
                  <LabelContentReviewWrap>
                    <ChatSummaryLabel bottom>EXPERT REVIEW</ChatSummaryLabel>
                    {chatRefundDetails.expert_review ? (
                      <div>
                        {chatRefundDetails.expert_review.reason_text ? (
                          <ChatSummaryContent>
                            {chatRefundDetails.expert_review.reason_text}
                          </ChatSummaryContent>
                        ) : (
                          "User hasn't got any reviews from the expert."
                        )}
                      </div>
                    ) : (
                      "User hasn't got any reviews from the expert."
                    )}
                  </LabelContentReviewWrap>
                </ColumnOneLabelContentWrap>

                <ColumnTwoLabelContentWrap>
                  <LabelContentWrap>
                    <ChatSummaryLabel>USER</ChatSummaryLabel>
                    <ButtonContentWrap>
                      <ChatSummaryContent>
                        {chatRefundDetails.user_name}
                      </ChatSummaryContent>
                      {/* <SupportButton>Email</SupportButton> */}
                    </ButtonContentWrap>
                  </LabelContentWrap>
                  <LabelContentWrap>
                    <ChatSummaryLabel>USER RATING</ChatSummaryLabel>
                    {chatRefundDetails.user_review ? (
                      <RatingStars>
                        {chatRefundDetails.user_review.rating ? (
                          <StarRatingComponent
                            name="rate1"
                            starCount={5}
                            value={chatRefundDetails.user_review.rating}
                            onStarClick={this.onStarClick}
                            starColor="#5fd6ff"
                            // style={{backgroundColor: "blue"}}
                          />
                        ) : (
                          " Not available"
                        )}
                      </RatingStars>
                    ) : (
                      " Not available"
                    )}
                    {/* <RatingStars>
                      <StarRatingComponent
                        name="rate1"
                        starCount={5}
                        value={chatRefundDetails.expert_review.rating}
                        onStarClick={this.onStarClick}
                        starColor="#5fd6ff"
                      />
                    </RatingStars> */}
                  </LabelContentWrap>
                  <LabelContentReviewWrap>
                    <ChatSummaryLabel bottom>USER REVIEW</ChatSummaryLabel>

                    {chatRefundDetails.user_review ? (
                      <div>
                        {chatRefundDetails.user_review.reason_text ? (
                          <ChatSummaryContent>
                            {chatRefundDetails.user_review.reason_text}
                          </ChatSummaryContent>
                        ) : (
                          "Expert hasn't got any reviews from the user."
                        )}
                      </div>
                    ) : (
                      "Expert hasn't got any reviews from the user."
                    )}

                    {/* {chatRefundDetails.user_review.reason_text ? (
                      <ChatSummaryContent>
                        {chatRefundDetails.user_review.reason_text}
                      </ChatSummaryContent>
                    ) : (
                      "Details not provided..."
                    )} */}
                  </LabelContentReviewWrap>
                </ColumnTwoLabelContentWrap>
              </ColumnsWrap>
              <ExportDiv>
                {/* <ChatTxtLabel>Chat.txt</ChatTxtLabel>
                <ChatExportPDFButton>Export as PDF</ChatExportPDFButton> */}
              </ExportDiv>
            </ChatSummaryContentLabelWrap>
          </ChatSummaryFullWrap>
          {/* 
          {chatRefundDetails.user || chatRefundDetails.total_amount === 0 ? ( */}

          {chatRefundDetails.user_name ? (
            <RefundReqWrap>
              <RefundReasonDiv>
                <ChatSummaryLabel bottom>CHAT HISTORY</ChatSummaryLabel>
                <ChatHistoryLink onClick={this.openChatDetailsPopup}>
                  Click Here
                </ChatHistoryLink>
              </RefundReasonDiv>

              <RefundReasonDiv>
                <ChatSummaryLabel bottom>REASON FOR REFUND</ChatSummaryLabel>
                <RefundReasonTextArea
                  disabled
                  value={chatRefundDetails.user_refund_comment}
                />
              </RefundReasonDiv>

              <TypeOfRefund>
                <ChatSummaryLabel bottom>
                  SELECT TYPE OF REFUND
                </ChatSummaryLabel>
                <RadioLabelFullWrap>
                  <RadioLabelWrap>
                    <TypeOfRefundRadio
                      type="radio"
                      id="fullrefundbtn"
                      value="fullrefundbtn"
                      checked={fullrefundbtn}
                      onClick={() => this.onFullRefundbtnClicked()}
                    />
                    <RadioLabel>Full Refund</RadioLabel>
                  </RadioLabelWrap>
                  <RadioLabelWrap>
                    <TypeOfRefundRadio
                      type="radio"
                      id="noRefundbtn"
                      value="noRefundbtn"
                      checked={noRefundbtn}
                      onClick={() => this.onNoRefundbtnClicked()}
                    />
                    <RadioLabel>No Refund</RadioLabel>
                  </RadioLabelWrap>
                  <RadioLabelWrap>
                    <TypeOfRefundRadio
                      type="radio"
                      id="partialRefundbtn"
                      value="partialRefundbtn"
                      checked={partialRefundbtn}
                      onClick={() => this.onPartialbtnClicked()}
                    />
                    <RadioLabel>Partial Refund</RadioLabel>
                  </RadioLabelWrap>
                </RadioLabelFullWrap>
                {partialRefundbtn ? (
                  <RefundInputLabelWrap>
                    <RefundInput
                      id="refundamt"
                      name="refundamt"
                      placeholder="Write Partial Amount's Percentage here "
                      // disabled={isPartialButtonClicked}
                      type="number"
                      max="100"
                      min="1"
                      onChange={
                        partialRefundbtn
                          ? e => this.handleOnAmountChange(e)
                          : null
                      }
                    />
                    {percentage >= 0 && percentage <= 100 ? (
                      <RefundLabel>
                        SAR{" "}
                        <NumberFormat
                          decimalScale={2}
                          value={
                            `${chatRefundDetails.total_amount * percentage}` /
                            100
                          }
                          displayType="text"
                        />{" "}
                        will be refunded
                      </RefundLabel>
                    ) : (
                      <RefundLabel>
                        Please enter value between 0 to 100
                      </RefundLabel>
                    )}
                  </RefundInputLabelWrap>
                ) : null}
              </TypeOfRefund>

              <CommentsWrap>
                <ChatSummaryLabel>COMMENT IF ANY</ChatSummaryLabel>
              </CommentsWrap>
              <CommentTextBoxWrap>
                <CommentTextWrap>
                  <CommentTextBox
                    rows="5"
                    cols="5"
                    placeholder="Not Approved Expert Description Here.."
                    onChange={e => this.handleOnExpertCommentChange(e)}
                  />

                  <CommentTextBox
                    rows="5"
                    cols="5"
                    placeholder="Not Approved User Description Here.."
                    onChange={e => this.handleOnChange(e)}
                  />
                </CommentTextWrap>
                <DoneButton
                  type="button"
                  onClick={() => {
                    this.updateWithdrawalManagementDetails();
                  }}
                >
                  Done
                </DoneButton>
              </CommentTextBoxWrap>
            </RefundReqWrap>
          ) : null}
        </div>
      );
    }
    return null;
  };

  // openPopup = index => {
  //   this.setState({open: true, selectedRefundReq: index});
  // };

  openPopup = e => {
    // console.log("Target", e.target);
    const {index} = e.target.dataset;
    // console.log("index", typeof index);
    this.setState({open: true, selectedRefundReq: index});
  };

  closeChatDetailsPopup() {
    // console.log("close popup clicked");
    this.setState({
      openChatDetails: false,
    });
  }

  closePopup() {
    // console.log("close popup clicked");
    this.setState(
      {
        open: false,
      },
      () => this.clearState(),
    );
  }

  render() {
    const {
      data,
      checked,
      open,
      rating,
      isNotOkClicked,
      openChatDetails,
      getChatRefundRequest,
      selectedRefundReq,
      loading,
    } = this.state;
    const columns = [
      {
        id: "user",
        Header: () => (
          <TableHeaderDiv center>
            <b>User</b>
          </TableHeaderDiv>
        ),
        // Header: "User",
        // accessor: "user",
        accessor: d => (d.user_name ? d.user_name : "-"),
        style: {color: "#4D4F5C"},
      },
      {
        id: "expert",
        Header: () => (
          <TableHeaderDiv center>
            <b>Expert</b>
          </TableHeaderDiv>
        ),
        // Header: "Expert",
        // accessor: "expert",
        accessor: d => (d.expert_name ? d.expert_name : "-"),
        style: {color: "#4D4F5C"},
      },
      {
        id: "date",
        Header: () => (
          <TableHeaderDiv center>
            <b>Date</b>
          </TableHeaderDiv>
        ),
        // Header: "Date",
        // accessor: "date",
        accessor: d => d.refund_date,
        sortMethod: (a, b) => {
          const splitdate1 = a.split("-");
          const splitdate2 = b.split("-");
          const date1 = new Date(
            splitdate1[2],
            splitdate1[1],
            splitdate1[0],
          ).getTime();
          const date2 = new Date(
            splitdate2[2],
            splitdate2[1],
            splitdate2[0],
          ).getTime();

          // const date2
          if (date1 > date2) return 1;
          return -1;
        },
        style: {textAlign: "center", color: "#4D4F5C"},
      },
      {
        id: "amount",
        Header: () => (
          <TableHeaderDiv center>
            <b>Amount</b>
          </TableHeaderDiv>
        ),
        // Header: "Amounts",
        // accessor: "amounts",
        accessor: d => d.total_amount,
        style: {textAlign: "center", color: "#4D4F5C"},
        Cell: props => {
          // console.log(props);
          return (
            <span>
              {props.value ? (
                <NumberFormat
                  value={props.value}
                  decimalScale={2}
                  displayType="text"
                />
              ) : (
                0
              )}
            </span>
          );
        },
      },
      {
        id: "userrefundcomment",
        Header: () => (
          <TableHeaderDiv center>
            <b>User Refund Comment</b>
          </TableHeaderDiv>
        ),
        // Header: "Reason",
        // accessor: "reason",
        accessor: d => d.user_refund_comment,
        width: 350,
        style: {color: "#4D4F5C"},
      },
      {
        id: "status",
        Header: () => (
          <TableHeaderDiv center>
            <b>Status</b>
          </TableHeaderDiv>
        ),

        accessor: d => (d.refund_status === "initiated" ? "Initiated" : " "),
        style: {color: "#4D4F5C"},
      },
      {
        Header: "More",
        Cell: props => {
          return (
            <ArrowButton
              onClick={e => this.openPopup(e)}
              data-index={props.index}
            >
              <ArrowImg src={arrowimg} />
            </ArrowButton>
          );
        },
        style: {textAlign: "center", color: "#4D4F5C"},
        filterable: false,
      },
    ];
    return (
      <FullTableWrap>
        <SmartToaster store={toast} lightBackground position="top_center" />
        <TitleDiv>Chat Refund Request</TitleDiv>

        <TableWrap>
          <ReactTable
            noDataText={loading ? "" : "No Rows Found"}
            loading={loading}
            loadingText="Loading..."
            filterable
            columns={columns}
            data={getChatRefundRequest}
            defaultPageSize={10}
            defaultSorted={[
              {
                id: "date",
                desc: true,
              },
            ]}
          />

          <Modal
            isOpen={open}
            onRequestClose={this.closePopup}
            style={customStyles}
            ariaHideApp={false}
          >
            <div>
              <div>{this.modalrender()}</div>
            </div>
          </Modal>

          <Modal
            isOpen={openChatDetails}
            onRequestClose={this.closeChatDetailsPopup}
            style={customStylesChatDetails}
            ariaHideApp={false}
          >
            <MoreReasonPopupDiv>
              <div>{this.modalRenderChatDetails()}</div>
            </MoreReasonPopupDiv>
          </Modal>
        </TableWrap>
      </FullTableWrap>
    );
  }
}

export default ChatRefundReactTable;
