import React from "react";
import {render} from "react-dom";
import {createGlobalStyle} from "styled-components";
// import App from "./components/App";
import Routes from "./Routes";

const GlobalStyles = createGlobalStyle`
  body {
    @import url('https://fonts.googleapis.com/css?family=Lato');
    font-family: 'Lato', sans-serif;
  }
  .ReactTable .rt-noData{
    z-index: 0 !important;
  }
  `;

const RoutesInj = () => (
  <React.Fragment>
    <GlobalStyles />
    <Routes />
  </React.Fragment>
);
// select the root element
const root = document.getElementById("root");

// render the component
render(<RoutesInj />, root);
