import React from "react";
import styled from "styled-components";
import Modal from "react-modal";
import ReactTable from "react-table";

import NumberFormat from "react-number-format";
import "react-table/react-table.css";
import StarRatingComponent from "react-star-rating-component";
import arrowimg from "./arrow.png";
import closebtn from "../AboutShawer/closebtn.png";
import { searchInTable } from "../../utils/util";

const FullTableWrap = styled.div`
  margin-left: 4.5em;

  margin-top: 0.6em;
  max-height: 100%;
  width: 1360px;
  background-color: #ffffff;
`;

const ArrowButton = styled.button`
  border: none;
  outline: 0;
  cursor: pointer;
`;
const ArrowImg = styled.img`
  pointer-events: none;
`;

// Popup css
const ChatSummaryFullWrap = styled.div`
  margin: 30px;
`;
const ChatSummaryTitle = styled.h1`
  color: #1a173b;
  margin-top: 0px;
`;
const ChatSummaryContentLabelWrap = styled.div`
  display: flex;
  flex-direction: column;
`;
const ColumnOneLabelContentWrap = styled.div`
  margin: 0px 20px 0px 20px;
  border-right: 1px solid #b4bac6;
  max-width: 300px;
  min-height: 200px;
`;
const ColumnTwoLabelContentWrap = styled.div`
  max-width: 300px;
  min-height: 200px;
`;
const ChatSummaryLabel = styled.label`
  color: #b4bac6;
  font-size: 12px;
  padding-right: 20px;
`;
const ChatSummaryContent = styled.p`
  color: #000000;
  font-size: 17px;
`;
const RatingStars = styled.div`
  width: 100px;
`;
const DateAmountDiv = styled.div`
  display: flex;
  width: 800px;
`;
const DateAmountLabelDiv = styled.div`
  padding-right: 40px;
  padding-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LabelContentWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
  padding-bottom: 10px;
`;
const LabelContentReviewWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`;

const ButtonContentWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ColumnsWrap = styled.div`
  display: flex;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  padding: 40px 0px 40px 0px;
`;

const CloseButton = styled.button`
  outline: 0;
  border: none;

  margin-left: 600px;
  /* margin-top: -10px; */
`;

const CloseButtonImg = styled.img`
  height: 30px;
  width: 30px;
  cursor: pointer;
`;

const FilterInput = styled.input`
  ::-webkit-input-placeholder {
    font-size: 10px;
  }
`;

const TableHeaderDiv = styled.div`
  /* text-align: left; */
  text-align: ${props => (props.center ? "center" : "left")};
`;

const customStyles = {
  content: {
    height: "60%",
    width: "50%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

class ChatReactTable extends React.Component {
  state = {
    checked: false,
    open: false,
    rating: 1,
    selectedChatDetails: null,
    loading: true,
    // data: makeData(),
  };

  // openPopup = this.openPopup.bind(this);

  closePopup = this.closePopup.bind(this);

  onStarClick(nextValue) {
    this.setState({rating: nextValue});
  }

  openChatMorePopup = e => {
    // console.log("Target", e.target);
    const {index} = e.target.dataset;
    // console.log("indexxx", index);
    this.setState({open: true, selectedChatDetails: index});
  };

  closePopup() {
    this.setState({open: false});
  }

  renderChatSummaryModal = () => {
    const {chatHistoryList} = this.props;
    // console.log("Chat Details Listtttt", chatHistoryList);
    const {selectedChatDetails} = this.state;

    if (selectedChatDetails !== null) {
      const chatDetails = chatHistoryList[selectedChatDetails];
      // console.log("INDEX IS", selectedChatDetails);
      // console.log("Selected Chat Detail is", chatDetails);
      return (
        <div>
          <CloseButton onClick={this.closePopup}>
            <CloseButtonImg src={closebtn} />
          </CloseButton>
          <ChatSummaryTitle>Chat Summary</ChatSummaryTitle>
          <ChatSummaryContentLabelWrap>
            <DateAmountDiv>
              <DateAmountLabelDiv>
                <ChatSummaryLabel>DATE OF CHAT</ChatSummaryLabel>
                <ChatSummaryContent>{chatDetails.date}</ChatSummaryContent>
              </DateAmountLabelDiv>

              <DateAmountLabelDiv>
                <ChatSummaryLabel>TOTAL AMOUNT</ChatSummaryLabel>
                {chatDetails.total_amount ? (
                  <ChatSummaryContent>
                    <NumberFormat
                      value={chatDetails.total_amount}
                      decimalScale={2}
                      displayType="text"
                    />
                  </ChatSummaryContent>
                ) : (
                  "0"
                )}
              </DateAmountLabelDiv>

              <DateAmountLabelDiv>
                <ChatSummaryLabel>TYPE OF CHAT</ChatSummaryLabel>
                <ChatSummaryContent>{chatDetails.chat_type}</ChatSummaryContent>
              </DateAmountLabelDiv>
            </DateAmountDiv>
            <ColumnsWrap>
              <ColumnOneLabelContentWrap>
                <LabelContentWrap>
                  <ChatSummaryLabel>EXPERT</ChatSummaryLabel>
                  <ButtonContentWrap>
                    <ChatSummaryContent>
                      {chatDetails.expert_name}
                    </ChatSummaryContent>
                    {/* <SupportButton>Email</SupportButton> */}
                  </ButtonContentWrap>
                </LabelContentWrap>
                <LabelContentWrap>
                  <ChatSummaryLabel>EXPERT RATING</ChatSummaryLabel>
                  {chatDetails.expert_chat_feedback ? (
                    <RatingStars>
                      {chatDetails.expert_chat_feedback.rating ? (
                        <StarRatingComponent
                          name="rate1"
                          starCount={5}
                          value={chatDetails.expert_chat_feedback.rating}
                          onStarClick={this.onStarClick}
                          starColor="#5fd6ff"
                          // style={{backgroundColor: "blue"}}
                        />
                      ) : (
                        " Not available"
                      )}
                    </RatingStars>
                  ) : (
                    " Not available"
                  )}
                </LabelContentWrap>
                <LabelContentReviewWrap>
                  <ChatSummaryLabel>EXPERT REVIEW</ChatSummaryLabel>
                  {chatDetails.expert_chat_feedback ? (
                    <div>
                      {chatDetails.expert_chat_feedback.reason_text ? (
                        <ChatSummaryContent>
                          {chatDetails.expert_chat_feedback.reason_text}
                        </ChatSummaryContent>
                      ) : (
                        "Expert hasn't got any reviews from the user."
                      )}
                    </div>
                  ) : (
                    "Expert hasn't got any reviews from the user."
                  )}
                </LabelContentReviewWrap>
              </ColumnOneLabelContentWrap>

              <ColumnTwoLabelContentWrap>
                <LabelContentWrap>
                  <ChatSummaryLabel>USER</ChatSummaryLabel>
                  <ButtonContentWrap>
                    <ChatSummaryContent>
                      {chatDetails.user_name}
                    </ChatSummaryContent>
                    {/* <SupportButton>Email</SupportButton> */}
                  </ButtonContentWrap>
                </LabelContentWrap>
                <LabelContentWrap>
                  <ChatSummaryLabel>USER RATING</ChatSummaryLabel>
                  {chatDetails.user_chat_feedback ? (
                    <RatingStars>
                      {chatDetails.user_chat_feedback.rating ? (
                        <StarRatingComponent
                          name="rate1"
                          starCount={5}
                          value={chatDetails.user_chat_feedback.rating}
                          onStarClick={this.onStarClick}
                          starColor="#5fd6ff"
                          // style={{backgroundColor: "blue"}}
                        />
                      ) : (
                        " Not available"
                      )}
                    </RatingStars>
                  ) : (
                    " Not available"
                  )}

                  {/* <RatingStars>
                    <StarRatingComponent
                      name="rate1"
                      starCount={5}
                      value={chatDetails.user_chat_feedback.rating}
                      onStarClick={this.onStarClick}
                      starColor="#5fd6ff"
                    />
                  </RatingStars> */}
                </LabelContentWrap>
                <LabelContentReviewWrap>
                  <ChatSummaryLabel>USER REVIEW</ChatSummaryLabel>
                  {chatDetails.expert_chat_feedback ? (
                    <div>
                      {chatDetails.expert_chat_feedback.reason_text ? (
                        <ChatSummaryContent>
                          {chatDetails.user_chat_feedback.reason_text}
                        </ChatSummaryContent>
                      ) : (
                        "User hasn't got any reviews from the expert."
                      )}
                    </div>
                  ) : (
                    "User hasn't got any reviews from the expert."
                  )}
                  {/* <ChatSummaryContent>
                    {chatDetails.user_chat_feedback.reason_text}
                  </ChatSummaryContent> */}
                </LabelContentReviewWrap>
              </ColumnTwoLabelContentWrap>
            </ColumnsWrap>
            {/* <ExportDiv>
              <ChatTxtLabel>Chat.txt</ChatTxtLabel>
              <ChatExportPDFButton>Export as PDF</ChatExportPDFButton>
            </ExportDiv> */}
          </ChatSummaryContentLabelWrap>
        </div>
      );
    }
    return null;
  };

  render() {
    const {chatHistoryList, loading} = this.props;

    // console.log("Chat Details", chatHistoryList);
    const {open} = this.state;
    const columns = [
      {
        id: "date",
        Header: () => (
          <TableHeaderDiv center>
            <b>Date of Chat</b>
          </TableHeaderDiv>
        ),
        // Header: "Date of Chat",
        // accessor: "dateofchat",

        accessor: d => d.date,

        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search Date of Chat..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        sortMethod: (a, b) => {
          const splitdate1 = a.split("-");
          const splitdate2 = b.split("-");
          const date1 = new Date(
            splitdate1[2],
            splitdate1[1],
            splitdate1[0],
          ).getTime();
          const date2 = new Date(
            splitdate2[2],
            splitdate2[1],
            splitdate2[0],
          ).getTime();

          // const date2
          if (date1 > date2) return 1;
          return -1;
        },
        // filterMethod: (filter, rows) =>
        //   matchSorter(rows, filter.value, {keys: ["date"]}),
        // desc: true,
        filterMethod: (filter, row) => searchInTable(filter, row),
        style: {textAlign: "center", color: "#4D4F5C"},
      },
      {
        id: "chatid",
        Header: () => (
          <TableHeaderDiv center>
            <b>Chat ID</b>
          </TableHeaderDiv>
        ),
        // Header: "Date of Chat",
        // accessor: "dateofchat",
        width: 250,
        accessor: d => d.chat_id,
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search Chat ID..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        style: {textAlign: "center", color: "#4D4F5C"},
        filterMethod: (filter, row) => searchInTable(filter, row),
      },
      {
        id: "expert",
        Header: () => (
          <TableHeaderDiv center>
            <b>Expert Name</b>
          </TableHeaderDiv>
        ),
        // Header: "Expert",
        // accessor: "expert",
        accessor: d => (d.expert_name ? d.expert_name : "-"),
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search Expert..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        style: {color: "#4D4F5C"},
        filterMethod: (filter, row) => searchInTable(filter, row),
        // style: {textAlign: "center"},
      },
      {
        id: "user",
        Header: () => (
          <TableHeaderDiv center>
            <b>User Name</b>
          </TableHeaderDiv>
        ),
        // Header: "User",
        // accessor: "user",
        // style: {textAlign: "center"},
        style: {color: "#4D4F5C"},

        accessor: d => (d.user_name ? d.user_name : "-"),
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search User..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        filterMethod: (filter, row) => searchInTable(filter, row),
      },
      {
        id: "totalamount",
        Header: () => (
          <TableHeaderDiv center>
            <b>Amount</b>
          </TableHeaderDiv>
        ),

        // Header: "Amount",
        // accessor: "amount",
        accessor: d => d.total_amount,
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search Amount..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        style: {textAlign: "center", color: "#4D4F5C"},
        Cell: props => {
          // console.log(props);
          return (
            <span>
              {props.value ? (
                <NumberFormat
                  value={props.value}
                  decimalScale={2}
                  displayType="text"
                />
              ) : (
                0
              )}
            </span>
          );
        },
      },
      {
        id: "chattype",
        Header: () => (
          <TableHeaderDiv center>
            <b>Chat Type</b>
          </TableHeaderDiv>
        ),
        // Header: "Chat Type",
        accessor: d =>
          d.chat_type === "online"
            ? "Online"
            : d.chat_type === "offline"
            ? "Offline"
            : d.chat_type === "schedule"
            ? "Schedule"
            : "",
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search Chat Type..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        filterMethod: (filter, row) => searchInTable(filter, row),
        style: {color: "#4D4F5C"},
      },
      {
        Header: () => (
          <TableHeaderDiv center>
            <b>More</b>
          </TableHeaderDiv>
        ),
        // Header: "More",
        Cell: props => {
          return (
            <ArrowButton
              onClick={e => this.openChatMorePopup(e)}
              data-index={props.index}
            >
              {/* {console.log("props Value", props.index)} */}
              <ArrowImg src={arrowimg} />
            </ArrowButton>
          );
        },
        style: {textAlign: "center", color: "#4D4F5C"},
        filterable: false,
      },
    ];
    return (
      <FullTableWrap>
        <ReactTable
          noDataText={loading ? "" : "No Rows Found"}
          loading={loading}
          loadingText="Loading..."
          filterable
          columns={columns}
          data={chatHistoryList}
          defaultPageSize={10}
          defaultSorted={[
            {
              id: "date",
              desc: true,
            },
          ]}
          // showPaginationTop
          // showPaginationBottom={false}
        />

        <Modal
          isOpen={open}
          // onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closePopup}
          style={customStyles}
          ariaHideApp={false}
          // contentLabel="Example Modal"
        >
          {/* <Popup open={open}> */}
          <ChatSummaryFullWrap>
            {this.renderChatSummaryModal()}
          </ChatSummaryFullWrap>
        </Modal>
      </FullTableWrap>
    );
  }
}

export default ChatReactTable;
