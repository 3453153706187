import React from "react";
import styled from "styled-components";
import Modal from "react-modal";
import ReactTable from "react-table";
import "react-table/react-table.css";
import axios from "axios";
import NumberFormat from "react-number-format";
import {apiURL, createBuffer, generateSHA2, searchInTable} from "../../utils/util";
import closebtn from "../AboutShawer/closebtn.png";

const FullTableWrap = styled.div`
  margin-left: 1.2em;

  margin-top: 2.5em;
  max-height: 100%;
  width: 1200px;
  background-color: #ffffff;
`;

const TableWrap = styled.div`
  margin-left: 20px;
  width: 1150px;
`;

const TableHeaderDiv = styled.div`
  /* text-align: left; */
  text-align: ${props => (props.center ? "center" : "left")};
`;

const FilterInput = styled.input`
  ::-webkit-input-placeholder {
    font-size: 10px;
  }
`;

const MoreReasonButton = styled.button`
  border: 0px;
  color: #207fc6;
  padding: 0px;
  /* border: 1px solid black; */
  border-bottom: 1px solid #207fc6;
  width: 40px;
  font-size: 15px;
  cursor: pointer;
  outline: none;
`;

const CloseButton = styled.button`
  outline: 0;
  border: none;

  margin-left: 370px;
  margin-top: -10px;
`;

const CloseButtonImg = styled.img`
  height: 20px;
  width: 20px;
`;

const MoreReasonTitle = styled.h2`
  margin-top: 0px;
`;

const DetailsContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between;
  align-items: center; */
`;

const MoreReasonPopupDiv = styled.div``;

const email = window.sessionStorage.getItem("email");
const password = window.sessionStorage.getItem("token");

const customStylesMore = {
  content: {
    height: "25%",
    width: "30%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    // border: "0px",
  },
};

class RefundReactTable extends React.Component {
  state = {
    RefundTransactionList: [],
    selectedRefundTransactionDetails: null,
    openReasonMore: false,
    loading: true,
    // data: makeData(),
  };

  closeReasonMorePopup = this.closeReasonMorePopup.bind(this);

  // lifecycle methods

  componentDidMount() {
    this.getRefundTransactionDetails();
  }

  openReasonMorePopup = e => {
    // console.log("Target", e.target);
    const {index} = e.target.dataset;
    this.setState({
      openReasonMore: true,
      selectedRefundTransactionDetails: index,
    });
  };

  modalrender = () => {
    const {
      selectedRefundTransactionDetails,
      RefundTransactionList,
    } = this.state;

    if (selectedRefundTransactionDetails !== null) {
      const RefundTransactionDetails =
        RefundTransactionList[selectedRefundTransactionDetails];

      return (
        <div>
          <CloseButton more onClick={this.closeReasonMorePopup}>
            <CloseButtonImg src={closebtn} />
          </CloseButton>
          <MoreReasonTitle>User Comment</MoreReasonTitle>
          <DetailsContentWrap>
            {RefundTransactionDetails.user_refund_comment}
          </DetailsContentWrap>
        </div>
      );
    }
    return null;
  };

  // api calls

  getRefundTransactionDetails = () => {
    const buffer = createBuffer({email});
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/transaction/RefundTransaction`,
        {
          json_string: JSON.stringify({email}),
        },
        {headers: {signature, email, channel: "web"}},
      )

      // .post(`${apiURL}/transaction/RefundTransaction`, {})

      .then(response => {
        // console.log(response);

        if (response.status === 200 && response.data) {
          // console.log(response.data);
          const {data} = response;
          // console.log("Data", data);

          this.setState({RefundTransactionList: data.content, loading: false});
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };

  closeReasonMorePopup() {
    this.setState({openReasonMore: false});
  }

  render() {
    const {RefundTransactionList, openReasonMore, loading} = this.state;
    const columns = [
      {
        id: "startdate",
        Header: () => (
          <TableHeaderDiv center>
            <b>Start Date</b>
          </TableHeaderDiv>
        ),
        // Header: "Date of Transaction",
        // accessor: "dateoftransaction",
        accessor: d => d.refund_initiated_date,
        style: {textAlign: "center", color: "#4D4F5C"},
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search Start Date of Refund..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        sortMethod: (a, b) => {
          const splitdate1 = a.split("-");
          const splitdate2 = b.split("-");
          const date1 = new Date(
            splitdate1[2],
            splitdate1[1],
            splitdate1[0],
          ).getTime();
          const date2 = new Date(
            splitdate2[2],
            splitdate2[1],
            splitdate2[0],
          ).getTime();

          // const date2
          if (date1 > date2) return 1;
          return -1;
        },
        filterMethod: (filter, row) => searchInTable(filter, row),
      },
      {
        id: "enddate",
        Header: () => (
          <TableHeaderDiv center>
            <b>End Date</b>
          </TableHeaderDiv>
        ),

        // Header: "Transaction ID",
        // accessor: "transactionid",
        accessor: d => d.refund_closed_date,
        style: {textAlign: "center", color: "#4D4F5C"},
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search End Date of Refund..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        sortMethod: (a, b) => {
          const splitdate1 = a.split("-");
          const splitdate2 = b.split("-");
          const date1 = new Date(
            splitdate1[2],
            splitdate1[1],
            splitdate1[0],
          ).getTime();
          const date2 = new Date(
            splitdate2[2],
            splitdate2[1],
            splitdate2[0],
          ).getTime();

          // const date2
          if (date1 > date2) return 1;
          return -1;
        },
        filterMethod: (filter, row) => searchInTable(filter, row),
      },

      {
        id: "chatid",
        Header: () => (
          <TableHeaderDiv center>
            <b>Chat ID</b>
          </TableHeaderDiv>
        ),
        width: 270,
        // Header: "Transaction ID",
        // accessor: "transactionid",
        accessor: d => d.chat_id,
        style: {textAlign: "center", color: "#4D4F5C"},
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search Chat ID of Refund..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        filterMethod: (filter, row) => searchInTable(filter, row),
      },
      {
        id: "usercomment",
        Header: () => (
          <TableHeaderDiv center>
            <b>User Comment</b>
          </TableHeaderDiv>
        ),
        width: 250,

        accessor: d => d.user_refund_comment,
        style: {color: "#4D4F5C"},
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search Comment..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        Cell: props => {
          const usercomment = props.value;

          const minifiedString = usercomment.substring(0, 22);

          return (
            <div>
              <div>
                {`${minifiedString}`}
                <label>... </label>
                <MoreReasonButton
                  onClick={e => this.openReasonMorePopup(e)}
                  data-index={props.index}
                >
                  More
                </MoreReasonButton>
              </div>
            </div>
          );
        },
      },

      // {
      //   id: "admincomment",
      //   Header: () => (
      //     <TableHeaderDiv>
      //       <b>Admin Comment</b>
      //     </TableHeaderDiv>
      //   ),
      //   width: 250,
      //
      //   accessor: "expertcomment",
      //   // accessor: d => d.user_refund_comment,
      //   Filter: ({filter, onChange}) => (
      //     <FilterInput
      //       type="text"
      //       placeholder="Search expert Comment..."
      //       value={filter ? filter.value : ""}
      //       onChange={event => onChange(event.target.value)}
      //     />
      //   ),
      // },

      {
        id: "username",
        Header: () => (
          <TableHeaderDiv center>
            <b>User Name</b>
          </TableHeaderDiv>
        ),
        // Header: "User Name",
        // accessor: "username",
        accessor: d => (d.user_name ? d.user_name : "-"),
        style: {color: "#4D4F5C"},
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search User Name..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        filterMethod: (filter, row) => searchInTable(filter, row),
      },
      {
        id: "amount",
        Header: () => (
          <TableHeaderDiv center>
            <b>Amount</b>
          </TableHeaderDiv>
        ),
        // Header: "Amounts",
        // accessor: "amount",
        accessor: d => d.refund_amount,
        style: {textAlign: "center", color: "#4D4F5C"},
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search Amount..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        Cell: props => {
          // console.log(props);
          return (
            <span>
              {props.value ? (
                <NumberFormat
                  value={props.value}
                  decimalScale={2}
                  displayType="text"
                />
              ) : (
                0
              )}
            </span>
          );
        },
      },
      {
        id: "status",
        Header: () => (
          <TableHeaderDiv>
            <b>Status</b>
          </TableHeaderDiv>
        ),
        // Header: "Status",
        // accessor: "status",
        style: {color: "#4D4F5C"},
        accessor: d =>
          d.refund_status === "initiated"
            ? "Initiated"
            : d.refund_status === "partially_done"
            ? "Partially Done"
            : d.refund_status === "fully_done"
            ? "Full Refund"
            : d.refund_status === "rejected"
            ? "No Refund"
            : "-",
        // style: {textAlign: "center"},
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search Status..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        filterMethod: (filter, row) => searchInTable(filter, row),
      },
    ];
    return (
      <FullTableWrap>
        <TableWrap>
          <ReactTable
            noDataText={loading ? "" : "No Rows Found"}
            loading={loading}
            loadingText="Loading..."
            filterable
            columns={columns}
            data={RefundTransactionList}
            defaultPageSize={10}
            defaultSorted={[
              {
                id: "startdate",
                desc: true,
              },
            ]}
            // expanded={expanded}
            // showPaginationTop
            // showPaginationBottom={false}
          />
        </TableWrap>

        <Modal
          isOpen={openReasonMore}
          onRequestClose={this.closeReasonMorePopup}
          style={customStylesMore}
          ariaHideApp={false}
        >
          <MoreReasonPopupDiv>
            <div>{this.modalrender()}</div>
          </MoreReasonPopupDiv>
        </Modal>
      </FullTableWrap>
    );
  }
}

export default RefundReactTable;
