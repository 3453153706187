import React from "react";
import styled from "styled-components";

import axios from "axios";

import "../Common/datetimepicker.css";
import {Form, Field, FieldArray, Formik} from "formik";

// api URL
import {apiURL, createBuffer, generateSHA2} from "../../utils/util";

// import "../../styles/global.css";

const FullWrap = styled.div`
  width: 950px;
  /* border: 1px solid black; */
  display: flex;
  /* flex-wrap: wrap; */
  margin-top: 2em;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.23);
  background-color: #ffffff;
  margin-left: 1em;
  border-radius: 6px;
  flex-direction: column;
`;
const TwoFieldWrap = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 700px;
`;

const LableInput = styled.div`
  margin: 20px;
  width: ${props => (props.full ? 540 : 270)}px;
`;

const Label = styled.label`
  font-size: 12px;
  color: #323c47;
`;

const FormField = styled(Field)`
  margin-top: 10px;
  position: ${props => (props.absolute ? "absolute" : "")};
  width: 100%;
  border: solid 1px #e9eff4;
  text-indent: 15px;
  padding: 10px;
  font-size: 17px;
  border-radius: 4px;
  background-color: #ffffff;
  opacity: 0.5;
  ::-webkit-input-placeholder {
    font-family: Ubuntu;
    /* font-size: 20px; */
    font-weight: 300;
    color: #0d3868;
    opacity: 0.5;
    padding: 15px;
    /* text-align: left; */
  }
`;

const FormFieldTextarea = styled.textarea`
  height: 40px;
  margin-top: 10px;
  position: ${props => (props.absolute ? "absolute" : "")};
  width: 100%;
  border: solid 1px #e9eff4;
  text-indent: 15px;
  padding: 10px;
  font-size: 17px;
  border-radius: 4px;
  background-color: #ffffff;
  opacity: 0.5;
  ::-webkit-input-placeholder {
    font-family: Ubuntu;
    /* font-size: 20px; */
    font-weight: 300;
    color: #0d3868;
    opacity: 0.5;
    padding: 15px;
  }
`;

const email = window.sessionStorage.getItem("email");
const password = window.sessionStorage.getItem("token");

export default class FinancialForm extends React.Component {
  state = {
    expertID: sessionStorage.getItem("expertID"),
    reviewsRatings: [],
  };

  componentDidMount() {
    this.getParticularExpertDetails();
  }

  getParticularExpertDetails = () => {
    const {expertID} = this.state;

    const buffer = createBuffer({email, expert_id: expertID});
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/expert/GetReviewandRating`,
        {
          json_string: JSON.stringify({email, expert_id: expertID}),
        },
        {headers: {signature, email, channel: "web"}},
      )
      // axios
      //   .post(`${apiURL}/expert/GetReviewandRating`, {
      //     expert_id: expertID,
      //   })
      .then(response => {
        // console.log(response);
        if (response.status === 200 && response.data) {
          const {data} = response;

          this.setState({reviewsRatings: data.content});
          // console.log(data);
          // console.log("dc", data.content);

          // if (this.state.experience) {
          //   this.setState({apiLoading: false});
          // }
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };

  render() {
    const {reviewsRatings} = this.state;

    return (
      <div>
        <Formik
          enableReinitialize
          initialValues={{reviewsRatingsDetails: reviewsRatings}}
          render={({values}) => (
            <Form>
              <FieldArray
                name="reviewsRatingsDetails"
                render={() => (
                  <React.Fragment>
                    {/* {console.log("reviewsRatings", reviewsRatings)} */}
                    {values.reviewsRatingsDetails.map((finDetail, index) => (
                      // <div key={expDetail.experience_id}>
                      // {console.log(values)}
                      <FullWrap key={finDetail.id}>
                        <TwoFieldWrap>
                          <LableInput>
                            <Label>DATE</Label>
                            <FormField
                              type="text"
                              placeholder="Write here..."
                              value={reviewsRatings[index].date}
                              name={`reviewsRatingsDetails[${index}].date`}
                              data-name="date"
                            />
                          </LableInput>

                          <LableInput>
                            <Label>USER NAME</Label>
                            <FormField
                              type="text"
                              placeholder="Write here..."
                              value={reviewsRatings[index].user_name}
                              name={`reviewsRatingsDetails[${index}].user_name`}
                              data-name="user_name"
                            />
                          </LableInput>

                          <LableInput full>
                            <Label>REASONS</Label>
                            <FormField
                              type="text"
                              placeholder="Write here..."
                              value={reviewsRatings[index].reasons}
                              name={`reviewsRatingsDetails[${index}].reasons`}
                              data-name="reasons"
                            />
                          </LableInput>

                          <LableInput>
                            <Label>RATING</Label>
                            <FormField
                              type="text"
                              placeholder="Write here..."
                              // value={bank_details[index].full_name}
                              // name={`financialDetails[${index}].full_name`}
                              value={reviewsRatings[index].rating}
                              name={`reviewsRatingsDetails[${index}].rating`}
                              data-name="rating"
                            />
                          </LableInput>

                          <LableInput full>
                            <Label> REVIEW</Label>
                            {reviewsRatings[index].reason_text ? (
                              <FormFieldTextarea
                                type="text"
                                placeholder="Write here..."
                                // value={bank_details[index].full_name}
                                // name={`financialDetails[${index}].full_name`}

                                value={reviewsRatings[index].reason_text}
                                name={`reviewsRatingsDetails[${index}].reason_text`}
                                data-name="reason_text"
                              />
                            ) : (
                              <FormFieldTextarea value="Review is not available" />
                            )}
                          </LableInput>
                        </TwoFieldWrap>
                      </FullWrap>
                    ))}
                  </React.Fragment>
                )}
              />
              {/* </FullWrap> */}
            </Form>
          )}
        />
      </div>
    );
  }
}
