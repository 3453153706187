import React from "react";
import styled from "styled-components";
import axios from "axios";

import NavigationBar from "../NavigationBar/NavigationBar";
import SideBar from "../NavigationBar/SideBar";
import ExpertTable from "./ExpertTable";
import Ecards from "./Ecards";
import FullForm from "../Common/FullForm";
import ExpertSideBar from "./ExpertSideBar";
import optionfilter from "./optionmain.png";
import FilterOptions from "./FilterOptions";

import ExperienceForm from "./ExperienceForm";
import ServiceChargesForm from "./ServiceChargesForm";
import SkillsForm from "./SkillsForm";
import EducationForm from "./EducationForm";

// api URL
import {apiURL} from "../../utils/util";
import ExpertReactTable from "./ExpertReactTable";

const FullSection = styled.section`
  background-color: #fafbfc;
  display: grid;
  grid-template-rows: 1fr auto;
  max-height: 100%;

  /* grid-template-columns: auto auto; */
`;
const LabelInputWrap = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 4.5em;
  margin-left: 4.5em;
  margin-top: 0.5em;
`;

const ExpertListLabel = styled.label`
  font-size: 17px;
  color: #1a173b;
  /* margin-left: 23px; */
`;
const AddExpert = styled.button`
  padding: 10px;
  width: 15%;
  border-radius: 5px;
  background-color: #e54666;
  text-align: center;
  border: 0;
  outline: 0px;
  color: #fff;
`;
const BacktoExpertButton = styled.button`
  color: #ffffff;
  padding: 10px;
  width: 18%;
  border-radius: 5px;
  background-color: #e54666;
  text-align: center;
  border: 0;
`;
const PageOneContentWrap = styled.div``;
const PageTwoContentWrap = styled.div``;
const PageTwoContent = styled.div`
  display: flex;
  border-radius: 3.8px;
  border: solid 1px #ebedf8;
  background-color: #ffffff;
  margin: 0.5em 0 2em 6em;
  height: 100%;
`;
const FilterOption = styled.img`
  padding-right: 10px;
`;

const FilterOptionButton = styled.button`
  border: 0;
  background-color: #ffffff;
`;

const TitleLabel = styled.label`
  font-size: 15px;
  padding-right: 10px;
`;

const FullCardWrap = styled.div`
  display: flex;
  margin-left: 70px;
  justify-content: space-between;
  max-width: 750px;
`;
const CardTitleWrap = styled.div`
  padding-top: 20px;
`;

const CardContentWrap = styled.div`
  /* padding-top: 20px;
  padding-left: 20px; */
  padding: 10px 20px 70px 10px;
`;
const LableWrap = styled.div`
  padding-top: ${props => (props.padding ? "10" : "")}px;
  /* padding: 10px 20px 50px 10px; */
`;
const NumberLabel = styled.label`
  /* padding-left: 10px; */
  padding-right: 5px;
  font-size: 20px;
  color: #4da1ff;
`;
const NumberLabelReview = styled(NumberLabel)`
  color: #ff6d4a;
`;

const MonthLabel = styled.label`
  font-size: 15px;
`;
const YearLabel = styled.label`
  font-size: 15px;
`;

const ActiveExpertsList = styled.li`
  list-style: none;
  color: #417505;
`;

// const docid = {
//   docid: this.state.docid,
// };
class ExpertReview extends React.Component {
  state = {
    isPageLoad: true,
    isOptionClicked: false,
    isBackToExpertListClicked: false,
    expertsList: [],
    particularExpertDetails: [],
    expertID: sessionStorage.getItem("expertID"),
    isPersonalDetailsClicked: true,
    isEducationDetailsClicked: false,
    isServiceChargesDetailsClicked: false,
    isSkillsDetailsClicked: false,
    isExperienceDetailsClicked: false,
    isPersonalDetailsActive: true,
    isEducationDetailsActive: false,
    isServiceChargesDetailsActive: false,
    isSkillsDetailsActive: false,
    isExperienceDetailsActive: false,
  };

  // lifecycle methods

  componentDidMount() {
    this.getExpertDetails();
  }

  // api calls

  getExpertDetails = () => {
    axios
      .post(`${apiURL}/expert/getExpertList`, {})
      .then(response => {
        if (response.status === 200 && response.data) {
          const {data} = response;
          this.setState({expertsList: data});
        }
      })
      .catch(error => {});
  };

  // below api Not To be included

  // getParticularExpertDetails = () => {
  //   const {expertID} = this.state;
  //   axios
  //     .post(`${apiURL}/expert/getParticularExpert`, {
  //       docid: expertID,
  //     })
  //     // .post(`${apiURL}/expert/getParticularExpert`, {}, {params: {}})
  //     .then(response => {
  //       console.log(response);
  //       if (response.status === 200 && response.data) {
  //         const {particularexpertdata} = response;
  //         this.setState({particularExpertDetails: particularexpertdata});
  //       }
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // };

  // event handling methods

  handleReview = event => {
    console.log(event.target.dataset.expertid);
    const expertId = event.target.dataset.expertid;

    sessionStorage.setItem("expertID", expertId);

    this.setState({
      isPageLoad: false,
    });
  };

  optionClicked = () => {
    this.setState({
      isOptionClicked: true,
    });
  };

  personalDetailsClicked = () => {
    this.setState({
      isPersonalDetailsClicked: true,
      isPersonalDetailsActive: true,
      isEducationDetailsActive: false,
      isServiceChargesDetailsActive: false,
      isSkillsDetailsActive: false,
      isExperienceDetailsActive: false,
    });
  };

  educationDetailsClicked = event => {
    console.log(event.target);
    console.log("Education Details button clicked");
    this.setState({
      isPersonalDetailsClicked: false,
      isEducationDetailsClicked: true,
      isPersonalDetailsActive: false,
      isEducationDetailsActive: true,
      isServiceChargesDetailsActive: false,
      isSkillsDetailsActive: false,
      isExperienceDetailsActive: false,
    });
  };

  serviceChargesDetailsClicked = () => {
    this.setState({
      isPersonalDetailsClicked: false,
      isEducationDetailsClicked: false,
      isServiceChargesDetailsClicked: true,
      isPersonalDetailsActive: false,
      isEducationDetailsActive: false,
      isServiceChargesDetailsActive: true,
      isSkillsDetailsActive: false,
      isExperienceDetailsActive: false,
    });
  };

  skillsDetailsClicked = () => {
    this.setState({
      isPersonalDetailsClicked: false,
      isEducationDetailsClicked: false,
      isServiceChargesDetailsClicked: false,
      isSkillsDetailsClicked: true,
      isPersonalDetailsActive: false,
      isEducationDetailsActive: false,
      isServiceChargesDetailsActive: false,
      isSkillsDetailsActive: true,
      isExperienceDetailsActive: false,
    });
  };

  experienceDetailsClicked = () => {
    this.setState({
      isPersonalDetailsClicked: false,
      isEducationDetailsClicked: false,
      isServiceChargesDetailsClicked: false,
      isSkillsDetailsClicked: false,
      isExperienceDetailsClicked: true,
      isPersonalDetailsActive: false,
      isEducationDetailsActive: false,
      isServiceChargesDetailsActive: false,
      isSkillsDetailsActive: false,
      isExperienceDetailsActive: true,
    });
  };

  backToExpertClicked = () => {
    // isBackToExpertListClicked: true,
    // browserHistory.push("/experts");
  };

  render() {
    const {match} = this.props;
    console.log("Expert Review Path", match.path);
    console.log("Expert Review Props", this.props);
    console.log("Param ID", match.params.id);
    const {
      isPageLoad,
      isOptionClicked,
      expertsList,
      expertID,
      particularExpertDetails,
      isPersonalDetailsClicked,
      isEducationDetailsClicked,
      isServiceChargesDetailsClicked,
      isSkillsDetailsClicked,
      isExperienceDetailsClicked,
      isPersonalDetailsActive,
      isEducationDetailsActive,
      isServiceChargesDetailsActive,
      isSkillsDetailsActive,
      isExperienceDetailsActive,
      isBackToExpertListClicked,
    } = this.state;

    // const {isPersonalInfoClicked, isEducationInfoClicked} = this.props;

    return (
      <FullSection>
        <NavigationBar />
        <SideBar />
        <PageTwoContentWrap>
          <LabelInputWrap>
            <ExpertListLabel>Expert Review</ExpertListLabel>
            {/* 
              <BacktoExpertButton onClick={this.backToExpertClicked}>
                Back To Expert List
              </BacktoExpertButton>
              {isBackToExpertListClicked ? <Experts /> : null} */}
          </LabelInputWrap>
          <PageTwoContent>
            <ExpertSideBar
              personalInfoClicked={this.personalDetailsClicked}
              isPersonalInfoActive={isPersonalDetailsActive}
              educationInfoClicked={this.educationDetailsClicked}
              isEducationInfoActive={isEducationDetailsActive}
              serviceChargesInfoClicked={this.serviceChargesDetailsClicked}
              isServiceChargesInfoActive={isServiceChargesDetailsActive}
              skillsInfoClicked={this.skillsDetailsClicked}
              isSkillsInfoActive={isSkillsDetailsActive}
              experienceInfoClicked={this.experienceDetailsClicked}
              isExperienceInfoActive={isExperienceDetailsActive}
            />
            {/* if(isPersonalDetailsClicked)
              {<FullForm onClick={this.personalDetailsClicked} />}
              else if(isEducationDetailsClicked)
              {
                <QualificationDegreeForm
                  onClick={this.educationDetailsClicked}
                />
              } */}

            {isPersonalDetailsClicked ? (
              <FullForm particularExpertDetails={particularExpertDetails} />
            ) : isEducationDetailsClicked ? (
              // <QualificationDegreeForm
              //   particularExpertDetails={particularExpertDetails}
              // />
              <EducationForm />
            ) : isServiceChargesDetailsClicked ? (
              <ServiceChargesForm />
            ) : isSkillsDetailsClicked ? (
              <SkillsForm />
            ) : isExperienceDetailsClicked ? (
              <ExperienceForm />
            ) : null}
          </PageTwoContent>
        </PageTwoContentWrap>
      </FullSection>
    );
  }
}

export default ExpertReview;
