import React, {Component} from "react";
import styled from "styled-components";
import {SmartToaster, toast} from "react-smart-toaster";

import axios from "axios";
import Modal from "react-modal";
import ReactTable from "react-table";
import "react-table/react-table.css";

// api URL
import {apiURL, createBuffer, generateSHA2, notiicationResponse, Spinner} from "../../utils/util";

import closebtn from "../AboutShawer/closebtn.png";
import moment from "moment";

const FullTableWrap = styled.div`
  margin-left: 4.5em;

  margin-top: 0.6em;
  max-height: 100%;
  width: 1000px;
  background-color: #ffffff;
`;

const EditRemoveButtonWrap = styled.div`
  align-items: center;
  /* margin-left: 550px; */
  // width: 150px;
  display: flex;
  justify-content: space-around;
`;

const EditButton = styled.button`
  border: none;
  color: #39a1dd;
  font-size: 15px;
  cursor: pointer;
`;

const RemoveButton = styled.button`
  border: none;
  color: #d0021b;
  font-size: 15px;
  cursor: pointer;
`;

const customStyles = {
  content: {
    height: "80%",
    width: "38%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    position: 'absolute',
    transform: "translate(-50%, -50%)",
    // border: "0px",
  },
};

const PopupWrap = styled.div`
  margin-left: 60px;
  display: flex;
  flex-direction: column;
`;

const PopupTitle = styled.h3`
  margin-bottom: 30px;
  margin-top: 0px;
`;

const LabelInputPopupWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 60px; */
  height: ${props => (props.height ? "280" : "60")}px;
  /* margin-bottom: 25px; */
  margin-bottom: ${props => (props.margin ? "5" : "25")}px;
`;

const Label = styled.label`
  font-size: 10px;
`;

const Input = styled.input`
  color: #323c47;
  border-radius: 4px;
  border: solid 1px #e9eff4;
  width: 350px;
  height: 40px;
`;

const DoneButton = styled.button`
  width: 100px;
  color: #fff;
  height: 30px;
  border-radius: 5px;
  background-color: #085db0;
  outline: 0;
  cursor: pointer;
`;

const CloseButton = styled.button`
  outline: 0;
  border: none;
  cursor: pointer;
  margin-left: 400px;
  margin-top: -10px;
`;

const CloseButtonImg = styled.img`
  height: 30px;
  width: 30px;
`;

const email = window.sessionStorage.getItem("email");
const password = window.sessionStorage.getItem("token");

class NotificationReactTable extends React.Component {
  state = {
    selectedDetails: null,
    checked: false,
    open: false,
    adminList: [],
    loading: false,
    isLoading: false,
    superAdminClicked: false,
    contentManagerClicked: false,
    approvalManagerClicked: false,
    financialManagerClicked: false,
    selectedRadioButtonValue: null,
    adminName: "",
    data: [
      {
        adminname: "Omar Khalife",
        email: "OmarKhalife@gmail.com",
        role: "Super Admin",
      },
      {
        adminname: "Nour Ballane",
        email: "Nourballane@gmail.com",
        role: "Super Reader",
      },
    ],
  };

  closePopup = this.closePopup.bind(this);

  componentDidMount() {
    // this.getNotificationDetails();
  }

  handleOnNameChange = e => {
    const {selectedDetails, adminList} = this.state;

    const {value} = e.target;

    adminList[selectedDetails].name = value;
    this.setState({
      adminName: e.target.value,
    });
  };

  modalrender = () => {
    const {selectedDetails, adminList, isLoading} = this.state;

    if (selectedDetails && adminList.length > 0) {
      const selectedPopupDetails = adminList[selectedDetails];

      return (
        <div>
          <PopupWrap>
            <CloseButton more onClick={this.closePopup}>
              <CloseButtonImg src={closebtn} />
            </CloseButton>
            <PopupTitle>Edit Notification</PopupTitle>
            <LabelInputPopupWrap>
              <Label>Message</Label>
              <Input
                name="adminName"
                value={selectedPopupDetails.name}
                onChange={e => this.handleOnNameChange(e)}
                // onChange={e => this.setState({name: e.target.value})}
              />
            </LabelInputPopupWrap>
            <LabelInputPopupWrap>
              <DoneButton
                onClick={() => {
                  this.editAdmin();
                }}
              >
                Done {isLoading ? <Spinner /> : null}
              </DoneButton>
            </LabelInputPopupWrap>
          </PopupWrap>
        </div>
      );
    }
    return null;
  };

  sendNoticationAgain = notificationId => {
    console.log("notificationId", notificationId);
    const buffer = createBuffer({notification_type_id: notificationId});
    const signature = generateSHA2(buffer, password);
    const { get }= this.props;
    axios
      .post(
        `${apiURL}/notification/updateAdminNotifications`,
        {
          json_string: JSON.stringify({
            notification_type_id: notificationId,
          }),
        },
        {headers: {signature, email, channel: "web"}},
      )
      .then(response => {
        if (response.status === 200) {
          get();
          toast.info("Notification sent successfully!!");
        }
      })
      .catch(error => {
        get();
        toast.info("Something went wrong!!");
      });
  };

  deleteNotification = notificationId => {
    console.log("deleteNotification", notificationId);
    const buffer = createBuffer({notification_type_id: notificationId});
    const signature = generateSHA2(buffer, password);
    const { get }= this.props;
    axios
      .post(
        `${apiURL}/notification/deleteAdminNotifications`,
        {
          json_string: JSON.stringify({
            notification_type_id: notificationId,
          }),
        },
        {headers: {signature, email, channel: "web"}},
      )
      .then(response => {
        if (response.status === 200) {
          get();
          toast.info("Notification deleted successfully!!");
        }
      })
      .catch(error => {
        get();
        toast.info("Something went wrong!!");
      });
  };

  editAdmin = () => {
    // const {
    //   adminName,
    //   selectedRadioButtonValue,
    //   adminList,
    //   selectedDetails,
    //   isLoading,
    //   selectedPopupDetails,
    // } = this.state;

    // this.setState({isLoading: true});

    // const buffer = createBuffer({
    //   email,
    //   id: adminList[selectedDetails].id,
    //   name: adminList[selectedDetails].name,
    //   type_of_user: adminList[selectedDetails].type_of_user,
    // });
    // const signature = generateSHA2(buffer, password);

    // axios
    //   .post(
    //     `${apiURL}/user/UpdateAdmin`,
    //     {
    //       json_string: JSON.stringify({
    //         email,
    //         id: adminList[selectedDetails].id,
    //         name: adminList[selectedDetails].name,
    //         type_of_user: adminList[selectedDetails].type_of_user,
    //       }),
    //     },
    //     {headers: {signature, email, channel: "web"}},
    //   )

    //   .then(response => {
    //     if (response.status === 200 && response.data) {
    //       this.getNotificationDetails();
    //       toast.info("Record had been updated!!");
    //       this.closePopup();
    //       this.setState({isLoading: false});
    //     }
    //   })
    //   .catch(error => {
    //     alert(error.response.data.content);
    //   });
  };

  openPopup = e => {
    const {index} = e.target.dataset;
    this.setState({open: true, selectedDetails: index});
  };

  closePopup() {
    this.setState({
      open: false,
    });
  }

  renderEditable = info => {
    const {adminList} = this.state;
    return (
      <div
        contentEditable
        suppressContentEditableWarning
        onBlur={e => {
          const data = adminList;
          data[info.index][info.column.id] = e.target.value;
          this.setState({adminList: data});
        }}
      />
    );
  };

  render() {
    const {data, checked, open, adminList, loading} = this.state;
    const {list} = this.props;
    console.log("notificationList", list);
    const columns = [
      {
        id: "notification_message",
        Header: "Message",
        accessor: d => d.message,
        style: {color: "#4D4F5C"},
      },
      {
        id: "last_updated_time",
        Header: "Updated At",
        accessor: d => moment(d.last_updated_time).format("DD-MM-YYYY hh:mm a"),
        style: {color: "#4D4F5C", textAlign: "center"},
      },
      {
        Header: "Manage",
        Cell: props => {
          console.log("props.original", props.original);
          return (
            <EditRemoveButtonWrap>
              <EditButton onClick={() => this.sendNoticationAgain(props.original.notification_type_id)}>Send Again</EditButton>
              <RemoveButton
                data-index={props.index}
                onClick={() => this.deleteNotification(props.original.notification_type_id)}>
                Remove
              </RemoveButton>
            </EditRemoveButtonWrap>
          );
        },
        // width: 150,
        style: {textAlign: "center"},
        filterable: false,
      },
    ];
    return (
      <FullTableWrap>
        <SmartToaster store={toast} lightBackground position="top_center" />
        <ReactTable
          noDataText={loading ? "" : "No Rows Found"}
          loading={loading}
          loadingText="Loading..."
          filterable
          columns={columns}
          data={list}
          defaultPageSize={10}
          showPagination={true}
          ariaHideApp={false}
        />
        <Modal
          isOpen={open}
          onRequestClose={this.closePopup}
          style={customStyles}
          className={{
            afterOpen: {
              "z-index": 1
            }
          }}
          ariaHideApp={false}
        >
          <div>
            <div>{this.modalrender()}</div>
          </div>
        </Modal>
      </FullTableWrap>
    );
  }
}

export default NotificationReactTable;
