// import React from "react";
// import styled from "styled-components";

// // import {Button} from "./components/common.js";

// const FullSection = styled.div``;

// const Transactions = () => (
//   <FullSection>
//     <h1>Hello from Transaction Component</h1>
//     {/* <div>{this.props.children}</div> */}
//   </FullSection>
// );
// export default Transactions;

import React from "react";
import styled from "styled-components";

import NavigationBar from "../NavigationBar/NavigationBar";
import SideBar from "../NavigationBar/SideBar";

import WithdrawalReactTable from "./WithdrawalReactTable";

import UserWalletReactTable from "./UserWalletReactTable";
import ChatTransaction from "./ChatTransactionReactTable";
import RefundReactTable from "./RefundReactTable";
import UserOverviewReactTable from "./UserOverviewReactTable"
import NotAccessiblePNG from "../Common/NotAccessiblePNG.png";

const FullSection = styled.section`
  background-color: #fafbfc;
  display: grid;
  grid-template-rows: 1fr auto;
  max-height: 100%;

  /* grid-template-columns: auto auto; */
`;
const LabelInputWrap = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 4.5em;
  margin-left: 4.5em;
  margin-top: 0.5em;
`;

const ChatListLabel = styled.h3`
  font-size: 17px;
  color: #1a173b;
`;

const TransactionFullWrap = styled.div`
  width: 1300px;
  /* height: 100%; */
`;

const TransactionWrap = styled.div`
  margin-top: 0.5em;
  margin-left: 4.5em;
  background-color: #fff;
`;

const TabButtonWrap = styled.div`
  margin-left: 2.5em;
  width: 400px;
  display: flex;
  justify-content: space-between;
`;

const TabButton = styled.button`
  height: 30px;
  outline: none;
  border: none;
  font-size: 15px;
  color: ${props => (props.isActive ? "#e54666" : "")};
  border-bottom: ${props => (props.isActive ? "2px solid #e54666" : "")};
  cursor: pointer;
  /* font-weight: bold; */
`;

const TitleDiv = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
`;
const FilterImage = styled.img``;

const Title = styled.label`
  color: #1a173b;
  font-size: 20px;
  padding-right: 10px;
`;

const NotAccessibleDiv = styled.div`
  /* margin-left: 100px; */
  /* margin-top: 100px; */
  padding-top: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  height: 100%;
  width: 100%;
`;

const NotAccessibleImg = styled.img``;

const NotAccessibleLabel = styled.h2``;

class Transactions extends React.Component {
  state = {
    isUserWalletClicked: true,
    isRefundClicked: false,
    isChatTransactionClicked: false,
    isWithdrawalClicked: false,
    isUserOverviewClicked: false,
    isWithdrawalButtonActive: false,
    isUserWalletButtonActive: true,
    isRefundButtonActive: false,
    isChatTransactionButtonActive: false,
    isUserOverviewButtonActive: false,
    typeofuser: ["superadmin", "superreader", "financialmanager"],
    isAuthourized: false,
  };

  componentDidMount() {
    this.userData();
  }

  onUserWalletClicked = () => {
    this.setState({
      isUserWalletClicked: true,
      isRefundClicked: false,
      isChatTransactionClicked: false,
      isWithdrawalClicked: false,
      isUserOverviewClicked: false,
      isWithdrawalButtonActive: false,
      isUserWalletButtonActive: true,
      isRefundButtonActive: false,
      isChatTransactionButtonActive: false,
      isUserOverviewButtonActive: false
    });
  };

  onRefundClicked = () => {
    this.setState({
      isUserWalletClicked: false,
      isRefundClicked: true,
      isChatTransactionClicked: false,
      isWithdrawalClicked: false,
      isUserOverviewClicked: false,
      isWithdrawalButtonActive: false,
      isUserWalletButtonActive: false,
      isRefundButtonActive: true,
      isChatTransactionButtonActive: false,
      isUserOverviewButtonActive: false,
    });
  };

  onChatTransactionClicked = () => {
    this.setState({
      isUserWalletClicked: false,
      isRefundClicked: false,
      isChatTransactionClicked: true,
      isWithdrawalClicked: false,
      isUserOverviewClicked: false,
      isWithdrawalButtonActive: false,
      isUserWalletButtonActive: false,
      isRefundButtonActive: false,
      isChatTransactionButtonActive: true,
      isUserOverviewButtonActive: false,
    });
  };

  onWithdrawalClicked = () => {
    this.setState({
      isUserWalletClicked: false,
      isRefundClicked: false,
      isChatTransactionClicked: false,
      isWithdrawalClicked: true,
      isUserOverviewClicked: false,
      isWithdrawalButtonActive: true,
      isUserWalletButtonActive: false,
      isRefundButtonActive: false,
      isChatTransactionButtonActive: false,
      isUserOverviewButtonActive: false,
    });
  };

  onUserOverviewClicked = () => {
    this.setState({
      isUserWalletClicked: false,
      isRefundClicked: false,
      isChatTransactionClicked: false,
      isWithdrawalClicked: false,
      isUserOverviewClicked: true,
      isWithdrawalButtonActive: false,
      isUserWalletButtonActive: false,
      isRefundButtonActive: false,
      isChatTransactionButtonActive: false,
      isUserOverviewButtonActive: true,
    });
  };

  userData = () => {
    let {isAuthourized, typeofuser} = this.state;
    const role = sessionStorage.getItem("typeofuser");
    isAuthourized = typeofuser.includes(role);
    this.setState({isAuthourized});
  };

  render() {
    const {
      isChatTransactionClicked,
      isRefundClicked,
      isUserWalletClicked,
      isWithdrawalClicked,
      isUserOverviewClicked,
      isWithdrawalButtonActive,
      isUserWalletButtonActive,
      isRefundButtonActive,
      isChatTransactionButtonActive,
      isUserOverviewButtonActive,
      isAuthourized,
    } = this.state;
    const role = sessionStorage.getItem("typeofuser");
    return (
      <FullSection>
        <NavigationBar />
        <SideBar />
        {isAuthourized ? (
          <TransactionFullWrap>
            <LabelInputWrap>
              <ChatListLabel>Transactions</ChatListLabel>
            </LabelInputWrap>

            <TransactionWrap>
              <TitleDiv>
                {/* <Title>May 2019</Title>
                <FilterImage src={filterimg} /> */}
              </TitleDiv>
              <TabButtonWrap>
                <TabButton
                  onClick={this.onUserWalletClicked}
                  isActive={isUserWalletButtonActive}
                >
                  User Wallet
                </TabButton>
                <TabButton
                  onClick={this.onChatTransactionClicked}
                  isActive={isChatTransactionButtonActive}
                >
                  Chat
                </TabButton>
                <TabButton
                  onClick={this.onRefundClicked}
                  isActive={isRefundButtonActive}
                >
                  Refund
                </TabButton>
                <TabButton
                  onClick={this.onWithdrawalClicked}
                  isActive={isWithdrawalButtonActive}
                >
                  Withdrawal
                </TabButton>
                <TabButton
                  onClick={this.onUserOverviewClicked}
                  isActive={isUserOverviewButtonActive}
                >
                  Overview
                </TabButton>
              </TabButtonWrap>
              {isUserWalletClicked ? (
                <UserWalletReactTable />
              ) : isChatTransactionClicked ? (
                <ChatTransaction />
              ) : isRefundClicked ? (
                <RefundReactTable />
              ) : isWithdrawalClicked ? (
                <WithdrawalReactTable />
              ) : isUserOverviewClicked ? (
                <UserOverviewReactTable />
              ) : null}
              {/* <UserWalletReactTable /> */}
              {/* <ChatTransaction /> */}
            </TransactionWrap>
          </TransactionFullWrap>
        ) : (
          <NotAccessibleDiv>
            <NotAccessibleLabel>
              Sorry you’re a{" "}
              {role === "contentmanager"
                ? "Content Manager"
                : role === "approvalmanager"
                ? "Approval Manager"
                : null}
              . Please contact the Super Admin to access this area.
            </NotAccessibleLabel>
            <NotAccessibleImg alt="Not accessible" src={NotAccessiblePNG} />
          </NotAccessibleDiv>
        )}
      </FullSection>
    );
  }
}

export default Transactions;
