import React from "react";
import styled from "styled-components";
import axios from "axios";
import {SmartToaster, toast} from "react-smart-toaster";

// import DatePicker from "react-date-picker/dist/entry.nostyle";
import {Form, Field, FieldArray, Formik} from "formik";
import "../Common/datetimepicker.css";

import approved from "../Common/approvedbtn.png";
import notapproved from "../Common/notapprovedbtn.png";
import Allapprove from "../Common/Allapprove.svg";

// api URL
import {apiURL, createBuffer, generateSHA2} from "../../utils/util";

const FullWrap = styled.div`
  width: 950px;
  /* border: 1px solid black; */
  display: flex;
  flex-wrap: wrap;
  margin-top: 2em;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.23);
  background-color: #ffffff;
  margin-left: 1em;
  border-radius: 6px;
`;
const TwoFieldWrap = styled.div`
  display: flex;
  max-width: 700px;
`;
const ThreeFieldWrap = styled.div`
  display: flex;
`;

const LableInput = styled.div`
  margin: 20px;
  width: ${props => (props.full ? 640 : 270)}px;
`;

const Label = styled.label`
  font-size: 12px;
  color: #323c47;
`;

const ApproveAllImg = styled.img`
  padding-right: 1em;
  height: 35px;
`;

const Img = styled.img`
  padding-right: 1em;
`;

const FormField = styled(Field)`
  margin-top: 10px;
  position: ${props => (props.absolute ? "absolute" : "")};
  width: 100%;
  // border: solid 1px #d0d0d0;
  border: ${props => (props.active ? "1px solid #d0d0d0" : "none")};
  text-indent: 15px;
  padding: 10px;
  font-size: 17px;
  border-radius: 4px;
  background-color: #ffffff;
  opacity: 0.5;
  ::-webkit-input-placeholder {
    font-family: Ubuntu;
    /* font-size: 20px; */
    font-weight: 300;
    color: #0d3868;
    opacity: 0.5;
    padding: 15px;
    /* text-align: left; */
  }
`;

const ConfirmWrap = styled.div`
  display: flex;
  margin: 20px;
  max-width: 600px;
  justify-content: space-between;
`;
const ConfirmButton = styled.button`
  background-color: #ffffff;
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  font-size: 17px;
  /* width: 150px; */
  width: ${props => (props.notapproved ? 170 : 150)}px;
  margin-right: 20px;
  height: 40px;
  text-decoration: ${props => (props.underline ? "underline" : "")};
  color: ${props => (props.underline ? "#39a1dd" : "")};
  outline: 0;
`;

// const EditButton = styled.button`
//   margin-left: 860px;
//   margin-top: 30px;
//   width: 100px;
//   height: 40px;
//   border-radius: 5px;
//   background-color: #085db0;
//   color: white;
//   font-size: large;
// `;

const EditRemoveButtonWrap = styled.div`
  width: 100px;
  display: flex;
  justify-content: space-between;
  margin-left: 800px;
  margin-top: 20px;
`;

const SaveButton = styled.button`
  border: none;
  color: #39a1dd;
  font-size: 20px;
  outline: 0;
  cursor: pointer;
`;

const EditButton = styled.button`
  border: none;
  color: #d0021b;
  font-size: 20px;
  outline: 0;
  cursor: pointer;
`;

const CommentTextBox = styled.textarea`
  /* width: 450px; */
  width: 100%;
  margin: 20px;
  height: 100px;
  font-size: 15px;
  /* margin-left: 120px; */
`;

const DoneButton = styled.button`
  width: 100px;
  height: 40px;
  border-radius: 5px;
  background-color: #085db0;
  color: white;
  cursor: pointer;
  margin-left: 20px;
`;

const ApprovedMsgDiv = styled.div`
  margin-left: 20px;
`;

const ApprovedMsg = styled.label`
  color: #39a1dd;
`;

const ConfirmFullWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

const email = window.sessionStorage.getItem("email");
const password = window.sessionStorage.getItem("token");

export default class EducationForm extends React.Component {
  state = {
    expertID: sessionStorage.getItem("expertID"),
    startDate: new Date(),
    endDate: new Date(),
    isNotOkClicked: false,
    admin_comment: "",
    isFieldEnable: false,
    admin_comment: "",
    isOkClicked: false,
    notokclicked: false,
    isFieldActive: false,
    isApproveAllClicked: false,
    personal_info: {},
    // currentEditing: -1,
    education: [
      {
        education_id: "",
        data: {
          field_of_study: "",
          fileList: [],
          from_year: "",
          qualification_degree: "",
          school: "",
          to_year: "",
        },
      },
    ],
    // expertApprovalObj: {
    //   personal_info_status: null,
    //   personal_info_comment: null,
    //   experience_status: null,
    //   experience_comment: null,
    //   education_status: null,
    //   education_comment: null,
    //   service_charges_status: null,
    //   service_charges_comment: null,
    //   skills_status: null,
    //   skills_comment: null,
    // },
    // education: [],
  };

  componentDidMount() {
    this.getParticularExpertDetails();
  }

  getParticularExpertDetails = () => {
    const {expertID} = this.state;
    // console.log(expertID);

    const buffer = createBuffer({email, docid: expertID});
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/expert/getParticularExpert`,
        {
          json_string: JSON.stringify({email, docid: expertID}),
        },
        {headers: {signature, email, channel: "web"}},
      )
      // axios
      //   .post(`${apiURL}/expert/getParticularExpert`, {
      //     docid: expertID,
      //   })
      .then(response => {
        // console.log(response);
        if (response.status === 200 && response.data) {
          const {data} = response;
          this.setState({
            education: data.education,
            personal_info: data.personal_info,
            isNotOkClicked: false,
          });
          // console.log(data);
          // if (this.state.education) {
          //   this.setState({apiLoading: false});
          // }
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };

  updateEducationInfo = () => {
    const {education} = this.state;

    const buffer = createBuffer({email, data: education});
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}expert/UpdateEducationalDetails`,
        {
          json_string: JSON.stringify({email, data: education}),
        },
        {headers: {signature, email, channel: "web"}},
      )
      // axios
      //   .post(`${apiURL}/expert/UpdateEducationalDetails`, {
      //     data: education,
      //   })

      .then(response => {
        // console.log(response);

        this.getParticularExpertDetails();
      })
      .catch(error => {
        // console.log(error);
      });
    this.setState({isFieldActive: false});
  };

  handleStartChange = startDate => {
    this.setState({startDate});
  };

  handleEndChange = endDate => {
    this.setState({endDate});
  };

  // handleOnButtonClick = index => {
  //   this.setState({currentEditing: index});
  // };

  notOkClicked = () => {
    this.setState({
      isNotOkClicked: true,
    });
  };

  handleOnButtonClick = () => {
    this.setState({isFieldEnable: true, isFieldActive: true});
  };

  // handleOnChange = e => {
  //   const {education} = this.state;
  //   console.log(education);
  //   const {name, value} = e.target;
  //   const tempObj = {
  //     [name]: value,
  //   };
  //   console.log("Temp OBJ", tempObj);
  //   const educationInfoObj = education;

  //   const copyobj = Object.assign(educationInfoObj, tempObj);
  //   console.log("copy obj education ", copyobj);

  //   this.setState({education: copyobj});
  //   console.log("Updated Data", education);
  //   console.log(education[0].data.field_of_study);
  // };

  handleOnChange = (e, index) => {
    const {education} = this.state;

    const editData = education[index].data;

    // console.log(editData);
    const {value} = e.target;
    const {name} = e.target.dataset;
    const tempObj = {
      [name]: value,
    };

    const updatedObject = Object.assign(editData, tempObj);

    const tempArrayData = {
      data: updatedObject,
    };

    const updatedArrayData = Object.assign(education[index], tempArrayData);

    education[index] = updatedArrayData;

    // console.log("Updated Education", education);

    this.setState({
      education,
    });
  };

  onOkClicked = () => {
    const {
      expertID,
      isOkClicked,
      notokclicked,

      admin_comment,

      // expertApprovalObj,
    } = this.state;
    const {expertApprovalObj} = this.props;
    // console.log("on Ok Clicked");

    this.setState({
      isOkClicked: true,
    });

    // console.log("after", isOkClicked);

    const admincomment = admin_comment;
    // console.log("admin comment1");

    let status = expertApprovalObj.education_status;

    status = isOkClicked ? "accepted" : notokclicked ? "rejected" : null;
    // console.log("Status", status);

    expertApprovalObj.education_status = status;

    // console.log("Expert Approval Obj", expertApprovalObj);

    if (status === "accepted" || status === "rejected") {
      axios
        .post(`${apiURL}/expert/ExpertApproval`, {
          expert_id: expertID,
          expert_approve: expertApprovalObj,
        })

        .then(response => {
          // console.log(response);
        });
      // console.log("approval details", expertID);
    }
  };

  onApproveAllClicked = () => {
    this.setState({
      isApproveAllClicked: true,
    });
  };

  handleExpertApproval = () => {
    this.setState({
      isOkClicked: true,
    });

    // this.setState({});

    setTimeout(() => {
      const {
        expertID,
        isOkClicked,
        isNotOkClicked,
        admin_comment,
        isApproveAllClicked,
      } = this.state;
      const {expertApprovalObj} = this.props;

      let expertStatus = expertApprovalObj.education_status;

      // console.log("ok clicked", isOkClicked);

      // expertStatus = isOkCkicked
      //   ? "accepted"
      //   : isNotOkClicked
      //   ? "rejected"
      //   : null;

      expertApprovalObj.education_comment = admin_comment;

      if (isOkClicked) {
        if (
          expertStatus === "submitted" ||
          expertStatus === "rejected" ||
          expertStatus === null
        ) {
          expertStatus = "accepted";
        }

        // console.log("Is Ok Clicked called", expertStatus);
      } else {
        // console.log("Is Ok Clicked called", isOkClicked);
      }

      if (isNotOkClicked) {
        expertStatus = "rejected";
        // console.log("Is Not Ok Clicked called", expertStatus);
      }

      expertApprovalObj.education_status = expertStatus;

      if (isApproveAllClicked) {
        expertStatus = "accepted";
        expertApprovalObj.personal_info_status = expertStatus;
        expertApprovalObj.experience_status = expertStatus;
        expertApprovalObj.education_status = expertStatus;
        expertApprovalObj.service_charges_status = expertStatus;
        expertApprovalObj.skills_status = expertStatus;
        expertApprovalObj.personal_info_comment = null;
        expertApprovalObj.experience_comment = null;
        expertApprovalObj.education_comment = null;
        expertApprovalObj.service_charges_comment = null;
        expertApprovalObj.skills_comment = null;
      }

      const buffer = createBuffer({
        email,
        expert_id: expertID,
        expert_approve: expertApprovalObj,
      });
      const signature = generateSHA2(buffer, password);

      axios
        .post(
          `${apiURL}/expert/ExpertApproval`,
          {
            json_string: JSON.stringify({
              email,
              expert_id: expertID,
              expert_approve: expertApprovalObj,
            }),
          },
          {headers: {signature, email, channel: "web"}},
        )

        // // console.log("After Api Call", expertApprovalObj);
        // axios
        //   .post(`${apiURL}/expert/ExpertApproval`, {
        //     expert_id: expertID,
        //     expert_approve: expertApprovalObj,
        //   })

        .then(response => {
          this.getParticularExpertDetails();
          // toast.info("Education Information Approved!");
          // console.log(response);
        });
    }, 0);
    // console.log("After Api Call", expertApprovalObj);
  };

  onNotOkClicked = () => {
    this.setState({
      notokclicked: true,
    });
  };

  handleOnAdminChange = e => {
    this.setState({admin_comment: e.target.value});
  };

  expertApproval = async () => {
    const {
      expertID,
      isOkClicked,
      notokclicked,
      isNotOkClicked,
      admin_comment,
      // expertApprovalObj,
    } = this.state;
    const {expertApprovalObj} = this.props;
    const admincomment = admin_comment;
    // console.log("admin comment1");

    let status = expertApprovalObj.education_status;

    status = isOkClicked ? "accepted" : notokclicked ? "rejected" : null;
    // console.log("Status", status);

    expertApprovalObj.education_status = status;

    // console.log("Expert Approval Obj", expertApprovalObj);

    if (status === "accepted" || status === "rejected") {
      const buffer = createBuffer({
        email,
        expert_id: expertID,
        expert_approve: expertApprovalObj,
      });
      const signature = generateSHA2(buffer, password);

      await axios
        .post(
          `${apiURL}/expert/ExpertApproval`,
          {
            json_string: JSON.stringify({
              email,
              expert_id: expertID,
              expert_approve: expertApprovalObj,
            }),
          },
          {headers: {signature, email, channel: "web"}},
        )

        // await axios
        //   .post(`${apiURL}/expert/ExpertApproval`, {
        //     expert_id: expertID,
        //     expert_approve: expertApprovalObj,
        //   })

        .then(response => {
          // console.log(response);
        });
      // console.log("approval details", expertID);
    }
  };

  // handleOnChange = (e, education_id, index) => {
  //   // get the intial state
  //   const {education} = this.state;
  //   // get particular education details from index
  //   const particularEducationDetails = education[index];
  //   console.log("Education from state", particularEducationDetails);

  //   // get the value from changed input
  //   const {value} = e.target;

  //   // get the name of the field
  //   const {name} = e.target.dataset;
  //   console.log("Data Name", name);
  //   console.log("Name and Value", name, value);

  //   // create the temporary object from updated data
  //   const tempObj = {
  //     [name]: value,
  //   };
  //   console.log("Updated data", tempObj);

  //   // update the particularEducationDetails data
  //   Object.assign(particularEducationDetails.data, tempObj);

  //   console.log("Updated Edu Details", particularEducationDetails);

  //   // update the intial array
  //   education[index] = particularEducationDetails;

  //   console.log("Updated Education Details", education);
  //   this.setState({
  //     education,
  //   });
  // };

  render() {
    const {
      education,
      personal_info,
      currentEditing,
      isNotOkClicked,
      isFieldEnable,
      isFieldActive,
      startDate,
      endDate,
    } = this.state;
    const {expertApprovalObj} = this.props;
    return (
      <div>
        <SmartToaster store={toast} lightBackground position="top_center" />
        <Formik
          enableReinitialize
          initialValues={{educationDetails: education}}
          // initialValues={{
          //   educationDetails: [
          //     {
          //       education_id: "D2a3tBIf7nwPWI0L0Vep",
          //       data: {
          //         field_of_study: "Lorem Ipsum",
          //         fileList: [],
          //         from_year: "",
          //         qualification_degree:
          //           "MBA - Master of Business Administration",
          //         school: "Alif International School",
          //         to_year: "",
          //       },
          //     },

          //     {
          //       education_id: "zk2AyvtGopFKHhR5yfZ4",
          //       data: {
          //         field_of_study: "Lorem Ipsum",
          //         fileList: [],
          //         from_year: "",
          //         qualification_degree:
          //           "MBA - Master of Business Administration",
          //         school: "International Indian School",
          //         to_year: "",
          //       },
          //     },
          //   ],
          // }}
          render={({values}) => (
            <Form>
              <EditRemoveButtonWrap>
                <EditButton
                  type="button"
                  onClick={this.handleOnButtonClick}

                  // onClick={(e, education_id, index) => {
                  //   this.handleOnChange(e, education_id, index);
                  // }}

                  // onClick={index => {
                  //   this.handleOnButtonClick(index);
                  // }}
                >
                  Edit
                </EditButton>
                <SaveButton
                  onClick={() => {
                    this.updateEducationInfo();
                    toast.info("Education Updated!");
                  }}
                >
                  Save
                </SaveButton>
              </EditRemoveButtonWrap>
              <FieldArray
                // rerenderOnEveryChange
                name="educationDetails"
                render={arrayHelpers => (
                  <React.Fragment>
                    {values.educationDetails.map((eduDetail, index) => (
                      <FullWrap key={eduDetail.education_id}>
                        {/* {console.log(values)} */}

                        {/* <EditButton
                          type="button"
                          // onClick={(e, education_id) => {
                          //   this.handleOnButtonClick(e, education_id, index);
                          // }}

                          onClick={() => {
                            this.handleOnButtonClick(index);
                          }}
                        >
                          Edit
                        </EditButton> */}

                        <TwoFieldWrap>
                          <LableInput>
                            <Label>QUALIFICATION DEGREE</Label>
                            <FormField
                              type="text"
                              placeholder="Write here..."
                              // value={eduDetail.data.qualification_degree}
                              // name="educationDetails.data.qualification_degree"
                              value={education[index].data.qualification_degree}
                              name={`educationDetails[${index}].data.qualification_degree`}
                              data-name="qualification_degree"
                              data-index={index}
                              active={isFieldEnable ? isFieldActive : null}
                              // disabled={index !== currentEditing}
                              // onChange={
                              //   index === currentEditing
                              //     ? (e, education_id) =>
                              //         this.handleOnChange(
                              //           e,
                              //           education_id,
                              //           index,
                              //         )
                              //     : null
                              // }

                              onChange={
                                isFieldEnable
                                  ? e => {
                                      this.handleOnChange(e, index);
                                    }
                                  : null
                              }
                            />
                          </LableInput>

                          <LableInput>
                            <Label>FIELD OF STUDY</Label>
                            <FormField
                              type="text"
                              placeholder="Write here..."
                              // value={education.data.field_of_study}
                              // name="educationDetails.data.field_of_study"
                              active={isFieldEnable ? isFieldActive : null}
                              value={education[index].data.field_of_study}
                              name={`educationDetails[${index}].data.field_of_study`}
                              data-name="field_of_study"
                              onChange={
                                isFieldEnable
                                  ? e => {
                                      this.handleOnChange(e, index);
                                    }
                                  : null
                              }
                              // disabled={index !== currentEditing}
                              // onChange={
                              //   index === currentEditing
                              //     ? (e, education_id) =>
                              //         this.handleOnChange(
                              //           e,
                              //           education_id,
                              //           index,
                              //         )
                              //     : null
                              // }
                            />
                          </LableInput>
                        </TwoFieldWrap>

                        <ThreeFieldWrap>
                          <LableInput>
                            <Label>SCHOOL</Label>
                            <FormField
                              type="text"
                              placeholder="Write here..."
                              // value={education.data.school}
                              // name="educationDetails.data.school"
                              value={education[index].data.school}
                              name={`educationDetails[${index}].data.school`}
                              data-name="school"
                              active={isFieldEnable ? isFieldActive : null}
                              // disabled={index !== currentEditing}
                              // onChange={
                              //   index === currentEditing
                              //     ? (e, education_id) => {
                              //         this.handleOnChange(
                              //           e,
                              //           education_id,
                              //           index,
                              //         );
                              //       }
                              //     : null
                              // }

                              onChange={
                                isFieldEnable
                                  ? e => {
                                      this.handleOnChange(e, index);
                                    }
                                  : null
                              }
                            />
                          </LableInput>

                          <LableInput>
                            <Label>DURATION(FROM)</Label>
                            <FormField
                              type="text"
                              // value={education.data.from_year}
                              // name="educationDetails.data.from_year"
                              active={isFieldEnable ? isFieldActive : null}
                              value={education[index].data.from_year}
                              name={`educationDetails[${index}].data.from_year`}
                              data-name="from_year"
                              // disabled={index !== currentEditing}
                              onChange={
                                isFieldEnable
                                  ? e => {
                                      this.handleOnChange(e, index);
                                    }
                                  : null
                              }
                              // onChange={
                              //   index === currentEditing
                              //     ? (e, education_id) => {
                              //         this.handleOnChange(
                              //           e,
                              //           education_id,
                              //           index,
                              //         );
                              //       }
                              //     : null
                              // }
                            />
                          </LableInput>

                          <LableInput>
                            <Label>DURATION(TO)</Label>
                            <FormField
                              type="text"
                              // value={education.data.year}
                              // name="educationDetails.data.to_year"
                              active={isFieldEnable ? isFieldActive : null}
                              value={education[index].data.to_year}
                              name={`educationDetails[${index}].data.to_year`}
                              data-name="from_to_year"
                              s
                              // onChange={
                              //   index === currentEditing
                              //     ? (e, education_id) => {
                              //         this.handleOnChange(
                              //           e,
                              //           education_id,
                              //           index,
                              //         );
                              //       }
                              //     : null
                              // }

                              onChange={
                                isFieldEnable
                                  ? e => {
                                      this.handleOnChange(e, index);
                                    }
                                  : null
                              }
                            />
                          </LableInput>
                        </ThreeFieldWrap>

                        {expertApprovalObj.education_status === "submitted" ? (
                          <LableInput>
                            <Label>Status</Label>
                            <FormField
                              type="text"
                              placeholder="Write here..."
                              value="Expert needs to be approved.."
                            />
                          </LableInput>
                        ) : expertApprovalObj.education_status ===
                          "rejected" ? (
                          <LableInput>
                            <Label>Status</Label>
                            <FormField
                              type="text"
                              placeholder="Write here..."
                              value="Expert is not approved"
                            />
                          </LableInput>
                        ) : null}

                        {personal_info.education_comment ? (
                          <LableInput full>
                            <Label>Not Approved Reason</Label>
                            <FormField
                              placeholder="Write here..."
                              component="textarea"
                              name="not_approved_reason"
                              // active={isFieldEnable ? isFieldActive : null}
                              value={personal_info.education_comment}
                              // onChange={
                              //   isFieldEnable
                              //     ? e => {
                              //         this.handleOnChange(e);
                              //       }
                              //     : null
                              // }
                            />
                          </LableInput>
                        ) : null}
                      </FullWrap>
                    ))}
                  </React.Fragment>
                )}
              />
            </Form>
          )}
        />

        <ConfirmFullWrap>
          {expertApprovalObj.education_status === "accepted" ? (
            <ApprovedMsgDiv>
              <ApprovedMsg>Education Info has been approved</ApprovedMsg>
            </ApprovedMsgDiv>
          ) : expertApprovalObj.education_status === "submitted" ? (
            <ApprovedMsgDiv />
          ) : null}
          <ConfirmWrap>
            <ConfirmButton
              type="button"
              disabled={expertApprovalObj.education_status === "accepted"}
              onClick={this.handleExpertApproval}
            >
              <Img src={approved} />
              Approved
            </ConfirmButton>
            <ConfirmButton
              red
              notapproved
              type="button"
              onClick={this.notOkClicked}
            >
              <Img src={notapproved} />
              Not Approved
            </ConfirmButton>
            <ConfirmButton
              red
              notapproved
              type="button"
              disabled={
                expertApprovalObj.personal_info_status === "accepted" &&
                expertApprovalObj.experience_status === "accepted" &&
                expertApprovalObj.education_status === "accepted" &&
                expertApprovalObj.service_charges_status === "accepted" &&
                expertApprovalObj.skills_status === "accepted"
              }
              onClick={() => {
                this.onApproveAllClicked();
                this.handleExpertApproval();
              }}
            >
              <ApproveAllImg src={Allapprove} />
              Approve All
            </ConfirmButton>
          </ConfirmWrap>
        </ConfirmFullWrap>
        {isNotOkClicked ? (
          // <ConfirmButton underline>
          //   +Add Comment
          // </ConfirmButton>
          <div>
            <CommentTextBox
              type="text"
              onChange={e => this.handleOnAdminChange(e)}
              placeholder="Add Comments Here.."
            />
            <DoneButton
              type="button"
              onClick={() => {
                this.handleExpertApproval();
                this.getParticularExpertDetails();
              }}
            >
              Done
            </DoneButton>
          </div>
        ) : null}
      </div>
    );
  }
}
