import React from "react";
import {Bar} from "react-chartjs-2";
import styled from "styled-components";

const ChartDataWrap = styled.div`
  /* height: 50px;*/

  width: 300px;
  padding-left: 10px;
  margin-bottom: 20px;
  margin-right: 20px;
`;

const options = {
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        // display: false,
        ticks: {
          fontSize: 9,
          // display: false,
        },
        barPercentage: 0.5,
        gridLines: {
          // color: "white",
          display: false,
          // drawBorder: false,
        },
      },
    ],
    xAxes: [
      {
        // display: false,
        ticks: {
          fontSize: 9,
        },
        barPercentage: 0.4,
        gridLines: {
          // color: "white",
          display: false,
          // drawBorder: false,
        },
      },
    ],
  },
};

// const UserBarChart = () => (
//   <ChartDataWrap>
//     <Bar data={data} options={options} />
//   </ChartDataWrap>
// );

class UserBarChart extends React.Component {
  state = {
    id: "",
  };

  render() {
    const {userBarchartDet} = this.props;
    // console.log("Expert bar chart info", userBarchartDet);

    const Bardata = {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          // labels: ["20", "40", " 60", 80, 100, 120, 140, 160, 180, 200],
          backgroundColor: "#004D9E",
          hoverBackgroundColor: "#004D9E",
          hoverBorderColor: "#004D9E",
          data: [
            userBarchartDet.January ? userBarchartDet.January : 0,
            userBarchartDet.February ? userBarchartDet.February : 0,
            userBarchartDet.March ? userBarchartDet.March : 0,
            userBarchartDet.April ? userBarchartDet.April : 0,
            userBarchartDet.May ? userBarchartDet.May : 0,
            userBarchartDet.June ? userBarchartDet.June : 0,
            userBarchartDet.July ? userBarchartDet.July : 0,
            userBarchartDet.August ? userBarchartDet.August : 0,
            userBarchartDet.September ? userBarchartDet.September : 0,
            userBarchartDet.October ? userBarchartDet.October : 0,
            userBarchartDet.November ? userBarchartDet.November : 0,
            userBarchartDet.December ? userBarchartDet.December : 0,
          ],
        },
      ],
    };

    return (
      <ChartDataWrap>
        <Bar data={Bardata} options={options} />
      </ChartDataWrap>
    );
  }
}

export default UserBarChart;
