import React from "react";
import styled from "styled-components";
import axios from "axios";
import Modal from "react-modal";

import NavigationBar from "../NavigationBar/NavigationBar";
import SideBar from "../NavigationBar/SideBar";

import Ecards from "../Experts/Ecards";
import closebtn from "../AboutShawer/closebtn.png";
import NotAccessiblePNG from "../Common/NotAccessiblePNG.png";

// api URL
import {apiURL, createBuffer, generateSHA2} from "../../utils/util";
import UserReactTable from "./UserReactTable";

const FullSection = styled.section`
  background-color: #fafbfc;
  display: grid;
  grid-template-rows: 1fr auto;
  max-height: 100%;

  /* grid-template-columns: auto auto; */
`;
const LabelInputWrap = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 4.5em;
  margin-left: 4.5em;
  margin-top: 0.5em;
  margin-bottom: 1.5em;
`;

const TitleLabel = styled.label`
  font-size: 15px;
  padding-right: 10px;
`;

const FullCardWrap = styled.div`
  display: flex;
  margin-left: 70px;
  justify-content: space-between;
  max-width: 500px;
`;

const CardContentWrap = styled.div`
  padding-top: 20px;
  padding-left: 20px;
  padding: 10px 20px 70px 10px;
`;
const LableWrap = styled.div`
  padding-top: ${props => (props.padding ? "10" : "")}px;
  /* padding: 10px 20px 50px 10px; */
`;
const NumberLabel = styled.label`
  /* padding-left: 10px; */
  padding-right: 5px;
  font-size: 20px;
  color: #4da1ff;
`;

const ExpertListLabel = styled.h3`
  font-size: 17px;
  color: #1a173b;
`;
const SearchInput = styled.input`
  padding: 10px;
  width: 18%;
  border-radius: 5px;
  background-color: #e8f0f6;
  text-align: center;
  border: 0;
  ::placeholder {
    color: #004389;
  }
`;

const ActiveExpertsList = styled.li`
  list-style: none;
  color: #417505;
`;

const PageOneContentWrap = styled.div``;

// add user Popup CSS

const customStyles = {
  content: {
    height: "80%",
    width: "38%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    // border: "0px",
  },
};

const PopupWrap = styled.div`
  margin-left: 60px;
`;

const PopupTitle = styled.h3`
  margin-bottom: 30px;
  margin-top: 0px;
`;

const LabelInputPopupWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60px;
  margin-bottom: 25px;
`;

const InputDOB = styled.input`
  border-radius: 4px 0px 0px 4px;
  border: solid 1px #e9eff4;
  width: 300px;
  height: 40px;
`;

const InputDOBImgWrap = styled.div`
  display: flex;
`;

const CalendarInput = styled.div`
  border-radius: 0px 4px 4px 0px;
  border: solid 1px #e9eff4;
  width: 50px;
  height: 40px;
  border-left: none;
`;

const Label = styled.label`
  font-size: 10px;
`;

const Input = styled.input`
  border-radius: 4px;
  border: solid 1px #e9eff4;
  width: 350px;
  height: 40px;
`;

const DoneButton = styled.button`
  width: 100px;
  color: #fff;
  height: 30px;
  border-radius: 5px;
  background-color: #085db0;
`;

const CloseButton = styled.button`
  outline: 0;
  border: none;

  margin-left: 400px;
  margin-top: -10px;
`;

const CloseButtonImg = styled.img`
  height: 20px;
  width: 20px;
`;

const NotAccessibleDiv = styled.div`
  /* margin-left: 100px; */
  /* margin-top: 100px; */
  padding-top: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  height: 100%;
  width: 100%;
`;

const NotAccessibleImg = styled.img``;

const NotAccessibleLabel = styled.h2``;

const email = window.sessionStorage.getItem("email");
const password = window.sessionStorage.getItem("token");

class Users extends React.Component {
  state = {
    isOptionClicked: false,
    open: false,
    newDate: new Date(),
    userList: [],
    typeofuser: ["superadmin", "superreader"],
    isAuthourized: false,
    loading: true,
    userAnalytics: {
      user: [],
    },
  };

  openPopup = this.openPopup.bind(this);

  closePopup = this.closePopup.bind(this);

  // lifecycle methods

  componentDidMount() {
    this.userData();
    this.geUserAnalyticsData();
    this.getUserDetails();
  }

  // api calls
  getUserDetails = () => {
    const buffer = createBuffer({email});
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/user/getUserList`,
        {
          json_string: JSON.stringify({email}),
        },
        {headers: {signature, email, channel: "web"}},
      )

      // axios
      //   .post(`${apiURL}/user/getUserList`, {})
      .then(response => {
        // console.log(response);
        if (response.status === 200 && response.data) {
          // console.log(response.data);
          const {data} = response;
          // console.log(data);
          this.setState({userList: data, loading: false});
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };

  geUserAnalyticsData = () => {
    const buffer = createBuffer({email});
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/user/UserAnalyticsData`,
        {
          json_string: JSON.stringify({email}),
        },
        {headers: {signature, email, channel: "web"}},
      )

      // axios
      //   .post(`${apiURL}/user/UserAnalyticsData`, {})

      .then(response => {
        // console.log(response);

        if (response.status === 200 && response.data) {
          // console.log(response.data);
          const {data} = response;
          // console.log("analytics", data);
          this.setState({userAnalytics: data.content, loading: false});
          // console.log("userssss", data.content);
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };

  // event handling methods

  handleDateChange = newDate => {
    this.setState({newDate});
  };

  userData = () => {
    let {isAuthourized, typeofuser} = this.state;
    const role = sessionStorage.getItem("typeofuser");
    isAuthourized = typeofuser.includes(role);
    this.setState({isAuthourized});
  };

  openPopup() {
    this.setState({open: true});
  }

  closePopup() {
    this.setState({open: false});
  }

  render() {
    // const {userList} = this.props;
    const {
      open,

      userList,
      isAuthourized,
      loading,
      userAnalytics,
    } = this.state;
    const role = sessionStorage.getItem("typeofuser");
    return (
      <FullSection>
        <NavigationBar />
        <SideBar />
        {isAuthourized ? (
          <div>
            <PageOneContentWrap>
              <LabelInputWrap>
                <ExpertListLabel>Users Data Management</ExpertListLabel>
                {/* <AddUser onClick={this.openPopup}>Add User</AddUser> */}

                <Modal
                  isOpen={open}
                  onRequestClose={this.closePopup}
                  style={customStyles}
                >
                  <PopupWrap>
                    <CloseButton more onClick={this.closePopup}>
                      <CloseButtonImg src={closebtn} />
                    </CloseButton>
                    <PopupTitle>Register a new User</PopupTitle>
                    <LabelInputPopupWrap>
                      <Label>FIRST NAME</Label>
                      <Input placeholder="Write here" />
                    </LabelInputPopupWrap>

                    <LabelInputPopupWrap>
                      <Label>LAST NAME</Label>
                      <Input placeholder="Write here" />
                    </LabelInputPopupWrap>

                    <LabelInputPopupWrap>
                      <Label>EMAIL ADDRESS</Label>
                      <Input placeholder="Write here" />
                    </LabelInputPopupWrap>

                    <LabelInputPopupWrap>
                      <Label>PASSWORD</Label>
                      <Input placeholder="Write here" />
                    </LabelInputPopupWrap>

                    <LabelInputPopupWrap>
                      <Label>CONFIRM PASSWORD</Label>
                      <Input placeholder="Write here" />
                    </LabelInputPopupWrap>

                    <LabelInputPopupWrap>
                      <Label>DATE OF BIRTH</Label>
                      <InputDOBImgWrap>
                        <InputDOB placeholder="Choose from the calendar..">
                          {/* <DatePicker
                      name="dob"
                      // onChange={this.handleDateChange}
                      value={newDate}
                    /> */}
                        </InputDOB>

                        <CalendarInput>{/* <CalendarImg /> */}</CalendarInput>
                      </InputDOBImgWrap>
                    </LabelInputPopupWrap>

                    <DoneButton>Done</DoneButton>
                  </PopupWrap>
                </Modal>

                {/* <SearchInput placeholder="Search for an Expert" /> */}
              </LabelInputWrap>
              <FullCardWrap>
                <Ecards>
                  <CardContentWrap>
                    <TitleLabel>
                      <LableWrap>
                        <NumberLabel>
                          {userAnalytics.total_user_registered}
                        </NumberLabel>
                      </LableWrap>
                      Users registered this Month
                    </TitleLabel>
                  </CardContentWrap>
                </Ecards>
                <Ecards>
                  <CardContentWrap>
                    <TitleLabel>Hightest number of </TitleLabel>
                    <LableWrap>
                      <TitleLabel>Completed chats by Users</TitleLabel>
                    </LableWrap>
                    <LableWrap padding>
                      {userAnalytics.user.map((item, i) => {
                        return (
                          <ActiveExpertsList key={i}>{item}</ActiveExpertsList>
                        );
                      })}
                    </LableWrap>
                  </CardContentWrap>
                </Ecards>
                <Ecards />
              </FullCardWrap>
              <UserReactTable userList={userList} loading={loading} />
            </PageOneContentWrap>
          </div>
        ) : (
          <NotAccessibleDiv>
            <NotAccessibleLabel>
              Sorry you’re a{" "}
              {role === "contentmanager"
                ? "Content Manager"
                : role === "financialmanager"
                ? "Financial Manager"
                : role === "approvalmanager"
                ? "Approval Manager"
                : null}
              . Please contact the Super Admin to access this area.
            </NotAccessibleLabel>
            <NotAccessibleImg alt="Not accessible" src={NotAccessiblePNG} />
          </NotAccessibleDiv>
        )}

        {/* <PageOneContentWrap>
          <LabelInputWrap>
            <ExpertListLabel>User Data Management</ExpertListLabel>
            <SearchInput placeholder="Search for an User" />
          </LabelInputWrap>
          <UserTable />
        </PageOneContentWrap> */}
      </FullSection>
    );
  }
}

export default Users;
