import React from "react";
import styled from "styled-components";
import axios from "axios";
import Modal from "react-modal";
import shortid from "shortid";
import {withFormik, Form, Field, FieldArray, Formik} from "formik";
import closebtn from "./closebtn.png";

// api URL
import {apiURL, generateSHA2, createBuffer, Spinner} from "../../utils/util";

const FullWrap = styled.div`
  width: 950px;
  /* border: 1px solid black; */
  /* display: flex;
  flex-wrap: wrap; */
  margin-top: 2em;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.23);
  background-color: #ffffff;
  margin-left: 1em;
  border-radius: 6px;
`;
const RatingTitle = styled.h2`
  font-size: 18px;
  margin-left: 20px;
  padding-top: 20px;
`;

const TabOneButton = styled.button`
  height: 30px;
  font-size: 15px;
  border: none;
  display: flex;
  outline: 0;
  font-weight: ${props => (props.isActive ? "700" : "")};
  border-bottom: ${props => (props.isActive ? "2px solid #e54666" : "")};
  align-items: center;
`;

const StarRatingWrap = styled.div`
  margin: 40px 20px 20px 20px;
`;

const QualificationDetailsDiv = styled.div``;

const SearchEditWrap = styled.div`
  width: 550px;
  border: 1px solid #e9eff4;
  border-radius: 4px;
  margin-top: 20px;
  border-bottom: none;
`;
const SerachInput = styled.input`
  width: 100%;
  height: 30px;
  padding: 20px;
  border: none;
  border-bottom: 1px solid #e9eff4;
  background-color: #e8f0f6;
  ::-webkit-input-placeholder {
    font-size: 14px;
    color: #004389;
  }
`;
const SearchWrap = styled.div``;

const Wrap = styled.div`
  padding: 20px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid #e9eff4;
`;

const SearchInputLabel = styled.label`
  font-size: 14px;
`;

const EditRemoveButtonWrap = styled.div`
  width: 100px;
  display: flex;
  justify-content: space-between;
  margin-left: 550px;
`;

const EditButton = styled.button`
  border: none;
  color: #39a1dd;
  font-size: 17px;
  cursor: pointer;
  outline: 0;
`;

const RemoveButton = styled.button`
  border: none;
  color: #d0021b;
  font-size: 17px;
  cursor: pointer;
  outline: 0;
`;

// const FormField = styled(Field)`
//   position: ${props => (props.absolute ? "absolute" : "")};
//   width: 100%;

//   border: none;
//   text-indent: 15px;
//   padding: 10px 10px 10px 5px;
//   font-size: 17px;
//   border-radius: 4px;
//   background-color: #ffffff;
//   opacity: 0.5;
//   outline: none;
//   ::-webkit-input-placeholder {
//     font-family: Ubuntu;
//     /* font-size: 20px; */
//     font-weight: 300;
//     color: #0d3868;
//     opacity: 0.5;
//     padding: 15px;
//     /* text-align: left; */
//   }
// `;

const FormField = styled(Field)`
  width: 600px;
  /* width: ${props => (props.large ? "750" : "350")}px; */
  height: 40px;
  border-radius: 4px;
  border: ${props =>
    props.isActive ? "solid 1px dimgrey" : "solid 1px #e9eff4"};
  /* border: solid 1px #e9eff4;
  border-bottom: solid 1px #e9eff4; */
  margin-top: 15px;
  font-size:15px;
`;

const FormFieldAdd = styled.input`
  width: 600px;
  /* width: ${props => (props.large ? "750" : "350")}px; */
  height: 40px;
  border-radius: 4px;
  border: ${props =>
    props.isActive ? "solid 1px dimgrey" : "solid 1px #e9eff4"};
  /* border: solid 1px #e9eff4;
  border-bottom: solid 1px #e9eff4; */
  margin-top: 15px;
  font-size:15px;
`;

const FormFieldTextarea = styled(Field)`
  width: 600px;
  /* width: ${props => (props.large ? "750" : "350")}px; */
  height: 100px;
  border-radius: 4px;
  /* border: solid 1px #e9eff4;
  border-bottom: solid 1px #e9eff4; */
  border: ${props =>
    props.isActive ? "solid 1px dimgrey" : "solid 1px #e9eff4"};
  margin-top: 15px;
  font-size:15px;
`;

const FormFieldTextareaAdd = styled.textarea`
  width: 600px;
  /* width: ${props => (props.large ? "750" : "350")}px; */
  height: 100px;
  border-radius: 4px;
  /* border: solid 1px #e9eff4;
  border-bottom: solid 1px #e9eff4; */
  border: ${props =>
    props.isActive ? "solid 1px dimgrey" : "solid 1px #e9eff4"};
  margin-top: 15px;
  font-size:15px;
`;

const InputWrap = styled.div`
  margin-top: 20px;
  width: 700px;
  display: flex;
  flex-direction: column;
`;

const AddCategoryButton = styled.button`
  height: 30px;
  width: 100px;
  border-radius: 5px;
  background-color: #39a1dd;
  color: #fff;
  font-size: 13px;
  margin-left: 600px;
  outline: 0;
  cursor: pointer;
`;

const AddPrivacyPoilicyButton = styled.button`
  height: 30px;
  width: 100px;
  border-radius: 5px;
  background-color: #39a1dd;
  color: #fff;
  font-size: 13px;
  margin-top: 30px;
  outline: 0;
  cursor: pointer;
`;

const CloseButton = styled.button`
  outline: 0;
  border: none;
  cursor: pointer;
  margin-left: 650px;
  margin-top: -10px;
  outline: 0;
`;

const CloseButtonImg = styled.img`
  height: 30px;
  width: 30px;
`;

const customStyles = {
  content: {
    height: "60%",
    width: "50%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const Label = styled.label``;

const email = window.sessionStorage.getItem("email");
const password = window.sessionStorage.getItem("token");

class TermsOfUse extends React.Component {
  state = {
    // terms_Of_Use: [{id: ""}],
    terms_Of_Use: [],
    isFieldEnable: false,
    currentEditing: -1,
    isFieldActive: false,
    open: false,
    titleE: "",
    descE: "",
    titleA: "",
    descA: "",
    isLoading: false,
    updatedTermsOfUseObj: {},
  };

  // lifecycle methods

  componentDidMount() {
    this.getTermsOfUseDetails();
  }

  onChangeTitleEValue = e => {
    this.setState({
      titleE: e.target.value,
    });
  };

  onChangeDescEValue = e => {
    this.setState({
      descE: e.target.value,
    });
  };

  onChangeTitleAValue = e => {
    this.setState({
      titleA: e.target.value,
    });
  };

  onChangeDescAValue = e => {
    this.setState({
      descA: e.target.value,
    });
  };

  onClickAdd = () => {
    const {titleE, descE, titleA, descA, terms_Of_Use} = this.state;
    // const titleEng = titleE;
    // const descEng = descE;
    // const titleAr = titleA;
    // const descAr = descA;
    const newObj = {
      arabic_description: descA,
      arabic_title: titleA,
      description: descE,
      title: titleE,
    };
    // console.log("before", terms_Of_Use);
    const newArray = terms_Of_Use.slice(); // Create a copy
    newArray.push(newObj); // Push the object
    this.setState({terms_Of_Use: newArray});
    // console.log("after", terms_Of_Use);
  };

  addTermsOfUseInfo = index => {
    const {titleE, descE, titleA, descA, isLoading} = this.state;

    const tempObj = {
      arabic_description: descA,
      arabic_title: titleA,
      description: descE,
      title: titleE,
    };

    this.setState({isLoading: true});

    // console.table("tempObj", tempObj);

    // axios
    //   .post(`${apiURL}/aboutshawer/AddTermOfUse`, {
    //     term_of_use: {
    //       arabic_description: descA,
    //       arabic_title: titleA,
    //       description: descE,
    //       title: titleE,
    //     },
    //   })

    const buffer = createBuffer({
      email,
      term_of_use: {
        arabic_description: descA,
        arabic_title: titleA,
        description: descE,
        title: titleE,
      },
    });
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/aboutshawer/AddTermOfUse`,
        {
          json_string: JSON.stringify({
            email,
            term_of_use: {
              arabic_description: descA,
              arabic_title: titleA,
              description: descE,
              title: titleE,
            },
          }),
        },
        {headers: {signature, email, channel: "web"}},
      )

      .then(response => {
        if (response.status === 200 && response.data) {
          this.getTermsOfUseDetails();
          this.closePopup();
          this.setState({isLoading: false});
        }
      })

      .catch(error => {
        alert(error.response.data.content);
        this.setState({isLoading: false});
      });

    // console.log("added", privacyPolicyObj);
  };

  handleOnChange = (e, index) => {
    const {terms_Of_Use, updatedTermsOfUseObj} = this.state;

    // console.log("terms of useeeeee", terms_Of_Use);

    const editData = terms_Of_Use[index];

    // console.log("edit data", editData);

    const {value} = e.target;
    const {name} = e.target.dataset;
    const tempObj = {
      [name]: value,
    };


    const updatedObject = Object.assign(editData, tempObj);



    this.setState({
      updatedTermsOfUseObj: updatedObject,
    });

    // console.log("Updated terms of use", terms_Of_Use);
  };

  // handleOnChange = (e, index) => {
  //   const {terms_Of_Use} = this.state;

  //   const editData = terms_Of_Use[index];

  //   console.log("edit data", editData);

  //   const {value} = e.target;
  //   const {name} = e.target.dataset;

  //   console.log("name and value", name, value);

  //   terms_Of_Use[index] = value;
  //   // arrayHelpers.replace(index, qualification[index]);
  //   // console.log("Updated qualification", arrayHelpers);
  //   console.log("Updated qualification....", terms_Of_Use);

  //   // arrayHelpers.replace(index, qualification[index])
  //   this.setState({
  //     terms_Of_Use,
  //   });
  //   console.log("Updated qualification....!!!!!!", terms_Of_Use);
  // };

  // updateQualificationInfo = index => {
  //   const {terms_Of_Use} = this.state;
  //   console.log("Updated details", terms_Of_Use);
  //   axios
  //     .post(`${apiURL}/aboutshawer/UpdateExpertProfile`, {
  //       terms_Of_Use,
  //     })

  //     .then(response => {
  //       console.log(response);
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // };

  handleOnButtonClick = index => {
    this.setState({currentEditing: index, isFieldActive: true});
  };

  // api calls

  getTermsOfUseDetails = () => {
    const {terms_Of_Use} = this.state;

    const buffer = createBuffer({email});
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/aboutshawer/TermsandCondition`,
        {
          json_string: JSON.stringify({email}),
        },
        {headers: {signature, email, channel: "web"}},
      )

      .then(response => {
        if (response.status === 200 && response.data) {
          const {data} = response;

          const termsofuse = data.content;

          this.setState({
            terms_Of_Use: termsofuse,
          });
        }
      })
      .catch(error => {
        //  console.log(error);
      });
    // console.log("terms_Of_Use", terms_Of_Use);
  };

  updateTermsOfUseInfo = index => {
    const {terms_Of_Use, updatedTermsOfUseObj} = this.state;

    console.log("Updated details", terms_Of_Use);
    // axios
    //   .post(`${apiURL}/aboutshawer/UpdateTermOfUse`, {
    //     term_of_use: terms_Of_Use,
    //   })

    const buffer = createBuffer({email, term_of_use: updatedTermsOfUseObj});
    const signature = generateSHA2(buffer, password);
    console.log("updatedTermsOfUseObj-->", updatedTermsOfUseObj, signature);
    axios
      .post(
        `${apiURL}/aboutshawer/UpdateTermOfUse`,
        {
          json_string: JSON.stringify({
            email,
            term_of_use: updatedTermsOfUseObj,
          }),
        },
        {headers: {signature, email, channel: "web"}},
      )

      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });
    this.setState({currentEditing: -1});
    // console.log("updated....", terms_Of_Use);
  };

  openPopup = () => {
    this.setState({open: true});
  };

  closePopup = () => {
    this.setState({open: false});
  };

  render() {
    const {
      isFieldActive,
      currentEditing,
      terms_Of_Use,
      id,
      open,
      isLoading,
    } = this.state;

    return (
      <QualificationDetailsDiv>
        {/* ---------Formik----------- */}
        <FullWrap>
          <RatingTitle>Terms Of Use</RatingTitle>
          <AddCategoryButton onClick={this.openPopup}>Add</AddCategoryButton>
          <Formik
            enableReinitialize
            initialValues={{termsofuseDetails: terms_Of_Use}}
            render={({values}) => (
              <Form>
                {/* {console.log("Formil Val", values)}
                {console.log("TOU", terms_Of_Use)} */}
                <FieldArray
                  name="fieldOfStudyDetails"
                  render={({arrayHelpers, remove}) => (
                    <React.Fragment>
                      {values.termsofuseDetails.map((touDetail, index) => (
                        <StarRatingWrap key={id}>
                          <div>
                            {/* <AddCategoryButton
                              type="button"
                              data-index={index}
                              onClick={() => {
                                this.handleOnButtonClick(index);
                              }}
                            >
                              Edit
                            </AddCategoryButton>

                            <AddCategoryButton
                              type="button"
                              data-index={index}
                              onClick={() => {
                                this.updateTermsOfUseInfo(index);
                              }}
                            >
                              Save
                            </AddCategoryButton> */}
                            <EditRemoveButtonWrap>
                              <EditButton
                                type="button"
                                data-index={index}
                                onClick={() => {
                                  this.handleOnButtonClick(index);
                                }}
                              >
                                Edit
                              </EditButton>
                              <RemoveButton
                                type="button"
                                data-index={index}
                                onClick={() => {
                                  this.updateTermsOfUseInfo(index);
                                }}
                              >
                                Save
                              </RemoveButton>
                            </EditRemoveButtonWrap>
                          </div>
                          <InputWrap>
                            <Label>Title - English</Label>
                            <FormField
                              type="text"
                              placeholder="Write here..."
                              // value={index.title}
                              isActive={
                                index === currentEditing ? isFieldActive : ""
                              }
                              name={`termsofuseDetails[${index}].title`}
                              data-name="title"
                              data-index={index}
                              disabled={index !== currentEditing}
                              onChange={
                                index === currentEditing
                                  ? e => this.handleOnChange(e, index)
                                  : null
                              }
                            />
                          </InputWrap>
                          <InputWrap>
                            <Label>Description - English</Label>
                            <FormFieldTextarea
                              type="text"
                              placeholder="Write here..."
                              // value={index.title}
                              isActive={
                                index === currentEditing ? isFieldActive : ""
                              }
                              name={`termsofuseDetails[${index}].description`}
                              data-name="description"
                              data-index={index}
                              component="textarea"
                              disabled={index !== currentEditing}
                              onChange={
                                index === currentEditing
                                  ? e => this.handleOnChange(e, index)
                                  : null
                              }
                            />
                          </InputWrap>

                          <InputWrap>
                            <Label>Title - Arabic</Label>
                            <FormField
                              type="text"
                              placeholder="Write here..."
                              // value={index.title}
                              isActive={
                                index === currentEditing ? isFieldActive : ""
                              }
                              name={`termsofuseDetails[${index}].arabic_title`}
                              data-name="arabic_title"
                              data-index={index}
                              disabled={index !== currentEditing}
                              onChange={
                                index === currentEditing
                                  ? e => this.handleOnChange(e, index)
                                  : null
                              }
                            />
                          </InputWrap>
                          <InputWrap>
                            <Label>Description - Arabic</Label>
                            <FormFieldTextarea
                              type="text"
                              placeholder="Write here..."
                              // value={index.title}
                              isActive={
                                index === currentEditing ? isFieldActive : ""
                              }
                              name={`termsofuseDetails[${index}].arabic_description`}
                              data-name="arabic_description"
                              data-index={index}
                              component="textarea"
                              disabled={index !== currentEditing}
                              onChange={
                                index === currentEditing
                                  ? e => this.handleOnChange(e, index)
                                  : null
                              }
                            />
                          </InputWrap>
                        </StarRatingWrap>
                      ))}
                    </React.Fragment>
                  )}
                />
              </Form>
            )}
          />

          <Modal
            isOpen={open}
            onRequestClose={this.closePopup}
            style={customStyles}
            ariaHideApp={false}
          >
            <div>
              <CloseButton onClick={this.closePopup}>
                <CloseButtonImg src={closebtn} />
              </CloseButton>
            </div>
            <h1>Add Privacy Policy</h1>
            <InputWrap>
              <Label>Title - English</Label>
              <FormFieldAdd
                onChange={this.onChangeTitleEValue}
                type="text"
                placeholder="Write here..."
              />
            </InputWrap>
            <InputWrap>
              <Label>Description - English</Label>
              <FormFieldTextareaAdd
                onChange={this.onChangeDescEValue}
                type="text"
                placeholder="Write here..."
              />
            </InputWrap>
            <InputWrap>
              <Label>Title - Arabic</Label>
              <FormFieldAdd
                onChange={this.onChangeTitleAValue}
                type="text"
                placeholder="Write here..."
              />
            </InputWrap>
            <InputWrap>
              <Label>Description - Arabic</Label>
              <FormFieldTextareaAdd
                onChange={this.onChangeDescAValue}
                type="text"
                placeholder="Write here..."
                // value={index.title}
              />
            </InputWrap>
            <AddPrivacyPoilicyButton onClick={this.addTermsOfUseInfo}>
              Add {isLoading ? <Spinner /> : null}
            </AddPrivacyPoilicyButton>
          </Modal>
        </FullWrap>
      </QualificationDetailsDiv>
    );
  }
}

export default TermsOfUse;
