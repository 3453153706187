import firebase from "firebase/app";

// import "firebase/auth";
require("firebase/auth");
require("firebase/firestore");
// import * as firebase from "firebase";

// const app = firebase.initializeApp({
//   apiKey: process.env.REACT_APP_FIREBASE_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
//   databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,

//   // appId: "1:858567025860:web:041b1ea14a57eae9",
// });

// const app = firebase.initializeApp({
//   apiKey: "AIzaSyDIzEVQN4JSGFAYt--LmEuBQMJom6NN4dE",
//   authDomain: "shawer-dev.firebaseapp.com",
//   databaseURL: "https://shawer-dev.firebaseio.com",
//   projectId: "shawer-dev",
//   storageBucket: "shawer-dev.appspot.com",
//   messagingSenderId: "858567025860",
//   appId: "1:858567025860:web:041b1ea14a57eae9",
// });

const app = firebase.initializeApp({
  apiKey: "AIzaSyAw2THeMryEfmPtqp68E_R9lCMZyfB-3YE",
  authDomain: "shawer-91c74.firebaseapp.com",
  databaseURL: "https://shawer-91c74.firebaseio.com",
  projectId: "shawer-91c74",
  storageBucket: "shawer-91c74.appspot.com",
  messagingSenderId: "449739507389",
  appId: "1:449739507389:web:a2932a21fcbc413f",
});

export default app;

// <!-- The core Firebase JS SDK is always required and must be listed first -->
// <script src="https://www.gstatic.com/firebasejs/6.0.2/firebase-app.js"></script>

// <!-- TODO: Add SDKs for Firebase products that you want to use
//     https://firebase.google.com/docs/web/setup#config-web-app -->

// <script>
//  // Your web app's Firebase configuration
//  var firebaseConfig = {
//    apiKey: "AIzaSyDIzEVQN4JSGFAYt--LmEuBQMJom6NN4dE",
//    authDomain: "shawer-dev.firebaseapp.com",
//    databaseURL: "https://shawer-dev.firebaseio.com",
//    projectId: "shawer-dev",
//    storageBucket: "shawer-dev.appspot.com",
//    messagingSenderId: "858567025860",
//    appId: "1:858567025860:web:041b1ea14a57eae9"
//  };
//  // Initialize Firebase
//  firebase.initializeApp(firebaseConfig);
// </script>
