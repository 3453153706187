import React from "react";
import {Pie} from "react-chartjs-2";
import styled from "styled-components";
import axios from "axios";
// api URL
import {apiURL} from "../../utils/util";

const PieChartWrap = styled.div`
  margin-top: 20px;
  width: 550px;
  height: 300px;
`;

const data = {
  labels: ["Online Chat", "Offline Chat", "Scheduled Chat"],
  datasets: [
    {
      // labels: ["20", "40", " 60", 80, 100, 120, 140, 160, 180, 200],
      // backgroundColor: "#004D9E",
      backgroundColor: ["#01274D", "#013C7A", "#0E65B5"],
      hoverBackgroundColor: ["#01274D", "#013C7A", "#0E65B5"],
      // hoverBackgroundColor: "#004D9E",
      hoverBorderColor: "#004D9E",
      data: [53, 70, 25],
    },
  ],
};

const options = {
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        // display: false,
        ticks: {
          fontSize: 9,
          // display: false,
        },
        barPercentage: 0.5,
        gridLines: {
          // color: "white",
          display: false,
          // drawBorder: false,
        },
      },
    ],
    xAxes: [
      {
        // display: false,
        ticks: {
          fontSize: 9,
        },
        barPercentage: 0.4,
        gridLines: {
          // color: "white",
          display: false,
          // drawBorder: false,
        },
      },
    ],
  },
};

class PieChart extends React.Component {
  state = {
    id: "",
    // typeofchats: {},
  };

  // componentDidMount() {
  //   this.getPieChartDetails();
  // }

  // shouldComponentUpdate() {
  //   return true;
  // }

  // getPieChartDetails = () => {
  //   axios
  //     .post(`${apiURL}/overview/NoOfTypeOfChats`, {})

  //     .then(response => {
  //       console.log(response);

  //       if (response.status === 200 && response.data) {
  //         console.log(" pie chart Data", response.data);
  //         const {data} = response;
  //         console.log(" pie chart Data.....", data.content);

  //         this.setState({typeofchats: data.content});
  //       }
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // };

  render() {
    // const {typeofchats} = this.state;
    const {typeofchats} = this.props;
    // console.log("chatssss", typeofchats);
    const Piedata = {
      labels: ["Online Chat", "Offline Chat", "Schedule Chat"],

      datasets: [
        {
          data: [
            typeofchats.online_chat,
            typeofchats.offline_chat,
            typeofchats.schedule_chat,
          ],
          backgroundColor: ["#01274D", "#013C7A", "#0E65B5"],
          hoverBackgroundColor: ["#01274D", "#013C7A", "#0E65B5"],
        },
      ],
    };

    // console.log(Piedata);

    return (
      <PieChartWrap>
        <Pie data={Piedata} redraw />
      </PieChartWrap>
    );
  }
}

// const PieChart = () => (
//   <PieChartWrap>
//     <Pie data={data} />
//   </PieChartWrap>
// );
export default PieChart;
