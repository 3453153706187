import React from "react";
import styled from "styled-components";

import ReactTable from "react-table";
import "react-table/react-table.css";
import { searchInTable, sortByDate } from "../../utils/util";

const FullTableWrap = styled.div`
  margin-left: 4em;
  margin-right: 4em;
  margin-top: 2em;
  max-height: 100%;

  background-color: #ffffff;
`;

const TableHeaderDiv = styled.div`
  /* text-align: left; */
  text-align: ${props => (props.center ? "center" : "left")}; 
  /* padding-top: ${props => (props.padding ? "" : "8")}px; */
  /* padding-top: 8px; */
`;

const EmailButton = styled.a`
  width: 150px;
  height: 30px;
  background-color: #fafbfc;
  /* background-color: #0052a9; */
  color: #c0c5d2;
  font-size: 15px;
  border-radius: 4px;
  border: solid 0.5px #c0c5d2;
  cursor: pointer;
  outline: 0;
  padding: 5px;
  text-decoration: none;
`;

const FilterInput = styled.input`
  width: 250px;
  ::-webkit-input-placeholder {
    font-size: 10px;
  }
`;

class UserReactTable extends React.Component {
  state = {
    checked: false,
    // data: makeData(),
  };

  render() {
    const {userList, loading} = this.props;

    const columns = [
      {
        Header: () => (
          <TableHeaderDiv>
            <b>Name</b>
          </TableHeaderDiv>
        ),

        id: "name",
        style: {color: "#4D4F5C"},
        accessor: d => `${d.name}`,
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search Name.."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        filterMethod: (filter, row) => searchInTable(filter, row),
      },
      {
        Header: () => (
          <TableHeaderDiv>
            <b>Email</b>
          </TableHeaderDiv>
        ),

        id: "email",

        accessor: d => d.email,
        style: {color: "#4D4F5C"},

        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search Email.."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        filterMethod: (filter, row) => searchInTable(filter, row),
      },
      {
        Header: () => (
          <TableHeaderDiv>
            <b>Date of Birth</b>
          </TableHeaderDiv>
        ),

        id: "dob",

        accessor: d => d.dob,
        style: {color: "#4D4F5C"},

        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search Date of Birth.."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        sortMethod: (a, b) => sortByDate(a, b),
        filterMethod: (filter, row) => searchInTable(filter, row),
      },

      {
        Header: () => (
          <TableHeaderDiv center>
            <b>Support</b>
          </TableHeaderDiv>
        ),
        // Header: "Support",
        Cell: props => {
          return (
            <EmailButton href="mailto:noreply@shawer.sa">Email</EmailButton>
          );
        },
        style: {textAlign: "center", color: "#4D4F5C"},
        filterable: false,
      },
    ];
    return (
      <FullTableWrap>
        <ReactTable
          noDataText={loading ? "" : "No Rows Found"}
          loading={loading}
          loadingText="Loading..."
          filterable
          columns={columns}
          data={userList}
          defaultPageSize={10}
        />
      </FullTableWrap>
    );
  }
}

export default UserReactTable;
