import React from "react";
import styled from "styled-components";
import axios from "axios";
import shortid from "shortid";
import {withFormik, Form, Field, FieldArray, Formik} from "formik";
import QualificationDetails from "./QualificationDetails";

// api URL
import {apiURL} from "../../utils/util";
import FieldOfStudyDetails from "./FieldOfStudyDetails";
import SchoolDetails from "./SchoolDetails";
import CompanyDetails from "./CompanyDetails";
import SkillsDetails from "./SkillsDetails";

const FullWrap = styled.div`
  width: 950px;
  /* border: 1px solid black; */
  /* display: flex;
  flex-wrap: wrap; */
  margin-top: 2em;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.23);
  background-color: #ffffff;
  margin-left: 1em;
  border-radius: 6px;
`;
const RatingTitle = styled.h2`
  font-size: 17px;
  margin-left: 20px;
  margin-top: 20px;
`;

const TabOneButton = styled.button`
  height: 30px;
  font-size: 15px;
  border: none;
  display: flex;
  outline: 0;
  font-weight: ${props => (props.isActive ? "700" : "")};
  border-bottom: ${props => (props.isActive ? "2px solid #e54666" : "")};
  align-items: center;
  cursor: pointer;
`;

const TabTwoButton = styled.button`
  height: 30px;
  font-size: 15px;
  border: none;
  display: flex;
  border-bottom: ${props => (props.isActive ? "2px solid #0052a9" : "")};
  align-items: center;
  outline: 0;
  cursor: pointer;
`;

const TabOne = styled.div`
  margin-top: 20px;
  margin-left: 20px;
  width: 550px;
  display: flex;
  justify-content: space-between;
`;

const TabTwo = styled.div`
  margin-top: 20px;

  width: 400px;
  display: flex;
  justify-content: space-between;
`;

const StarRatingWrap = styled.div`
  margin: 40px 20px 20px 20px;
`;

const InputButtonWrap = styled.div`
  margin-top: 20px;
  width: 530px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AddInput = styled.input`
  width: 400px;
  height: 35px;
  border-radius: 4px;
  border: solid 1px #e9eff4;
  border-bottom: solid 1px #e9eff4;
`;

const AddReasonButton = styled.button`
  width: 100px;
  height: 30px;
  border-radius: 2px;
  border: solid 1px rgba(77, 161, 255, 0.7);
  background-color: #39a1dd;
  color: #fff;
`;

const QualificationDetailss = styled.div``;

const SearchEditWrap = styled.div`
  width: 550px;
  border: 1px solid #e9eff4;
  border-radius: 4px;
  margin-top: 20px;
  border-bottom: none;
`;
const SerachInput = styled.input`
  width: 100%;
  height: 30px;
  padding: 20px;
  border: none;
  border-bottom: 1px solid #e9eff4;
  background-color: #e8f0f6;
  ::-webkit-input-placeholder {
    font-size: 14px;
    color: #004389;
  }
`;
const SearchWrap = styled.div``;

const Wrap = styled.div`
  padding: 20px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid #e9eff4;
`;

const SearchInputLabel = styled.label`
  font-size: 14px;
`;

const EditRemoveButtonWrap = styled.div`
  width: 100px;
  display: flex;
  justify-content: space-between;
`;

const EditButton = styled.button`
  border: none;
  color: #39a1dd;
  font-size: 14px;
`;

const RemoveButton = styled.button`
  border: none;
  color: #d0021b;
  font-size: 14px;
`;

const FormField = styled(Field)`
  position: ${props => (props.absolute ? "absolute" : "")};
  width: 100%;
  /* border: solid 1px #d0d0d0; */
  border: none;
  text-indent: 15px;
  padding: 10px 10px 10px 5px;
  font-size: 17px;
  border-radius: 4px;
  background-color: #ffffff;
  opacity: 0.5;
  outline: none;
  ::-webkit-input-placeholder {
    font-family: Ubuntu;
    /* font-size: 20px; */
    font-weight: 300;
    color: #0d3868;
    opacity: 0.5;
    padding: 15px;
    /* text-align: left; */
  }
`;

class ExpertProfileDetailsTab extends React.Component {
  state = {
    isEducationListClicked: true,
    isQualificationDetailsClicked: true,
    isFieldOfStudyDetailsClicked: false,
    isSchoolDetailsclicked: false,
    isEducationButtonActive: true,
    isExperienceButtonActive: false,

    isQualificationButtonActive: true,
    isFieldOfStudyButtonActive: false,
    isSchoolButtonActive: false,
    isExperienceDetailsClicked: false,
    isCompanyDetailsClicked: true,
    isSkillListClicked: false,
    isSkillsButtonActive: false,
    isSkillButtonActive: true,

    getProfileList: [],
    qualification: [],
    fieldOfStudy: [],
    school: [],
  };

  // lifecycle methods

  // componentDidMount() {
  //   this.getCategoryDetails();
  // }

  // education details tab

  onEducationListClicked = () => {
    this.setState({
      isEducationListClicked: true,
      isExperienceDetailsClicked: false,
      isExperienceButtonActive: false,
      isEducationButtonActive: true,
      isSkillsButtonActive: false,
    });
  };

  onQualificationDetailsClicked = () => {
    this.setState({
      isQualificationDetailsClicked: true,
      isQualificationButtonActive: true,
      isFieldOfStudyButtonActive: false,
      isSchoolButtonActive: false,
    });
  };

  onFieldOfStudyDetailsClicked = () => {
    this.setState({
      isQualificationDetailsClicked: false,
      isFieldOfStudyDetailsClicked: true,
      isQualificationButtonActive: false,
      isFieldOfStudyButtonActive: true,
      isSchoolButtonActive: false,
    });
  };

  onSchoolClicked = () => {
    this.setState({
      isQualificationDetailsClicked: false,
      isFieldOfStudyDetailsClicked: false,
      isSchoolDetailsclicked: true,
      isQualificationButtonActive: false,
      isFieldOfStudyButtonActive: false,
      isSchoolButtonActive: true,
    });
  };

  // experience details tab

  onExperienceListClicked = () => {
    this.setState({
      isEducationListClicked: false,
      isExperienceDetailsClicked: true,
      isEducationButtonActive: false,
      isExperienceButtonActive: true,
      isCompanyButtonActive: true,
      isSkillsButtonActive: false,
    });
  };

  onCompanyDetailsClicked = () => {
    this.setState({
      isCompanyDetailsClicked: true,
      isCompanyButtonActive: true,
    });
  };

  // Skills Details Tab

  onSkillsListClicked = () => {
    this.setState({
      isEducationListClicked: false,
      isExperienceDetailsClicked: false,
      isSkillListClicked: true,

      isEducationButtonActive: false,
      isExperienceButtonActive: false,
      isSkillsButtonActive: true,
    });
  };

  onSkillsDetailsClicked = () => {
    this.setState({
      isSkillListClicked: true,
      isSkillButtonActive: true,
    });
  };

  // api calls

  getCategoryDetails = () => {
    axios
      .post(`${apiURL}/aboutshawer/GetExpertProfile`, {})

      .then(response => {
        if (response.status === 200 && response.data) {
          const {data} = response;

          this.setState({
            qualification: data.content.list_qualification_degree,
            fieldOfStudy: data.content.list_fields_of_study,
            school: data.content.list_schools,
          });
        }
      })
      .catch(error => {});
  };

  render() {
    const {
      isEducationListClicked,
      isCompanyDetailsClicked,
      isQualificationDetailsClicked,
      isFieldOfStudyDetailsClicked,
      isSchoolDetailsclicked,
      isEducationButtonActive,
      isQualificationButtonActive,
      isFieldOfStudyButtonActive,
      isSchoolButtonActive,
      isCompanyButtonActive,
      isExperienceButtonActive,
      isExperienceDetailsClicked,
      isSkillsButtonActive,
      isSkillListClicked,
      isSkillButtonActive,
      getProfileList,

      qualification,
      fieldOfStudy,
      school,
    } = this.state;

    return (
      <div>
        {/* {console.log("dataaaa", fieldOfStudy)} */}
        <RatingTitle>Expert Profile Details</RatingTitle>
        <TabOne>
          <TabOneButton
            onClick={this.onEducationListClicked}
            isActive={isEducationButtonActive}
          >
            Education
          </TabOneButton>
          <TabOneButton
            onClick={this.onExperienceListClicked}
            isActive={isExperienceButtonActive}
          >
            Experience
          </TabOneButton>
          <TabOneButton
            onClick={this.onSkillsListClicked}
            isActive={isSkillsButtonActive}
          >
            Skills
          </TabOneButton>
        </TabOne>
        {isEducationListClicked ? (
          <StarRatingWrap>
            <TabTwo>
              <TabTwoButton
                onClick={this.onQualificationDetailsClicked}
                isActive={isQualificationButtonActive}
              >
                Qualification
              </TabTwoButton>
              <TabTwoButton
                onClick={this.onFieldOfStudyDetailsClicked}
                isActive={isFieldOfStudyButtonActive}
              >
                Field of Study
              </TabTwoButton>
              <TabTwoButton
                onClick={this.onSchoolClicked}
                isActive={isSchoolButtonActive}
              >
                School
              </TabTwoButton>
            </TabTwo>

            {isQualificationDetailsClicked ? (
              <QualificationDetails />
            ) : isFieldOfStudyDetailsClicked ? (
              <FieldOfStudyDetails />
            ) : isSchoolDetailsclicked ? (
              <SchoolDetails />
            ) : null}
          </StarRatingWrap>
        ) : isExperienceDetailsClicked ? (
          <StarRatingWrap>
            <TabTwo>
              <TabTwoButton
                onClick={this.onCompanyDetailsClicked}
                isActive={isCompanyButtonActive}
              >
                Company
              </TabTwoButton>
            </TabTwo>
            {/* eslint-disable */}
            {isCompanyDetailsClicked ? <CompanyDetails /> : null}
          </StarRatingWrap>
        ) : isSkillListClicked ? (
          <StarRatingWrap>
            <TabTwo>
              <TabTwoButton
                onClick={this.onSkillsListClicked}
                isActive={isSkillButtonActive}
              >
                Skills
              </TabTwoButton>
            </TabTwo>
            {/* eslint-disable */}
            {isSkillListClicked ? <SkillsDetails /> : null}
          </StarRatingWrap>
        ) : null}
      </div>
    );
  }
}

export default ExpertProfileDetailsTab;
