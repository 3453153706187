import React from "react";
import {Route, Switch, BrowserRouter, Redirect} from "react-router-dom";
import Login from "./components/LoginForm/Login";
// import Dashboard from "./components/Dahboard/Dashboard";
import Dashboard from "./components/Dashboard/Dashboard";
import Transactions from "./components/Transactions/Transactions";
import Chats from "./components/Chats/Chats";
import Coupons from "./components/Coupons/Coupons";
import Experts from "./components/Experts/Experts";
import Users from "./components/Users/Users";
import AboutShawer from "./components/AboutShawer/AboutShawer";
import DisputeMangement from "./components/DisputeManagement/DisputeManagement";
import ExpertReview from "./components/Experts/ExpertReview";
import AdminSettings from "./components/AdminSettings/AdminSettings";
import ReviewManagement from "./components/ReviewManagement/ReviewManagement";
import WithdrawalManagement from "./components/WithdrawalMangement/WithdrawalManagement";
import ForgotPassword from "./components/LoginForm/ForgotPassword";
import {AuthProvider} from "./Auth";
import PrivateRoute from "./PrivateRoute";
// import {PkiValidation} from './components/AboutShawer/Pkivalidation'
import ComingSoon from "./components/ComingSoon/ComingSoon";
import ScheduleChatSettings from "./components/ScheduleChatSettings/ScheduleChatSettings";
import NotificationsPage from "./components/Notification/NotificationsPage";

// const Routes = () => (

// <BrowserRouter>
//   <Switch>
//     <Route exact path="/" render={() => <Redirect to="/login" />} />
//     <Route path="/login" render={props => <Login {...props} />} />
//     <Route path="/dashboard" component={Dashboard} />
//     <Route path="/transactions" component={Transactions} />
//     <Route path="/chats" component={Chats} />
//     <Route path="/experts" name="experts" component={Experts} />
//     {/* <Route path="/experts" render={props => <Experts {...props} />} /> */}
//     <Route path="/users" render={props => <Users {...props} />} />
//     <Route path="/aboutshawer" render={props => <AboutShawer {...props} />} />
//     <Route
//       path="/disputemanagement"
//       render={props => <DisputeMangement {...props} />}
//     />
//     {/* <Route path="/experts" component={Users} /> */}
//     {/*

//     <Route path="/aboutshawer" component={AboutShawer} /> */}
//     function Experts({match}) {}
//   </Switch>
// </BrowserRouter>
// );
// export default Routes;

export default class Routes extends React.Component {
  state = {};

  render() {
    return (
      <AuthProvider>
        <BrowserRouter>
          <Switch>
            {/* <Route exact path="/" component={ComingSoon} /> */}
            <Route exact path="/" render={() => <Redirect to="/login" />} />
            <Route path="/login" component={Login} />
            <Route path="/forgotpassword" component={ForgotPassword} />
            {/* <Route path="/.well-known/pki-validation/godaddy.html" component={PkiValidation} /> */}
            <PrivateRoute path="/dashboard" component={Dashboard} />
            <PrivateRoute path="/transactions" component={Transactions} />
            <PrivateRoute path="/chats" component={Chats} />
            <PrivateRoute path="/coupons" component={Coupons} />
            <PrivateRoute path="/experts" component={Experts} />
            <PrivateRoute path="/review" component={ExpertReview} />
            <PrivateRoute path="/users" component={Users} />
            <PrivateRoute path="/aboutshawer" component={AboutShawer} />
            <PrivateRoute
              path="/disputemanagement"
              component={DisputeMangement}
            />
            <PrivateRoute path="/adminsettings" component={AdminSettings} />
            <PrivateRoute
              path="/reviewmanagement"
              component={ReviewManagement}
            />
            <PrivateRoute
              path="/withdrawalmanagement"
              component={WithdrawalManagement}
            />
            <PrivateRoute
              path="/schedulechatsettings"
              component={ScheduleChatSettings}
            />
            <PrivateRoute path="/notifications" component={NotificationsPage} />
          </Switch>
        </BrowserRouter>
      </AuthProvider>
    );
  }
}
