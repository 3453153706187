import React, {Component} from "react";
import styled from "styled-components";
import {SmartToaster, toast} from "react-smart-toaster";

import axios from "axios";
import Modal from "react-modal";
import ReactTable from "react-table";
import "react-table/react-table.css";

// api URL
import {apiURL, createBuffer, generateSHA2, Spinner} from "../../utils/util";

import closebtn from "../AboutShawer/closebtn.png";

const FullTableWrap = styled.div`
  margin-left: 4.5em;

  margin-top: 0.6em;
  max-height: 100%;
  width: 1000px;
  background-color: #ffffff;
`;

const EditRemoveButtonWrap = styled.div`
  align-items: center;
  /* margin-left: 550px; */
  // width: 150px;
  display: flex;
  justify-content: space-between;
`;

const EditButton = styled.button`
  border: none;
  color: #39a1dd;
  font-size: 15px;
`;

const RemoveButton = styled.button`
  border: none;
  color: #d0021b;
  font-size: 15px;
`;

const customStyles = {
  content: {
    height: "80%",
    width: "38%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    // border: "0px",
  },
};

const PopupWrap = styled.div`
  margin-left: 60px;
  display: flex;
  flex-direction: column;
`;

const PopupTitle = styled.h3`
  margin-bottom: 30px;
  margin-top: 0px;
`;

const LabelInputPopupWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 60px; */
  height: ${props => (props.height ? "280" : "60")}px;
  /* margin-bottom: 25px; */
  margin-bottom: ${props => (props.margin ? "5" : "25")}px;
`;

const Label = styled.label`
  font-size: 10px;
`;

const Input = styled.input`
  color: #323c47;
  border-radius: 4px;
  border: solid 1px #e9eff4;
  width: 350px;
  height: 40px;
`;

const RadioButtonInput = styled.input`
  font-size: 10px;
  margin-right: 15px;
  padding-bottom: 0px;
`;

const RadioButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  margin-top: 15px;
  margin-bottom: 15px;
`;

const AdminRoleLabel = styled.label`
  color: #323c47;
  font-size: 13px;
  /* padding-left: 15px; */
  display: flex;
  align-items: center;
  padding: 20px 0px 20px 0px;
  /* margin-bottom: 10px; */
  /* padding-right: 10px; */
`;

const DoneButton = styled.button`
  width: 100px;
  color: #fff;
  height: 30px;
  border-radius: 5px;
  background-color: #085db0;
  outline: 0;
  cursor: pointer;
`;

const CloseButton = styled.button`
  outline: 0;
  border: none;
  cursor: pointer;
  margin-left: 400px;
  margin-top: -10px;
`;

const CloseButtonImg = styled.img`
  height: 30px;
  width: 30px;
`;

const LabelPassword = styled.label`
  font-size: 10px;
  color: #d0d0d0;
  margin-bottom: 20px;
`;

const email = window.sessionStorage.getItem("email");
const password = window.sessionStorage.getItem("token");

class AdminSettingsReactTable extends React.Component {
  state = {
    selectedDetails: null,
    checked: false,
    open: false,
    adminList: [],
    loading: true,
    isLoading: false,
    superAdminClicked: false,
    contentManagerClicked: false,
    approvalManagerClicked: false,
    financialManagerClicked: false,
    selectedRadioButtonValue: null,
    adminName: "",
    data: [
      {
        adminname: "Omar Khalife",
        email: "OmarKhalife@gmail.com",
        role: "Super Admin",
      },
      {
        adminname: "Nour Ballane",
        email: "Nourballane@gmail.com",
        role: "Super Reader",
      },
    ],
  };

  // onSwitchHandleChange = this.onSwitchHandleChange.bind(this);

  // openPopup = this.openPopup.bind(this);

  closePopup = this.closePopup.bind(this);

  componentDidMount() {
    this.getAdminDetails();
  }

  onSwitchHandleChange(checked) {
    this.setState({checked});
  }

  onOptionChange = e => {
    const {selectedDetails, adminList} = this.state;
    const {value} = e.target;

    adminList[selectedDetails].type_of_user = value;

    // this.setState({
    //   selectedRadioButtonValue: e.target.value,
    // });
  };

  handleOnNameChange = e => {
    const {selectedDetails, adminList} = this.state;

    const {value} = e.target;

    adminList[selectedDetails].name = value;
    this.setState({
      adminName: e.target.value,
    });
  };

  modalrender = () => {
    const {selectedDetails, adminList, isLoading} = this.state;

    if (selectedDetails && adminList.length > 0) {
      const selectedPopupDetails = adminList[selectedDetails];

      return (
        <div>
          <PopupWrap>
            <CloseButton more onClick={this.closePopup}>
              <CloseButtonImg src={closebtn} />
            </CloseButton>
            <PopupTitle>Edit Admin</PopupTitle>
            <LabelInputPopupWrap>
              <Label>FULL NAME</Label>
              <Input
                name="adminName"
                value={selectedPopupDetails.name}
                onChange={e => this.handleOnNameChange(e)}
                // onChange={e => this.setState({name: e.target.value})}
              />
            </LabelInputPopupWrap>

            <LabelInputPopupWrap margin>
              <Label>EMAIL ADDRESS</Label>
              <Input
                value={selectedPopupDetails.email}
                placeholder="Write here"
                disabled
              />
            </LabelInputPopupWrap>

            <LabelPassword>
              Password would be send to this email id
            </LabelPassword>

            <LabelInputPopupWrap height>
              <Label>SELECT ADMIN ROLE</Label>
              <RadioButtonWrap>
                <AdminRoleLabel>
                  <RadioButtonInput
                    name="roleName"
                    value="superadmin"
                    type="radio"
                    defaultChecked={
                      selectedPopupDetails.type_of_user === "superadmin"
                    }
                    onChange={e => this.onOptionChange(e)}
                  />
                  Super Admin
                </AdminRoleLabel>

                <AdminRoleLabel>
                  <RadioButtonInput
                    name="roleName"
                    value="approvemanagement"
                    defaultChecked={
                      selectedPopupDetails.type_of_user === "approvalmanager"
                    }
                    type="radio"
                    onChange={e => this.onOptionChange(e)}
                  />
                  Approval Manager
                </AdminRoleLabel>

                <AdminRoleLabel>
                  <RadioButtonInput
                    name="roleName"
                    value="financialmanagement"
                    defaultChecked={
                      selectedPopupDetails.type_of_user === "financialmanager"
                    }
                    type="radio"
                    onChange={e => this.onOptionChange(e)}
                  />
                  Financial Manager
                </AdminRoleLabel>

                <AdminRoleLabel>
                  <RadioButtonInput
                    name="roleName"
                    value="contentmanagement"
                    type="radio"
                    defaultChecked={
                      selectedPopupDetails.type_of_user === "contentmanagement"
                    }
                    onChange={e => this.onOptionChange(e)}
                  />
                  Content Manager
                </AdminRoleLabel>
              </RadioButtonWrap>
            </LabelInputPopupWrap>

            <LabelInputPopupWrap>
              <DoneButton
                onClick={() => {
                  this.editAdmin();
                }}
              >
                Done {isLoading ? <Spinner /> : null}
              </DoneButton>
            </LabelInputPopupWrap>
          </PopupWrap>
        </div>
      );
    }
    return null;
  };

  // openPopup = index => {
  //   this.setState({open: true, selectedRefundReq: index});
  // };

  // api calls

  getAdminDetails = () => {
    const buffer = createBuffer({email});
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/user/ListOfAdmin`,
        {
          json_string: JSON.stringify({
            email,
          }),
        },
        {headers: {signature, email, channel: "web"}},
      )

      // axios
      //   .post(`${apiURL}/user/ListOfAdmin`, {})
      .then(response => {
        if (response.status === 200 && response.data) {
          const {data} = response;
          this.setState({adminList: data.content, loading: false});
        }
      })
      .catch(error => {});
  };

  deleteAdmin = categoryId => {
    const buffer = createBuffer({email, id: categoryId});
    const signature = generateSHA2(buffer, password);

    axios

      .post(
        `${apiURL}/user/DeleteAdmin`,
        {
          json_string: JSON.stringify({
            email,
            id: categoryId,
          }),
        },
        {headers: {signature, email, channel: "web"}},
      )

      // axios
      //   .post(`${apiURL}/user/ListOfAdmin`, {})
      .then(response => {
        if (response.status === 200 && response.data) {
          this.getAdminDetails();
          toast.info("Record had been updated!!");
        }
      })
      .catch(error => {});
  };

  editAdmin = () => {
    const {
      adminName,
      selectedRadioButtonValue,
      adminList,
      selectedDetails,
      isLoading,
      selectedPopupDetails,
    } = this.state;

    this.setState({isLoading: true});

    const buffer = createBuffer({
      email,
      id: adminList[selectedDetails].id,
      name: adminList[selectedDetails].name,
      type_of_user: adminList[selectedDetails].type_of_user,
    });
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/user/UpdateAdmin`,
        {
          json_string: JSON.stringify({
            email,
            id: adminList[selectedDetails].id,
            name: adminList[selectedDetails].name,
            type_of_user: adminList[selectedDetails].type_of_user,
          }),
        },
        {headers: {signature, email, channel: "web"}},
      )

      .then(response => {
        if (response.status === 200 && response.data) {
          this.getAdminDetails();
          toast.info("Record had been updated!!");
          this.closePopup();
          this.setState({isLoading: false});
        }
      })
      .catch(error => {
        alert(error.response.data.content);
      });
  };

  openPopup = e => {
    const {index} = e.target.dataset;
    this.setState({open: true, selectedDetails: index});
  };

  closePopup() {
    this.setState({
      open: false,
    });
  }

  renderEditable = info => {
    const {adminList} = this.state;
    return (
      <div
        // style={{backgroundColor: "#fafafa"}}
        contentEditable
        suppressContentEditableWarning
        onBlur={e => {
          const data = adminList;
          data[info.index][info.column.id] = e.target.value;
          this.setState({adminList: data});
        }}
      />
    );
  };

  render() {
    const {data, checked, open, adminList, loading} = this.state;

    const columns = [
      {
        id: "adminname",
        Header: "ADMIN NAME",
        // accessor: "adminname",
        accessor: d => d.name,
        // Cell: this.renderEditable,
        style: {color: "#4D4F5C"},
      },
      {
        id: "email",
        Header: "EMAIL",
        // accessor: "email",
        accessor: d => d.email,
        // Cell: this.renderEditable,
        style: {color: "#4D4F5C"},
      },
      {
        id: "typeofuser",
        Header: "ROLE",
        // Cell: this.renderEditable,
        // accessor: "role",
        accessor: d =>
          d.type_of_user === "superadmin"
            ? "Super Admin"
            : d.type_of_user === "superreader"
            ? "Super Reader"
            : d.type_of_user === "contentmanager"
            ? "Content Manger"
            : d.type_of_user === "approvalmanager"
            ? "Approval Manager"
            : d.type_of_user === "financialmanager"
            ? "Financial Manager"
            : "-",
        style: {color: "#4D4F5C"},
      },
      {
        Header: "MANAGE",
        Cell: props => {
          return (
            <EditRemoveButtonWrap>
              <EditButton
                onClick={e => this.openPopup(e)}
                data-index={props.index}
              >
                Edit
              </EditButton>
              <RemoveButton
                data-index={props.index}
                onClick={() => this.deleteAdmin(props.original.id)}
              >
                Remove
              </RemoveButton>
            </EditRemoveButtonWrap>
          );
        },
        width: 150,
        style: {textAlign: "center"},
        filterable: false,
      },
      // {
      //   Cell: props => {
      //     return <RemoveButton>Remove</RemoveButton>;
      //   },
      //   style: {textAlign: "center"},
      //   filterable: false,
      // },
    ];
    return (
      <FullTableWrap>
        <SmartToaster store={toast} lightBackground position="top_center" />
        <ReactTable
          noDataText={loading ? "" : "No Rows Found"}
          loading={loading}
          loadingText="Loading..."
          filterable
          columns={columns}
          data={adminList}
          defaultPageSize={15}
          showPagination={false}
          ariaHideApp={false}
          // showPaginationTop
          // showPaginationBottom={false}
        />

        <Modal
          isOpen={open}
          onRequestClose={this.closePopup}
          style={customStyles}
          ariaHideApp={false}
        >
          <div>
            <div>{this.modalrender()}</div>
          </div>
        </Modal>
      </FullTableWrap>
    );
  }
}

export default AdminSettingsReactTable;
