import React from "react";
import styled from "styled-components";

const CircleBackground = styled.circle`
  fill: none;
  stroke: #ddd;
`;
const CircleProgress = styled.circle`
  fill: none;
  stroke: #004ea0;
  stroke-linecap: round;
  stroke-linejoin: round;
`;
const CircleSVG = styled.svg`
  padding-right: 1em;
`;

class CircularProgressBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {squareSize, strokeWidth, percentage} = this.props;

    // const sqSize = this.props.sqSize;

    const radius = (squareSize - strokeWidth) / 2;
    const viewBox = `0 0 ${squareSize} ${squareSize}`;
    const dashArray = radius * Math.PI * 2;
    const dashOffset = dashArray - (dashArray * percentage) / 100;

    return (
      <CircleSVG width={squareSize} height={squareSize} viewBox={viewBox}>
        <CircleBackground
          cx={squareSize / 2}
          cy={squareSize / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
        />
        <CircleProgress
          cx={squareSize / 2}
          cy={squareSize / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
          // Start progress marker at 12 O'Clock
          transform={`rotate(-90 ${squareSize / 2} ${squareSize / 2})`}
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset,
          }}
        />
      </CircleSVG>
    );
  }
}

export default CircularProgressBar;
// ReactDOM.render( <App />,document.getElementById('app')

// const rootElement = document.getElementById("root");
// ReactDOM.render(<App />, rootElement);
