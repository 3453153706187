import React from "react";
import styled from "styled-components";
// // import {withFormik, Form, Field} from "formik";
// import Yup from "yup";
import axios from "axios";
import img from "./login-img.svg";
import logo from "./shawerlogo.png";
import {Button, CancelButton} from "../Common/Button";
import LoginIcons from "../icons/LoginIcons";
import {apiURL, generateSHA2, createBuffer} from "../../utils/util";

const FullSection = styled.div`
  background: url(${img});
  height: 100vh;
  /* background-size: contain; */
  background-size: cover;
`;

const LoginboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 10em;
`;
const ContentHolder = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  flex-grow: 1;
`;
const ShawerAdminLabel = styled.label`
  font-size: 23px;
  color: #ffffff;
  padding: 35px;
`;

const ShawerAdminLabel2 = styled.label`
  opacity: 0.7;

  font-size: 20px;
  color: #ffffff;
  text-align: center;
`;

const ShawerLogo = styled.img`
  width: 200px;
`;

const FullSectionBox = styled.div`
  display: flex;
  width: 40em;
  border: 1px solid black;
  border-radius: 26px 0px 0px 26px;
  flex-direction: column;
  padding: 40px;
  background-color: white;
`;
const Error = styled.label`
  color: red;
`;
const FormField = styled.input`
  width: 100%;
  border: 1px solid grey;
  margin: 20px;
  padding: 15px;
  text-indent: 25px;
  font-size: 20px;
  border-style: none none solid none;
  /* caret-color: red; */
  ::-webkit-input-placeholder {
    font-family: Ubuntu;
    font-size: 20px;
    font-weight: 300;
    color: #0d3868;
    opacity: 0.5;
    padding: 15px;
  }
`;

const TitleLable = styled.label`
  text-align: center;
  margin-bottom: 15px;
  font-size: 25px;
  color: #0f283d;
`;

const InstructionlLabel = styled.label`
  opacity: 0.8;
  color: #0f283d;
  font-size: 18px;
  text-align: center;
  padding: 15px 50px 10px 50px;
`;

const InputTextLabel = styled.label`
  display: flex;
  align-items: center;
  padding: 15px 50px 15px 50px;
`;

const ButtonWrap = styled.div`
  text-align: center;
  padding-left: 20px;
`;

const Authemail = window.sessionStorage.getItem("email");
const password = window.sessionStorage.getItem("token");

class ForgotPassword extends React.Component {
  state = {email: ""};

  handleOnEmailChange = e => {
    // console.log("target", e.target);
    this.setState({email: e.target.value});
  };

  forgetPassword = () => {
    const {history} = this.props;
    const {email} = this.state;
    console.log("email", email);
    // const buffer = createBuffer({email});
    // const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/user/ForgotPassword`,
        {
          json_string: JSON.stringify({
            email,
          }),
        },
        {headers: {email, channel: "web"}},
      )

      // axios
      //   .post(`${apiURL}/user/ForgotPassword`, {
      //     email,
      //   })

      .then(response => {
        console.log(response);
        history.push("/login");
      })

      .catch(error => {
        // console.log(error);
      });
  };

  render() {
    const {history} = this.props;
    return (
      // <Form>

      <FullSection>
        <LoginboxContainer>
          <ContentHolder>
            <ShawerLogo src={logo} />
            <ShawerAdminLabel>Welcome to Shawer admin portal</ShawerAdminLabel>
            <ShawerAdminLabel2>
              Thousands of Experts ready to answer <br /> & help your business.
            </ShawerAdminLabel2>
          </ContentHolder>

          <FullSectionBox>
            <TitleLable>Forgot Password?</TitleLable>
            <InstructionlLabel>
              We will send you instructions on how to reset your password by
              email
            </InstructionlLabel>
            <InputTextLabel>
              <FormField
                placeholder="Email"
                type="email"
                name="email"
                onChange={e => this.handleOnEmailChange(e)}
              />
              <LoginIcons forgotpwd />
              <br />
            </InputTextLabel>
            <ButtonWrap>
              <CancelButton
                onClick={() => {
                  history.push("/login");
                }}
              >
                Cancel
              </CancelButton>
              <Button
                onClick={() => {
                  this.forgetPassword();
                }}
              >
                Send
              </Button>
            </ButtonWrap>
          </FullSectionBox>
        </LoginboxContainer>
      </FullSection>
      // </Form>
    );
  }
}

// const ForgotPassword = ({isSubmitting, isCancelling, errors, touched}) => (
//   <Form>
//     <FullSection>
//       <TitleLable>Forgot Password?</TitleLable>
//       <InstructionlLabel>
//         We will send you instructions on how to reset your password by email
//       </InstructionlLabel>
//       <InputTextLabel>
//         <FormField placeholder="Email" type="email" name="email" />
//         <LoginIcons forgotpwd />
//         <br />
//         {touched.email && errors.email && <Error>{errors.email}</Error>}
//       </InputTextLabel>
//       <ButtonWrap>
//         <CancelButton disabled={isCancelling}>Cancel</CancelButton>
//         <Button disabled={isSubmitting}>Send</Button>
//       </ButtonWrap>
//     </FullSection>
//   </Form>
// );
// const FormikApp = withFormik({
//   mapPropsToValues({email}) {
//     return {
//       email: email || "",
//     };
//   },
//   validationSchema: Yup.object().shape({
//     email: Yup.string()
//       .email("Email not valid")
//       .required("Email is required"),
//   }),
// })(ForgotPassword);

export default ForgotPassword;
