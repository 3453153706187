import React, {Component} from "react";
import styled from "styled-components";
import axios from "axios";
import {SmartToaster, toast} from "react-smart-toaster";

import {apiURL, generateSHA2, createBuffer} from "../../utils/util";

class Transactions extends Component {
  state = {
    percent: 1,
  };

  componentDidMount() {
    this.getTransactionPercentage();
  }

  getTransactionPercentage = async () => {
    const email = window.sessionStorage.getItem("email");
    const password = window.sessionStorage.getItem("token");
    try {
      const buffer = createBuffer({email});
      const signature = generateSHA2(buffer, password);
      axios
        .post(
          `${apiURL}/aboutshawer/GetShawerPercentage`,
          {
            json_string: JSON.stringify({
              email,
            }),
          },
          {headers: {signature, email, channel: "web"}},
        )
        .then(response => {
          if (
            response.status >= 200 &&
            response.status < 300 &&
            response.data
          ) {
            const {content} = response.data;
            this.setState({
              percent: content.percentage,
            });
          } else {
            toast.error("Couldn't get the data");
          }
        });
    } catch (error) {
      toast.error("Couldn't get the data");
    }
  };

  updatePercentage = async () => {
    const email = window.sessionStorage.getItem("email");
    const password = window.sessionStorage.getItem("token");

    const {percent} = this.state;

    try {
      const buffer = createBuffer({email, percentage: percent});
      const signature = generateSHA2(buffer, password);

      axios
        .post(
          `${apiURL}/aboutshawer/UpdateShawerPercentage`,
          {
            json_string: JSON.stringify({
              email,
              percentage: percent,
            }),
          },
          {headers: {signature, email, channel: "web"}},
        )
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            toast.success("Successfully updated");
            this.getTransactionPercentage();
          } else {
            toast.error("Couldn't update the data");
          }
        });
    } catch (error) {
      toast.error("Couldn't update the data");
    }
  };

  handleChange = e => {
    this.setState({
      percent: e.target.value,
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.updatePercentage();
  };

  render() {
    const {percent} = this.state;
    return (
      <FullWrap>
        <SmartToaster store={toast} lightBackground position="top_center" />
        <Form onSubmit={this.handleSubmit}>
          <InputLabel>Transaction Fee (%)</InputLabel>
          <InputField
            type="number"
            name="percentage"
            id="percentage"
            value={percent}
            onChange={this.handleChange}
            min={1}
            max={100}
          />

          <Button type="submit">Save</Button>
        </Form>
      </FullWrap>
    );
  }
}

export default Transactions;

// styles
const FullWrap = styled.div`
  width: 950px;
  /* border: 1px solid black; */
  /* display: flex;
  flex-wrap: wrap; */
  margin-top: 2em;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.23);
  background-color: #ffffff;
  margin-left: 1em;
  border-radius: 6px;
`;

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 5rem 2rem;
`;

const InputLabel = styled.label`
  flex: 1 1 100%;
  margin: 0.5rem 0;
  font-size: 18px;
`;

const InputField = styled.input`
  margin: 0 2rem 0 0;
  padding: 0.5rem 1rem;
  width: 150px;
  font-size: 18px;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  font-size: 16px;
`;
