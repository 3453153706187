import React from "react";
import styled from "styled-components";
import chat from "./chat.png";
import cog from "./cog.png";
import dashboard from "./dashboard.png";
import folder from "./folder.png";
import message from "./message.png";
import dismanagement from "./dismanagement.svg";
import reviewmanagement from "./reviewmanagement.svg";
import wallet from "./wallet.svg";
import notification from "./notification.svg";

const FullWrap = styled.div`
  position: absolute;
  max-width: 4.8em;
  /* height: 100vh; */
  height: 100%;
  background-color: #ffffff;
  margin-top: 75px;
  /* display: flex;
  flex-wrap: wrap; */
`;
const LinkImageWrap = styled.div`
  display: flex;
  /* grid-template-rows: repeat(5, 1fr); */
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
  padding-top: 2em;
  padding-left: 0.7em;
  padding-right: 0.7em;
`;

const LinkWrap = styled.div`
  padding-top: 2em;
  padding-bottom: 1em;
`;

const ImageLinks = styled.a``;

const Images = styled.img`
  width: 20px;
`;

const isNotificationAccessible = () => {
  let typeofuser = ["superadmin"];
  const role = sessionStorage.getItem("typeofuser");
  let isAuthourized = typeofuser.includes(role);
  return isAuthourized;
};

const SideBar = () => (
  <FullWrap>
    <LinkImageWrap>
      <LinkWrap>
        <ImageLinks title="Dispute Management" href="/disputemanagement">
          <Images src={dismanagement} />
        </ImageLinks>
      </LinkWrap>
      <LinkWrap>
        <ImageLinks title="Admin Settings" href="/adminsettings">
          <Images src={cog} />
        </ImageLinks>
      </LinkWrap>
      <LinkWrap>
        <ImageLinks
          title="Expert Feedback Approval Management"
          href="/reviewmanagement"
        >
          <Images src={reviewmanagement} />
        </ImageLinks>
      </LinkWrap>
      <LinkWrap>
        <ImageLinks title="Withdrawal Management" href="/withdrawalmanagement">
          <Images src={wallet} />
        </ImageLinks>
      </LinkWrap>
      <LinkWrap>
        <ImageLinks title="Schedule Chat Settings" href="/schedulechatsettings">
          <Images src={cog} />
        </ImageLinks>
      </LinkWrap>
      {
        isNotificationAccessible() ?
        <LinkWrap>
          <ImageLinks title="Notifications" href="/notifications">
            <Images src={notification} />
          </ImageLinks>
        </LinkWrap>
        : null
      }
    </LinkImageWrap>
  </FullWrap>
);

export default SideBar;
