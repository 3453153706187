import React from "react";
import styled from "styled-components";
import axios from "axios";
import {SmartToaster, toast} from "react-smart-toaster";
import Map from "./Map";

// api URL
import {apiURL, generateSHA2, createBuffer} from "../../utils/util";

const FullWrap = styled.div`
  width: 950px;
  /* border: 1px solid black; */
  /* display: flex;
  flex-wrap: wrap; */
  margin-top: 2em;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.23);
  background-color: #ffffff;
  margin-left: 1em;
  border-radius: 6px;
`;
const RatingTitle = styled.h2`
  font-size: 17px;
  margin-left: 20px;
  margin-top: 20px;
`;

const StarRatingWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 40px 20px 20px 20px;
`;

const InputWrap = styled.div`
  margin-top: 20px;
  width: 300px;

  justify-content: space-between;
  align-items: center;
`;

const Label = styled.label``;
const AddInput = styled.input`
  /* width: 350px; */
  width: ${props => (props.large ? "750" : "350")}px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #e9eff4;
  border-bottom: solid 1px #e9eff4;
  margin-top: 15px;
  font-size: 15px;
`;
const MapDiv = styled.div`
  height: 400px;
  width: 750px;
  border-radius: 4px;
  border: solid 1px #e9eff4;
  margin-top: 15px;
`;

const QualificationDetails = styled.div``;

const RowOneWrap = styled.div`
  width: 700px;
  display: flex;
  justify-content: space-between;
`;

// const EditButton = styled.button`
//   border: none;
//   color: #39a1dd;
//   font-size: 15px;
//   margin-left: 700px;
//   cursor: pointer;
// `;

const EditRemoveButtonWrap = styled.div`
  margin-left: 670px;
  width: 100px;
  display: flex;
  justify-content: space-between;
`;

const EditButton = styled.button`
  border: none;
  color: #39a1dd;
  font-size: 15px;
  outline: 0;
  cursor: pointer;
`;

const RemoveButton = styled.button`
  border: none;
  color: #d0021b;
  font-size: 15px;
  outline: 0;
  cursor: pointer;
`;

const email = window.sessionStorage.getItem("email");
const password = window.sessionStorage.getItem("token");

class ContactUs extends React.Component {
  state = {
    contactUs: {
      lat: "",
      long: "",
      selectedAddress: "",
    },
    isFieldEnable: false,
    lat: "",
    long: "",
    selectedAddress: "",
  };

  // lifecycle methods

  componentDidMount() {
    this.getContactUsDetails();
  }

  handleOnButtonClick = () => {
    this.setState({isFieldEnable: true});
  };

  handleOnChange = e => {
    const {contactUs} = this.state;
    // console.log(contactUs);
    const {name, value} = e.target;
    const tempObj = {
      [name]: value,
    };

    // console.log("name and value", name, value);
    // console.log("temp obj", tempObj);
    const contactUsObj = contactUs;
    // console.log("Contact obj", contactUsObj);

    const copyobj = Object.assign(contactUsObj, tempObj);
    // console.log("copy obj", copyobj);
    this.setState({contactUs});
    //  console.log("Updated Data", contactUs);
  };

  getContactUsDetails = () => {
    const {contactUs} = this.state;

    const buffer = createBuffer({email});
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/aboutshawer/ContactUs`,
        {
          json_string: JSON.stringify({email}),
        },
        {headers: {signature, email, channel: "web"}},
      )

      // axios
      //   .post(`${apiURL}/aboutshawer/ContactUs`, {})

      .then(response => {
        if (response.status === 200 && response.data) {
          const {data} = response;
          // console.log("All Details", data);
          // console.log("All Details", data.content);
          // console.log("Description", data.content[0].description);
          // console.log("Description", terms_Of_Use[0].description);
          const contactus = data.content;
          // console.log("contactus", contactus);
          this.setState({
            contactUs: contactus,
          });
        }
      })
      .catch(error => {
        // console.log(error);
      });
    // console.log("terms_Of_Use", contactUs);
  };

  getLocationData = object => {
    // console.log("obj", object);
    const {lat, long, selectedAddress, contactUs} = this.state;
    Object.assign(contactUs, object);
    this.setState({contactUs});
    // console.log("contact us in location data", contactUs);
    // this.setState({
    //   lat: object.lat,
    //   long: object.long,
    //   selectedAddress: object.selectedAddress,
    // });
  };

  updateContactUsDeatils = () => {
    const {contactUs} = this.state;

    //  console.log("upadted api contact us", contactUs);

    // axios
    //   .post(`${apiURL}/aboutshawer/UpdateContactUs`, {
    //     contact_us: contactUs,
    //   })

    const buffer = createBuffer({email, contact_us: contactUs});
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/aboutshawer/UpdateContactUs`,
        {
          json_string: JSON.stringify({email, contact_us: contactUs}),
        },
        {headers: {signature, email, channel: "web"}},
      )

      .then(response => {
        // console.log(response);
        this.getContactUsDetails();
        toast.info("Details has been updated!");
      })
      .catch(error => {
        //  console.log(error);
      });
  };

  render() {
    const {contactUs, isFieldEnable} = this.state;
    return (
      <FullWrap>
        <SmartToaster store={toast} lightBackground position="top_center" />
        <RatingTitle>Contact Us</RatingTitle>
        <EditRemoveButtonWrap>
          <EditButton onClick={this.handleOnButtonClick}>Edit</EditButton>
          <RemoveButton onClick={this.updateContactUsDeatils}>
            Save
          </RemoveButton>
        </EditRemoveButtonWrap>
        {/* <EditButton onClick={this.handleOnChange}>Save</EditButton> */}
        <StarRatingWrap>
          {/* {console.log("email", contactUs)} */}
          <QualificationDetails>
            {/* {contactUs.map(index => (
              <div key={index}> */}
            <RowOneWrap>
              <InputWrap>
                <Label>Email</Label>
                <AddInput
                  placeholder="  Write Email here"
                  value={contactUs.email}
                  name="email"
                  // data-index={index}
                  data-name={contactUs.email}
                  onChange={
                    isFieldEnable
                      ? e => {
                          this.handleOnChange(e);
                        }
                      : null
                  }
                />
              </InputWrap>

              <InputWrap>
                <Label>Phone Number</Label>
                <AddInput
                  placeholder="  Write Phone Number here"
                  value={contactUs.phone_no}
                  name="phone_no"
                  data-name={contactUs.phone_no}
                  onChange={
                    isFieldEnable
                      ? e => {
                          this.handleOnChange(e);
                        }
                      : null
                  }
                />
              </InputWrap>
            </RowOneWrap>

            <InputWrap>
              <Label>Location</Label>
              <MapDiv>
                <Map
                  contactUs={contactUs}
                  getLocationData={this.getLocationData}
                />
              </MapDiv>
            </InputWrap>
          </QualificationDetails>
        </StarRatingWrap>
      </FullWrap>
    );
  }
}

export default ContactUs;
