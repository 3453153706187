import React from "react";
import styled from "styled-components";
import axios from "axios";
import NavigationBar from "../NavigationBar/NavigationBar";
import SideBar from "../NavigationBar/SideBar";

// api URL
import {apiURL} from "../../utils/util";

import WithdrawalManagementReactTable from "./WithdrawalManagementReactTable";
import NotAccessiblePNG from "../Common/NotAccessiblePNG.png";

const FullSection = styled.section`
  background-color: #fafbfc;
  display: grid;
  grid-template-rows: 1fr auto;
  max-height: 100%;

  /* grid-template-columns: auto auto; */
`;
const LabelInputWrap = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 4.5em;
  margin-left: 4.5em;
  margin-top: 0.5em;
`;

const ExpertListLabel = styled.h3`
  font-size: 17px;
  color: #1a173b;
  margin-left: 23px;
`;

const PageOneContentWrap = styled.div``;
const PageTwoContentWrap = styled.div``;
const PageTwoContent = styled.div`
  display: flex;
  border-radius: 3.8px;
  border: solid 1px #ebedf8;
  background-color: #ffffff;
  margin: 0.5em 0 2em 6em;
  height: 100%;
`;

const NotAccessibleDiv = styled.div`
  /* margin-left: 100px; */
  /* margin-top: 100px; */
  padding-top: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  height: 100%;
  width: 100%;
`;

const NotAccessibleImg = styled.img``;

const NotAccessibleLabel = styled.h2``;

class WithdrawalManagement extends React.Component {
  state = {
    typeofuser: [
      "superadmin",
      "superreader",
      "financialmanager",
      "approvalmanager",
    ],
    isAuthourized: false,
  };

  // lifecycle methods

  componentDidMount() {
    this.userData();
  }

  // api calls

  userData = () => {
    let {isAuthourized, typeofuser} = this.state;
    const role = sessionStorage.getItem("typeofuser");
    isAuthourized = typeofuser.includes(role);
    this.setState({isAuthourized});
  };

  render() {
    const {isAuthourized} = this.state;
    return (
      <FullSection>
        <NavigationBar />
        <SideBar />
        {isAuthourized ? (
          <PageTwoContentWrap>
            <LabelInputWrap>
              <ExpertListLabel>Withdrawal Management</ExpertListLabel>
            </LabelInputWrap>

            <PageTwoContent>
              <WithdrawalManagementReactTable />
            </PageTwoContent>
          </PageTwoContentWrap>
        ) : (
          <NotAccessibleDiv>
            <NotAccessibleLabel>
              Sorry you’re a Content Manager. Please contact the Super Admin to
              access this area.
            </NotAccessibleLabel>
            <NotAccessibleImg alt="Not accessible" src={NotAccessiblePNG} />
          </NotAccessibleDiv>
        )}
      </FullSection>
    );
  }
}

export default WithdrawalManagement;
