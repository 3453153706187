import React from "react";
import styled from "styled-components";
import {SmartToaster, toast} from "react-smart-toaster";
// import Popup from "reactjs-popup";
import Modal from "react-modal";
import axios from "axios";
import shortid from "shortid";
import {Form, Field, FieldArray, Formik} from "formik";

// api URL
import {apiURL, generateSHA2, createBuffer} from "../../utils/util";

const StarRatingWrap = styled.div`
  margin: 40px 20px 20px 20px;
`;

const ReasonInputButtonWrap = styled.div`
  width: 500px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ReasonInput = styled.input`
  width: 300px;
  height: 35px;
  border-radius: 4px;
  border: solid 1px #e9eff4;
`;

const AddReasonButton = styled.button`
  width: 100px;
  height: 30px;
  border-radius: 2px;
  border: solid 1px rgba(77, 161, 255, 0.7);
  background-color: #39a1dd;
  color: #fff;
`;

const ReasonLabel = styled.label``;

const ReasonEditInputWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0px 20px 0px;
`;

const ReasonEditInput = styled(Field)`
  height: 35px;
  width: 500px;
  margin: 20px 0px 20px 0px;
  border-radius: 4px;
  font-size: 15px;
  /* border: solid 1px #e9eff4; */
  border: ${props =>
    props.isActive ? "solid 1px dimgrey" : "solid 1px #e9eff4"};
`;

const EditRemoveButtonWrap = styled.div`
  /* margin-left: 550px; */
  width: 130px;
  display: flex;
  justify-content: space-between;
`;

const EditButton = styled.button`
  border: none;
  color: #39a1dd;
  font-size: 15px;
  outline: 0;
  cursor: pointer;
`;

const RemoveButton = styled.button`
  border: none;
  color: #d0021b;
  font-size: 15px;
  outline: 0;
  cursor: pointer;
`;

const EditInputWrap = styled.div`
  display: flex;
  width: 700px;
  justify-content: space-between;
  align-items: center;
`;

const email = window.sessionStorage.getItem("email");
const password = window.sessionStorage.getItem("token");

// Popup CSS

class RatingThree extends React.Component {
  state = {
    open: false,
    currentEditing: -1,
    getRatingThree: [],
    isFieldActive: false,
  };

  openPopup = this.openPopup.bind(this);

  closePopup = this.closePopup.bind(this);

  // lifecycle methods

  componentDidMount() {
    this.getCategoryDetails();
  }

  deleteElementFromArray = e => {
    const {index} = e.target.dataset;

    const {getRatingThree} = this.state;

    getRatingThree.splice(index, 1);

    this.updateRatingThreeInfo();

    this.getCategoryDetails();

    // this.setState(this.state);
    toast.info("Data Deleted!");
  };

  getCategoryDetails = () => {
    const {getRatingList} = this.state;

    const buffer = createBuffer({email});
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/aboutshawer/GetRatingList`,
        {
          json_string: JSON.stringify({email}),
        },
        {headers: {signature, email, channel: "web"}},
      )

      // axios
      //   .post(`${apiURL}/aboutshawer/GetRatingList`, {})

      .then(response => {
        // console.log(response);

        if (response.status === 200 && response.data) {
          // console.log(response.data);
          const {data} = response;

          // console.log("Data", data);
          const RatingOneContent = data.content[0][3];
          this.setState({getRatingThree: RatingOneContent});

          // console.log("data-content", data.content);
          // console.log("data-content", RatingOneContent);
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };

  handleOnChange = (e, index) => {
    const {getRatingThree} = this.state;

    const editData = getRatingThree[index];

    // console.log("edit data", editData);

    const {value} = e.target;
    const {name} = e.target.dataset;

    // console.log("name and value", name, value);

    getRatingThree[index] = value;
    // arrayHelpers.replace(index, qualification[index]);
    // console.log("Updated qualification", arrayHelpers);
    // console.log("Updated....", getRatingThree);

    // arrayHelpers.replace(index, qualification[index])
    this.setState({
      getRatingThree,
    });
    // console.log("Updated....!!!!!!", getRatingThree);
  };

  // Adding value to Array

  onChangeValue = e => {
    this.setState({value: e.target.value});
  };

  onClickRatingAdd = () => {
    const {getRatingThree, value} = this.state;
    // console.log("before updating Array", getRatingThree);
    // console.log("valueeee", value);
    getRatingThree.push(value);
    this.setState({getRatingThree});
    // console.log("updated Array", getRatingThree);
    this.updateRatingThreeInfo();
  };

  // updating value to array

  updateRatingThreeInfo = async index => {
    const {getRatingThree} = this.state;

    const buffer = createBuffer({email, rating: getRatingThree, index: 3});
    const signature = generateSHA2(buffer, password);

    await axios
      .post(
        `${apiURL}/aboutshawer/UpdateRating`,
        {
          json_string: JSON.stringify({
            email,
            rating: getRatingThree,
            index: 3,
          }),
        },
        {headers: {signature, email, channel: "web"}},
      )

      .then(response => {
        this.getCategoryDetails();
        // console.log(response);
      })
      .catch(error => {
        // console.log(error);
      });
    this.setState({currentEditing: -1});
  };

  handleOnButtonClick = index => {
    this.setState({currentEditing: index, isFieldActive: true});
  };

  openPopup() {
    this.setState({open: true});
  }

  closePopup() {
    this.setState({open: false});
  }

  render() {
    const {open, currentEditing, getRatingThree, isFieldActive} = this.state;
    return (
      <StarRatingWrap>
        <SmartToaster store={toast} lightBackground position="top_center" />
        <ReasonInputButtonWrap>
          <ReasonInput
            onChange={this.onChangeValue}
            placeholder="  Write a reason for 3 star here"
          />
          <AddReasonButton onClick={this.onClickRatingAdd}>Add</AddReasonButton>
        </ReasonInputButtonWrap>

        {/* ---------Formik----------- */}
        <div>
          <Formik
            enableReinitialize
            initialValues={{qualificationDetails: getRatingThree}}
            render={({values}) => (
              <Form>
                <ReasonEditInputWrap>
                  <FieldArray
                    // rerenderOnEveryChange
                    name="qualificationDetails"
                    render={({arrayHelpers, remove}) => (
                      <React.Fragment>
                        {values.qualificationDetails.map(
                          (qualDetail, index) => (
                            <ReasonEditInputWrap key={index}>
                              {/* <Wrap key={shortid.generate()}> */}
                              {/* {console.log(arrayHelpers)} */}
                              <EditInputWrap>
                                <ReasonLabel>Reason {index + 1}</ReasonLabel>
                                <ReasonEditInput
                                  type="text"
                                  placeholder="Write here..."
                                  // value={qualification[index]}
                                  name={`qualificationDetails[${index}]`}
                                  data-name={qualDetail}
                                  data-index={index}
                                  isActive={
                                    index === currentEditing
                                      ? isFieldActive
                                      : ""
                                  }
                                  disabled={index !== currentEditing}
                                  onChange={
                                    index === currentEditing
                                      ? e => this.handleOnChange(e, index)
                                      : null
                                  }
                                />
                                <EditRemoveButtonWrap>
                                  <EditButton
                                    type="button"
                                    data-index={index}
                                    // onClick={this.handleOnButtonClick}
                                    onClick={() => {
                                      this.handleOnButtonClick(index);
                                    }}
                                  >
                                    Edit
                                  </EditButton>
                                  <RemoveButton
                                    type="button"
                                    data-index={index}
                                    // onClick={() => remove(index)}
                                    // onClick={e => {
                                    //   const myIndex = e.target.dataset.index;
                                    //   arrayHelpers.remove(myIndex);
                                    // }}
                                    onClick={() => {
                                      this.updateRatingThreeInfo(index);
                                      toast.info("Data Updated!");
                                    }}
                                  >
                                    Save
                                  </RemoveButton>
                                  <RemoveButton
                                    type="button"
                                    data-index={index}
                                    onClick={e => {
                                      this.deleteElementFromArray(e);
                                    }}
                                  >
                                    Remove
                                  </RemoveButton>
                                  {/* <RemoveButton
                              type="button"
                              data-index={index}
                              // onClick={() => remove(index)}
                              // onClick={e => {
                              //   const myIndex = e.target.dataset.index;
                              //   remove(myIndex);
                              // }}
                              // onClick={() =>
                              //   this.updateQualificationInfo(index)
                              // }
                            >
                              Remove
                            </RemoveButton> */}
                                </EditRemoveButtonWrap>
                              </EditInputWrap>
                            </ReasonEditInputWrap>
                          ),
                        )}
                      </React.Fragment>
                    )}
                  />
                </ReasonEditInputWrap>
              </Form>
            )}
          />
        </div>

        {/* {getRatingList.map(index => (
          <div key={shortid.generate()}>
            <ReasonEditInputWrap>
              <ReasonLabel>Reason 1</ReasonLabel>
              <EditInputWrap>
                {console.log("rating list", index)}
                <ReasonEditInput placeholder="  Content" value={index[1][0]} />
                <EditRemoveButtonWrap>
                  <EditButton onClick={this.openPopup}>Edit</EditButton>
                  <RemoveButton>Remove</RemoveButton>
                </EditRemoveButtonWrap>
              </EditInputWrap>
            </ReasonEditInputWrap>
            <ReasonEditInputWrap>
              <ReasonLabel>Reason 2</ReasonLabel>
              <EditInputWrap>
                <ReasonEditInput placeholder="  Content" value={index[1][1]} />
                <EditRemoveButtonWrap>
                  <EditButton>Edit</EditButton>
                  <RemoveButton>Remove</RemoveButton>
                </EditRemoveButtonWrap>
              </EditInputWrap>
            </ReasonEditInputWrap>

            <ReasonEditInputWrap>
              <ReasonLabel>Reason 2</ReasonLabel>
              <EditInputWrap>
                <ReasonEditInput placeholder="  Content" value={index[1][2]} />
                <EditRemoveButtonWrap>
                  <EditButton onClick={this.openPopup}>Edit</EditButton>
                  <RemoveButton>Remove</RemoveButton>
                </EditRemoveButtonWrap>
              </EditInputWrap>
            </ReasonEditInputWrap>
          </div>
        ))} */}
      </StarRatingWrap>
    );
  }
}

export default RatingThree;
