import React from "react";
import styled from "styled-components";
// import Popup from "reactjs-popup";
import Modal from "react-modal";
import axios from "axios";
import shortid from "shortid";
import {generateKeyPair} from "crypto";
import starimg from "./star.png";
import closebtn from "./closebtn.png";
import RatingOne from "./RatingOne";
import RatingTwo from "./RatingTwo";
import RatingThree from "./RatingThree";
import RatingFour from "./RatingFour";
import RatingFive from "./RatingFive";

// api URL
import {apiURL} from "../../utils/util";

const FullWrap = styled.div`
  width: 950px;
  /* border: 1px solid black; */
  /* display: flex;
  flex-wrap: wrap; */
  margin-top: 2em;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.23);
  background-color: #ffffff;
  margin-left: 1em;
  border-radius: 6px;
`;
const RatingTitle = styled.h2`
  font-size: 17px;
  margin-left: 20px;
  margin-top: 20px;
`;

const StarImage = styled.img`
  margin-right: 5px;
`;

const RatingsButton = styled.button`
  height: 30px;
  font-size: 15px;
  border: none;
  display: flex;
  outline: 0;
  border-bottom: ${props => (props.isActive ? "2px solid #e54666" : "")};
  opacity: ${props => (props.isActive ? "1" : "0.3")};
  align-items: center;
  cursor: pointer;
`;

const RatingButtonWrap = styled.div`
  margin-top: 30px;
  margin-left: 20px;
  width: 900px;
  display: flex;
  justify-content: space-between;
`;

const StarRatingWrap = styled.div`
  margin: 40px 20px 20px 20px;
`;

const ReasonInputButtonWrap = styled.div`
  width: 500px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ReasonInput = styled.input`
  width: 300px;
  height: 35px;
  border-radius: 4px;
  border: solid 1px #e9eff4;
`;

const AddReasonButton = styled.button`
  width: 100px;
  height: 30px;
  border-radius: 2px;
  border: solid 1px rgba(77, 161, 255, 0.7);
  background-color: #39a1dd;
  color: #fff;
`;

const ReasonLabel = styled.label``;

const ReasonEditInputWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0px 20px 0px;
`;
const EditInputWrap = styled.div`
  display: flex;
  width: 650px;
  justify-content: space-between;
  align-items: center;
`;

const ReasonEditInput = styled.input`
  height: 35px;
  width: 500px;
  margin: 20px 0px 20px 0px;
  border-radius: 4px;
  border: solid 1px #e9eff4;
`;

const EditRemoveButtonWrap = styled.div`
  /* margin-left: 550px; */
  width: 100px;
  display: flex;
  justify-content: space-between;
`;

const EditButton = styled.button`
  border: none;
  color: #39a1dd;
  font-size: 15px;
  outline: 0;
`;

const RemoveButton = styled.button`
  border: none;
  color: #d0021b;
  font-size: 15px;
  outline: 0;
`;

// Popup CSS

const PopupWrap = styled.div`
  /* width: 500px; */
`;

const PopupTitle = styled.h3`
  margin-top: 0px;
`;

const InputLabelWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100px;
`;

const InputLabel = styled.label``;

const Input = styled.input`
  width: 300px;
  border-radius: 4px;
  border: solid 1px #e9eff4;
  height: 35px;
`;

const SaveButton = styled.button`
  height: 30px;
  width: 80px;
  border-radius: 2px;
  border: solid 1px rgba(77, 161, 255, 0.7);
  background-color: #39a1dd;
  color: #fff;
`;

const CloseButton = styled.button`
  outline: 0;
  border: none;

  margin-left: 370px;
  margin-top: -10px;
`;

const CloseButtonImg = styled.img`
  height: 20px;
  width: 20px;
`;

const customStyles = {
  content: {
    height: "40%",
    width: "30%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

class Ratings extends React.Component {
  state = {
    open: false,
    isOnestarButtonClicked: true,
    isTwoStarButtonClicked: false,
    isThreeStarButtonClicked: false,
    isFourStarButtonClicked: false,
    isFiveStarButtonClicked: false,

    isOneStarButtonActive: true,
    isTwoStarButtonActive: false,
    isThreeStarButtonActive: false,
    isFourStarButtonActive: false,
    isFiveStarButtonActive: false,

    getRatingList: [],
  };

  openPopup = this.openPopup.bind(this);

  closePopup = this.closePopup.bind(this);

  // lifecycle methods

  componentDidMount() {
    this.getCategoryDetails();
  }

  onOneStarButtonClicked = () => {
    this.setState({
      isOnestarButtonClicked: true,
      isTwoStarButtonClicked: false,
      isThreeStarButtonClicked: false,
      isFourStarButtonClicked: false,
      isFiveStarButtonClicked: false,

      isOneStarButtonActive: true,
      isTwoStarButtonActive: false,
      isThreeStarButtonActive: false,
      isFourStarButtonActive: false,
      isFiveStarButtonActive: false,
    });
  };

  onTwoStarButtonClicked = () => {
    this.setState({
      isOnestarButtonClicked: false,
      isTwoStarButtonClicked: true,
      isThreeStarButtonClicked: false,
      isFourStarButtonClicked: false,
      isFiveStarButtonClicked: false,

      isOneStarButtonActive: false,
      isTwoStarButtonActive: true,
      isThreeStarButtonActive: false,
      isFourStarButtonActive: false,
      isFiveStarButtonActive: false,
    });
  };

  onThreeStarButtonClicked = () => {
    this.setState({
      isOnestarButtonClicked: false,
      isTwoStarButtonClicked: false,
      isThreeStarButtonClicked: true,
      isFourStarButtonClicked: false,
      isFiveStarButtonClicked: false,

      isOneStarButtonActive: false,
      isTwoStarButtonActive: false,
      isThreeStarButtonActive: true,
      isFourStarButtonActive: false,
      isFiveStarButtonActive: false,
    });
  };

  onFourStarButtonClicked = () => {
    this.setState({
      isOnestarButtonClicked: false,
      isTwoStarButtonClicked: false,
      isThreeStarButtonClicked: false,
      isFourStarButtonClicked: true,
      isFiveStarButtonClicked: false,

      isOneStarButtonActive: false,
      isTwoStarButtonActive: false,
      isThreeStarButtonActive: false,
      isFourStarButtonActive: true,
      isFiveStarButtonActive: false,
    });
  };

  onFiveStarButtonClicked = () => {
    this.setState({
      isOnestarButtonClicked: false,
      isTwoStarButtonClicked: false,
      isThreeStarButtonClicked: false,
      isFourStarButtonClicked: false,
      isFiveStarButtonClicked: true,

      isOneStarButtonActive: false,
      isTwoStarButtonActive: false,
      isThreeStarButtonActive: false,
      isFourStarButtonActive: false,
      isFiveStarButtonActive: true,
    });
  };

  getCategoryDetails = () => {
    const {getRatingList} = this.state;
    axios
      .post(`${apiURL}/aboutshawer/GetRatingList`, {})

      .then(response => {
        // console.log(response);

        if (response.status === 200 && response.data) {
          // console.log(response.data);
          const {data} = response;
          // console.log("Data", data);

          this.setState({getRatingList: data.content});

          // console.log(data.content);
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };

  openPopup() {
    this.setState({open: true});
  }

  closePopup() {
    this.setState({open: false});
  }

  render() {
    const {
      open,
      isOnestarButtonClicked,
      isTwoStarButtonClicked,
      isThreeStarButtonClicked,
      isFourStarButtonClicked,
      isFiveStarButtonClicked,
      isOneStarButtonActive,
      isTwoStarButtonActive,
      isThreeStarButtonActive,
      isFourStarButtonActive,
      isFiveStarButtonActive,

      getRatingList,
    } = this.state;
    return (
      <FullWrap>
        <RatingTitle>Rating Reasons</RatingTitle>
        <RatingButtonWrap>
          <RatingsButton
            onClick={this.onOneStarButtonClicked}
            isActive={isOneStarButtonActive}
          >
            <StarImage src={starimg} />1 Star
          </RatingsButton>

          <RatingsButton
            onClick={this.onTwoStarButtonClicked}
            isActive={isTwoStarButtonActive}
          >
            <StarImage src={starimg} />
            <StarImage src={starimg} />2 Star
          </RatingsButton>
          <RatingsButton
            onClick={this.onThreeStarButtonClicked}
            isActive={isThreeStarButtonActive}
          >
            <StarImage src={starimg} />
            <StarImage src={starimg} />
            <StarImage src={starimg} />3 Star
          </RatingsButton>
          <RatingsButton
            onClick={this.onFourStarButtonClicked}
            isActive={isFourStarButtonActive}
          >
            <StarImage src={starimg} />
            <StarImage src={starimg} />
            <StarImage src={starimg} />
            <StarImage src={starimg} />4 Star
          </RatingsButton>
          <RatingsButton
            onClick={this.onFiveStarButtonClicked}
            isActive={isFiveStarButtonActive}
          >
            <StarImage src={starimg} />
            <StarImage src={starimg} />
            <StarImage src={starimg} />
            <StarImage src={starimg} />
            <StarImage src={starimg} />5 Star
          </RatingsButton>
        </RatingButtonWrap>
        {isOnestarButtonClicked ? (
          <RatingOne />
        ) : // <StarRatingWrap>
        //   <ReasonInputButtonWrap>
        //     <ReasonInput placeholder="  Write a reason for 1 star here" />
        //     <AddReasonButton>Add</AddReasonButton>
        //   </ReasonInputButtonWrap>

        //   {/* {Object.keys(getRatingList).map((key) => (
        //   )
        //   )} */}

        //   {/* {Object.keys(getRatingList).map(key => {
        //     return ( <div key={key}>
        //     getRatingList[key].map((index)=> {
        //       return(
        //         <ReasonEditInputWrap>
        //         <ReasonLabel>Reason 1</ReasonLabel>
        //         <EditInputWrap>
        //           {console.log("rating list", index)}
        //           <ReasonEditInput
        //             placeholder="  Content"
        //             value={index[1][0]}
        //           />
        //           <EditRemoveButtonWrap>
        //             <EditButton onClick={this.openPopup}>Edit</EditButton>
        //             <RemoveButton>Remove</RemoveButton>
        //           </EditRemoveButtonWrap>
        //         </EditInputWrap>
        //       </ReasonEditInputWrap>
        //       )
        //     })
        //     </div>);
        //   })} */}

        //   {getRatingList.map(index => (
        //     <div key={shortid.generate()}>
        //       <ReasonEditInputWrap>
        //         <ReasonLabel>Reason 1</ReasonLabel>
        //         <EditInputWrap>
        //           {console.log("rating list", index)}
        //           <ReasonEditInput
        //             placeholder="  Content"
        //             value={index[1][0]}
        //           />
        //           <EditRemoveButtonWrap>
        //             <EditButton onClick={this.openPopup}>Edit</EditButton>
        //             <RemoveButton>Remove</RemoveButton>
        //           </EditRemoveButtonWrap>
        //         </EditInputWrap>
        //       </ReasonEditInputWrap>
        //       <ReasonEditInputWrap>
        //         <ReasonLabel>Reason 2</ReasonLabel>
        //         <EditInputWrap>
        //           <ReasonEditInput
        //             placeholder="  Content"
        //             value={index[1][1]}
        //           />
        //           <EditRemoveButtonWrap>
        //             <EditButton onClick={this.openPopup}>Edit</EditButton>
        //             <RemoveButton>Remove</RemoveButton>
        //           </EditRemoveButtonWrap>
        //         </EditInputWrap>
        //       </ReasonEditInputWrap>

        //       <ReasonEditInputWrap>
        //         <ReasonLabel>Reason 2</ReasonLabel>
        //         <EditInputWrap>
        //           <ReasonEditInput
        //             placeholder="  Content"
        //             value={index[1][2]}
        //           />
        //           <EditRemoveButtonWrap>
        //             <EditButton onClick={this.openPopup}>Edit</EditButton>
        //             <RemoveButton>Remove</RemoveButton>
        //           </EditRemoveButtonWrap>
        //         </EditInputWrap>
        //       </ReasonEditInputWrap>
        //     </div>
        //   ))}

        //   <Modal
        //     isOpen={open}
        //     // onAfterOpen={this.afterOpenModal}
        //     onRequestClose={this.closePopup}
        //     style={customStyles}
        //     ariaHideApp={false}
        //     // contentLabel="Example Modal"
        //   >
        //     <PopupWrap>
        //       <CloseButton onClick={this.closePopup}>
        //         <CloseButtonImg src={closebtn} />
        //       </CloseButton>
        //       <PopupTitle>Edit Rating Reason</PopupTitle>
        //       <InputLabelWrap>
        //         <InputLabel>Reason 1*</InputLabel>
        //         <Input />
        //         <SaveButton>Save</SaveButton>
        //       </InputLabelWrap>
        //     </PopupWrap>
        //   </Modal>
        //   {/* <Popup open={open}>
        //     <PopupWrap>
        //       <PopupTitle>Edit Rating Reason</PopupTitle>
        //       <InputLabelWrap>
        //         <InputLabel>Rating Reason</InputLabel>
        //         <Input />
        //         <SaveButton>Save</SaveButton>
        //       </InputLabelWrap>
        //     </PopupWrap>
        //   </Popup> */}
        // </StarRatingWrap>
        isTwoStarButtonClicked ? (
          <RatingTwo />
        ) : // <StarRatingWrap>
        //   <ReasonInputButtonWrap>
        //     <ReasonInput placeholder="  Write a reason for 2 star here" />

        //     <AddReasonButton>Add</AddReasonButton>
        //   </ReasonInputButtonWrap>
        //   {getRatingList.map(index => (
        //     <div key={shortid.generate()}>
        //       <ReasonEditInputWrap>
        //         <ReasonLabel>Reason 1</ReasonLabel>
        //         <EditInputWrap>
        //           <ReasonEditInput
        //             placeholder="  Content"
        //             value={index[2][0]}
        //           />
        //           <EditRemoveButtonWrap>
        //             <EditButton onClick={this.openPopup}>Edit</EditButton>
        //             <RemoveButton>Remove</RemoveButton>
        //           </EditRemoveButtonWrap>
        //         </EditInputWrap>
        //       </ReasonEditInputWrap>
        //       <ReasonEditInputWrap>
        //         <ReasonLabel>Reason 2</ReasonLabel>
        //         <EditInputWrap>
        //           <ReasonEditInput
        //             placeholder="  Content"
        //             value={index[2][1]}
        //           />
        //           <EditRemoveButtonWrap>
        //             <EditButton onClick={this.openPopup}>Edit</EditButton>
        //             <RemoveButton>Remove</RemoveButton>
        //           </EditRemoveButtonWrap>
        //         </EditInputWrap>
        //       </ReasonEditInputWrap>
        //       <ReasonEditInputWrap>
        //         <ReasonLabel>Reason 3</ReasonLabel>
        //         <EditInputWrap>
        //           <ReasonEditInput
        //             placeholder="  Content"
        //             value={index[2][2]}
        //           />
        //           <EditRemoveButtonWrap>
        //             <EditButton onClick={this.openPopup}>Edit</EditButton>
        //             <RemoveButton>Remove</RemoveButton>
        //           </EditRemoveButtonWrap>
        //         </EditInputWrap>
        //       </ReasonEditInputWrap>
        //     </div>
        //   ))}
        //   {/*
        //   <Modal
        //     isOpen={open}
        //     // onAfterOpen={this.afterOpenModal}
        //     onRequestClose={this.closePopup}
        //     style={customStyles}
        //     // contentLabel="Example Modal"
        //   >
        //     <PopupWrap>
        //       <CloseButton onClick={this.closePopup}>
        //         <CloseButtonImg src={closebtn} />
        //       </CloseButton>
        //       <PopupTitle>Edit Rating Reason</PopupTitle>
        //       <InputLabelWrap>
        //         <InputLabel>Reason 1*</InputLabel>
        //         <Input />
        //         <SaveButton>Save</SaveButton>
        //       </InputLabelWrap>
        //     </PopupWrap>
        //   </Modal> */}
        // </StarRatingWrap>
        isThreeStarButtonClicked ? (
          // <StarRatingWrap>
          //   <ReasonInputButtonWrap>
          //     <ReasonInput placeholder="  Write a reason for 3 star here" />
          //     <AddReasonButton>Add</AddReasonButton>
          //   </ReasonInputButtonWrap>

          //   {getRatingList.map(index => (
          //     <div key={shortid.generate()}>
          //       <ReasonEditInputWrap>
          //         <ReasonLabel>Reason 1</ReasonLabel>
          //         <EditInputWrap>
          //           <ReasonEditInput
          //             placeholder="  Content"
          //             value={index[3][0]}
          //           />
          //           <EditRemoveButtonWrap>
          //             <EditButton onClick={this.openPopup}>Edit</EditButton>
          //             <RemoveButton>Remove</RemoveButton>
          //           </EditRemoveButtonWrap>
          //         </EditInputWrap>
          //       </ReasonEditInputWrap>
          //       <ReasonEditInputWrap>
          //         <ReasonLabel>Reason 2</ReasonLabel>
          //         <EditInputWrap>
          //           <ReasonEditInput
          //             placeholder="  Content"
          //             value={index[3][1]}
          //           />
          //           <EditRemoveButtonWrap>
          //             <EditButton onClick={this.openPopup}>Edit</EditButton>
          //             <RemoveButton>Remove</RemoveButton>
          //           </EditRemoveButtonWrap>
          //         </EditInputWrap>
          //       </ReasonEditInputWrap>
          //       <ReasonEditInputWrap>
          //         <ReasonLabel>Reason 3</ReasonLabel>
          //         <EditInputWrap>
          //           <ReasonEditInput
          //             placeholder="  Content"
          //             value={index[3][2]}
          //           />
          //           <EditRemoveButtonWrap>
          //             <EditButton onClick={this.openPopup}>Edit</EditButton>
          //             <RemoveButton>Remove</RemoveButton>
          //           </EditRemoveButtonWrap>
          //         </EditInputWrap>
          //       </ReasonEditInputWrap>
          //     </div>
          //   ))}
          //   {/* <ReasonEditInputWrap>
          //     <ReasonLabel>Reason 1</ReasonLabel>
          //     <EditInputWrap>
          //       <ReasonEditInput placeholder="  Content" />
          //       <EditRemoveButtonWrap>
          //         <EditButton>Edit</EditButton>
          //         <RemoveButton>Remove</RemoveButton>
          //       </EditRemoveButtonWrap>
          //     </EditInputWrap>
          //   </ReasonEditInputWrap>
          //   <ReasonEditInputWrap>
          //     <ReasonLabel>Reason 2</ReasonLabel>
          //     <EditInputWrap>
          //       <ReasonEditInput placeholder="  Content" />
          //       <EditRemoveButtonWrap>
          //         <EditButton>Edit</EditButton>
          //         <RemoveButton>Remove</RemoveButton>
          //       </EditRemoveButtonWrap>
          //     </EditInputWrap>
          //   </ReasonEditInputWrap> */}
          // </StarRatingWrap>
          <RatingThree />
        ) : isFourStarButtonClicked ? (
          <RatingFour />
        ) : // <StarRatingWrap>
        //   <ReasonInputButtonWrap>
        //     <ReasonInput placeholder="  Write a reason for 4 star here" />
        //     <AddReasonButton>Add</AddReasonButton>
        //   </ReasonInputButtonWrap>

        //   {getRatingList.map(index => (
        //     <div key={shortid.generate()}>
        //       <ReasonEditInputWrap>
        //         <ReasonLabel>Reason 1</ReasonLabel>
        //         <EditInputWrap>
        //           <ReasonEditInput
        //             placeholder="  Content"
        //             value={index[4][0]}
        //           />
        //           <EditRemoveButtonWrap>
        //             <EditButton onClick={this.openPopup}>Edit</EditButton>
        //             <RemoveButton>Remove</RemoveButton>
        //           </EditRemoveButtonWrap>
        //         </EditInputWrap>
        //       </ReasonEditInputWrap>
        //       <ReasonEditInputWrap>
        //         <ReasonLabel>Reason 2</ReasonLabel>
        //         <EditInputWrap>
        //           <ReasonEditInput
        //             placeholder="  Content"
        //             value={index[4][1]}
        //           />
        //           <EditRemoveButtonWrap>
        //             <EditButton onClick={this.openPopup}>Edit</EditButton>
        //             <RemoveButton>Remove</RemoveButton>
        //           </EditRemoveButtonWrap>
        //         </EditInputWrap>
        //       </ReasonEditInputWrap>
        //       <ReasonEditInputWrap>
        //         <ReasonLabel>Reason 3</ReasonLabel>
        //         <EditInputWrap>
        //           <ReasonEditInput
        //             placeholder="  Content"
        //             value={index[4][2]}
        //           />
        //           <EditRemoveButtonWrap>
        //             <EditButton onClick={this.openPopup}>Edit</EditButton>
        //             <RemoveButton>Remove</RemoveButton>
        //           </EditRemoveButtonWrap>
        //         </EditInputWrap>
        //       </ReasonEditInputWrap>
        //     </div>
        //   ))}
        //   {/* <ReasonEditInputWrap>
        //     <ReasonLabel>Reason 1</ReasonLabel>
        //     <EditInputWrap>
        //       <ReasonEditInput placeholder="  Content" />
        //       <EditRemoveButtonWrap>
        //         <EditButton>Edit</EditButton>
        //         <RemoveButton>Remove</RemoveButton>
        //       </EditRemoveButtonWrap>
        //     </EditInputWrap>
        //   </ReasonEditInputWrap>
        //   <ReasonEditInputWrap>
        //     <ReasonLabel>Reason 2</ReasonLabel>
        //     <EditInputWrap>
        //       <ReasonEditInput placeholder="  Content" />
        //       <EditRemoveButtonWrap>
        //         <EditButton>Edit</EditButton>
        //         <RemoveButton>Remove</RemoveButton>
        //       </EditRemoveButtonWrap>
        //     </EditInputWrap>
        //   </ReasonEditInputWrap> */}
        // </StarRatingWrap>
        isFiveStarButtonClicked ? (
          <RatingFive />
        ) : // <StarRatingWrap>
        //   <ReasonInputButtonWrap>
        //     <ReasonInput placeholder="  Write a reason for 5 star here" />
        //     <AddReasonButton>Add</AddReasonButton>
        //   </ReasonInputButtonWrap>
        //   {getRatingList.map(index => (
        //     <div key={shortid.generate()}>
        //       <ReasonEditInputWrap>
        //         <ReasonLabel>Reason 1</ReasonLabel>
        //         <EditInputWrap>
        //           <ReasonEditInput
        //             placeholder="  Content"
        //             value={index[5][0]}
        //           />
        //           <EditRemoveButtonWrap>
        //             <EditButton onClick={this.openPopup}>Edit</EditButton>
        //             <RemoveButton>Remove</RemoveButton>
        //           </EditRemoveButtonWrap>
        //         </EditInputWrap>
        //       </ReasonEditInputWrap>
        //       <ReasonEditInputWrap>
        //         <ReasonLabel>Reason 2</ReasonLabel>
        //         <EditInputWrap>
        //           <ReasonEditInput
        //             placeholder="  Content"
        //             value={index[5][1]}
        //           />
        //           <EditRemoveButtonWrap>
        //             <EditButton onClick={this.openPopup}>Edit</EditButton>
        //             <RemoveButton>Remove</RemoveButton>
        //           </EditRemoveButtonWrap>
        //         </EditInputWrap>
        //       </ReasonEditInputWrap>
        //       <ReasonEditInputWrap>
        //         <ReasonLabel>Reason 3</ReasonLabel>
        //         <EditInputWrap>
        //           <ReasonEditInput
        //             placeholder="  Content"
        //             value={index[5][2]}
        //           />
        //           <EditRemoveButtonWrap>
        //             <EditButton onClick={this.openPopup}>Edit</EditButton>
        //             <RemoveButton>Remove</RemoveButton>
        //           </EditRemoveButtonWrap>
        //         </EditInputWrap>
        //       </ReasonEditInputWrap>
        //     </div>
        //   ))}
        //   {/* <ReasonEditInputWrap>
        //     <ReasonLabel>Reason 1</ReasonLabel>
        //     <EditInputWrap>
        //       <ReasonEditInput placeholder="  Content" />
        //       <EditRemoveButtonWrap>
        //         <EditButton>Edit</EditButton>
        //         <RemoveButton>Remove</RemoveButton>
        //       </EditRemoveButtonWrap>
        //     </EditInputWrap>
        //   </ReasonEditInputWrap>
        //   <ReasonEditInputWrap>
        //     <ReasonLabel>Reason 2</ReasonLabel>
        //     <EditInputWrap>
        //       <ReasonEditInput placeholder="  Content" />
        //       <EditRemoveButtonWrap>
        //         <EditButton>Edit</EditButton>
        //         <RemoveButton>Remove</RemoveButton>
        //       </EditRemoveButtonWrap>
        //     </EditInputWrap>
        //   </ReasonEditInputWrap> */}
        // </StarRatingWrap>
        null}
      </FullWrap>
    );
  }
}

export default Ratings;
