import React from "react";
import {Bar} from "react-chartjs-2";
import styled from "styled-components";

const ChartDataWrap = styled.div`
  /* height: 50px;*/

  width: 300px;
  padding-left: 10px;
  margin-bottom: 20px;
  margin-right: 20px;
`;

const options = {
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        // display: false,
        ticks: {
          fontSize: 9,
          // display: false,
        },
        barPercentage: 0.5,
        gridLines: {
          // color: "white",
          display: false,
          // drawBorder: false,
        },
      },
    ],
    xAxes: [
      {
        // display: false,
        ticks: {
          fontSize: 9,
        },
        barPercentage: 0.4,
        gridLines: {
          // color: "white",
          display: false,
          // drawBorder: false,
        },
      },
    ],
  },
};

class ExpertBarChart extends React.Component {
  state = {
    id: "",
  };

  render() {
    const {expertBarchartDet} = this.props;

    const Bardata = {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],

      datasets: [
        {
          // labels: ["20", "40", " 60", 80, 100, 120, 140, 160, 180, 200],
          backgroundColor: "#25bb80",
          hoverBackgroundColor: "#25bb80",
          hoverBorderColor: "#25bb80",
          data: [
            expertBarchartDet.January ? expertBarchartDet.January : 0,
            expertBarchartDet.February ? expertBarchartDet.February : 0,
            expertBarchartDet.March ? expertBarchartDet.March : 0,
            expertBarchartDet.April ? expertBarchartDet.April : 0,
            expertBarchartDet.May ? expertBarchartDet.May : 0,
            expertBarchartDet.June ? expertBarchartDet.June : 0,
            expertBarchartDet.July ? expertBarchartDet.July : 0,
            expertBarchartDet.August ? expertBarchartDet.August : 0,
            expertBarchartDet.September ? expertBarchartDet.September : 0,
            expertBarchartDet.October ? expertBarchartDet.October : 0,
            expertBarchartDet.November ? expertBarchartDet.November : 0,
            expertBarchartDet.December ? expertBarchartDet.December : 0,
          ],
        },
      ],
    };

    return (
      <ChartDataWrap>
        <Bar data={Bardata} options={options} />
      </ChartDataWrap>
    );
  }
}

export default ExpertBarChart;
