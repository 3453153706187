import React from "react";
import styled from "styled-components";
import Modal from "react-modal";
import ReactTable from "react-table";
import "react-table/react-table.css";
import axios from "axios";
import NumberFormat from "react-number-format";
import moment from "moment";
import {apiURL, createBuffer, generateSHA2, searchInTable} from "../../utils/util";
import closebtn from "../AboutShawer/closebtn.png";

const FullTableWrap = styled.div`
  margin-left: 1.2em;

  margin-top: 2.5em;
  max-height: 100%;
  width: 1200px;
  background-color: #ffffff;
`;

const TableWrap = styled.div`
  margin-left: 20px;
  width: 1150px;
`;

const TableHeaderDiv = styled.div`
  /* text-align: left; */
  text-align: ${props => (props.center ? "center" : "left")};
`;

const Bold = styled.b`
  font-weight: bolder;
  color: black;
`;

const Label = styled.label``;

const CloseButton = styled.button`
  outline: 0;
  border: none;

  margin-left: 370px;
  margin-top: -10px;
`;

const CloseButtonImg = styled.img`
  height: 20px;
  width: 20px;
`;

const MoreReasonTitle = styled.h2`
  margin-top: 0px;
`;

const DetailsContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between;
  align-items: center; */
`;

const FilterInput = styled.input`
  ::-webkit-input-placeholder {
    font-size: 10px;
  }
`;

const MoreReasonPopupDiv = styled.div``;

const customStylesMore = {
  content: {
    height: "25%",
    width: "30%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    // border: "0px",
  },
};

const email = window.sessionStorage.getItem("email");
const password = window.sessionStorage.getItem("token");

class WithdrawalReactTable extends React.Component {
  state = {
    withdrawalTransactionList: [],
    selectedRefundTransactionDetails: null,
    openReasonMore: false,
    loading: true,
    // data: makeData(),
  };

  closeReasonMorePopup = this.closeReasonMorePopup.bind(this);

  // lifecycle methods

  componentDidMount() {
    this.getRefundTransactionDetails();
  }

  openReasonMorePopup = e => {
    // console.log("Target", e.target);
    const {index} = e.target.dataset;
    this.setState({
      openReasonMore: true,
      selectedRefundTransactionDetails: index,
    });
  };

  modalrender = () => {
    const {
      selectedRefundTransactionDetails,
      RefundTransactionList,
    } = this.state;
    // console.log("INDEX IS", selectedRefundTransactionDetails);
    // console.log("Details", RefundTransactionList);
    if (selectedRefundTransactionDetails !== null) {
      const RefundTransactionDetails =
        RefundTransactionList[selectedRefundTransactionDetails];
      // console.log("INDEX IS", selectedRefundTransactionDetails);
      // console.log(
      //   "Selected Details is",
      //   RefundTransactionDetails.user_refund_comment,
      // );
      return (
        <div>
          <CloseButton more onClick={this.closeReasonMorePopup}>
            <CloseButtonImg src={closebtn} />
          </CloseButton>
          <MoreReasonTitle>User Comment</MoreReasonTitle>
          <DetailsContentWrap>
            {RefundTransactionDetails.user_refund_comment}
          </DetailsContentWrap>
        </div>
      );
    }
    return null;
  };

  // api calls

  getRefundTransactionDetails = () => {
    const buffer = createBuffer({email});
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/transaction/WithdrawalTransaction`,
        {
          json_string: JSON.stringify({email}),
        },
        {headers: {signature, email, channel: "web"}},
      )

      // .post(`${apiURL}/transaction/WithdrawalTransaction`, {})

      .then(response => {
        // console.log(response);

        if (response.status === 200 && response.data) {
          // console.log(response.data);
          const {data} = response;
          // console.log("Data", data);

          this.setState({
            withdrawalTransactionList: data.content,
            loading: false,
          });
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };

  closeReasonMorePopup() {
    this.setState({openReasonMore: false});
  }

  render() {
    const {withdrawalTransactionList, openReasonMore, loading} = this.state;
    const columns = [
      {
        id: "initiateddate",
        Header: () => (
          <TableHeaderDiv center>
            <b>Initiated Date</b>
          </TableHeaderDiv>
        ),
        width: 120,
        accessor: d => {
          return moment(d.withdrawal_initiated_date)
            .local()
            .format("DD-MM-YYYY");
        },
        style: {textAlign: "center", color: "#4D4F5C"},
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search Initiated Date..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        sortMethod: (a, b) => {
          const splitdate1 = a.split("-");
          const splitdate2 = b.split("-");
          const date1 = new Date(
            splitdate1[2],
            splitdate1[1],
            splitdate1[0],
          ).getTime();
          const date2 = new Date(
            splitdate2[2],
            splitdate2[1],
            splitdate2[0],
          ).getTime();

          // const date2
          if (date1 > date2) return 1;
          return -1;
        },
        filterMethod: (filter, row) => searchInTable(filter, row),
      },
      {
        id: "acceptancedate",
        Header: () => (
          <TableHeaderDiv center>
            <b>Acceptance Date</b>
          </TableHeaderDiv>
        ),
        width: 130,

        accessor: d => {
          return moment(d.withdrawal_closed_date)
            .local()
            .format("DD-MM-YYYY");
        },

        style: {textAlign: "center", color: "#4D4F5C"},
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search Acceptance Date..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        sortMethod: (a, b) => {
          const splitdate1 = a.split("-");
          const splitdate2 = b.split("-");
          const date1 = new Date(
            splitdate1[2],
            splitdate1[1],
            splitdate1[0],
          ).getTime();
          const date2 = new Date(
            splitdate2[2],
            splitdate2[1],
            splitdate2[0],
          ).getTime();

          // const date2
          if (date1 > date2) return 1;
          return -1;
        },
        filterMethod: (filter, row) => searchInTable(filter, row),
      },

      {
        id: "withdrawalcomment",
        Header: () => (
          <TableHeaderDiv center>
            <b>Withdrawal Comment</b>
          </TableHeaderDiv>
        ),
        width: 220,

        accessor: d => (d.withdrawal_comment ? d.withdrawal_comment : "-"),
        style: {color: "#4D4F5C"},
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search Comment..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        filterMethod: (filter, row) => searchInTable(filter, row),
      },

      {
        id: "expertname",
        width: 170,
        Header: () => (
          <TableHeaderDiv center>
            <b>Expert Name</b>
          </TableHeaderDiv>
        ),
        // Header: "User Name",
        // accessor: "username",
        accessor: d => (d.expert_name ? d.expert_name : "-"),
        style: {color: "#4D4F5C"},
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search Expert Name..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        filterMethod: (filter, row) => searchInTable(filter, row),
      },
      {
        id: "amount",
        width: 100,
        Header: () => (
          <TableHeaderDiv center>
            <b>Amount</b>
          </TableHeaderDiv>
        ),
        // Header: "Amounts",
        // accessor: "amount",
        accessor: d => d.withdrawal_amount,
        style: {textAlign: "center", color: "#4D4F5C"},
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search Amount..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        Cell: props => {
          // console.log(props);
          return (
            <span>
              {props.value ? (
                <NumberFormat
                  value={props.value}
                  decimalScale={2}
                  displayType="text"
                />
              ) : (
                0
              )}
            </span>
          );
        },
      },

      {
        id: "bankdetails",

        Header: () => (
          <TableHeaderDiv center>
            <b>Bank Details</b>
          </TableHeaderDiv>
        ),
        accessor: d => d.bank_detail[0].bank_name,
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search Bank Name..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        Cell: props => {
          // console.log(props.original.bank_detail[0]);
          return (
            <div>
              <Label>
                <Bold>Bank Name :</Bold>{" "}
                {props.original.bank_detail[0].bank_name}
              </Label>
              <br />
              <Label>
                <Bold>Iban No. : </Bold>
                {props.original.bank_detail[0].iban}
              </Label>
              <br />
              <Label>
                <Bold>Full Name : </Bold>{" "}
                {props.original.bank_detail[0].full_name}
              </Label>
              <br />
              <Label>
                <Bold> Nick Name : </Bold>{" "}
                {props.original.bank_detail[0].nick_name}
              </Label>
            </div>
          );
        },
        filterMethod: (filter, row) => searchInTable(filter, row),
        style: {color: "#4D4F5C"},
      },

      {
        id: "status",
        width: 100,
        Header: () => (
          <TableHeaderDiv>
            <b>Status</b>
          </TableHeaderDiv>
        ),
        // Header: "Status",
        // accessor: "status",
        style: {color: "#4D4F5C"},
        accessor: d =>
          d.withdrawal_status === "initiated"
            ? "Initiated"
            : d.withdrawal_status === "done"
            ? "Accepted"
            : d.withdrawal_status === "rejected"
            ? "Rejected"
            : null,
        // style: {textAlign: "center"},
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search Status..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        filterMethod: (filter, row) => searchInTable(filter, row),
      },
    ];
    return (
      <FullTableWrap>
        <TableWrap>
          <ReactTable
            noDataText={loading ? "" : "No Rows Found"}
            loading={loading}
            loadingText="Loading..."
            filterable
            columns={columns}
            data={withdrawalTransactionList}
            defaultPageSize={10}
            defaultSorted={[
              {
                id: "initiateddate",
                desc: true,
              },
            ]}
          />
        </TableWrap>

        <Modal
          isOpen={openReasonMore}
          onRequestClose={this.closeReasonMorePopup}
          style={customStylesMore}
          ariaHideApp={false}
        >
          <MoreReasonPopupDiv>
            <div>{this.modalrender()}</div>
          </MoreReasonPopupDiv>
        </Modal>
      </FullTableWrap>
    );
  }
}

export default WithdrawalReactTable;
