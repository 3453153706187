import React from "react";
import styled from "styled-components";
import logoimg from "../LoginForm/shawerlogo.png";

const FullWrap = styled.div`
  background-image: linear-gradient(124deg, #01192e, #003c7c, #41ace4);
  height: 100vh;
`;

const Label = styled.label`
  color: white;
`;

const ContentWrap = styled.div`
  padding-top: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  //   border: 1px solid white;
  flex-direction: column;
  //   width: fit-content;
`;

const RowOne = styled.div`
  display: flex;
  width: 20%;

  justify-content: space-between;
`;
const LogoImage = styled.img`
  height: 60px;
`;
const RowOneLabel = styled.label`
  font-size: 50px;
  color: white;
`;

const RowTwo = styled.div`
  padding: 15px;
  border-bottom: 1px solid grey;
`;
const RowTwoContent = styled.label`
  color: #39a1dd;
  font-size: 35px;
`;

const RowThree = styled.div`
  display: flex;
  padding: 25px;
  width: 17%;
  display: flex;
  align-items: center;
  //   border: 1px solid white;
  justify-content: space-between;
`;

const RowThreeContentOne = styled.label`
  color: #b3b3b3;
  font-size: 20px;
`;
const RowThreeContentTwo = styled.label`
  color: #bab9b9;
  font-size: 35px;
`;

const ComingSoon = () => {
  return (
    <FullWrap>
      <ContentWrap>
        <RowOne>
          <LogoImage src={logoimg} />
          <RowOneLabel>شاور</RowOneLabel>
        </RowOne>
        <RowTwo>
          <RowTwoContent>ما خاب من استشار</RowTwoContent>
        </RowTwo>
        <RowThree>
          <RowThreeContentOne>Coming Soon</RowThreeContentOne>
          <RowThreeContentTwo>قريبا</RowThreeContentTwo>
        </RowThree>
      </ContentWrap>
    </FullWrap>
  );
};

export default ComingSoon;
