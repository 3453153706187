import cipher from "aes-ecb";
import React from "react";
// import styled, {keyframes} from "styled-components";
// Loader styles
import {FaSpinner} from "react-icons/fa";
import styles from "./loader.module.css";

// const spinner = keyframes`
// 0% {
//   transform: rotate(0deg);
// }
// 100% {
//   transform: rotate(360deg);
// }`;

// const SpinnerDiv = styled.div`
//   margin-left: 10px;
//   animation: ${spinner} 1s ease-in-out infinite;
// `;

/* 
  local dev env - 
  public env - https://shawer-dashboard.herokuapp.com
*/
// export const apiURL = "http://192.168.0.145:5000";

// export const apiURL = "http://18.196.131.206:5000";
// export const apiURL = "http://192.168.1.9:5000";
// export const apiURL = "http://192.168.0.177:3000";
// export const apiURL = "http://192.168.1.5:3000";
// export const apiURL = "http://192.168.1.4:5000";
// export const apiURL = "http://192.168.1.2:3000";
// export const apiURL = "http://192.168.1.7:3000";
// export const apiURL = "http://192.168.1.10:3000";
// export const apiURL = "http://52.59.228.65";

// export const apiURL = "https://shawer-dashboard.herokuapp.com";
export const apiURL = "https://api.shawer.sa";
// export const apiURL = "http://localhost:5000"

// create buffer
export const createBuffer = body => {
  const encodeBuffer = Buffer.from(JSON.stringify(body));
  return encodeBuffer;
};

// generate SHA2
export const generateSHA2 = (encodeBuffer, password) => {
  return cipher.encrypt(password, encodeBuffer);
};

// loader spinner
export const Spinner = props => (
  // <SpinnerDiv>
  <FaSpinner className={styles.spinner} aria-label="Loading" {...props} />
  // </SpinnerDiv>
);

export const searchInTable = (filter, row) => {
  return row[filter.id] && row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
}

export const searchInTableExactMatch = (filter, row) => {
   return row[filter.id] && row[filter.id].toLowerCase() == filter.value.toLowerCase();
}
 
export const searchInTableForNumber = (filter, row) => {
  return row[filter.id] && row[filter.id] <= Number(filter.value);
}

export const sortByDate = (d1, d2) => {
  let dateA = 0;
  let dateB = 0;
  if(d1 != '-'){
    const splitdate1 = d1.split("-");
    dateA = new Date(
      splitdate1[2],
      splitdate1[1],
      splitdate1[0],
    ).getTime();
  }
  if(d2 != '-'){
    const splitdate2 = d2.split("-");
    dateB = new Date(
      splitdate2[2],
      splitdate2[1],
      splitdate2[0],
    ).getTime();  
  }
  return dateA < dateB ? -1 : 1;
}