import React from "react";
import styled from "styled-components";
import axios from "axios";
import NavigationBar from "../NavigationBar/NavigationBar";
import SideBar from "../NavigationBar/SideBar";
import ChatReactTable from "./ChatReactTable";

// import chathistoryicon from "./chatHistoryIcon.png";
import NotAccessiblePNG from "../Common/NotAccessiblePNG.png";

// api URL
import {apiURL, createBuffer, generateSHA2} from "../../utils/util";

const FullSection = styled.section`
  background-color: #fafbfc;
  display: grid;
  grid-template-rows: 1fr auto;
  max-height: 100%;

  /* grid-template-columns: auto auto; */
`;
const LabelInputWrap = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 4.5em;
  margin-left: 4.5em;
  margin-top: 0.5em;
`;

const ChatListLabel = styled.h2`
  font-size: 17px;
  color: #1a173b;
`;

const ColumnOneContentWrap = styled.div``;

const ColumnWrap = styled.div`
  display: flex;
  width: 100%;
`;

const NotAccessibleDiv = styled.div`
  /* margin-left: 100px; */
  /* margin-top: 100px; */
  padding-top: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  height: 100%;
  width: 100%;
`;

const NotAccessibleImg = styled.img``;

const NotAccessibleLabel = styled.h2``;

const email = window.sessionStorage.getItem("email");
const password = window.sessionStorage.getItem("token");

class Chats extends React.Component {
  state = {
    chatHistoryList: [],
    typeofuser: ["superadmin", "superreader"],
    isAuthourized: false,
    loading: true,
  };

  // lifecycle methods

  componentDidMount() {
    this.userData();
    this.getChatDetails();
  }
  // api calls

  getChatDetails = () => {
    const buffer = createBuffer({email});
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/chathistory/ListOfChatCompleted`,
        {
          json_string: JSON.stringify({email}),
        },
        {headers: {signature, email, channel: "web"}},
      )

      // .post(`${apiURL}/chathistory/ListOfChatCompleted`, {})
      .then(response => {
        if (response.status === 200 && response.data) {
          const {data} = response;
          this.setState({chatHistoryList: data.content, loading: false});
        }
      })
      .catch(error => {});
  };

  userData = () => {
    let {isAuthourized, typeofuser} = this.state;
    const role = sessionStorage.getItem("typeofuser");
    isAuthourized = typeofuser.includes(role);
    this.setState({isAuthourized});
  };

  render() {
    const {chatHistoryList, isAuthourized, loading} = this.state;
    const role = sessionStorage.getItem("typeofuser");
    return (
      <FullSection>
        <NavigationBar />
        <SideBar />
        {isAuthourized ? (
          <ColumnWrap>
            <ColumnOneContentWrap>
              <LabelInputWrap>
                <ChatListLabel>Chat Data Management</ChatListLabel>
              </LabelInputWrap>
              {/* <ChatTable /> */}
              <ChatReactTable
                chatHistoryList={chatHistoryList}
                loading={loading}
              />
            </ColumnOneContentWrap>
          </ColumnWrap>
        ) : (
          <NotAccessibleDiv>
            <NotAccessibleLabel>
              Sorry you’re a{" "}
              {role === "contentmanager"
                ? "Content Manager"
                : role === "approvalmanager"
                ? "Approval Manager"
                : role === "financialmanager"
                ? "Financial Manager"
                : null}
              . Please contact the Super Admin to access this area.
            </NotAccessibleLabel>
            <NotAccessibleImg alt="Not accessible" src={NotAccessiblePNG} />
          </NotAccessibleDiv>
        )}
      </FullSection>
    );
  }
}

export default Chats;
