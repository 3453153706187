import React from "react";
import styled from "styled-components";
import axios from "axios";
import {SmartToaster, toast} from "react-smart-toaster";
import shortid from "shortid";
import {withFormik, Form, Field, FieldArray, Formik} from "formik";
import * as Yup from "yup";

// api URL
import {apiURL, generateSHA2, createBuffer} from "../../utils/util";

const FullWrap = styled.div`
  width: 950px;
  /* border: 1px solid black; */
  /* display: flex;
  flex-wrap: wrap; */
  margin-top: 2em;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.23);
  background-color: #ffffff;
  margin-left: 1em;
  border-radius: 6px;
`;
const RatingTitle = styled.h2`
  font-size: 17px;
  margin-left: 20px;
  margin-top: 20px;
`;

const TabOneButton = styled.button`
  height: 30px;
  font-size: 15px;
  border: none;
  display: flex;
  outline: 0;
  font-weight: ${props => (props.isActive ? "700" : "")};
  border-bottom: ${props => (props.isActive ? "2px solid #e54666" : "")};
  align-items: center;
`;

const TabTwoButton = styled.button`
  height: 30px;
  font-size: 15px;
  border: none;
  display: flex;
  border-bottom: ${props => (props.isActive ? "2px solid #0052a9" : "")};
  align-items: center;
  outline: 0;
`;

const TabOne = styled.div`
  margin-top: 20px;
  margin-left: 20px;
  width: 550px;
  display: flex;
  justify-content: space-between;
`;

const TabTwo = styled.div`
  margin-top: 20px;

  width: 400px;
  display: flex;
  justify-content: space-between;
`;

const InputButtonWrap = styled.form`
  margin-top: 20px;
  width: 530px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AddInput = styled(Field)`
  width: 400px;
  height: 35px;
  border-radius: 4px;
  border: solid 1px #e9eff4;
  border-bottom: solid 1px #e9eff4;
`;

const AddError = styled.span`
  font-size: 12px;
  color: red;
`;

const InputErrorSpan = styled.div`
  display: flex;
  flex-direction: column;
`;

const AddReasonButton = styled.button`
  width: 100px;
  height: 30px;
  border-radius: 2px;
  border: solid 1px rgba(77, 161, 255, 0.7);
  background-color: #39a1dd;
  color: #fff;
`;

const QualificationDetailsDiv = styled.div``;

const SearchEditWrap = styled.div`
  width: 550px;
  border: 1px solid #e9eff4;
  border-radius: 4px;
  margin-top: 20px;
  border-bottom: none;
`;

const SearchWrap = styled.div``;

const Wrap = styled.div`
  padding: 20px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid #e9eff4;
`;

const EditRemoveButtonWrap = styled.div`
  width: 130px;
  display: flex;
  justify-content: space-between;
`;

const EditButton = styled.button`
  border: none;
  color: #39a1dd;
  font-size: 14px;
  cursor: pointer;
  outline: 0;
`;

const RemoveButton = styled.button`
  border: none;
  color: #d0021b;
  font-size: 14px;
  cursor: pointer;
  outline: 0;
`;

const FormField = styled(Field)`
  position: ${props => (props.absolute ? "absolute" : "")};
  width: 100%;
  /* border: solid 1px #d0d0d0; */
  border: none;
  text-indent: 15px;
  padding: 10px 10px 10px 5px;
  font-size: 17px;
  border-radius: 4px;
  background-color: #ffffff;
  opacity: 0.5;
  outline: none;
  ::-webkit-input-placeholder {
    font-family: Ubuntu;
    /* font-size: 20px; */
    font-weight: 300;
    color: #0d3868;
    opacity: 0.5;
    padding: 15px;
    /* text-align: left; */
  }
`;

const email = window.sessionStorage.getItem("email");
const password = window.sessionStorage.getItem("token");

class QualificationDetails extends React.Component {
  state = {
    // getProfileList: [],
    list_qualification_degree: [],
    // getProfileList: {
    //   qualification: [],
    // },
    fieldOfStudy: [],
    school: [],
    isFieldEnable: false,
    currentEditing: -1,
  };

  // lifecycle methods

  componentDidMount() {
    this.getExpertProfileDetails();
  }

  // handleOnChange = (e, index) => {
  //   const {qualification} = this.state;

  //   const editData = qualification[index];

  //   console.log("edit data", editData);

  //   // console.log(editData);
  //   const {value} = e.target;
  //   const {name} = e.target.dataset;
  //   const tempObj = {
  //     [name]: value,
  //   };

  //   const updatedObject = Object.assign(editData, tempObj);

  //   console.log("updated obj", updatedObject);

  //   const tempArrayData = {
  //     data: updatedObject,
  //   };

  //   const updatedArrayData = Object.assign(qualification[index], tempArrayData);

  //   qualification[index] = updatedArrayData;

  //   console.log("Updated qualification", qualification);

  //   this.setState({
  //     qualification,
  //   });
  // };

  //

  // ---------(ArrayHelpers)------

  handleOnChange = (e, index) => {
    const {list_qualification_degree} = this.state;

    // const editData = list_qualification_degree[index];

    // console.log("edit data", editData);

    const {value} = e.target;
    const {name} = e.target.dataset;

    list_qualification_degree[index] = value;

    this.setState({
      list_qualification_degree,
    });
  };

  deleteElementFromArray = e => {
    const {index} = e.target.dataset;

    const {list_qualification_degree} = this.state;

    list_qualification_degree.splice(index, 1);

    this.updateQualificationInfo();

    // this.getExpertProfileDetails();
  };

  // Adding value to Array

  onChangeValue = values => {
    console.log("values qualification", values);
    const {qualificationName} = values;
    this.setState({value: qualificationName});
  };

  onClickAdd = values => {
    const {qualificationName} = values;
    const {list_qualification_degree} = this.state;

    list_qualification_degree.push(qualificationName);
    this.setState({list_qualification_degree});

    this.updateQualificationInfo();
  };

  // updating value to array

  updateQualificationInfo = async index => {
    const {list_qualification_degree} = this.state;
    // console.log("Updated details", list_qualification_degree);

    const buffer = createBuffer({
      email,
      list_qualification_degree,
      label: "list_qualification_degree",
    });
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/aboutshawer/UpdateExpertProfile`,
        {
          json_string: JSON.stringify({
            email,
            list_qualification_degree,
            label: "list_qualification_degree",
          }),
        },
        {headers: {signature, email, channel: "web"}},
      )

      // await axios
      //   .post(`${apiURL}/aboutshawer/UpdateExpertProfile`, {
      //     list_qualification_degree,
      //     label: "list_qualification_degree",
      //   })
      .catch(error => {});

    // axios
    //   .post(`${apiURL}/aboutshawer/UpdateExpertProfile`, {
    //     list_qualification_degree,
    //     label: "list_qualification_degree",
    //   })

    //   .then(response => {
    //     console.log(response);
    //     this.getExpertProfileDetails();
    //   })
    //   .catch(error => {
    //     console.log(error);
    //   });
    this.setState({currentEditing: -1});
  };

  // handleOnButtonClick = () => {
  //   this.setState({isFieldEnable: true});
  // };

  handleOnButtonClick = index => {
    this.setState({currentEditing: index});
  };

  // api calls

  getExpertProfileDetails = () => {
    const buffer = createBuffer({email});
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/aboutshawer/GetExpertProfile`,
        {
          json_string: JSON.stringify({email}),
        },
        {headers: {signature, email, channel: "web"}},
      )

      // axios
      //   .post(`${apiURL}/aboutshawer/GetExpertProfile`, {})

      .then(response => {
        if (response.status === 200 && response.data) {
          const {data} = response;
          // console.log("Expert Profile Details", data);

          this.setState({
            list_qualification_degree: data.content.list_qualification_degree,
          });
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };

  render() {
    const {
      qualification,
      list_qualification_degree,
      isFieldEnable,
      currentEditing,
    } = this.state;

    return (
      <QualificationDetailsDiv>
        <SmartToaster store={toast} lightBackground position="top_center" />
        {/* <InputButtonWrap>
          <AddInput
            onChange={this.onChangeValue}
            placeholder="  Write a Qualification here"
          />
          <AddReasonButton onClick={this.onClickRatingAdd}>Add</AddReasonButton>
        </InputButtonWrap> */}

        <Formik
          initialValues={{qualificationName: ""}}
          validationSchema={Yup.object().shape({
            qualificationName: Yup.string()
              .required("Please Enter Qualification Name")
              .matches(
                /^((?!(\*\/|\/\*|--|<|>)).)*$/,
                "Should not contain following patterns - '/*','*/', '--', '>','<'",
              )
              .matches(/\S+/, "Please Enter Valid Input"),
          })}
          onSubmit={values => {
            this.onClickAdd(values);
          }}
        >
          {props => {
            const {touched, errors, handleSubmit} = props;
            return (
              <InputButtonWrap onSubmit={handleSubmit}>
                <InputErrorSpan>
                  <AddInput
                    id="qualificationName"
                    name="qualificationName"
                    placeholder="Enter Qualification here"
                    type="text"
                  />
                  {console.log("props", props)}
                  {errors.qualificationName && touched.qualificationName && (
                    <AddError>{errors.qualificationName}</AddError>
                  )}
                </InputErrorSpan>
                <AddReasonButton type="submit">Add</AddReasonButton>
              </InputButtonWrap>
            );
          }}
        </Formik>

        <SearchEditWrap>
          <SearchWrap>
            {/* <SerachInput placeholder="Search Qualification" /> */}
          </SearchWrap>

          {/* ---------Formik----------- */}
          <div>
            <Formik
              enableReinitialize
              initialValues={{qualificationDetails: list_qualification_degree}}
              render={({values}) => (
                <Form>
                  <FieldArray
                    // rerenderOnEveryChange
                    name="qualificationDetails"
                    render={({arrayHelpers, remove}) => (
                      <React.Fragment>
                        {values.qualificationDetails.map(
                          (qualDetail, index) => (
                            <Wrap key={index}>
                              {/* <Wrap key={shortid.generate()}> */}
                              {/* {console.log(arrayHelpers)} */}
                              <FormField
                                type="text"
                                placeholder="Write here..."
                                // value={qualification[index]}
                                name={`qualificationDetails[${index}]`}
                                data-name={qualDetail}
                                data-index={index}
                                disabled={index !== currentEditing}
                                onChange={
                                  index === currentEditing
                                    ? e => this.handleOnChange(e, index)
                                    : null
                                }
                              />
                              <EditRemoveButtonWrap>
                                <EditButton
                                  type="button"
                                  data-index={index}
                                  // onClick={this.handleOnButtonClick}
                                  onClick={() => {
                                    this.handleOnButtonClick(index);
                                  }}
                                >
                                  Edit
                                </EditButton>
                                <RemoveButton
                                  type="button"
                                  data-index={index}
                                  // onClick={() => remove(index)}
                                  // onClick={e => {
                                  //   const myIndex = e.target.dataset.index;
                                  //   arrayHelpers.remove(myIndex);
                                  // }}
                                  onClick={() => {
                                    this.updateQualificationInfo(index);
                                    toast.info("Data Updated!");
                                  }}
                                >
                                  Save
                                </RemoveButton>
                                <RemoveButton
                                  type="button"
                                  data-index={index}
                                  // onClick={() => remove(index)}
                                  onClick={e => {
                                    this.deleteElementFromArray(e);
                                    toast.info("Data Deleted!");
                                  }}
                                  // onClick={() =>
                                  //   this.updateQualificationInfo(index)
                                  // }
                                >
                                  Remove
                                </RemoveButton>
                              </EditRemoveButtonWrap>
                            </Wrap>
                          ),
                        )}
                      </React.Fragment>
                    )}
                  />
                </Form>
              )}
            />
          </div>

          {/* --------- //Formik----------- */}

          {/* 
        {Object.keys(getProfileList).map(key => {
          return (
            <div key={key}>
              {getProfileList[key].map(item => {
                console.log("item", item);
                return (
                  <Wrap key={shortid.generate()}>

                    {console.log(
                      "qualification degree",
                      item.list_qualification_degree,
                    )}
                    <SearchInputLabel>
                      {item.list_qualification_degree}
                    </SearchInputLabel>
                    <EditRemoveButtonWrap>
                      <EditButton>Edit</EditButton>
                      <RemoveButton>Remove</RemoveButton>
                    </EditRemoveButtonWrap>
                  </Wrap>
                );
              })}
            </div>
          );
        })} */}

          {/* {getProfileList.map(index => (
          <Wrap key={shortid.generate()}>
            {console.log(
              "qualification degree",
              index.list_qualification_degree,
            )}
            <SearchInputLabel>
              {index.list_qualification_degree}
            </SearchInputLabel>
            <EditRemoveButtonWrap>
              <EditButton>Edit</EditButton>
              <RemoveButton>Remove</RemoveButton>
            </EditRemoveButtonWrap>
          </Wrap>
        ))} */}

          {/* <Wrap>
          <SearchInputLabel>Master in Communication</SearchInputLabel>
          <EditRemoveButtonWrap>
            <EditButton>Edit</EditButton>
            <RemoveButton>Remove</RemoveButton>
          </EditRemoveButtonWrap>
        </Wrap>
        <Wrap>
          <SearchInputLabel>Master in Communication</SearchInputLabel>
          <EditRemoveButtonWrap>
            <EditButton>Edit</EditButton>
            <RemoveButton>Remove</RemoveButton>
          </EditRemoveButtonWrap>
        </Wrap> */}
        </SearchEditWrap>
      </QualificationDetailsDiv>

      // <FullWrap>
      //   {/* ---------Formik----------- */}
      //   <div>
      //     <Formik
      //       enableReinitialize
      //       initialValues={{qualificationDetails: qualification}}
      //       render={({values}) => (
      //         <Form>
      //           <FieldArray
      //             // rerenderOnEveryChange
      //             name="qualificationDetails"
      //             render={arrayHelpers => (
      //               <React.Fragment>
      //                 {values.qualificationDetails.map((qualDetail, index) => (
      //                   // <Wrap key={qualDetail.education_id}>
      //                   <Wrap key={shortid.generate()}>
      //                     <FormField
      //                       type="text"
      //                       placeholder="Write here..."
      //                       value={qualification[index]}
      //                       name={`qualificationDetails[${index}]`}
      //                       data-name=""
      //                       data-index={index}
      //                     />
      //                     <EditRemoveButtonWrap>
      //                       <EditButton>Edit</EditButton>
      //                       <RemoveButton>Remove</RemoveButton>
      //                     </EditRemoveButtonWrap>
      //                   </Wrap>
      //                 ))}
      //               </React.Fragment>
      //             )}
      //           />
      //         </Form>
      //       )}
      //     />
      //   </div>
      // </FullWrap>
    );
  }
}

export default QualificationDetails;
