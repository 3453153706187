import styled from "styled-components";

export const Button = styled.button`
  width: ${props => (props.medium ? 150 : 200)}px;
  padding: 0.8em;
  color: white;
  margin-right:15px;
  align-self: ${props => (props.medium ? "center" : "flex-end")};
  background-color: #0d3868;
  /* background-color: ${props => (props.medium ? "#0d3868" : "none")}; */
  font-size: 18px;
  margin-top: ${props => (props.medium ? 3 : 0)}em;
  cursor: pointer;

`;

export const CancelButton = styled.button`
  width:  200px;
  padding: 0.8em;
  color: #0d3868;
  cursor: pointer;
  margin-right:15px;
  align-self: ${props => (props.medium ? "center" : "flex-end")};
  background-color: none;
  border:1px solid #0d3868;
  /* background-color: ${props => (props.medium ? "#0d3868" : "none")}; */
  font-size: 18px;
  margin-top: ${props => (props.medium ? 3 : 0)}em;
  
`;
