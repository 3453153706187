import React from "react";
import styled from "styled-components";
import axios from "axios";
import DatePicker from "react-date-picker";
// import DatePicker from "react-date-picker/dist/entry.nostyle";
import {withFormik, Form, Field, FieldArray, Formik} from "formik";
import "../Common/datetimepicker.css";
import fileicon from "../Common/fileicon.png";
import okbtn from "../Common/okbutton.png";
import notokbtn from "../Common/notok.png";

// api URL
import {apiURL} from "../../utils/util";

const FullWrap = styled.div`
  width: 950px;
  /* border: 1px solid black; */
  display: flex;
  flex-wrap: wrap;
  margin-top: 2em;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.23);
  background-color: #ffffff;
  margin-left: 1em;
  border-radius: 6px;
`;
const TwoFieldWrap = styled.div`
  display: flex;
  max-width: 700px;
`;
const ThreeFieldWrap = styled.div`
  display: flex;
`;

const LableInput = styled.div`
  margin: 20px;
  width: ${props => (props.full ? 640 : 270)}px;
`;
const ImageInput = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
  width: 200px;
`;

const Label = styled.label`
  font-size: 12px;
  color: #323c47;
`;
const ImgLabelWrap = styled.div`
  margin-top: 10px;
  display: flex;
`;

const Img = styled.img`
  padding-right: 1em;
`;
const ImgLabel = styled.label`
  width: 80px;
  text-align: center;
  /* border: 1px solid black; */
  padding-top: 3px;
`;

const FormField = styled(Field)`
  margin-top: 10px;
  position: ${props => (props.absolute ? "absolute" : "")};
  width: 100%;
  border: solid 1px #e9eff4;
  text-indent: 15px;
  padding: 10px;
  font-size: 17px;
  border-radius: 4px;
  background-color: #ffffff;
  opacity: 0.5;
  ::-webkit-input-placeholder {
    font-family: Ubuntu;
    /* font-size: 20px; */
    font-weight: 300;
    color: #0d3868;
    opacity: 0.5;
    padding: 15px;
    /* text-align: left; */
  }
`;

const ConfirmWrap = styled.div`
  display: flex;
  margin: 20px;
  max-width: 700px;
  justify-content: space-between;
`;
const ConfirmButton = styled.button`
  background-color: #ffffff;
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 17px;
  width: 150px;
  margin-right: 20px;
  height: 40px;
  text-decoration: ${props => (props.underline ? "underline" : "")};
  color: ${props => (props.underline ? "#39a1dd" : "")};
`;

const EditButton = styled.button`
  margin-left: 800px;
  margin-top: 20px;
  width: 100px;
  height: 40px;
  border-radius: 5px;
  background-color: #085db0;
  color: white;
  font-size: large;
`;
const FormWrap = styled.div`
  width: 900px;
  display: flex;
  flex-wrap: wrap;
`;
export default class QualificationDegreeForm extends React.Component {
  state = {
    startDate: new Date(),
    endDate: new Date(),
    expertID: sessionStorage.getItem("expertID"),
    education: [
      {
        education_id: "",
        data: {
          field_of_study: "",
          fileList: [],
          from_year: "",
          qualification_degree: "",
          school: "",
          to_year: "",
        },
      },
    ],
  };

  componentDidMount() {
    this.getParticularExpertDetails();
  }

  getParticularExpertDetails = () => {
    const {expertID} = this.state;
    axios
      .post(`${apiURL}/expert/getParticularExpert`, {
        docid: expertID,
      })
      // .post(`${apiURL}/expert/getParticularExpert`, {})
      // .post(`${apiURL}/expert/getParticularExpert`, {}, {params: {}})
      .then(response => {
        // console.log(response);
        if (response.status === 200 && response.data) {
          const {data} = response;
          // this.setState({education: data.education});
          // console.log(data);
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };

  handleStartChange = startDate => {
    this.setState({startDate});
  };

  handleEndChange = endDate => {
    this.setState({endDate});
  };

  render() {
    const {startDate, endDate, education} = this.state;
    const {values} = this.props;
    return (
      <div />
      // <Formik
      // render={({values})=> (
      // <Form>
      //   <FieldArray name="educationdetails" render={arrayHelpers=>(
      //           <div>

      //           </div>
      //   )}
      //   />
      //  </Form>
      // )   }
    );
  }
}

//       <Form>
//         <FieldArray
//           name="educationdetails"
//           render={arrayHelpers => (
//             <FullWrap>
//               <EditButton>Edit</EditButton>
//               {values.educationdetails && values.educationdetails.length > 0
//                 ? values.educationdetails.map((educationdetail, {data}) => {
//                     console.log(data);
//                     return (
//                       <FormWrap key={data.education_id}>
//                         <TwoFieldWrap>
//                           <LableInput>
//                             <Label>QUALIFICATION DEGREE</Label>
//                             <FormField
//                               // name={`educationdetail.${
//                               //   data.qualification_degree
//                               // }`}
//                               name={`educationdetails[${data}].qualification_degree`}
//                               placeholder="Write here..."
//                               // type="degree"
//                               // name="qualification_degree"
//                               // value={education.qualification_degree}
//                             />
//                           </LableInput>

//                           <LableInput>
//                             <Label>FIELD OF STUDY</Label>
//                             <FormField
//                               placeholder="Write here..."
//                               type="fieldofstudy"
//                               name={`educationdetail.${data.field_of_study}`}
//                               value={education.field_of_study}
//                             />
//                           </LableInput>
//                         </TwoFieldWrap>

//                         <ThreeFieldWrap>
//                           <LableInput>
//                             <Label>SCHOOL</Label>
//                             <FormField
//                               placeholder="Write here..."
//                               type="school"
//                               name="school"
//                             />
//                           </LableInput>

//                           <LableInput>
//                             <Label>DURATION</Label>
//                             <DatePicker
//                               className="datePickerWidth"
//                               onChange={this.handleStartChange}
//                               value={startDate}
//                             />
//                           </LableInput>

//                           <LableInput>
//                             <Label>DURATION</Label>
//                             <DatePicker
//                               className="datePickerWidth"
//                               onChange={this.handleEndChange}
//                               value={endDate}
//                             />
//                           </LableInput>
//                         </ThreeFieldWrap>
//                         <ImageInput>
//                           <Label>ATTACHMENTS</Label>
//                           <ImgLabelWrap>
//                             <Img src={fileicon} />
//                             <ImgLabel>Certificate.pdf</ImgLabel>
//                           </ImgLabelWrap>
//                         </ImageInput>

//                         <ImageInput>
//                           <Label>ATTACHMENTS</Label>
//                           <ImgLabelWrap>
//                             <Img src={fileicon} />
//                             <ImgLabel>Certificate.pdf</ImgLabel>
//                           </ImgLabelWrap>
//                         </ImageInput>
//                       </FormWrap>
//                     );
//                   })
//                 : null}
//             </FullWrap>
//           )}
//         />
//         <ConfirmWrap>
//           <ConfirmButton>
//             <Img src={okbtn} />
//             Ok
//           </ConfirmButton>
//           <ConfirmButton>
//             <Img src={notokbtn} />
//             Not Ok
//           </ConfirmButton>
//           <ConfirmButton underline>+Add Comment</ConfirmButton>
//         </ConfirmWrap>
//       </Form>
//     );
//   }
// }
// const FormikFullForm = withFormik({
//   mapPropsToValues({degree, fieldofstudy}) {
//     return {
//       degree: degree || "",
//       fieldofstudy: fieldofstudy || "",
//     };
//   },
// })(QualificationDegreeForm);

// export default FormikFullForm;

{
  /* <Form>
          <FullWrap>
            <EditButton>Edit</EditButton>
            <FormWrap>
              <LableInput>
                <Label>INTEREST</Label>
                <FormField
                  placeholder="Computer Science"
                  type="interest"
                  name="interest"
                />
              </LableInput>

              <LableInput>
                <Label>SUB CATEGORY</Label>
                <FormField
                  placeholder="C++/C"
                  type="category"
                  name="category"
                />
              </LableInput>

              <LableInput>
                <Label>CHARGE/ MIN</Label>
                <div>
                  <ChargeField type="number" placeholder="20" name="phone" />
                  <ChargesSpan>SR</ChargesSpan>
                </div>
              </LableInput>

              <LableInput>
                <Label>CHARGE/ HOUR</Label>
                <div>
                  <ChargeField type="number" placeholder="40" name="charges" />
                  <ChargesSpan>SR</ChargesSpan>
                </div>
              </LableInput>
            </FormWrap>
          </FullWrap>
          <ConfirmWrap>
            <ConfirmButton>
              <Img src={okbtn} />
              Ok
            </ConfirmButton>
            <ConfirmButton>
              <Img src={notokbtn} />
              Not Ok
            </ConfirmButton>
            <ConfirmButton underline>+Add Comment</ConfirmButton>
          </ConfirmWrap>
        </Form> */
}

// class ExperienceForm extends React.Component {
//   state = {
//     startDate: new Date(),
//     endDate: new Date(),
//   };

//   handleStartChange = startDate => {
//     this.setState({startDate});
//   };

//   handleEndChange = endDate => {
//     this.setState({endDate});
//   };

//   render() {
//     const {startDate, endDate} = this.state;
//     return (
//       <Form>
//         <FullWrap>
//           <EditButton>Edit</EditButton>
//           <FormWrap>
//             <TwoFieldWrap>
//               <LableInput>
//                 <Label>COMPANY</Label>
//                 <FormField
//                   placeholder="Write here..."
//                   type="degree"
//                   name="degree"
//                 />
//               </LableInput>

//               <LableInput>
//                 <Label>TITLE</Label>
//                 <FormField
//                   placeholder="Write here..."
//                   type="fieldofstudy"
//                   name="fieldofstudy"
//                 />
//               </LableInput>
//             </TwoFieldWrap>

//             <ThreeFieldWrap>
//               <LableInput>
//                 <Label>LOCATION</Label>
//                 <FormField
//                   placeholder="Write here..."
//                   type="school"
//                   name="school"
//                 />
//               </LableInput>
//               {/* <LableInput>
//           <Label>Calendar</Label>
//           <FormField placeholder="Write here..." type="date" name="calendar" />
//         </LableInput> */}

//               <LableInput>
//                 <Label>DURATION</Label>
//                 <DatePicker
//                   className="datePickerWidth"
//                   onChange={this.handleStartChange}
//                   value={startDate}
//                 />
//               </LableInput>

//               <LableInput>
//                 <Label>DURATION</Label>
//                 <DatePicker
//                   className="datePickerWidth"
//                   onChange={this.handleEndChange}
//                   value={endDate}
//                 />
//               </LableInput>
//             </ThreeFieldWrap>
//             <ImageInput>
//               <Label>ATTACHMENTS</Label>
//               <ImgLabelWrap>
//                 <Img src={fileicon} />
//                 <ImgLabel>Certificate.pdf</ImgLabel>
//               </ImgLabelWrap>
//             </ImageInput>

//             <ImageInput>
//               <Label>ATTACHMENTS</Label>
//               <ImgLabelWrap>
//                 <Img src={fileicon} />
//                 <ImgLabel>Certificate.pdf</ImgLabel>
//               </ImgLabelWrap>
//             </ImageInput>
//             {/* <ConfirmWrap>
//               <ConfirmButton>
//                 <Img src={okbtn} />
//                 Ok
//               </ConfirmButton>
//               <ConfirmButton>
//                 <Img src={notokbtn} />
//                 Not Ok
//               </ConfirmButton>
//               <ConfirmButton underline>+Add Comment</ConfirmButton>
//             </ConfirmWrap> */}
//           </FormWrap>
//         </FullWrap>

//         <ConfirmWrap>
//           <ConfirmButton>
//             <Img src={okbtn} />
//             Ok
//           </ConfirmButton>
//           <ConfirmButton>
//             <Img src={notokbtn} />
//             Not Ok
//           </ConfirmButton>
//           <ConfirmButton underline>+Add Comment</ConfirmButton>
//         </ConfirmWrap>
//       </Form>
//     );
//   }
// }
// const FormikFullForm = withFormik({
//   mapPropsToValues({company, fieldofstudy}) {
//     return {
//       company: company || "",
//       title: fieldofstudy || "",
//     };
//   },
// })(ExperienceForm);

// export default FormikFullForm;
