import React from "react";
import styled from "styled-components";
import axios from "axios";
import {
  LoadScript,
  GoogleMap,
  Marker,
  StandaloneSearchBox,
  Autocomplete,
} from "@react-google-maps/api";
import Geocode from "react-geocode";

// import aeStyles from "./add-event.module.css";

// api URL
import {apiURL, createBuffer, generateSHA2} from "../../utils/util";

const AddInput = styled.input`
  /* width: 350px; */
  width: ${props => (props.large ? "750" : "350")}px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #e9eff4;
  border-bottom: solid 1px #e9eff4;
  /* margin-top: 15px; */
`;

const email = window.sessionStorage.getItem("email");
const password = window.sessionStorage.getItem("token");

Geocode.setApiKey("AIzaSyDBPy89k56BF78bGE87gpToolxhPE_y1Zo");
Geocode.enableDebug();

const libraries = ["places"];

class Map extends React.Component {
  state = {
    contactUs: {
      lat: "",
      long: "",
      selectedAddress: "",
    },
    currentLat: 0.0,
    currentLng: 0.0,
    selectedAddress: "",
  };

  searchInputRef = React.createRef();
  // markerRef = React.createRef();

  componentDidMount() {
    this.getContactUsDetails();

    // this.setState({
    //   currentLat: 51.51582399999999,
    //   currentLng: -0.13276380000002064,
    //   selectedAddress: ""
    // });
  }

  handleSearchLocationInputChange = () => {
    const {getLocationData} = this.props;
    const {contactUs} = this.state;
    // console.log(this.searchInputRef.getPlaces());
    const result = this.searchInputRef.getPlaces();
    // console.log("result..", result);
    const lat = result[0].geometry.location.lat();
    const lng = result[0].geometry.location.lng();
    const selectedAddress = result[0].formatted_address;
    const tempObj = {
      lat: Number.parseFloat(lat),
      long: Number.parseFloat(lng),
      address: selectedAddress,
    };

    const contactUsObj = contactUs;
    // console.log("Contact obj map", contactUsObj);

    const copyobj = Object.assign(contactUsObj, tempObj);
    //  console.log("copy obj map", copyobj);
    // this.setState({contactUs});
    // console.log("Updated Data map", contactUs);
    this.setState({
      // currentLat: Number.parseFloat(lat),
      // currentLng: Number.parseFloat(lng),
      // selectedAddress,
      contactUs,
    });

    // console.log("temp obj map", tempObj, lat, lng);
    getLocationData(tempObj);
  };

  getContactUsDetails = () => {
    const {contactUs} = this.state;

    const buffer = createBuffer({email});
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/aboutshawer/ContactUs`,
        {
          json_string: JSON.stringify({email}),
        },
        {headers: {signature, email, channel: "web"}},
      )
      // axios
      //   .post(`${apiURL}/aboutshawer/ContactUs`, {})

      .then(response => {
        if (response.status === 200 && response.data) {
          const {data} = response;

          //  console.log("All Details map", data.content);

          const contactus = data.content;
          // console.log("contactus map", contactus);
          this.setState({
            contactUs: contactus,
          });
        }
      })
      .catch(error => {
        // console.log(error);
      });
    // console.log("contactUs map", contactUs);
  };

  // handleMarkerDrag = () => {
  //   console.log(this.markerRef);

  //   // const internalLat = this.markerRef.internalPosition.lat();
  //   // const internallng = this.markerRef.internalPosition.lng();

  //   // console.log(
  //   //   `Internal Position is, Lat is ${internalLat} and Lng is ${internallng}`
  //   // );

  //   const markerLat = this.markerRef.position.lat();
  //   const markerLng = this.markerRef.position.lng();

  //   Geocode.fromLatLng(markerLat, markerLng)
  //     .then(response => {
  //       console.log(response);
  //       const result = response.results[0];
  //       console.log(response);
  //       const lat = result.geometry.location.lat;
  //       const lng = result.geometry.location.lng;
  //       const selectedAddress = result.formatted_address;

  //       console.table(lat, lng);
  //       console.log(selectedAddress);
  //       console.log(this);
  //       this.setState({
  //         currentLat: lat,
  //         currentLng: lng,
  //         selectedAddress
  //       });
  //       console.log(this.state);
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // };

  render() {
    const {currentLat, currentLng, selectedAddress, contactUs} = this.state;

    return (
      <div>
        <LoadScript
          id="google-maps-script-loader"
          googleMapsApiKey="AIzaSyDBPy89k56BF78bGE87gpToolxhPE_y1Zo"
          region="UK"
          libraries={libraries}
        >
          <StandaloneSearchBox
            onLoad={ref => (this.searchInputRef = ref)}
            onPlacesChanged={this.handleSearchLocationInputChange}
          >
            <AddInput
              type="text"
              placeholder="Search"
              large

              // value={selectedAddress}
              // onChange={this.handleSearchLocationInputChange}
            />
          </StandaloneSearchBox>
          <GoogleMap
            mapContainerStyle={{
              height: "350px",
              width: "750px",
            }}
            zoom={7}
            center={{
              lat: contactUs.lat,
              lng: contactUs.long,
              // lat: contactUs.location._lat,
              // lng: contactUs.location._lon,
            }}
          >
            <Marker
              // draggable
              // onLoad={(marker, ref) => {
              //   // console.log("marker: ", marker);
              //   this.markerRef = marker;
              // }}
              // onPositionChanged={this.handleMarkerDrag}
              position={{
                lat: contactUs.lat,
                lng: contactUs.long,
              }}
            />
          </GoogleMap>
        </LoadScript>
      </div>
    );
  }
}

export default Map;
