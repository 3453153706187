import React from "react";
import styled from "styled-components";
import app from "../../firebase";
import logo from "./logo.png";
import logout from "./logout.png";

const Navigation = styled.nav`
  /* display: grid;
  grid-column: 1/3; */
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1em;
  border: 1px solid lightcyan;
  background-image: linear-gradient(95deg, #01192e, #0052a9 54%, #5fd6ff);
`;
const FullWrap = styled.div`
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
`;
const LinkWrapDiv = styled.div`
  display: flex;
  align-items: center;
`;

const ImageLink = styled.link``;

const CircleDiv = styled.div`
  /* border-radius: 50%; */
  /* background-color: black; */
`;

const LogoutBtn = styled.button`
  font-size: 18px;
  color: white;
  cursor: pointer;
  text-decoration: none;
  outline: 0;
  background: none;
  border: none;
  /* margin-top: 25px; */
`;

const LogoutIMG = styled.img``;

const ImageLogo = styled.img``;

const LinkWrap = styled.div`
  margin-right: 3em;
  margin-left: 3em;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  /* border-bottom: 3px solid black; */
  /* position: absolute; */

  &:hover {
    border-bottom: 3px solid #e54666;
  }
`;

const Links = styled.a`
  font-size: 18px;
  color: white;
  cursor: pointer;
  text-decoration: none;
  /* position: relative; */

  /* &:active {
  border-bottom: 3px solid black;
  } */

  /* &:before {
    height: 0%;
    width: 100%;
    bottom: 0px;
  }

  &:hover::before {
    height: 5px;
  } */

  /* &:hover {
    text-decoration: underline; */
  /* } */
  /* &:before,
  &:after {
    content: "";
    position: absolute;
    transition: transform 0.5s ease;
  } */
  /* &:before {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    /* background: $hover; */
  /* transform: scaleX(0); */
  /* } */
  /* &:hover:before {
    transform: scaleX(1);
  } */

  /* &:hover {
    color: #53bfb5;
    background-size: 4px 50px;
  } */
`;

const NavigationBar = () => (
  <Navigation>
    <FullWrap>
      <Links href="/dashboard">
        <ImageLogo src={logo} />
      </Links>
      {/* <ImageLogo src={logo} /> */}
      <LinkWrapDiv>
        <LinkWrap>
          <Links href="/dashboard">Overview</Links>
        </LinkWrap>
        <LinkWrap>
          <Links href="/transactions">Transactions</Links>
        </LinkWrap>
        <LinkWrap>
          <Links href="/chats">Chats</Links>
        </LinkWrap>
        <LinkWrap>
          <Links href="/coupons">Coupons</Links>
        </LinkWrap>
        <LinkWrap>
          <Links href="/experts">Experts</Links>
        </LinkWrap>
        <LinkWrap>
          <Links href="/users">Users</Links>
        </LinkWrap>
        <LinkWrap>
          <Links href="/aboutshawer">About Shawer</Links>
        </LinkWrap>
      </LinkWrapDiv>
      <CircleDiv>
        {/* <LogoutBtn href="/">Logout</LogoutBtn> */}
        <LogoutBtn
          onClick={() => {
            app.auth().signOut();
            sessionStorage.clear();
          }}
        >
          <LogoutIMG src={logout} />
        </LogoutBtn>
      </CircleDiv>
      {/* <ImageLogo src={logo} /> */}
    </FullWrap>
  </Navigation>
);
export default NavigationBar;
