import React, {Component} from "react";
// import Chart from "react-apexcharts";
import styled from "styled-components";

/* eslint-disable*/

const BarWrap = styled.div``;

class DashboardBarChart extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const chartOptions = {
      options: {
        chart: {
          id: "basic-bar",
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
      },
      series: [
        {
          name: "series-1",
          data: [53, 70, 25, 30, 13, 29, 53, 70, 25, 30, 13, 29],
        },
      ],
    };

    const {options, series} = chartOptions;
    return (
      <Chart
        options={options}
        series={series}
        type="bar"
        width={500}
        height={350}
      />
    );
  }
}

export default DashboardBarChart;
