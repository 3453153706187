import React from "react";
import styled from "styled-components";
import axios from "axios";
import {SmartToaster, toast} from "react-smart-toaster";
import {Form, Field, FieldArray, Formik} from "formik";
import * as Yup from "yup";

// api URL
import {apiURL, generateSHA2, createBuffer} from "../../utils/util";

const InputButtonWrap = styled.form`
  margin-top: 20px;
  width: 530px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AddInput = styled(Field)`
  width: 400px;
  height: 35px;
  border-radius: 4px;
  border: solid 1px #e9eff4;
  border-bottom: solid 1px #e9eff4;
`;

const AddError = styled.span`
  font-size: 12px;
  color: red;
`;

const InputErrorSpan = styled.div`
  display: flex;
  flex-direction: column;
`;

const AddReasonButton = styled.button`
  width: 100px;
  height: 30px;
  border-radius: 2px;
  border: solid 1px rgba(77, 161, 255, 0.7);
  background-color: #39a1dd;
  color: #fff;
`;

const QualificationDetailsDiv = styled.div``;

const SearchEditWrap = styled.div`
  width: 550px;
  border: 1px solid #e9eff4;
  border-radius: 4px;
  margin-top: 20px;
  border-bottom: none;
`;

const SearchWrap = styled.div``;

const Wrap = styled.div`
  padding: 20px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid #e9eff4;
`;

const EditRemoveButtonWrap = styled.div`
  width: 130px;
  display: flex;
  justify-content: space-between;
`;

const EditButton = styled.button`
  border: none;
  color: #39a1dd;
  font-size: 14px;
  cursor: pointer;
  outline: 0;
`;

const RemoveButton = styled.button`
  border: none;
  color: #d0021b;
  font-size: 14px;
  cursor: pointer;
  outline: 0;
`;

const FormField = styled(Field)`
  position: ${props => (props.absolute ? "absolute" : "")};
  width: 100%;
  /* border: solid 1px #d0d0d0; */
  border: none;
  text-indent: 15px;
  padding: 10px 10px 10px 5px;
  font-size: 17px;
  border-radius: 4px;
  background-color: #ffffff;
  opacity: 0.5;
  outline: none;
  ::-webkit-input-placeholder {
    font-family: Ubuntu;
    /* font-size: 20px; */
    font-weight: 300;
    color: #0d3868;
    opacity: 0.5;
    padding: 15px;
    /* text-align: left; */
  }
`;

const email = window.sessionStorage.getItem("email");
const password = window.sessionStorage.getItem("token");

class FieldOfStudyDetails extends React.Component {
  state = {
    getProfileList: [],
    qualification: [],
    fieldOfStudy: [],
    currentEditing: -1,
    list_fields_of_study: [],
  };

  // lifecycle methods

  componentDidMount() {
    this.getExpertProfileDetails();
  }

  handleOnChange = (e, index) => {
    const {list_fields_of_study} = this.state;

    const {value} = e.target;
    const {name} = e.target.dataset;
    list_fields_of_study[index] = value;
    this.setState({
      list_fields_of_study,
    });
  };

  // Adding value to Array

  onChangeValue = e => {
    this.setState({value: e.target.value});
  };

  onClickAdd = values => {
    const {list_fields_of_study} = this.state;
    console.log("values", values);

    const {fieldOfStudyName} = values;

    list_fields_of_study.push(fieldOfStudyName);
    this.setState({list_fields_of_study});

    this.updateFieldOfStudyInfo();
  };

  // updating value to array

  updateFieldOfStudyInfo = async index => {
    const {list_fields_of_study} = this.state;

    // await axios.post(`${apiURL}/aboutshawer/UpdateFieldsOfStudy`, {
    //   list_fields_of_study,
    //   label: "list_fields_of_study",
    // });

    const buffer = createBuffer({
      email,
      list_fields_of_study,
      label: "list_fields_of_study",
    });
    const signature = generateSHA2(buffer, password);

    await axios
      .post(
        `${apiURL}/aboutshawer/UpdateFieldsOfStudy`,
        {
          json_string: JSON.stringify({
            email,
            list_fields_of_study,
            label: "list_fields_of_study",
          }),
        },
        {headers: {signature, email, channel: "web"}},
      )

      .then(response => {
        // this.getExpertProfileDetails();
      })
      .catch(error => {});
    this.setState({currentEditing: -1});
  };

  handleOnButtonClick = index => {
    this.setState({currentEditing: index});
  };

  deleteElementFromArray = e => {
    const {index} = e.target.dataset;

    const {list_fields_of_study} = this.state;

    list_fields_of_study.splice(index, 1);

    this.updateFieldOfStudyInfo();

    // this.getExpertProfileDetails();
  };

  // api calls

  getExpertProfileDetails = () => {
    const buffer = createBuffer({email});
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/aboutshawer/GetExpertProfile`,
        {
          json_string: JSON.stringify({email}),
        },
        {headers: {signature, email, channel: "web"}},
      )

      // axios
      //   .post(`${apiURL}/aboutshawer/GetExpertProfile`, {})

      .then(response => {
        if (response.status === 200 && response.data) {
          const {data} = response;

          this.setState({
            list_fields_of_study: data.content.list_fields_of_study,
          });
        }
      })
      .catch(error => {});
  };

  render() {
    const {list_fields_of_study, fieldOfStudy, currentEditing} = this.state;

    return (
      <QualificationDetailsDiv>
        <SmartToaster store={toast} lightBackground position="top_center" />
        {/* <InputButtonWrap>
          <AddInput
            onChange={this.onChangeValue}
            placeholder="  Write a Field Of Study here"
          />
          <AddReasonButton onClick={this.onClickAdd}>Add</AddReasonButton>
        </InputButtonWrap> */}

        <Formik
          initialValues={{fieldOfStudyName: ""}}
          validationSchema={Yup.object().shape({
            fieldOfStudyName: Yup.string()
              .required("Please Enter Field of Study")
              .matches(
                /^((?!(\*\/|\/\*|--|<|>)).)*$/,
                "Should not contain following patterns - '/*','*/', '--', '>','<'",
              )
              .matches(/\S+/, "Please Enter Valid Input"),
          })}
          onSubmit={values => {
            this.onClickAdd(values);
          }}
        >
          {props => {
            const {touched, errors, handleSubmit} = props;
            return (
              <InputButtonWrap onSubmit={handleSubmit}>
                <InputErrorSpan>
                  <AddInput
                    id="fieldOfStudyName"
                    name="fieldOfStudyName"
                    placeholder="Enter Field of Study here"
                    type="text"
                  />
                  {/* {console.log("props", props)} */}
                  {errors.fieldOfStudyName && touched.fieldOfStudyName && (
                    <AddError>{errors.fieldOfStudyName}</AddError>
                  )}
                </InputErrorSpan>
                <AddReasonButton type="submit">Add</AddReasonButton>
              </InputButtonWrap>
            );
          }}
        </Formik>

        <SearchEditWrap>
          <SearchWrap>
            {/* <SerachInput placeholder="Search Field Of Study" /> */}
          </SearchWrap>

          {/* ---------Formik----------- */}
          <div>
            <Formik
              enableReinitialize
              initialValues={{fieldOfStudyDetails: list_fields_of_study}}
              render={({values}) => (
                <Form>
                  {/* {console.log("Formil Val", values)} */}
                  <FieldArray
                    name="fieldOfStudyDetails"
                    render={({arrayHelpers, remove}) => (
                      <React.Fragment>
                        {values.fieldOfStudyDetails.map((fosDetail, index) => (
                          <Wrap key={index}>
                            <FormField
                              type="text"
                              placeholder="Write here..."
                              // value={index}
                              name={`fieldOfStudyDetails[${index}]`}
                              data-name={fosDetail}
                              data-index={index}
                              disabled={index !== currentEditing}
                              onChange={
                                index === currentEditing
                                  ? e => this.handleOnChange(e, index)
                                  : null
                              }
                            />
                            <EditRemoveButtonWrap>
                              <EditButton
                                type="button"
                                data-index={index}
                                onClick={() => {
                                  this.handleOnButtonClick(index);
                                }}
                              >
                                Edit
                              </EditButton>
                              <RemoveButton
                                type="button"
                                data-index={index}
                                onClick={() => {
                                  this.updateFieldOfStudyInfo(index);
                                  toast.info("Data Updated!");
                                }}
                              >
                                Save
                              </RemoveButton>
                              <RemoveButton
                                type="button"
                                data-index={index}
                                // onClick={() => remove(index)}
                                onClick={e => {
                                  this.deleteElementFromArray(e);
                                  toast.info("Data Deleted!");
                                }}
                                // onClick={() =>
                                //   this.updateFieldOfStudyInfo(index)
                                // }
                              >
                                Remove
                              </RemoveButton>
                            </EditRemoveButtonWrap>
                          </Wrap>
                        ))}
                      </React.Fragment>
                    )}
                  />
                </Form>
              )}
            />
          </div>

          {/* --------- //Formik----------- */}

          {/* 
        {Object.keys(getProfileList).map(key => {
          return (
            <div key={key}>
              {getProfileList[key].map(item => {
                console.log("item", item);
                return (
                  <Wrap key={shortid.generate()}>

                    {console.log(
                      "qualification degree",
                      item.list_qualification_degree,
                    )}
                    <SearchInputLabel>
                      {item.list_qualification_degree}
                    </SearchInputLabel>
                    <EditRemoveButtonWrap>
                      <EditButton>Edit</EditButton>
                      <RemoveButton>Remove</RemoveButton>
                    </EditRemoveButtonWrap>
                  </Wrap>
                );
              })}
            </div>
          );
        })} */}

          {/* {getProfileList.map(index => (
          <Wrap key={shortid.generate()}>
            {console.log(
              "qualification degree",
              index.list_qualification_degree,
            )}
            <SearchInputLabel>
              {index.list_qualification_degree}
            </SearchInputLabel>
            <EditRemoveButtonWrap>
              <EditButton>Edit</EditButton>
              <RemoveButton>Remove</RemoveButton>
            </EditRemoveButtonWrap>
          </Wrap>
        ))} */}

          {/* <Wrap>
          <SearchInputLabel>Master in Communication</SearchInputLabel>
          <EditRemoveButtonWrap>
            <EditButton>Edit</EditButton>
            <RemoveButton>Remove</RemoveButton>
          </EditRemoveButtonWrap>
        </Wrap>
        <Wrap>
          <SearchInputLabel>Master in Communication</SearchInputLabel>
          <EditRemoveButtonWrap>
            <EditButton>Edit</EditButton>
            <RemoveButton>Remove</RemoveButton>
          </EditRemoveButtonWrap>
        </Wrap> */}
        </SearchEditWrap>
      </QualificationDetailsDiv>
    );
  }
}

export default FieldOfStudyDetails;
