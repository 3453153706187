import React from "react";
import styled from "styled-components";

const FullWrap = styled.div`
  display: flex;
`;
const Cards = styled.div`
  display: flex;
  /* border: 1px solid black; */
  /* margin-top: 40px;
  margin-left: 70px; */
  height: auto;
  width: auto;
  border-radius: 6px;
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.02);
  background-color: #ffffff;
`;

const CardContentWrap = styled.div`
  padding-top: 20px;
  padding-left: 20px;
`;
const TitleLabel = styled.label`
  font-size: 15px;
`;
const LableWrap = styled.div`
  padding-top: 10px;
`;
const NumberLabel = styled.label`
  /* padding-left: 10px; */
  padding-right: 5px;
  font-size: 18px;
  color: #4da1ff;
`;
const NumberLabelReview = styled(NumberLabel)`
  color: #ff6d4a;
`;

const MonthLabel = styled.label`
  font-size: 12px;
`;
const ActiveExpertsList = styled.li`
  list-style: none;
  color: #417505;
`;
const Ecards = props => (
  <Cards {...props}>{props.children}</Cards>
  // <Cards>
  //   <CardContentWrap>
  //     <TitleLabel>Number of experts in Total</TitleLabel>
  //     <LableWrap>
  //       <NumberLabel>200</NumberLabel>
  //       <MonthLabel>This month</MonthLabel>
  //     </LableWrap>
  //   </CardContentWrap>
  // </Cards>
  // <Cards>
  //   <CardContentWrap>
  //     <TitleLabel>Number of experts to review</TitleLabel>
  //     <LableWrap>
  //       <NumberLabelReview>10</NumberLabelReview>
  //       <MonthLabel>This month</MonthLabel>
  //     </LableWrap>
  //   </CardContentWrap>
  // </Cards>
  // <Cards>
  //   <CardContentWrap>
  //     <TitleLabel>Most active experts</TitleLabel>
  //     <LableWrap>
  //       <ActiveExpertsList />
  //     </LableWrap>
  //   </CardContentWrap>
  // </Cards>
);

export default Ecards;
