import React from "react";
import styled from "styled-components";
import ReactTable from "react-table";
import "react-table/react-table.css";
import axios from "axios";
import {apiURL, createBuffer, generateSHA2, searchInTable} from "../../utils/util";

const FullTableWrap = styled.div`
  margin-left: 1.2em;

  margin-top: 2.5em;
  max-height: 100%;
  width: 1200px;
  background-color: #ffffff;
`;

const TableWrap = styled.div`
  margin-left: 20px;
  width: 1150px;
`;

const TableHeaderDiv = styled.div`
  /* text-align: left; */
  text-align: ${props => (props.center ? "center" : "left")};
`;

const FilterInput = styled.input`
  ::-webkit-input-placeholder {
    font-size: 10px;
  }
`;

const email = window.sessionStorage.getItem("email");
const password = window.sessionStorage.getItem("token");

class UserOverviewReactTable extends React.Component {
  state = {
    loading: true,
    UserWalletTransactionList: [],
  };

  // lifecycle methods

  componentDidMount() {
    this.getUserWalletTransactionDetails();
  }

  // api calls
  getUserWalletTransactionDetails = () => {
    const buffer = createBuffer({email});
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/transaction/UserOverviewTransaction`,
        {
          json_string: JSON.stringify({email}),
        },
        {headers: {signature, email, channel: "web"}},
      )
      .then(response => {
        if (response.status === 200 && response.data) {
          const {data} = response;
          this.setState({
            UserOverviewTransactionList: data.content,
            loading: false,
          });
        }
      })
      .catch(error => {});
  };

  render() {
    const {UserOverviewTransactionList, loading} = this.state;
    const columns = [
      // {
      //   id: "transaction-date",
      //   Header: () => (
      //     <TableHeaderDiv center>
      //       <b>Date of Transaction</b>
      //     </TableHeaderDiv>
      //   ),
      //   // Header: "Date of Transaction",
      //   // accessor: "dateoftransaction",
      //   accessor: d => d.date,
      //   Filter: ({filter, onChange}) => (
      //     <FilterInput
      //       type="text"
      //       placeholder="Search Date of Transaction..."
      //       value={filter ? filter.value : ""}
      //       onChange={event => onChange(event.target.value)}
      //     />
      //   ),
      //   sortMethod: (a, b) => {
      //     const splitdate1 = a.split("-");
      //     const splitdate2 = b.split("-");
      //     const date1 = new Date(
      //       splitdate1[2],
      //       splitdate1[1],
      //       splitdate1[0],
      //     ).getTime();
      //     const date2 = new Date(
      //       splitdate2[2],
      //       splitdate2[1],
      //       splitdate2[0],
      //     ).getTime();

      //     // const date2
      //     if (date1 > date2) return 1;
      //     return -1;
      //   },
      //   style: {textAlign: "center", color: "#4D4F5C"},
      // },
      {
        id: "transactionid",
        Header: () => (
          <TableHeaderDiv center>
            <b>Transaction ID</b>
          </TableHeaderDiv>
        ),
        // Header: "Transaction ID",
        // accessor: "transactionid",
        accessor: d => (d.transaction_id ? d.transaction_id : "-"),
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search Transaction ID..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        filterMethod: (filter, row) => searchInTable(filter, row),
        style: {textAlign: "center", color: "#4D4F5C"},
      },
      {
        id: "merchantid",
        Header: () => (
          <TableHeaderDiv center>
            <b>Merchant ID</b>
          </TableHeaderDiv>
        ),
        // Header: "User Name",
        // accessor: "merchantid",
        accessor: d => d.merchant_id,
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search Merchant ID..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        filterMethod: (filter, row) => searchInTable(filter, row),
        style: {textAlign: "center", color: "#4D4F5C"},
      },
      {
        id: "username",
        Header: () => (
          <TableHeaderDiv center>
            <b>User Name</b>
          </TableHeaderDiv>
        ),
        style: {color: "#4D4F5C"},
        // Header: "User Name",
        // accessor: "username",
        accessor: d => d.user_name,
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search User Name..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        filterMethod: (filter, row) => searchInTable(filter, row),
      },
      {
        id: "amount",
        Header: () => (
          <TableHeaderDiv center>
            <b>Amount</b>
          </TableHeaderDiv>
        ),
        // Header: "Amounts",
        // accessor: "amount",
        accessor: d => d.amount,
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search Amount..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        style: {textAlign: "center", color: "#4D4F5C"},
      },
      {
        id: "status",
        Header: () => (
          <TableHeaderDiv center>
            <b>Status</b>
          </TableHeaderDiv>
        ),
        // Header: "Status",

        // accessor: "status",
        style: {color: "#4D4F5C"},
        accessor: d =>
          d.status === "success"
            ? "Success"
            : d.status === "failed"
            ? "Failed"
            : d.status === "in_progress"
            ? "In Progress"
            : " ",
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search Status..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        filterMethod: (filter, row) => searchInTable(filter, row),
      },
      {
        id: "chat",
        Header: () => (
          <TableHeaderDiv center>
            <b>Chat</b>
          </TableHeaderDiv>
        ),
        // Header: "Amounts",
        // accessor: "amount",
        accessor: d => (d.online_chat_transaction + d.offline_chat_transaction + d.scheduled_chat_transaction).toFixed(2),
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search Amount..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        style: {textAlign: "center", color: "#4D4F5C"},
      },
      {
        id: "refund",
        Header: () => (
          <TableHeaderDiv center>
            <b>Refund</b>
          </TableHeaderDiv>
        ),
        // Header: "Amounts",
        // accessor: "amount",
        accessor: d => d.refund_transaction.toFixed(2),
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search Amount..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        style: {textAlign: "center", color: "#4D4F5C"},
      },
      {
        id: "withdraw",
        Header: () => (
          <TableHeaderDiv center>
            <b>Withdraw</b>
          </TableHeaderDiv>
        ),
        // Header: "Amounts",
        // accessor: "amount",
        accessor: d => d.withdraw_transaction.toFixed(2),
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search Amount..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        style: {textAlign: "center", color: "#4D4F5C"},
      },
    ];
    return (
      <FullTableWrap>
        <TableWrap>
          <ReactTable
            noDataText={loading ? "" : "No Rows Found"}
            loading={loading}
            loadingText="Loading..."
            filterable
            columns={columns}
            data={UserOverviewTransactionList}
            defaultPageSize={10}
            defaultSorted={[
              {
                id: "transactiondate",
                desc: true,
              },
            ]}
            // onPageChange={page => this.setState({page})}
            // expanded={expanded}
            // showPaginationTop
            // showPaginationBottom={false}
          />
        </TableWrap>
      </FullTableWrap>
    );
  }
}

export default UserOverviewReactTable;
