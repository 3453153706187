import React from "react";
import styled from "styled-components";
import axios from "axios";
import {SmartToaster, toast} from "react-smart-toaster";
// import Popup from "reactjs-popup";
import Modal from "react-modal";
import Select from "react-select";
import "./reactpopup.css";
import shortid from "shortid";
import closebtn from "./closebtn.png";
import ShawerCategoriesEnglishForm from "./ShawerCategoriesEnglishForm";
import ShawerCategoriesArabicForm from "./ShawerCategoriesArabicForm";

// api URL
import {apiURL} from "../../utils/util";

const FullWrap = styled.div`
  width: 950px;
  /* border: 1px solid black; */
  /* display: flex;
  flex-wrap: wrap; */
  margin-top: 2em;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.23);
  background-color: #ffffff;
  margin-left: 1em;
  border-radius: 6px;
`;
const AddCategoryButton = styled.button`
  height: 30px;
  border-radius: 5px;
  background-color: #e54666;
  color: #fff;
  font-size: 15px;
  margin-left: 750px;
  margin-top: 20px;
`;

const CategoryListButton = styled.button`
  outline: 0;
  height: 30px;
  font-size: 15px;
  border: none;
  border-bottom: ${props => (props.isActive ? "2px solid #e54666" : "")};
`;

const PopularListButton = styled.button`
  outline: 0;
  height: 30px;
  font-size: 15px;
  border: none;
  border-bottom: ${props => (props.isActive ? "2px solid #e54666" : "")};
`;

const ListButtonWrap = styled.div`
  margin-top: 30px;
  margin-left: 20px;
  width: 400px;
  display: flex;
  justify-content: space-between;
`;

const CategoryListWrap = styled.div`
  margin-top: 20px;
`;

const CategoryInputWrap = styled.div`
  padding: 20px;
  border-bottom: 1px solid #dddddd;
  margin: 0px 80px 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CategoryLabel = styled.label`
  color: #323c47;
`;

const EditRemoveButtonWrap = styled.div`
  /* margin-left: 550px; */
  width: 100px;
  display: flex;
  justify-content: space-between;
`;

const EditButton = styled.button`
  outline: 0;
  border: none;
  color: #39a1dd;
  font-size: 15px;
`;

const RemoveButton = styled.button`
  outline: 0;
  border: none;
  color: #d0021b;
  font-size: 15px;
`;

// PopularList css

const PopularListWrap = styled.div`
  margin-top: 40px;
`;

const PopularCategoryListLabel = styled.label`
  font-size: 15px;
`;

const PopularCategoryDiv = styled.div`
  margin-top: 20px;
  margin-bottom: 30px;
  margin-left: 25px;
  width: 600px;
`;

// popup css
const EditCategoryWrap = styled.div`
  /* margin: 20px; */
  /* padding: 20px; */
`;

const Categorytitle = styled.h2`
  color: #0052a9;
  margin-top: 0px;
`;

const CategoryPopupLabel = styled.label`
  /* margin-bottom: 15px; */
  margin-bottom: ${props => (props.small ? "0" : "15")}px;
`;

const RankPreferenceLabel = styled.label``;

const CategoryEditInput = styled.input`
  height: 40px;
  width: 250px;
  border-radius: 5px;
  border: 1px solid #e9eff4;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const ColumnWrap = styled.div`
  display: flex;
  width: 900px;
  justify-content: space-between;
`;

const ColumnOneWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-right: solid 1px #979797;
  width: 450px;
`;

const ColumnTwoWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 450px;
`;

const CloseButton = styled.button`
  outline: 0;
  border: none;

  margin-left: 900px;
  margin-top: -10px;
`;

const CloseButtonImg = styled.img`
  height: 20px;
  width: 20px;
`;

const AddIconMore = styled.button`
  height: 30px;
  width: 80px;
  border-radius: 2px;
  border: solid 1px rgba(77, 161, 255, 0.7);
  background-color: #39a1dd;
  color: #fff;
  margin-bottom: 15px;
`;

const UploadSVGFormatLabel = styled.label`
  color: #999999;
  font-size: 12px;
  margin-bottom: 15px;
`;

const RemoveButtonWrap = styled.div`
  width: 350px;
  width: ${props => (props.small ? "250" : "350")}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DoneButton = styled.button`
  height: 30px;
  margin: 10px;
  width: 100px;
  border-radius: 4px;
  background-color: #085db0;
  color: #fff;
  margin-left: 400px;
`;

const RatingTitle = styled.h2`
  font-size: 17px;
  margin-left: 20px;
  margin-top: 20px;
`;

const customStyles = {
  content: {
    height: "60%",
    width: "67%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

class ShawerCategories extends React.Component {
  state = {
    open: false,
    isCategoryListClicked: true,
    isPopularListClicked: false,
    isCategoryButtonActive: true,
    isPopularButtonActive: false,
    getCategoryList: [],
    id: "",
    selectedCategoryList: {},
    upload: "",
    selectedCategoryId: "",
  };

  // lifecycle methods

  onCategoryListClicked = () => {
    this.setState({
      isCategoryButtonActive: true,
      isPopularButtonActive: false,
      isCategoryListClicked: true,
      isPopularListClicked: false,
    });
  };

  onCategoryArabicListClicked = () => {
    this.setState({
      isCategoryButtonActive: false,
      isPopularButtonActive: true,
      isCategoryListClicked: false,
      isPopularListClicked: true,
    });
  };
  // api calls

  render() {
    const {
      open,
      isCategoryListClicked,
      isCategoryButtonActive,
      isPopularButtonActive,
      getCategoryList,
      selectedCategoryId,
      selectedCategoryList,
    } = this.state;
    return (
      <FullWrap>
        <SmartToaster store={toast} lightBackground position="top_center" />
        {/* <AddCategoryButton onClick={this.openPopup}>
          Add Category
        </AddCategoryButton> */}
        <RatingTitle>Shawer Categories</RatingTitle>
        <ListButtonWrap>
          <CategoryListButton
            onClick={this.onCategoryListClicked}
            isActive={isCategoryButtonActive}
          >
            Category List
          </CategoryListButton>
          {/* <PopularListButton
            onClick={this.onCategoryArabicListClicked}
            isActive={isPopularButtonActive}
          >
            Category List(Arabic)
          </PopularListButton> */}
        </ListButtonWrap>
        {isCategoryListClicked ? (
          <ShawerCategoriesEnglishForm />
        ) : // <ShawerCategoriesArabicForm />
        null}
      </FullWrap>
    );
  }
}

export default ShawerCategories;
