import React from "react";
import styled from "styled-components";
import axios from "axios";
import Modal from "react-modal";
import shortid from "shortid";
import {withFormik, Form, Field, FieldArray, Formik} from "formik";
import closebtn from "./closebtn.png";

// api URL
import {apiURL, generateSHA2, createBuffer, Spinner} from "../../utils/util";

const FullWrap = styled.div`
  width: 950px;
  /* border: 1px solid black; */
  /* display: flex;
  flex-wrap: wrap; */
  margin-top: 2em;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.23);
  background-color: #ffffff;
  margin-left: 1em;
  border-radius: 6px;
`;
const RatingTitle = styled.h2`
  font-size: 18px;
  margin-left: 20px;
  padding-top: 20px;
`;

const TabOneButton = styled.button`
  height: 30px;
  font-size: 15px;
  border: none;
  display: flex;
  outline: 0;
  font-weight: ${props => (props.isActive ? "700" : "")};
  border-bottom: ${props => (props.isActive ? "2px solid #e54666" : "")};
  align-items: center;
`;

const TabTwoButton = styled.button`
  height: 30px;
  font-size: 15px;
  border: none;
  display: flex;
  border-bottom: ${props => (props.isActive ? "2px solid #0052a9" : "")};
  align-items: center;
  outline: 0;
`;

const TabOne = styled.div`
  margin-top: 20px;
  margin-left: 20px;
  width: 550px;
  display: flex;
  justify-content: space-between;
`;

const TabTwo = styled.div`
  margin-top: 20px;

  width: 400px;
  display: flex;
  justify-content: space-between;
`;

const StarRatingWrap = styled.div`
  margin: 40px 20px 20px 20px;
`;

const InputButtonWrap = styled.div`
  margin-top: 20px;
  width: 530px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AddInput = styled.input`
  width: 400px;
  height: 35px;
  border-radius: 4px;
  border: solid 1px #e9eff4;
  border-bottom: solid 1px #e9eff4;
`;

const AddReasonButton = styled.button`
  width: 100px;
  height: 30px;
  border-radius: 2px;
  border: solid 1px rgba(77, 161, 255, 0.7);
  background-color: #39a1dd;
  color: #fff;
`;

const QualificationDetailsDiv = styled.div``;

const SearchEditWrap = styled.div`
  width: 550px;
  border: 1px solid #e9eff4;
  border-radius: 4px;
  margin-top: 20px;
  border-bottom: none;
`;
const SerachInput = styled.input`
  width: 100%;
  height: 30px;
  padding: 20px;
  border: none;
  border-bottom: 1px solid #e9eff4;
  background-color: #e8f0f6;
  ::-webkit-input-placeholder {
    font-size: 14px;
    color: #004389;
  }
`;
const SearchWrap = styled.div``;

const Wrap = styled.div`
  padding: 20px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid #e9eff4;
`;

const SearchInputLabel = styled.label`
  font-size: 14px;
`;

const EditRemoveButtonWrap = styled.div`
  width: 100px;
  display: flex;
  justify-content: space-between;
  margin-left: 550px;
`;

const EditButton = styled.button`
  border: none;
  color: #39a1dd;
  font-size: 17px;
  cursor: pointer;
  outline: 0;
`;

const RemoveButton = styled.button`
  border: none;
  color: #d0021b;
  font-size: 17px;
  cursor: pointer;
  outline: 0;
`;

const AddCategoryButton = styled.button`
  height: 30px;
  width: 100px;
  border-radius: 5px;
  background-color: #39a1dd;
  color: #fff;
  font-size: 13px;
  margin-left: 600px;
  outline: 0;
  cursor: pointer;
`;

const AddPrivacyPoilicyButton = styled.button`
  height: 30px;
  width: 100px;
  border-radius: 5px;
  background-color: #39a1dd;
  color: #fff;
  font-size: 13px;
  margin-top: 30px;
  outline: 0;
  cursor: pointer;
`;

// const FormField = styled(Field)`
//   position: ${props => (props.absolute ? "absolute" : "")};
//   width: 100%;

//   border: none;
//   text-indent: 15px;
//   padding: 10px 10px 10px 5px;
//   font-size: 17px;
//   border-radius: 4px;
//   background-color: #ffffff;
//   opacity: 0.5;
//   outline: none;
//   ::-webkit-input-placeholder {
//     font-family: Ubuntu;
//     /* font-size: 20px; */
//     font-weight: 300;
//     color: #0d3868;
//     opacity: 0.5;
//     padding: 15px;
//     /* text-align: left; */
//   }
// `;

const FormField = styled(Field)`
  width: 600px;
  /* width: ${props => (props.large ? "750" : "350")}px; */
  height: 40px;
  border-radius: 4px;
  border: ${props =>
    props.isActive ? "solid 1px dimgrey" : "solid 1px #e9eff4"};
  /* border: solid 1px #e9eff4;
  border-bottom: solid 1px #e9eff4; */
  margin-top: 15px;
  font-size:15px;
`;

const FormFieldAdd = styled.input`
  width: 600px;
  /* width: ${props => (props.large ? "750" : "350")}px; */
  height: 40px;
  border-radius: 4px;
  border: ${props =>
    props.isActive ? "solid 1px dimgrey" : "solid 1px #e9eff4"};
  /* border: solid 1px #e9eff4;
  border-bottom: solid 1px #e9eff4; */
  margin-top: 15px;
  font-size:15px;
`;

const FormFieldTextarea = styled(Field)`
  width: 600px;
  /* width: ${props => (props.large ? "750" : "350")}px; */
  height: 100px;
  border-radius: 4px;
  border: ${props =>
    props.isActive ? "solid 1px dimgrey" : "solid 1px #e9eff4"};
  /* border: solid 1px #e9eff4;
  border-bottom: solid 1px #e9eff4; */
  margin-top: 15px;
  font-size:15px;
`;

const FormFieldTextareaAdd = styled.textarea`
  width: 600px;
  /* width: ${props => (props.large ? "750" : "350")}px; */
  height: 100px;
  border-radius: 4px;
  border: ${props =>
    props.isActive ? "solid 1px dimgrey" : "solid 1px #e9eff4"};
  /* border: solid 1px #e9eff4;
  border-bottom: solid 1px #e9eff4; */
  margin-top: 15px;
  font-size:15px;
`;

const InputWrap = styled.div`
  margin-top: 20px;
  width: 700px;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label``;

const CloseButton = styled.button`
  outline: 0;
  border: none;
  cursor: pointer;
  margin-left: 650px;
  margin-top: -10px;
  outline: 0;
`;

const CloseButtonImg = styled.img`
  height: 30px;
  width: 30px;
`;

const customStyles = {
  content: {
    height: "60%",
    width: "50%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const email = window.sessionStorage.getItem("email");
const password = window.sessionStorage.getItem("token");

class PrivacyPolicy extends React.Component {
  state = {
    privacyPolicy: [],
    privacyPolicyObj: {},
    isFieldEnable: false,
    currentEditing: -1,
    isFieldActive: false,
    open: false,
    titleE: "",
    descE: "",
    titleA: "",
    descA: "",
    isLoading: false,
    updatedPrivacyPolicyObj: {},
  };

  // openPopup = this.openPopup.bind(this);

  // closePopup = this.closePopup.bind(this);

  // lifecycle methods

  componentDidMount() {
    this.getTermsOfUseDetails();
  }

  onChangeTitleEValue = e => {
    this.setState({
      titleE: e.target.value,
    });
  };

  onChangeDescEValue = e => {
    this.setState({
      descE: e.target.value,
    });
  };

  onChangeTitleAValue = e => {
    this.setState({
      titleA: e.target.value,
    });
  };

  onChangeDescAValue = e => {
    this.setState({
      descA: e.target.value,
    });
  };

  onClickAdd = () => {
    const {
      titleE,
      descE,
      titleA,
      descA,
      privacyPolicy,
      privacyPolicyObj,
    } = this.state;
    // const titleEng = titleE;
    // const descEng = descE;
    // const titleAr = titleA;
    // const descAr = descA;
    const newObj = {
      arabic_description: descA,
      arabic_title: titleA,
      description: descE,
      title: titleE,
    };

    this.setState({privacyPolicyObj: newObj});
    // console.log("before", privacyPolicyObj);
    // const newArray = privacyPolicy.slice(); // Create a copy
    // newArray.push(newObj); // Push the object
    // this.setState({privacyPolicy: newArray});
    // console.log("after", privacyPolicy);

    this.addPrivacyPolicyInfo(privacyPolicyObj);
  };

  handleOnChange = (e, index) => {
    const {privacyPolicy, updatedPrivacyPolicyObj} = this.state;

    // console.log("privacy policy from state", privacyPolicy);

    const editedData = privacyPolicy[index];

    // console.log("edit data", editedData);

    const {value} = e.target;
    const {name} = e.target.dataset;
    const tempObj = {
      [name]: value,
    };

    // console.log("tempObj", tempObj);

    const updatedObject = Object.assign(editedData, tempObj);

    // console.log("updatedObject", updatedObject);

    // const tempArrayData = {
    //   data: updatedObject,
    // };

    // const updatedArrayData = Object.assign(privacyPolicy[index], tempArrayData);

    // privacyPolicy[index] = updatedArrayData;

    //  console.log("updatedArrayData", updatedArrayData);

    this.setState({
      privacyPolicy,
      updatedPrivacyPolicyObj: updatedObject,
    });

    // console.log("Updated privacy policy...", privacyPolicy);
  };

  addPrivacyPolicyInfo = index => {
    const {titleE, descE, titleA, descA, isLoading} = this.state;

    this.setState({isLoading: true});

    const tempObj = {
      arabic_description: descA,
      arabic_title: titleA,
      description: descE,
      title: titleE,
    };

    // console.table("Payload", tempObj);

    // axios
    //   .post(`${apiURL}/aboutshawer/AddPrivacyPolicy`, {
    //     privacy_policy: {
    //       arabic_description: descA,
    //       arabic_title: titleA,
    //       description: descE,
    //       title: titleE,
    //     },
    //   })

    const buffer = createBuffer({
      email,
      privacy_policy: {
        arabic_description: descA,
        arabic_title: titleA,
        description: descE,
        title: titleE,
      },
    });
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/aboutshawer/AddPrivacyPolicy`,
        {
          json_string: JSON.stringify({
            email,
            privacy_policy: {
              arabic_description: descA,
              arabic_title: titleA,
              description: descE,
              title: titleE,
            },
          }),
        },
        {headers: {signature, email, channel: "web"}},
      )

      .then(response => {
        // console.log(response);
        this.getTermsOfUseDetails();
        this.closePopup();
        this.setState({isLoading: false});
      })

      .catch(error => {
        alert(error.response.data.content);
        this.setState({isLoading: false});
      });

    // console.log("added", privacyPolicyObj);
  };

  updatePrivacyPolicyInfo = index => {
    const {privacyPolicy, updatedPrivacyPolicyObj} = this.state;
    // console.log("Updated details", privacyPolicy);
    // axios
    //   .post(`${apiURL}/aboutshawer/UpdatePrivacyPolicy`, {
    //     privacy_policy: privacyPolicy,
    //   })

    const buffer = createBuffer({
      email,
      privacy_policy: updatedPrivacyPolicyObj,
    });
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/aboutshawer/UpdatePrivacyPolicy`,
        {
          json_string: JSON.stringify({
            email,
            privacy_policy: updatedPrivacyPolicyObj,
          }),
        },
        {headers: {signature, email, channel: "web"}},
      )

      .then(response => {
        //  console.log(response);
        // this.getTermsOfUseDetails();
      })
      .catch(error => {
        // console.log(error);
      });
    this.setState({currentEditing: -1});
  };

  // handleOnChange = (e, index) => {
  //   const {terms_Of_Use} = this.state;

  //   const editData = terms_Of_Use[index];

  //   console.log("edit data", editData);

  //   const {value} = e.target;
  //   const {name} = e.target.dataset;

  //   console.log("name and value", name, value);

  //   terms_Of_Use[index] = value;
  //   // arrayHelpers.replace(index, qualification[index]);
  //   // console.log("Updated qualification", arrayHelpers);
  //   console.log("Updated qualification....", terms_Of_Use);

  //   // arrayHelpers.replace(index, qualification[index])
  //   this.setState({
  //     terms_Of_Use,
  //   });
  //   console.log("Updated qualification....!!!!!!", terms_Of_Use);
  // };

  // updateQualificationInfo = index => {
  //   const {terms_Of_Use} = this.state;
  //   console.log("Updated details", terms_Of_Use);
  //   axios
  //     .post(`${apiURL}/aboutshawer/UpdateExpertProfile`, {
  //       terms_Of_Use,
  //     })

  //     .then(response => {
  //       console.log(response);
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // };

  handleOnButtonClick = index => {
    this.setState({currentEditing: index, isFieldActive: true});
  };

  // api calls

  getTermsOfUseDetails = () => {
    const {privacyPolicy} = this.state;

    const buffer = createBuffer({email});
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/aboutshawer/PrivacyPolicy`,
        {
          json_string: JSON.stringify({email}),
        },
        {headers: {signature, email, channel: "web"}},
      )

      // axios
      //   .post(`${apiURL}/aboutshawer/PrivacyPolicy`, {})

      .then(response => {
        if (response.status === 200 && response.data) {
          const {data} = response;
          // console.log("All Details", data);
          // console.log("All Details", data.content);
          // console.log("Description", data.content[0].description);
          // console.log("Description", terms_Of_Use[0].description);
          const privacypolicy = data.content;

          this.setState({
            privacyPolicy: privacypolicy,
          });
        }
      })
      .catch(error => {
        // console.log(error);
      });
    // console.log("privacypolicy........", privacyPolicy);
  };

  openPopup = () => {
    this.setState({open: true});
  };

  closePopup = () => {
    this.setState({open: false});
  };

  render() {
    const {
      isFieldActive,
      currentEditing,
      privacyPolicy,
      id,
      open,
      isLoading,
    } = this.state;

    return (
      <QualificationDetailsDiv>
        {/* ---------Formik----------- */}
        <FullWrap>
          <RatingTitle>Privacy Policy</RatingTitle>
          <AddCategoryButton onClick={this.openPopup}>Add</AddCategoryButton>
          <Formik
            enableReinitialize
            initialValues={{privacyPolicyDetails: privacyPolicy}}
            render={({values}) => (
              <Form>
                {/* {console.log("Formil Val", values)}
                {console.log("TOU", privacyPolicy)} */}
                <FieldArray
                  name="privacyPolicyDetails"
                  render={({arrayHelpers, remove}) => (
                    <React.Fragment>
                      {values.privacyPolicyDetails.map((touDetail, index) => (
                        <StarRatingWrap key={id}>
                          <div>
                            {/* <AddCategoryButton
                              type="button"
                              data-index={index}
                              onClick={() => {
                                this.handleOnButtonClick(index);
                              }}
                            >
                              Edit
                            </AddCategoryButton>

                            <AddCategoryButton
                              type="button"
                              data-index={index}
                              onClick={() =>
                                this.updatePrivacyPolicyInfo(index)
                              }
                            >
                              Save
                            </AddCategoryButton> */}
                            <EditRemoveButtonWrap>
                              <EditButton
                                type="button"
                                data-index={index}
                                onClick={() => {
                                  this.handleOnButtonClick(index);
                                }}
                              >
                                Edit
                              </EditButton>
                              <RemoveButton
                                type="button"
                                data-index={index}
                                onClick={() =>
                                  this.updatePrivacyPolicyInfo(index)
                                }
                              >
                                Save
                              </RemoveButton>
                            </EditRemoveButtonWrap>
                          </div>
                          <InputWrap>
                            <Label>Title - English</Label>
                            <FormField
                              type="text"
                              placeholder="Write here..."
                              isActive={
                                index === currentEditing ? isFieldActive : ""
                              }
                              // value={index.title}
                              name={`privacyPolicyDetails[${index}].title`}
                              data-name="title"
                              data-index={index}
                              disabled={index !== currentEditing}
                              onChange={
                                index === currentEditing
                                  ? e => this.handleOnChange(e, index)
                                  : null
                              }
                            />
                          </InputWrap>
                          <InputWrap>
                            <Label>Description - English</Label>
                            <FormFieldTextarea
                              type="text"
                              placeholder="Write here..."
                              // value={index.title}
                              isActive={
                                index === currentEditing ? isFieldActive : ""
                              }
                              name={`privacyPolicyDetails[${index}].description`}
                              data-name="description"
                              data-index={index}
                              component="textarea"
                              disabled={index !== currentEditing}
                              onChange={
                                index === currentEditing
                                  ? e => this.handleOnChange(e, index)
                                  : null
                              }
                            />
                          </InputWrap>
                          <InputWrap>
                            <Label>Title - Arabic</Label>
                            <FormField
                              type="text"
                              placeholder="Write here..."
                              isActive={
                                index === currentEditing ? isFieldActive : ""
                              }
                              // value={index.title}
                              name={`privacyPolicyDetails[${index}].arabic_title`}
                              data-name="arabic_title"
                              data-index={index}
                              disabled={index !== currentEditing}
                              onChange={
                                index === currentEditing
                                  ? e => this.handleOnChange(e, index)
                                  : null
                              }
                            />
                          </InputWrap>
                          <InputWrap>
                            <Label>Description - Arabic</Label>
                            <FormFieldTextarea
                              type="text"
                              placeholder="Write here..."
                              // value={index.title}
                              isActive={
                                index === currentEditing ? isFieldActive : ""
                              }
                              name={`privacyPolicyDetails[${index}].arabic_description`}
                              data-name="arabic_description"
                              data-index={index}
                              component="textarea"
                              disabled={index !== currentEditing}
                              onChange={
                                index === currentEditing
                                  ? e => this.handleOnChange(e, index)
                                  : null
                              }
                            />
                          </InputWrap>
                        </StarRatingWrap>
                      ))}
                    </React.Fragment>
                  )}
                />
              </Form>
            )}
          />

          <Modal
            isOpen={open}
            onRequestClose={this.closePopup}
            style={customStyles}
            ariaHideApp={false}
          >
            <div>
              <CloseButton onClick={this.closePopup}>
                <CloseButtonImg src={closebtn} />
              </CloseButton>
            </div>
            <h1>Add Privacy Policy</h1>
            <InputWrap>
              <Label>Title - English</Label>
              <FormFieldAdd
                onChange={this.onChangeTitleEValue}
                type="text"
                placeholder="Write here..."
              />
            </InputWrap>
            <InputWrap>
              <Label>Description - English</Label>
              <FormFieldTextareaAdd
                onChange={this.onChangeDescEValue}
                type="text"
                placeholder="Write here..."
              />
            </InputWrap>
            <InputWrap>
              <Label>Title - Arabic</Label>
              <FormFieldAdd
                onChange={this.onChangeTitleAValue}
                type="text"
                placeholder="Write here..."
              />
            </InputWrap>
            <InputWrap>
              <Label>Description - Arabic</Label>
              <FormFieldTextareaAdd
                onChange={this.onChangeDescAValue}
                type="text"
                placeholder="Write here..."
                // value={index.title}
              />
            </InputWrap>
            <AddPrivacyPoilicyButton onClick={this.addPrivacyPolicyInfo}>
              Add {isLoading ? <Spinner /> : null}
            </AddPrivacyPoilicyButton>
          </Modal>
        </FullWrap>
      </QualificationDetailsDiv>
    );
  }
}

export default PrivacyPolicy;

// import React from "react";
// import styled from "styled-components";

// // api URL
// import {apiURL} from "../../utils/util";

// const FullWrap = styled.div`
//   width: 950px;
//   /* border: 1px solid black; */
//   /* display: flex;
//   flex-wrap: wrap; */
//   margin-top: 2em;
//   box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.23);
//   background-color: #ffffff;
//   margin-left: 1em;
//   border-radius: 6px;
// `;
// const RatingTitle = styled.h2`
//   font-size: 17px;
//   margin-left: 20px;
//   margin-top: 20px;
// `;

// const StarRatingWrap = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   margin: 40px 20px 20px 20px;
// `;

// const InputWrap = styled.div`
//   margin-top: 20px;
//   width: 700px;
//   display: flex;
//   flex-direction: column;
// `;

// const Label = styled.label``;
// const AddInput = styled.input`
//   width: 600px;
//   /* width: ${props => (props.large ? "750" : "350")}px; */
//   height: 40px;
//   border-radius: 4px;
//   border: solid 1px #e9eff4;
//   border-bottom: solid 1px #e9eff4;
//   margin-top: 15px;
//   font-size:15px;
// `;

// const AddInputTextArea = styled.textarea`
// width: 600px;
//   /* width: ${props => (props.large ? "750" : "350")}px; */
//   height: 100px;
//   border-radius: 4px;
//   border: solid 1px #e9eff4;
//   margin-top: 15px;
//   font-size:15px;
// `;

// const QualificationDetails = styled.div``;

// const AddCategoryButton = styled.button`
//   height: 30px;
//   width: 100px;
//   border-radius: 5px;
//   background-color: #39a1dd;
//   color: #fff;
//   font-size: 13px;
//   margin-left: 650px;
// `;

// class PrivacyPolicy extends React.Component {
//   state = {};

//   render() {
//     return (
//       <FullWrap>
//         <RatingTitle>Privacy Policy</RatingTitle>
//         <AddCategoryButton>Edit</AddCategoryButton>
//         <StarRatingWrap>
//           <QualificationDetails>
//             <InputWrap>
//               <Label>Title</Label>
//               <AddInput
//                 placeholder="  Write Title here"
//                 value="Changes to This Privacy Polic"
//                 disabled
//               />
//             </InputWrap>

//             <InputWrap>
//               <Label>Paragraph</Label>
//               <AddInputTextArea
//                 placeholder="  Write here"
//                 value="I may update our Privacy Policy from time to time. Thus, you are advised to review this
//                  page periodically for any changes. I will notify you of any changes by posting the new Privacy
//                   Policy on this page. These changes are effective immediately after they are posted on this page."
//                 disabled
//               />
//             </InputWrap>
//           </QualificationDetails>
//           <QualificationDetails>
//             <InputWrap>
//               <Label>Title</Label>
//               <AddInput
//                 placeholder="  Write Title here"
//                 value="Links to Other Sites"
//                 disabled
//               />
//             </InputWrap>

//             <InputWrap>
//               <Label>Paragraph</Label>
//               <AddInputTextArea
//                 placeholder="  Write here"
//                 value="This Service may contain links to other sites. If you click on a third-party link, you will
//                 be directed to that site. Note that these external sites are not operated by me. Therefore,
//                 I strongly advise you to review the Privacy Policy of these websites. I have no control over
//                 and assume no responsibility for the content, privacy policies, or practices of any third-party
//                 sites or services."
//                 disabled
//               />
//             </InputWrap>
//           </QualificationDetails>
//           <QualificationDetails>
//             <InputWrap>
//               <Label>Title</Label>
//               <AddInput
//                 placeholder="  Write Title here"
//                 value="Cookies"
//                 disabled
//               />
//             </InputWrap>

//             <InputWrap>
//               <Label>Paragraph</Label>
//               <AddInputTextArea
//                 placeholder="  Write here"
//                 value="Cookies are files with a small amount of data that are commonly used as anonymous
//                  unique identifiers. These are sent to your browser from the websites that you visit and are stored
//                  on your device's internal memory.  This Service does not use these “cookies” explicitly. However,
//                  the app may use third party code and libraries that use “cookies” to collect information and improve
//                  their services. You have the option to either accept or refuse these cookies and know when a cookie is
//                   being sent to your device. If you choose to refuse our cookies, you may not be able to use some
//                   portions of this Service."
//                 disabled
//               />
//             </InputWrap>
//           </QualificationDetails>

//           <QualificationDetails>
//             <InputWrap>
//               <Label>Title</Label>
//               <AddInput
//                 placeholder="  Write Title here"
//                 value="Security"
//                 disabled
//               />
//             </InputWrap>

//             <InputWrap>
//               <Label>Paragraph</Label>
//               <AddInputTextArea
//                 placeholder="  Write here"
//                 value="I value your trust in providing us your Personal Information, thus we are striving to use
//                  commercially acceptable means of protecting it. But remember that no method of transmission over the
//                  internet, or method of electronic storage is 100% secure and reliable, and I cannot guarantee its
//                  absolute security."
//                 disabled
//               />
//             </InputWrap>
//           </QualificationDetails>

//           <QualificationDetails>
//             <InputWrap>
//               <Label>Title</Label>
//               <AddInput
//                 placeholder="  Write Title here"
//                 value="Preserve right to terminate"
//                 disabled
//               />
//             </InputWrap>

//             <InputWrap>
//               <Label>Paragraph</Label>
//               <AddInputTextArea
//                 placeholder="  Write here"
//                 value="While termination may be implied in other clauses, having a distinct right outlined
//                 in the Terms and Conditions to terminate accounts is better."
//                 disabled
//               />
//             </InputWrap>
//           </QualificationDetails>
//         </StarRatingWrap>
//       </FullWrap>
//     );
//   }
// }

// export default PrivacyPolicy;

// import React from "react";
// import styled from "styled-components";

// // api URL
// import {apiURL} from "../../utils/util";

// const FullWrap = styled.div`
//   width: 950px;
//   /* border: 1px solid black; */
//   /* display: flex;
//   flex-wrap: wrap; */
//   margin-top: 2em;
//   box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.23);
//   background-color: #ffffff;
//   margin-left: 1em;
//   border-radius: 6px;
// `;
// const RatingTitle = styled.h2`
//   font-size: 17px;
//   margin-left: 20px;
//   margin-top: 20px;
// `;

// const StarRatingWrap = styled.div`
//   /* display: flex; */
//   /* flex-wrap: wrap; */
//   margin: 40px 20px 20px 20px;
// `;

// const UploadButton = styled.button`
//   height: 30px;
//   margin-left: 30px;
//   border-radius: 5px;
//   background-color: #39a1dd;
//   color: #fff;
//   font-size: 13px;

//   /* opacity: ${props => (props.isActive ? "1" : "0.3")}; */
// `;

// const InputWrapButtonWrap = styled.div`
//   /* margin-top: 20px; */
//   /* width: ${props => (props.small ? "350" : "700")}px; */
//   width: 370px;
//   display: flex;
//   justify-content:space-between;
//   align-items:center;
//   /* flex-direction: column; */
// `;

// const PdfName = styled.h5``;
// const AddInput = styled.input`
//   width: 200px;
//   /* width: ${props => (props.small ? "350" : "600")}px; */
//   height: 40px;
//   border-radius: 4px;
//   border: solid 1px #e9eff4;
//   border-bottom: solid 1px #e9eff4;
//   /* margin-top: 15px; */
// `;

// const PdfNameDownloadWrap = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   width: 200px;
// `;

// const AddCategoryButton = styled.button`
//   /* margin-top: 10px; */
//   height: 30px;
//   /* width: 100px; */
//   border-radius: 5px;
//   background-color: #39a1dd;
//   color: #fff;
//   font-size: 13px;
//   /* margin-left: 650px; */
// `;

// const PDFDiv = styled.div`
//   /* width: 200px;
//   height: 400px; */
//   border: 1px solid black;
// `;

// const EditRemoveButtonWrap = styled.div`
//   /* margin-left: 550px; */
//   width: 100px;
//   display: flex;
//   justify-content: space-between;
// `;

// const EditButton = styled.button`
//   border: none;
//   color: #39a1dd;
//   font-size: 15px;
// `;

// const RemoveButton = styled.button`
//   border: none;
//   color: #d0021b;
//   font-size: 15px;
// `;

// class PrivacyPolicy extends React.Component {
//   state = {isEditButtonActive: true, isEditButtonClicked: false};

//   onEditClick = () => {
//     this.setState({
//       isEditButtonActive: true,
//       isEditButtonClicked: true,
//     });
//   };

//   render() {
//     const {isEditButtonClicked, isEditButtonActive} = this.state;

//     return (
//       <FullWrap>
//         <RatingTitle>Privacy Policy</RatingTitle>

//         <StarRatingWrap>
//           <InputWrapButtonWrap>
//             <PdfNameDownloadWrap>
//               <PdfName>PrivacyPolicy.pdf</PdfName>

//               <AddCategoryButton>Upload</AddCategoryButton>
//             </PdfNameDownloadWrap>
//             <EditRemoveButtonWrap>
//               <EditButton onClick={this.onEditClick}>Edit</EditButton>
//               <RemoveButton>Remove</RemoveButton>
//             </EditRemoveButtonWrap>
//           </InputWrapButtonWrap>
//           {/* <UploadButton>Upload</UploadButton> */}
//           {/* {isEditButtonClicked ? (
//             <UploadButton isActive>Upload</UploadButton>
//           ) : (
//             <UploadButton>Upload</UploadButton>
//           )} */}
//         </StarRatingWrap>
//       </FullWrap>
//     );
//   }
// }

// export default PrivacyPolicy;
