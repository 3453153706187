import app from "./firebase";

export default function signinwithEmailandPassword(email, password) {
  return new Promise((resolve, reject) => {
    app
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(res => {
        // console.log("res", res);
        resolve(res);
      })
      .catch(e => {
        // console.log("error", e.message);
        alert(e.message);
        reject(e);
      });
  });
}

// module.exports.signinwithEmailandPassword = signinwithEmailandPassword;
