import React, {useCallback, useContext} from "react";
import {withRoute, Redirect} from "react-router-dom";
import crypto from "crypto";
import styled from "styled-components";
import {withFormik, Form, Field, Formik} from "formik";
import Yup from "yup";
// import {Button} from "../Common/Button";
import LoginIcons from "../icons/LoginIcons";
import ForgotPassword from "./ForgotPassword";
import app from "../../firebase";
import {AuthContext} from "../../Auth";
import firebaseData from "../../firebaseData";

const FullSection = styled.div`
  display: flex;
  width: 40em;
  border: 1px solid black;
  border-radius: 26px 0px 0px 26px;
  flex-direction: column;
  padding: 40px;
  background-color: white;
`;
const Error = styled.div`
  color: red;
`;
const FormField = styled(Field)`
  width: 100%;
  border: 1px solid grey;
  margin: 20px;
  padding: 15px;
  text-indent: 25px;
  font-size: 20px;
  border-style: none none solid none;
  /* position: relative; */
  ::-webkit-input-placeholder {
    font-family: Ubuntu;
    font-size: 20px;
    font-weight: 300;
    color: #0d3868;
    opacity: 0.5;
    padding: 15px;
  }
`;
export const Button = styled.button`
  width: ${props => (props.medium ? 150 : 200)}px;
  padding: 0.8em;
  color: white;
  margin-right:15px;
  align-self: ${props => (props.medium ? "center" : "flex-end")};
  background-color: #0d3868;
  /* background-color: ${props => (props.medium ? "#0d3868" : "none")}; */
  font-size: 18px;
  margin-top: ${props => (props.medium ? 3 : 0)}em;
  cursor: pointer;
`;

const TitleLable = styled.label`
  text-align: center;
  margin-bottom: 15px;
  font-family: HelveticaNeue;
  font-size: 20px;
  color: #0f283d;
`;
const InputTextLabel = styled.label`
  /* display: flex; */
  display: block;
  align-items: center;
  padding: 15px 30px 15px 30px;
  position: relative;
`;

const ForgotPwdLink = styled.a`
  text-align: right;
  padding-right: 40px;
  text-decoration: none;
  font-family: LucidaGrande;
  font-size: 18px;
  color: #0d3868;
`;

const LoginBoxAuth = ({history}) => {
  const handleLogin = useCallback(
    async event => {
      event.preventDefault();
      const {email, password} = event.target.elements;
      try {
        const appdata = await firebaseData(email.value, password.value);
        let userToken;
        console.log(appdata);
        if (appdata) {
          const userData = await app
            .firestore()
            .collection("users")
            .doc(appdata.user.uid)
            .get();
          if (userData && userData.data().token) {
            sessionStorage.setItem("email", appdata.user.email);
            sessionStorage.setItem("token", userData.data().token);
            history.push("/dashboard");
          } else {
            const token = crypto.randomBytes(16).toString("hex");
            const updatedDoc = await app
              .firestore()
              .collection("users")
              .doc(appdata.user.uid)
              .update({token});

            sessionStorage.setItem("email", appdata.user.email);
            sessionStorage.setItem("token", token);
            history.push("/dashboard");
          }
        }

        const userRef = await app
          .firestore()
          .collection("users")
          .doc(appdata.user.uid)
          .get()
          .then(userData => {
            const userSnap = userData.data();

            userSnap.id = userData.id;
            sessionStorage.setItem("typeofuser", userSnap.type_of_user);
            sessionStorage.setItem("currentUser", JSON.stringify(userSnap));
          })
          .catch(error => {
            console.log("error-->", error);
          });
      } catch (error) {
        console.log("error", error);
      }
    },
    [history],
  );
  return (
    <Formik>
      <Form onSubmit={handleLogin}>
        <FullSection>
          <TitleLable>Log In to access your dashboard</TitleLable>
          <InputTextLabel>
            <FormField placeholder="Email" type="email" name="email" />
            <LoginIcons />
          </InputTextLabel>
          <InputTextLabel>
            <FormField placeholder="Password" type="password" name="password" />
            <LoginIcons password />
          </InputTextLabel>
          <ForgotPwdLink href="/forgotpassword">
            Forgot Password?{" "}
          </ForgotPwdLink>
          <Button medium>Sign In</Button>
        </FullSection>
      </Form>
    </Formik>
  );
};

export default LoginBoxAuth;
