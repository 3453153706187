import React, {Component} from "react";
import styled from "styled-components";
import DashboardChart from "./DashboardChart";

const ChartDataWrap = styled.div``;

class DashboardChartData extends Component {
  constructor() {
    super();
    this.state = {
      chartData: {},
    };
  }

  componentWillMount() {
    this.getChartData();
  }

  getChartData() {
    this.setState({
      chartData: {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            label: "",
            data: [53, 70, 25, 30, 13, 29, 53, 70, 25, 30, 13, 29],
            backgroundColor: [
              "rgba(255, 99, 132, 0.6)",
              "rgba(54, 162, 235, 0.6)",
              "rgba(255, 206, 86, 0.6)",
              "rgba(75, 192, 192, 0.6)",
              "rgba(153, 102, 255, 0.6)",
              "rgba(255, 159, 64, 0.6)",
              "rgba(255, 99, 132, 0.6)",
              "rgba(255, 206, 86, 0.6)",
              "rgba(75, 192, 192, 0.6)",
              "rgba(153, 102, 255, 0.6)",
              "rgba(255, 159, 64, 0.6)",
              "rgba(255, 99, 132, 0.6)",
            ],
          },
        ],
        options: {
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                gridLines: {
                  color: "white",
                  // display: false,
                  // drawBorder: false,
                },
              },
            ],
            xAxes: [
              {
                // barThickness: 0.2,
                gridLines: {
                  color: "white",
                  // display: false,
                  // drawBorder: false,
                },
              },
            ],
          },
        },
        // options: {
        //   scales: {
        //     xAxes: [
        //       {
        //         display: false,
        //       },
        //     ],
        //   },
        // },
      },
    });
  }

  render() {
    const {chartData} = this.state;
    return (
      <ChartDataWrap>
        <DashboardChart chartData={chartData} />
      </ChartDataWrap>
    );
  }
}

export default DashboardChartData;
