import React from "react";
import styled from "styled-components";
import axios from "axios";
import {SmartToaster, toast} from "react-smart-toaster";
import {Form, Field, FieldArray, Formik} from "formik";
import Modal from "react-modal";
import "./reactpopup.css";
import closebtn from "./closebtn.png";

// api URL
import {apiURL} from "../../utils/util";

const FullWrap = styled.div`
  width: 950px;
  /* border: 1px solid black; */
  /* display: flex;
  flex-wrap: wrap; */
  margin-top: 2em;
  //   box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.23);
  //   background-color: #ffffff;
  margin-left: 1em;
  //   border-radius: 6px;
`;
const AddCategoryButton = styled.button`
  height: 30px;
  border-radius: 5px;
  background-color: #e54666;
  color: #fff;
  font-size: 15px;
  margin-left: 750px;
  margin-top: 20px;
  outline: 0;
  cursor: pointer;
`;

const CategoryListButton = styled.button`
  outline: 0;
  height: 30px;
  font-size: 15px;
  border: none;
  cursor: pointer;
  border-bottom: ${props => (props.isActive ? "2px solid #e54666" : "")};
`;

const PopularListButton = styled.button`
  outline: 0;
  height: 30px;
  font-size: 15px;
  border: none;
  border-bottom: ${props => (props.isActive ? "2px solid #e54666" : "")};
`;

const ListButtonWrap = styled.div`
  margin-top: 30px;
  margin-left: 20px;
  width: 400px;
  display: flex;
  justify-content: space-between;
`;

const CategoryListWrap = styled.div`
  margin-top: 20px;
`;

const CategoryInputWrap = styled.div`
  padding: 15px;
  border-bottom: 1px solid #dddddd;
  margin: 0px 80px 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CategoryLabel = styled.label`
  color: #323c47;
`;

const EditRemoveButtonWrap = styled.div`
  /* margin-left: 550px; */
  width: 140px;
  display: flex;
  justify-content: space-between;
`;

const EditButton = styled.button`
  outline: 0;
  border: none;
  color: #39a1dd;
  font-size: 15px;
  cursor: pointer;
`;

const RemoveButton = styled.button`
  outline: 0;
  border: none;
  color: #d0021b;
  font-size: 15px;
  cursor: pointer;
`;

// PopularList css

const PopularListWrap = styled.div`
  margin-top: 40px;
`;

const PopularCategoryListLabel = styled.label`
  font-size: 15px;
`;

const PopularCategoryDiv = styled.div`
  margin-top: 20px;
  margin-bottom: 30px;
  margin-left: 25px;
  width: 600px;
`;

// popup css
const EditCategoryWrap = styled.div`
  /* margin: 20px; */
  /* padding: 20px; */
`;

const Categorytitle = styled.h2`
  color: #0052a9;
  margin-top: 0px;
`;

const CategoryPopupLabel = styled.label`
  /* margin-bottom: 15px; */
  margin-bottom: ${props => (props.small ? "0" : "15")}px;
`;

const RankPreferenceLabel = styled.label``;

const CategoryEditInput = styled.input`
  height: 40px;
  width: 250px;
  border-radius: 5px;
  border: 1px solid #e9eff4;
`;

const ColumnWrap = styled.div`
  display: flex;
  width: 700px;
  justify-content: space-between;
`;

const ColumnOneWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0px 0px 0px;
  border-right: solid 1px #979797;
  width: 450px;
`;

const ColumnTwoWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0px 0px 20px;
  width: 450px;
`;

const CloseButton = styled.button`
  outline: 0;
  border: none;
  cursor: pointer;
  margin-left: 600px;
  margin-top: -10px;
`;

const CloseButtonImg = styled.img`
  height: 30px;
  width: 30px;
`;

const UploadSVGFormatLabel = styled.label`
  color: #999999;
  font-size: 12px;
  margin-bottom: 15px;
`;

const RemoveButtonWrap = styled.div`
  width: 350px;
  width: ${props => (props.small ? "250" : "350")}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DoneButton = styled.button`
  height: 30px;
  margin: 20px;
  width: 100px;
  border-radius: 4px;
  background-color: #085db0;
  color: #fff;
  margin-left: 300px;
  outline: 0;
  cursor: pointer;
`;

const RatingTitle = styled.h2`
  font-size: 17px;
  margin-left: 20px;
  margin-top: 20px;
`;

const EnablePopupWrap = styled.div``;
const EnablePopupHeading = styled.h2``;
const EnablePopupLabel = styled.label``;
const EnablePopupOkButton = styled.button``;

const customStyles = {
  content: {
    height: "40%",
    width: "47%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const FormField = styled(Field)`
  position: ${props => (props.absolute ? "absolute" : "")};
  width: 100%;
  /* border: solid 1px #d0d0d0; */
  border: none;
  text-indent: 15px;
  padding: 10px 10px 10px 5px;
  font-size: 17px;
  border-radius: 4px;
  background-color: #ffffff;
  opacity: 0.5;
  outline: none;
  /* border-bottom: ${props => (props.isActive ? "2px solid #e54666" : "")}; */
  border: ${props => (props.active ? "1px solid #ddd" : "")};
  text-decoration: ${props => (props.isactive ? "line-through" : "none")};
  ::-webkit-input-placeholder {
    font-family: Ubuntu;
    /* font-size: 20px; */
    font-weight: 300;
    color: #0d3868;
    opacity: 0.5;
    padding: 15px;
    /* text-align: left; */
  }
`;

class ShawerCategoriesArabicForm extends React.Component {
  state = {
    open: false,
    isCategoryButtonActive: true,
    isCategoryArabicButtonActive: false,
    getCategoryList: [],
    selectedCategoryList: {},
    upload: "",
    currentEditing: -1,
    isCategoryActive: false,
    file: null,
    isDisableClicked: false,
    isenabled: true,
    category_name: "",
    count: 0,
    openEnable: false,
    arabic_name: "",
  };

  openPopup = this.openPopup.bind(this);

  closePopup = this.closePopup.bind(this);

  openEnablePopup = this.openEnablePopup.bind(this);

  closeEnablePopup = this.closeEnablePopup.bind(this);

  // lifecycle methods

  componentDidMount() {
    this.getCategoryDetails();
  }

  onCategoryListClicked = () => {
    this.setState({
      isCategoryButtonActive: true,
      isCategoryArabicButtonActive: false,
    });
  };

  onCategoryArabicListClicked = () => {
    this.setState({
      isCategoryButtonActive: false,
      isCategoryArabicButtonActive: true,
    });
  };

  getBase64 = file => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      const reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log("baseURL", baseURL);
        resolve(baseURL);
      };
      console.log("file info", fileInfo);
    });
  };

  handleOnArabicCategoryName = e => {
    this.setState({arabic_name: e.target.value});
  };

  handleFileInputChange = e => {
    // console.log(e.target.files[0]);
    let {file} = this.state;

    file = e.target.files[0];

    this.getBase64(file)
      .then(result => {
        file.base64 = result;
        // console.log("File Is", file);
        this.setState({
          base64URL: result,
          file,
        });
      })
      .catch(err => {
        console.log(err);
      });

    this.setState({
      file: e.target.files[0],
    });

    // console.log("file dataaaa", e.target.files[0]);
  };

  category = () => {
    const {getCategoryList} = this.state;
    // console.log("dsfds", getCategoryList);
    return getCategoryList.map(i => ({
      label: i.category_name,
      value: i.id,
    }));
  };

  handleOnButtonClick = index => {
    this.setState({currentEditing: index, isCategoryActive: true});
  };

  checkEnableModalRender = () => {};

  checkEnable = () => {
    let {getCategoryList, count} = this.state;

    count = 0;
    getCategoryList.forEach(cat => {
      if (cat.isEnable === true) {
        count += 1;
      }
    });
    this.setState({count});
    // console.log("count", count);
    if (count <= 3 || count === 2 || count === 1) {
      //   this.openEnablePopup();
    }

    return count;
  };

  // api calls

  getCategoryDetails = () => {
    axios
      .post(`${apiURL}/aboutshawer/GetCategoryList`, {})

      .then(response => {
        if (response.status === 200 && response.data) {
          const {data} = response;
          //   console.log("data", data);
          this.setState({getCategoryList: data.content}, () =>
            this.checkEnable(),
          );
        }
      })

      .catch(error => {
        // console.log(error);
      });
  };

  updateCategoryInfo = (index, status) => {
    const {getCategoryList, isDisableClicked} = this.state;
    // console.log("Updated details", getCategoryList);
    // console.log("enabled/diabled", status);
    axios
      .post(`${apiURL}/aboutshawer/UpdateCategory`, {
        // category_list: getCategoryList,
        arabic_name: getCategoryList[index].arabic_name,
        id: getCategoryList[index].id,
        status,
      })

      .then(response => {
        // console.log(response);
        this.getCategoryDetails();
      })
      .catch(error => {
        console.log(error);
      });
    this.setState({currentEditing: -1});
    // console.log("updated getCategoryList", getCategoryList);
  };

  // addCategoryDetails = () => {
  //   const {
  //     getCategoryList,
  //     sub_category,
  //     upload,
  //     selectedCategory,
  //   } = this.state;

  //   console.log("Selected Category:", selectedCategory);
  //   axios
  //     .post(`${apiURL}/aboutshawer/AddCategory`, {
  //       catgeory_id: selectedCategory,
  //       sub_category,
  //       upload,
  //     })

  //     .then(response => {
  //       console.log(response);
  //     })

  //     .catch(error => {
  //       console.log(error);
  //     });
  // };

  addCategoryDetails = () => {
    const {
      getCategoryList,
      sub_category,
      category_name,
      upload,
      file,
      arabic_name,
      // base64URL,
      selectedCategory,
    } = this.state;

    // console.log(" Category:", category_name);
    // console.log("file:", file.base64);

    const categoryData = new FormData();
    // console.log("file", file);

    // append the data
    categoryData.append("category_name", category_name);
    categoryData.append("arabic_name", arabic_name);
    categoryData.append("upload", file);

    // console.log("category data", categoryData);

    axios
      .post(`${apiURL}/aboutshawer/AddCategory`, categoryData, {
        headers: {"Content-Type": "multipart/form-data"},
      })

      .then(response => {
        this.getCategoryDetails();
        // console.log(response);
      })

      .catch(error => {
        // console.log(error);
      });
  };

  handleOnCategoryName = e => {
    this.setState({category_name: e.target.value});
  };

  //   handleOnChange = e => {
  //     const {selectedCategoryList} = this.state;
  //     const {value} = e.target;
  //     const {index, key} = e.target.dataset;

  //     console.log(`Index is ${index}... Key is ${key}`);

  //     const subCats = selectedCategoryList.sub_category;

  //     const updatedSubCats = subCats.map(item => {
  //       if (item.key === key) {
  //         return {
  //           key,
  //           value,
  //         };
  //       }
  //       return item;
  //     });

  //     console.log("Updated Sub Cats are", updatedSubCats);
  //     // selectedCategoryList.sub_category.splice(index, 1, value);

  //     selectedCategoryList.sub_category = updatedSubCats;

  //     // Object.assign(selectedCategoryList, {sub_category: updatedSubCats});

  //     console.log(selectedCategoryList);

  //     console.log("Updated SubCategoryList", selectedCategoryList);

  //     this.setState({selectedCategoryList});
  //     // console.log("targettttttttt", selectedCategoryList);
  //   };

  

  onDisableClicked = index => {
    const {isenabled} = this.state;
    this.setState({
      isDisableClicked: true,
      currentEditing: index,
      isenabled: false,
    });
    // console.log("disabled clicked", isenabled);
  };

  onEnableClicked = index => {
    const {isenabled} = this.state;
    this.setState({
      isDisableClicked: false,
      currentEditing: -1,
      isenabled: true,
    });
    // console.log("enabled clicked", isenabled);
  };

  openPopup() {
    this.setState({open: true});
  }

  closePopup() {
    this.setState({open: false});
  }

  openEnablePopup() {
    this.setState({openEnable: true});
  }

  closeEnablePopup() {
    this.setState({openEnable: false});
  }

  render() {
    const {
      open,

      isCategoryButtonActive,
      isCategoryArabicButtonActive,
      getCategoryList,
      isCategoryActive,
      openEnable,
      currentEditing,
    } = this.state;
    return (
      <FullWrap>
        <SmartToaster store={toast} lightBackground position="top_center" />
        <AddCategoryButton onClick={this.openPopup}>
          Add Category
        </AddCategoryButton>
        {/* <RatingTitle>Shawer Categories</RatingTitle>
        <ListButtonWrap>
          <CategoryListButton
            onClick={this.onCategoryListClicked}
            isActive={isCategoryButtonActive}
          >
            Category List(English)
          </CategoryListButton>
          <PopularListButton
            onClick={this.onCategoryArabicListClicked}
            isActive={isCategoryArabicButtonActive}
          >
            Category List(Arabic)
          </PopularListButton>
        </ListButtonWrap> */}

        <CategoryListWrap>
          {/* ---------Formik----------- */}
          <div>
            <Formik
              enableReinitialize
              initialValues={{qualificationDetails: getCategoryList}}
              render={({values}) => (
                <Form>
                  {console.log("getCategoryList", getCategoryList)}
                  <FieldArray
                    // rerenderOnEveryChange
                    name="qualificationDetails"
                    render={({arrayHelpers, remove}) => (
                      <React.Fragment>
                        {values.qualificationDetails.map(
                          (qualDetail, index) => (
                            <CategoryInputWrap key={index}>
                              <FormField
                                type="text"
                                placeholder="Write here..."
                                // value={qualification[index]}
                                name={`qualificationDetails[${index}].arabic_name`}
                                data-name={qualDetail.arabic_name}
                                data-index={index}
                                // isActive={
                                //   index === currentEditing
                                //     ? isDisableClicked
                                //     : false
                                // }
                                isactive={qualDetail.isEnable === false}
                                active={
                                  index === currentEditing
                                    ? isCategoryActive
                                    : null
                                }
                                disabled={index !== currentEditing}
                                onChange={
                                  index === currentEditing
                                    ? e => this.handleOnCategoryChange(e, index)
                                    : null
                                }
                              />

                              {qualDetail.isEnable === false ? (
                                <EditRemoveButtonWrap>
                                  <RemoveButton
                                    type="button"
                                    data-index={index}
                                    onClick={() => {
                                      const status = true;
                                      this.onEnableClicked(index);
                                      this.updateCategoryInfo(index, status);
                                    }}
                                  >
                                    Enable
                                  </RemoveButton>
                                </EditRemoveButtonWrap>
                              ) : (
                                <EditRemoveButtonWrap>
                                  <EditButton
                                    type="button"
                                    data-index={index}
                                    // onClick={this.handleOnButtonClick}
                                    onClick={() => {
                                      this.handleOnButtonClick(index);
                                    }}
                                  >
                                    Edit
                                  </EditButton>
                                  <RemoveButton
                                    type="button"
                                    data-index={index}
                                    onClick={() => {
                                      const status = true;
                                      this.updateCategoryInfo(index, status);
                                      toast.info("Data Updated!");
                                    }}
                                    // onClick={() => remove(index)}
                                    // onClick={e => {
                                    //   const myIndex = e.target.dataset.index;
                                    //   arrayHelpers.remove(myIndex);
                                    // }}
                                    // onClick={() =>
                                    //   this.updateQualificationInfo(index)
                                    // }
                                  >
                                    Save
                                  </RemoveButton>

                                  <RemoveButton
                                    type="button"
                                    data-index={index}
                                    onClick={() => {
                                      const status = false;
                                      this.onDisableClicked(index);
                                      this.updateCategoryInfo(index, status);
                                    }}
                                  >
                                    Disable
                                  </RemoveButton>
                                </EditRemoveButtonWrap>
                              )}
                            </CategoryInputWrap>
                          ),
                        )}
                      </React.Fragment>
                    )}
                  />
                </Form>
              )}
            />
          </div>

          <Modal
            isOpen={openEnable}
            onRequestClose={this.closeEnablePopup}
            style={customStyles}
            ariaHideApp={false}
          >
            <EnablePopupWrap>
              <EnablePopupHeading>Alert!!</EnablePopupHeading>
              <EnablePopupLabel>
                There should be minmum 3 Categories Enabled.
              </EnablePopupLabel>
              <EnablePopupOkButton>OK</EnablePopupOkButton>
            </EnablePopupWrap>
          </Modal>

          <Modal
            isOpen={open}
            onRequestClose={this.closePopup}
            style={customStyles}
            ariaHideApp={false}
          >
            <EditCategoryWrap>
              <CloseButton onClick={this.closePopup}>
                <CloseButtonImg src={closebtn} />
              </CloseButton>

              <Categorytitle>Add Category</Categorytitle>
              <ColumnWrap>
                <ColumnOneWrap>
                  <CategoryPopupLabel>Category Name</CategoryPopupLabel>

                  {/* <React.Fragment> */}
                  <RemoveButtonWrap>
                    <CategoryEditInput
                      // data-index={index}
                      // data-key={subcategory.key}
                      onChange={this.handleOnCategoryName}
                      // placeholder="C++"
                      // value={subcategory.value}
                    />
                    {/* <RemoveButton>Remove</RemoveButton> */}
                  </RemoveButtonWrap>
                  {/* </React.Fragment> */}

                  <CategoryPopupLabel>Category Name(Arabic)</CategoryPopupLabel>
                  {/* <React.Fragment> */}
                  <RemoveButtonWrap>
                    <CategoryEditInput
                      // data-index={index}
                      // data-key={subcategory.key}
                      onChange={this.handleOnArabicCategoryName}
                      // placeholder="C++"
                      // value={subcategory.value}
                    />

                    {/* <RemoveButton>Remove</RemoveButton> */}
                  </RemoveButtonWrap>
                  {/* </React.Fragment> */}
                </ColumnOneWrap>
                <ColumnTwoWrap>
                  <CategoryPopupLabel>Category Icon</CategoryPopupLabel>
                  <UploadSVGFormatLabel>
                    Please upload icon in .PNG format
                  </UploadSVGFormatLabel>
                  {/* <AddIconMore>Add Icon</AddIconMore> */}
                  <RemoveButtonWrap small>
                    <input
                      id="upload"
                      name="upload"
                      type="file"
                      multiple={false}
                      accept="image/png"
                      onChange={this.handleFileInputChange}
                    />
                    {/* <CategoryPopupLabel>Computer.svg</CategoryPopupLabel> */}
                    {/* <RemoveButton>Remove</RemoveButton> */}
                  </RemoveButtonWrap>
                  {/* <RankPreferenceLabel>
                      Rank this category when user has not selected his/her
                      preference
                    </RankPreferenceLabel> */}
                  <CategoryPopupLabel />
                  {/* <CategoryEditInput placeholder="   1" /> */}
                </ColumnTwoWrap>
              </ColumnWrap>
              <DoneButton
                onClick={() => {
                  this.addCategoryDetails();
                  this.closePopup();
                  toast.info("Category has been added!");
                }}
              >
                Done
              </DoneButton>
            </EditCategoryWrap>
          </Modal>
        </CategoryListWrap>
      </FullWrap>
    );
  }
}

export default ShawerCategoriesArabicForm;
