import React from "react";
import styled from "styled-components";
import axios from "axios";
import {SmartToaster, toast} from "react-smart-toaster";
import {Form, Field, FieldArray, Formik} from "formik";
import Modal from "react-modal";
import "./reactpopup.css";
import closebtn from "./closebtn.png";
import downarrow from "./downarrow.svg";
import SubCategories from "./SubCategories";

// api URL
import {apiURL, generateSHA2, createBuffer, Spinner} from "../../utils/util";

const FullWrap = styled.div`
  width: 950px;
  margin-top: 2em;
  margin-left: 1em;
`;

const AddCategoryButton = styled.button`
  height: 30px;
  border-radius: 5px;
  background-color: #e54666;
  color: #fff;
  font-size: 15px;
  margin-left: 750px;
  margin-top: 0px;
  outline: 0;
  cursor: pointer;
`;

const CategoryListWrap = styled.div`
  margin-top: 20px;
`;

const CategoryInputWrap = styled.div`
  padding: 15px;
  border-bottom: 1px solid #dddddd;
  margin: 0px 80px 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EditRemoveButtonWrap = styled.div`
  /* margin-left: 550px; */
  width: 140px;
  display: flex;
  justify-content: space-between;
`;

const EditButton = styled.button`
  outline: 0;
  border: none;
  color: #39a1dd;
  font-size: 15px;
  cursor: pointer;
`;

const RemoveButton = styled.button`
  outline: 0;
  border: none;
  color: #d0021b;
  font-size: 15px;
  cursor: pointer;
`;

const ArrowImg = styled.img`
  pointer-events: none;
`;

// popup css
const EditCategoryWrap = styled.div`
  /* margin: 20px; */
  /* padding: 20px; */
`;

const Categorytitle = styled.h2`
  color: #0052a9;
  margin-top: 0px;
`;

const CategoryPopupLabel = styled.label`
  /* margin-bottom: 15px; */
  margin-bottom: ${props => (props.small ? "0" : "15")}px;
`;

const ColumnWrap = styled.div`
  display: flex;
  width: 700px;
  justify-content: space-between;
`;

const ColumnOneWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0px 0px 0px;
  border-right: solid 1px #979797;
  width: 450px;
`;

const ColumnTwoWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0px 0px 20px;
  width: 450px;
`;

const CloseButton = styled.button`
  outline: 0;
  border: none;
  cursor: pointer;
  margin-left: 600px;
  margin-top: -10px;
`;

const CloseButtonImg = styled.img`
  height: 30px;
  width: 30px;
`;

const UploadSVGFormatLabel = styled.label`
  color: #999999;
  font-size: 12px;
  margin-bottom: 15px;
`;

const RemoveButtonWrap = styled.div`
  width: 350px;
  width: ${props => (props.small ? "250" : "350")}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DoneButton = styled.button`
  height: 30px;
  margin: 20px;
  width: 100px;
  border-radius: 4px;
  background-color: #085db0;
  color: #fff;
  margin-left: 300px;
  outline: 0;
  cursor: pointer;
`;

const customStyles = {
  content: {
    height: "45%",
    width: "47%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const customStylesAlert = {
  content: {
    height: "25%",
    width: "27%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const CategoryEditInput = styled.input`
  height: 40px;
  width: 250px;
  border-radius: 5px;
  border: 1px solid #e9eff4;
  margin-bottom: 10px;
`;

const OkButton = styled.button`
  height: 30px;
  width: 100px;
  border-radius: 5px;
  background-color: #39a1dd;
  color: #fff;
  font-size: 13px;

  outline: 0;
  cursor: pointer;
`;

const EnablePopupWrap = styled.div`
  display: flex;
  flex-direction: column;
`;
const EnablePopupHeading = styled.h2``;
const EnablePopupLabel = styled.label``;
const EnablePopupOkButton = styled.button`
  border-radius: 5px;
  background-color: #39a1dd;
  color: #fff;
  height: 30px;
  width: 80px;
  margin-left: 250px;
  cursor: pointer;
  margin-top: 30px;
`;

const FormField = styled(Field)`
  position: ${props => (props.absolute ? "absolute" : "")};
  width: 40%;
  /* border: solid 1px #d0d0d0; */
  border: none;
  text-indent: 15px;
  padding: 10px 10px 10px 5px;
  font-size: 17px;
  border-radius: 4px;
  background-color: #ffffff;
  opacity: 0.5;
  outline: none;
  /* border-bottom: ${props => (props.isActive ? "2px solid #e54666" : "")}; */
  border: ${props => (props.active ? "1px solid #ddd" : "")};
  text-decoration: ${props => (props.isactive ? "line-through" : "none")};
  ::-webkit-input-placeholder {
    font-family: Ubuntu;
    /* font-size: 20px; */
    font-weight: 300;
    color: #0d3868;
    opacity: 0.5;
    padding: 15px;
    /* text-align: left; */
  }
`;

const ArrowButton = styled.button`
  background-color: transparent;
  outline: 0;
  border: none;
  cursor: pointer;
`;

const email = window.sessionStorage.getItem("email");
const password = window.sessionStorage.getItem("token");

class ShawerCategoriesEnglishForm extends React.Component {
  state = {
    getCategoryList: [],
    currentEditing: -1,
    isCategoryActive: false,
    isenabled: true,
    count: 0,
    openEnable: false,
    open: false,
    file: null,
    toggleButton: false,
    isToggleButtonClicked: true,
    selectedDetails: null,
    subCategories: [],
    isLoading: false,
  };

  openPopup = this.openPopup.bind(this);

  closePopup = this.closePopup.bind(this);

  openEnablePopup = this.openEnablePopup.bind(this);

  closeEnablePopup = this.closeEnablePopup.bind(this);

  // lifecycle methods

  componentDidMount() {
    this.getCategoryDetails();
  }

  // api calls

  getCategoryDetails = () => {
    const buffer = createBuffer({email});
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/aboutshawer/GetCategoryList`,
        {
          json_string: JSON.stringify({email}),
        },
        {headers: {signature, email, channel: "web"}},
      )

      .then(response => {
        if (response.status === 200 && response.data) {
          const {data} = response;

          this.setState({getCategoryList: data.content}, () =>
            this.checkEnable(),
          );
        }
      })

      .catch(error => {
        // console.log(error);
      });
  };

  handleOnToggleButtonClicked = () => {
    this.setState({
      toggleButton: true,
    });
  };

  handleOnEditButtonClick = index => {
    this.setState({currentEditing: index, isCategoryActive: true});
  };

  updateCategoryInfo = (index, status) => {
    const {getCategoryList, count} = this.state;

    const buffer = createBuffer({
      email,
      arabic_name: getCategoryList[index].arabic_name,
      name: getCategoryList[index].category_name,
      id: getCategoryList[index].id,
      status,
    });
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/aboutshawer/UpdateCategory`,
        {
          json_string: JSON.stringify({
            email,
            arabic_name: getCategoryList[index].arabic_name,
            name: getCategoryList[index].category_name,
            id: getCategoryList[index].id,
            status,
          }),
        },
        {headers: {signature, email, channel: "web"}},
      )

      .then(response => {
        // console.log("response", response);
      })
      .catch(error => {
        //  console.log(error);
      });
    this.setState({currentEditing: -1});
  };

  updateEnableDisableCategoryInfo = (index, status) => {
    const {getCategoryList, count} = this.state;

    const buffer = createBuffer({
      email,
      id: getCategoryList[index].id,
      status,
    });
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/aboutshawer/UpdateCategoryStatus`,
        {
          json_string: JSON.stringify({
            email,
            id: getCategoryList[index].id,
            status,
          }),
        },
        {headers: {signature, email, channel: "web"}},
      )

      .then(response => {
        //  console.log("response", response);
        this.getCategoryDetails();
      })
      .catch(error => {
        // console.log(error);
      });
    this.setState({currentEditing: -1});
  };

  handleOnArabicCategoryChange = (e, index) => {
    const {getCategoryList} = this.state;
    const {value} = e.target;
    const {name} = e.target.dataset;
    getCategoryList[index].arabic_name = value;
    this.setState({
      getCategoryList,
    });
  };

  handleOnEnglishCategoryChange = (e, index) => {
    const {getCategoryList} = this.state;

    const {value} = e.target;
    const {name} = e.target.dataset;

    getCategoryList[index].category_name = value;

    this.setState({
      getCategoryList,
    });
  };

  onDisableClicked = index => {
    const {isenabled, count} = this.state;
    const status = false;
    if (count > 3) {
      this.setState(
        {
          currentEditing: index,
          isenabled: false,
        },
        () => this.updateEnableDisableCategoryInfo(index, status),
      );
    } else {
      this.openEnablePopup();
    }
  };

  onEnableClicked = index => {
    const {isenabled} = this.state;
    this.setState({
      currentEditing: -1,
      isenabled: true,
    });
  };

  handleOnCategoryName = e => {
    this.setState({category_name: e.target.value});
  };

  handleOnArabicCategoryName = e => {
    this.setState({arabic_name: e.target.value});
  };

  getBase64 = file => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      const reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        // console.log("Called", reader);
        baseURL = reader.result;
        // console.log("baseURL", baseURL);
        resolve(baseURL);
      };
      // console.log("file info", fileInfo);
    });
  };

  handleFileInputChange = e => {
    //  console.log(e.target.files[0]);
    let {file} = this.state;

    file = e.target.files[0];

    this.getBase64(file)
      .then(result => {
        file.base64 = result;
        // console.log("File Is", file);
        this.setState({
          file,
        });
      })
      .catch(err => {
        // console.log(err);
      });

    this.setState({
      file: e.target.files[0],
    });

    // console.log("file dataaaa", e.target.files[0]);
  };

  addCategoryDetails = () => {
    const {category_name, arabic_name, file, isLoading} = this.state;

    const categoryData = new FormData();
    // console.log("file", file);

    this.setState({isLoading: true});

    const json_string = JSON.stringify({
      email,
      category_name,
      arabic_name,
    });

    // append the data

    const buffer = createBuffer({
      email,
      category_name,
      arabic_name,
    });
    const signature = generateSHA2(buffer, password);
    categoryData.append("json_string", json_string);
    categoryData.append("upload", file);
    axios

      .post(
        `${apiURL}/aboutshawer/AddCategory`,

        categoryData,
        {
          headers: {
            signature,
            email,
            channel: "web",
          },
        },
      )

      .then(response => {
        this.closePopup();
        toast.info("Category has been added!");
        this.getCategoryDetails();
        this.setState({isLoading: false});
      })

      .catch(error => {
        alert(error.response.data.content);
        this.setState({isLoading: false});
      });
  };

  checkEnable = () => {
    let {getCategoryList, count} = this.state;

    count = 0;
    getCategoryList.forEach(cat => {
      if (cat.isEnable === true) {
        count += 1;
      }
    });
    this.setState({count});
    return count;
  };

  openPopup() {
    this.setState({open: true});
  }

  closePopup() {
    this.setState({open: false});
  }

  openEnablePopup() {
    this.setState({openEnable: true});
  }

  closeEnablePopup() {
    this.setState({openEnable: false});
  }

  render() {
    const {
      getCategoryList,
      currentEditing,
      isCategoryActive,
      open,
      openEnable,
      toggleButton,
      selectedDetails,
      isToggleButtonClicked,
      isLoading,
    } = this.state;
    return (
      <FullWrap>
        <SmartToaster store={toast} lightBackground position="top_center" />
        <AddCategoryButton onClick={this.openPopup}>
          Add Category
        </AddCategoryButton>
        <CategoryListWrap>
          {/* ---------Formik----------- */}
          <div>
            <Formik
              enableReinitialize
              initialValues={{categoryDetails: getCategoryList}}
              render={({values}) => (
                <Form>
                  {/* {console.log("values", values.categoryDetails)} */}
                  <FieldArray
                    name="categoryDetails"
                    render={({arrayHelpers}) => (
                      <React.Fragment>
                        {values.categoryDetails.map((catDetail, index) => (
                          <>
                            <CategoryInputWrap key={index}>
                              {/* {console.log(catDetail)} */}

                              <ArrowButton
                                onClick={() => {
                                  this.setState({
                                    selectedDetails: index,
                                    isToggleButtonClicked: !isToggleButtonClicked,
                                  });
                                }}
                              >
                                <ArrowImg src={downarrow} />
                              </ArrowButton>

                              <FormField
                                type="text"
                                name={`categoryDetails[${index}].category_name`}
                                data-name={catDetail.category_name}
                                data-index={index}
                                disabled={index !== currentEditing}
                                isactive={catDetail.isEnable === false}
                                active={
                                  index === currentEditing
                                    ? isCategoryActive
                                    : null
                                }
                                onChange={
                                  index === currentEditing
                                    ? e =>
                                        this.handleOnEnglishCategoryChange(
                                          e,
                                          index,
                                        )
                                    : null
                                }
                              />

                              <FormField
                                type="text"
                                name={`categoryDetails[${index}].arabic_name`}
                                data-name={catDetail.arabic_name}
                                data-index={index}
                                disabled={index !== currentEditing}
                                isactive={catDetail.isEnable === false}
                                active={
                                  index === currentEditing
                                    ? isCategoryActive
                                    : null
                                }
                                onChange={
                                  index === currentEditing
                                    ? e =>
                                        this.handleOnArabicCategoryChange(
                                          e,
                                          index,
                                        )
                                    : null
                                }
                              />

                              {catDetail.isEnable === false ? (
                                <EditRemoveButtonWrap>
                                  <RemoveButton
                                    type="button"
                                    data-index={index}
                                    onClick={() => {
                                      const status = true;
                                      const mode = "enable";
                                      this.onEnableClicked(index);
                                      this.updateEnableDisableCategoryInfo(
                                        index,
                                        status,
                                      );
                                    }}
                                  >
                                    Enable
                                  </RemoveButton>
                                </EditRemoveButtonWrap>
                              ) : (
                                <EditRemoveButtonWrap>
                                  <EditButton
                                    type="button"
                                    data-index={index}
                                    onClick={() => {
                                      this.handleOnEditButtonClick(index);
                                    }}
                                  >
                                    Edit
                                  </EditButton>
                                  <RemoveButton
                                    type="button"
                                    data-index={index}
                                    onClick={() => {
                                      const status = true;
                                      const mode = "edit";
                                      this.updateCategoryInfo(index, status);
                                      toast.info("Data Updated!");
                                    }}
                                  >
                                    Save
                                  </RemoveButton>

                                  <RemoveButton
                                    type="button"
                                    data-index={index}
                                    onClick={() => {
                                      const status = false;
                                      const mode = "disable";
                                      this.onDisableClicked(index);
                                      // this.updateEnableDisableCategoryInfo(
                                      //   index,
                                      //   status,
                                      // );
                                    }}
                                  >
                                    Disable
                                  </RemoveButton>
                                </EditRemoveButtonWrap>
                              )}
                            </CategoryInputWrap>
                            {isToggleButtonClicked &&
                            selectedDetails === index ? (
                              <>
                                <SubCategories
                                  categoryId={
                                    values.categoryDetails[selectedDetails].id
                                  }
                                  subCategories={
                                    values.categoryDetails[selectedDetails]
                                      .sub_category
                                  }
                                />
                              </>
                            ) : null}
                          </>
                        ))}
                      </React.Fragment>
                    )}
                  />
                </Form>
              )}
            />
          </div>

          <Modal
            isOpen={openEnable}
            onRequestClose={this.closeEnablePopup}
            style={customStylesAlert}
            ariaHideApp={false}
          >
            <EnablePopupWrap>
              <EnablePopupHeading>Alert!!</EnablePopupHeading>

              <EnablePopupLabel>
                There should be minmum 3 Categories Enabled.
              </EnablePopupLabel>
              <EnablePopupOkButton onClick={this.closeEnablePopup}>
                OK
              </EnablePopupOkButton>
            </EnablePopupWrap>
          </Modal>

          <Modal
            isOpen={open}
            onRequestClose={this.closePopup}
            style={customStyles}
            ariaHideApp={false}
          >
            <EditCategoryWrap>
              <CloseButton onClick={this.closePopup}>
                <CloseButtonImg src={closebtn} />
              </CloseButton>

              <Categorytitle>Add Category</Categorytitle>
              <ColumnWrap>
                <ColumnOneWrap>
                  <CategoryPopupLabel>
                    Category Name(English)
                  </CategoryPopupLabel>
                  {/* <React.Fragment> */}
                  <RemoveButtonWrap>
                    <CategoryEditInput
                      // data-index={index}
                      // data-key={subcategory.key}
                      onChange={this.handleOnCategoryName}
                      // placeholder="C++"
                      // value={subcategory.value}
                    />

                    {/* <RemoveButton>Remove</RemoveButton> */}
                  </RemoveButtonWrap>
                  {/* </React.Fragment> */}

                  <CategoryPopupLabel>Category Name(Arabic)</CategoryPopupLabel>
                  {/* <React.Fragment> */}
                  <RemoveButtonWrap>
                    <CategoryEditInput
                      // data-index={index}
                      // data-key={subcategory.key}
                      onChange={this.handleOnArabicCategoryName}
                      // placeholder="C++"
                      // value={subcategory.value}
                    />

                    {/* <RemoveButton>Remove</RemoveButton> */}
                  </RemoveButtonWrap>
                  {/* </React.Fragment> */}
                </ColumnOneWrap>
                <ColumnTwoWrap>
                  <CategoryPopupLabel>Category Icon</CategoryPopupLabel>
                  <UploadSVGFormatLabel>
                    Please upload icon in .PNG format
                  </UploadSVGFormatLabel>
                  {/* <AddIconMore>Add Icon</AddIconMore> */}
                  <RemoveButtonWrap small>
                    <input
                      id="upload"
                      name="upload"
                      type="file"
                      multiple={false}
                      accept="image/png"
                      onChange={this.handleFileInputChange}
                    />
                    {/* <CategoryPopupLabel>Computer.svg</CategoryPopupLabel> */}
                    {/* <RemoveButton>Remove</RemoveButton> */}
                  </RemoveButtonWrap>
                  {/* <RankPreferenceLabel>
                      Rank this category when user has not selected his/her
                      preference
                    </RankPreferenceLabel> */}
                  <CategoryPopupLabel />
                  {/* <CategoryEditInput placeholder="   1" /> */}
                </ColumnTwoWrap>
              </ColumnWrap>
              <DoneButton
                onClick={() => {
                  this.addCategoryDetails();
                }}
              >
                Done {isLoading ? <Spinner /> : null}
              </DoneButton>
            </EditCategoryWrap>
          </Modal>
        </CategoryListWrap>
      </FullWrap>
    );
  }
}

export default ShawerCategoriesEnglishForm;
