import React from "react";
import styled from "styled-components";
import moment from "moment";
import ReactTable from "react-table";
import "react-table/react-table.css";
import NumberFormat from "react-number-format";
import axios from "axios";
import {apiURL, createBuffer, generateSHA2, searchInTable} from "../../utils/util";

const FullTableWrap = styled.div`
  margin-left: 1.2em;

  margin-top: 2.5em;
  max-height: 100%;
  width: 1200px;
  background-color: #fff;
`;

const TableWrap = styled.div`
  margin-left: 20px;
  width: 1150px;
`;

const TableHeaderDiv = styled.div`
  /* text-align: left; */
  text-align: ${props => (props.center ? "center" : "left")};
`;

const FilterInput = styled.input`
  ::-webkit-input-placeholder {
    font-size: 10px;
  }
`;

const email = window.sessionStorage.getItem("email");
const password = window.sessionStorage.getItem("token");

class ChatTransactionReactTable extends React.Component {
  state = {
    ChatTransactionList: [],
    loading: true,
  };

  // lifecycle methods

  componentDidMount() {
    this.getChatTransactionDetails();
  }

  // api calls

  getChatTransactionDetails = () => {
    const buffer = createBuffer({email});
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/transaction/ChatTransaction`,
        {
          json_string: JSON.stringify({email}),
        },
        {headers: {signature, email, channel: "web"}},
      )

      // .post(`${apiURL}/transaction/ChatTransaction`, {})

      .then(response => {
        // console.log(response);

        if (response.status === 200 && response.data) {
          // console.log(response.data);
          const {data} = response;
          // console.log("Data", data);

          this.setState({ChatTransactionList: data.content, loading: false});
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };

  render() {
    const {ChatTransactionList, loading} = this.state;
    const columns = [
      {
        id: "date",
        Header: () => (
          <TableHeaderDiv center>
            <b>Date</b>
          </TableHeaderDiv>
        ),

        style: {color: "#4D4F5C"},
        accessor: d => {
          return moment(d.created_at)
            .format("DD-MM-YYYY");
        },
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search User Name..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        sortMethod: (a, b) => {
          const splitdate1 = a.split("-");
          const splitdate2 = b.split("-");
          const date1 = new Date(
            splitdate1[2],
            splitdate1[1],
            splitdate1[0],
          ).getTime();
          const date2 = new Date(
            splitdate2[2],
            splitdate2[1],
            splitdate2[0],
          ).getTime();

          // const date2
          if (date1 > date2) return 1;
          return -1;
        },
        filterMethod: (filter, row) => searchInTable(filter, row),
      },
      {
        id: "username",
        Header: () => (
          <TableHeaderDiv center>
            <b>User Name</b>
          </TableHeaderDiv>
        ),
        // Header: "User Name",
        // accessor: "username",
        style: {color: "#4D4F5C"},
        accessor: d => (d.user_name ? d.user_name : "-"),
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search User Name..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        filterMethod: (filter, row) => searchInTable(filter, row),
      },
      {
        id: "expertname",
        Header: () => (
          <TableHeaderDiv center>
            <b>Expert Name</b>
          </TableHeaderDiv>
        ),
        // Header: "User Name",
        // accessor: "expertname",
        style: {color: "#4D4F5C"},
        accessor: d => (d.expert_name ? d.expert_name : "-"),
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search Expert Name..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        filterMethod: (filter, row) => searchInTable(filter, row),
      },
      {
        id: "chatid",
        Header: () => (
          <TableHeaderDiv center>
            <b>Chat ID</b>
          </TableHeaderDiv>
        ),
        // Header: "User Name",
        // accessor: "chatid",
        accessor: d => d.chat_id,
        width: 250,
        style: {textAlign: "center", color: "#4D4F5C"},
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search Chat ID..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        filterMethod: (filter, row) => searchInTable(filter, row),
      },
      {
        id: "typeofchat",
        Header: () => (
          <TableHeaderDiv center>
            <b>Type of Chat</b>
          </TableHeaderDiv>
        ),

        // Header: "User Name",
        // accessor: "typeofchat",
        accessor: d =>
          d.chat_type === "online"
            ? "Online"
            : d.chat_type === "offline"
            ? "Offline"
            : d.chat_type === "schedule"
            ? "Schedule"
            : "-",
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search Type of Chat..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        filterMethod: (filter, row) => searchInTable(filter, row),
        style: {textAlign: "center", color: "#4D4F5C"},
      },
      {
        id: "amount",
        Header: () => (
          <TableHeaderDiv center>
            <b>Amount</b>
          </TableHeaderDiv>
        ),
        // Header: "Amounts",
        // accessor: "amount",
        accessor: d => d.amount,
        style: {textAlign: "center", color: "#4D4F5C"},
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search Amount..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        Cell: props => {
          // console.log(props);
          return (
            <span>
              {props.value ? (
                <NumberFormat
                  value={props.value}
                  decimalScale={2}
                  displayType="text"
                />
              ) : (
                0
              )}
            </span>
          );
        },
      },
      {
        id: "status",
        Header: () => (
          <TableHeaderDiv center>
            <b>Status</b>
          </TableHeaderDiv>
        ),
        style: {color: "#4D4F5C"},
        // Header: "Status",
        // accessor: "status",
        // accessor: d => d.online_status,
        accessor: d => {
          let statustype;
          if (d.chat_type === "online") {
            statustype =
              d.online_status === "success"
                ? "Success"
                : d.online_status === "paid"
                ? "Paid"
                : "-";
          } else if (d.chat_type === "offline") {
            statustype =
              d.offline_status === "pending"
                ? "Pending"
                : d.offline_status === "paid"
                ? "Paid"
                : "-";
          } else if (d.chat_type === "schedule") {
            statustype =
              d.schedule_status === "pending"
                ? "Pending"
                : d.schedule_status === "cancelled_by_user"
                ? "Cancelled by User"
                : d.schedule_status === "rescheduled_by_user"
                ? "Rescheduled by User"
                : "-";
          }
          return statustype;
        },
        Filter: ({filter, onChange}) => (
          <FilterInput
            type="text"
            placeholder="Search Status..."
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),
        filterMethod: (filter, row) => searchInTable(filter, row),
      },
    ];
    return (
      <FullTableWrap>
        <TableWrap>
          <ReactTable
            // NoDataComponent={() => null}
            noDataText={loading ? "" : "No Rows Found"}
            loading={loading}
            loadingText="Loading..."
            filterable
            columns={columns}
            data={ChatTransactionList}
            defaultPageSize={10}
            // expanded={expanded}
            // showPaginationTop
            // showPaginationBottom={false}
          />
        </TableWrap>
      </FullTableWrap>
    );
  }
}

export default ChatTransactionReactTable;
