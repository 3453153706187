import React from "react";
import styled from "styled-components";
import axios from "axios";
import moment, {min, max} from "moment";
import Yup from "yup";

import "react-datepicker/dist/react-datepicker.css";
// import DatePicker from "react-date-picker";
import {SmartToaster, toast} from "react-smart-toaster";
// import DatePicker from "react-date-picker/dist/entry.nostyle";
import {withFormik, Form, Field} from "formik";
import "./datetimepicker.css";
import Select from "react-select";

import approved from "./approvedbtn.png";
import notapproved from "./notapprovedbtn.png";
import Allapprove from "./Allapprove.svg";

// api URL
import {apiURL, createBuffer, generateSHA2} from "../../utils/util";

const FullWrap = styled.div`
  width: 950px;
  /* border: 1px solid black; */
  display: flex;
  flex-wrap: wrap;
  margin-top: 2em;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.23);
  background-color: #ffffff;
  margin-left: 1em;
  border-radius: 6px;
`;

const NumberSpan = styled.span`
  opacity: 0.3;
  padding: 10px 12px 11px 12px;
  border: solid 1px #d0d0d0;
  border-right: none;
  border-radius: 4px 0px 0px 4px;
  border: solid 1px #d0d0d0;
  background-color: rgba(244, 247, 249, 0.4);
  color: #4da1ff;
`;

const LableInput = styled.div`
  margin: 20px;
  width: ${props => (props.full ? 840 : 400)}px;
`;

const Label = styled.label`
  font-size: 16px;
  color: #323c47;
`;

const ApproveAllImg = styled.img`
  padding-right: 1em;
  height: 35px;
`;

const Img = styled.img`
  padding-right: 1em;
`;

const FormField = styled(Field)`
  margin-top: 10px;
  position: ${props => (props.absolute ? "absolute" : "")};
  width: 100%;
  // border: solid 1px #d0d0d0;
  border: ${props => (props.active ? "1px solid #d0d0d0" : "none")};
  text-indent: 15px;
  padding: 10px;
  font-size: 17px;
  border-radius: 4px;
  background-color: #ffffff;
  opacity: 0.5;
  ::-webkit-input-placeholder {
    font-family: Ubuntu;
    /* font-size: 20px; */
    font-weight: 300;
    color: #0d3868;
    opacity: 0.5;
    /* padding: 15px; */
    /* text-align: left; */
  }
`;
const PhoneNumField = styled(FormField)`
  margin-top: 10px;
  width: 330px;
  border-radius: 0px 4px 4px 0px;

  ::-webkit-input-placeholder {
    padding-left: 45px;
  }
`;

const GenderFieldLegend = styled.legend`
  padding-left: 5px;
  padding-right: 10px;
`;

const GenderField = styled.label`
  display: flex;
  margin-left: 10px;
  justify-content: space-between;
`;
const GenderFieldWrap = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  /* border: 1px solid grey; */
  // border: solid 1px #e9eff4;
  border: ${props => (props.active ? "1px solid #d0d0d0" : "none")};
  border-radius: 4px;
  background-color: #ffffff;
  opacity: 0.5;
  height: 45px;
  align-items: center;
  width: 200px;
`;

const ConfirmFullWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const ConfirmWrap = styled.div`
  display: flex;
  margin: 20px 20px 20px 0px;
  width: 600px;
  justify-content: space-between;
`;
const ConfirmButton = styled.button`
  /* background-color: ${props => (props.red ? "#E80000" : "#00B936")}; */
  /* border: 1px solid black; */
  outline:0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 17px;
  border-radius: 5px;
  cursor:pointer;
  /* width: 150px; */
  width: ${props => (props.notapproved ? 170 : 150)}px;
  margin-right: 20px;
  margin-left: 15px;
  height: 40px;
  text-decoration: ${props => (props.underline ? "underline" : "")};
  color: ${props => (props.underline ? "#39a1dd" : "")};
`;

// const EditButton = styled.button`
//   margin-left: 800px;
//   margin-top: 20px;
//   width: 100px;
//   height: 30px;
//   border-radius: 5px;
//   background-color: #085db0;
//   color: white;
//   font-size: small;
// `;

const DoneButton = styled.button`
  width: 100px;
  height: 40px;
  border-radius: 5px;
  background-color: #085db0;
  color: white;
  cursor: pointer;
  margin-left: 20px;
`;

const FormWrap = styled.div`
  width: 900px;
  display: flex;
  flex-wrap: wrap;
`;

const EditRemoveButtonWrap = styled.div`
  width: 100px;
  display: flex;
  justify-content: space-between;
  margin-left: 800px;
  margin-top: 20px;
`;

const SaveButton = styled.button`
  border: none;
  color: #39a1dd;
  font-size: 20px;
  cursor: pointer;
  outline: 0;
`;

const EditButton = styled.button`
  border: none;
  color: #d0021b;
  font-size: 20px;
  cursor: pointer;
  outline: 0;
`;

const CommentTextBox = styled.textarea`
  /* width: 450px; */
  width: 100%;
  margin: 20px;
  height: 100px;
  font-size: 15px;
  /* margin-left: 120px; */
`;

const ApprovedMsgDiv = styled.div`
  margin-left: 20px;
`;

const ApprovedMsg = styled.label`
  color: #39a1dd;
`;

const ErrorSpan = styled.span`
  font-size: 12px;
`;

const options = [
  {
    value: "English",
    label: "English",
  },
  {
    value: "Arabic",
    label: "Arabic",
  },
];

const email = window.sessionStorage.getItem("email");
const password = window.sessionStorage.getItem("token");

class FullForm extends React.Component {
  state = {
    value: "",
    isFieldEnable: false,
    newDate: new Date(),

    optionSelected: null,
    expertID: sessionStorage.getItem("expertID"),

    personal_info: {
      name: "",
      lastname: "",
      gender: "",
      email: "",
      phone_number: "",
      full_address: "",
      language_preference: "",
      profile_pic: "",
      title: "",
      headline: "",
      about_yourself: "",
      dob: "",
      new_title:"",
      new_gender:"",
      new_education_details:"",
      new_about_yourself:"",
      new_phone_number:""
    },
    // status: "",
    isFieldActive: false,
    isNotOkClicked: false,
    admin_comment: "",
    isOkCkicked: false,
    notokclicked: false,
    isApproveAllClicked: false,
    oldEmail: "",
    updatedEmail: "",
    errors: {},
  };

  componentDidMount() {
    this.getParticularExpertDetails();
    this.handleOptions();
  }

  getParticularExpertDetails = () => {
    const {expertID, personal_info} = this.state;
    const buffer = createBuffer({email, docid: expertID});
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/expert/getParticularExpert`,
        {
          json_string: JSON.stringify({email, docid: expertID}),
        },
        {headers: {signature, email, channel: "web"}},
      )

      .then(response => {
        // console.log(response);
        if (response.status === 200 && response.data) {
          const {data} = response;
          console.log(data);
          this.setState(
            {
              personal_info: data.personal_info,
              isNotOkClicked: false,
              oldEmail: data.personal_info.email,
            },
            () => this.handleOptions(),
          );
        }
      })
      .catch(error => {
        // console.log(error);
      });
  };

  handleOptions = () => {
    let {optionSelected, personal_info} = this.state;
    // console.log("language", personal_info);
    if (personal_info.language_preference !== null) {
      optionSelected = {
        value: personal_info.language_preference,
        label: personal_info.language_preference,
      };
      this.setState({optionSelected});
      // console.log(
      //   "personal_info.language_preference",
      //   personal_info.language_preference,
      // );
      // console.log(optionSelected);
    }
  };

  updatePersonalInfo = () => {
    const {expertID, personal_info, optionSelected} = this.state;
    personal_info.language_preference = optionSelected.value;
    console.log(personal_info)
    const buffer = createBuffer({
      email,
      expert_id: expertID,
      data: personal_info,
    });
    const signature = generateSHA2(buffer, password);

    console.log("signature", signature);

    axios
      .post(
        `${apiURL}/expert/UpdatePersonalInfo`,
        {
          json_string: JSON.stringify({
            email,
            expert_id: expertID,
            data: personal_info,
          }),
        },
        {headers: {signature, email, channel: "web"}},
      )

      .then(response => {
        this.getParticularExpertDetails();
      })
      .catch(err => {});
    this.setState({isFieldActive: false});
  };

  emailUpdate = () => {
    const {personal_info, updatedEmail, oldEmail} = this.state;

    const buffer = createBuffer({
      new_email: updatedEmail,
      old_email: oldEmail,
    });
    const signature = generateSHA2(buffer, password);

    console.log("signature", signature);

    axios
      .post(
        `${apiURL}/expert/SendExpertVerificationLink`,
        {
          json_string: JSON.stringify({
            new_email: updatedEmail,
            old_email: oldEmail,
          }),
        },
        {headers: {signature, email, channel: "web"}},
      )

      .then(response => {
        console.log("email update res", response);
      })
      .catch(err => {});
  };

  handleOnAdminChange = e => {
    this.setState({admin_comment: e.target.value});
  };

  expertApproval = () => {
    const {
      expertID,
      personal_info,
      isOkCkicked,
      notokclicked,
      isNotOkClicked,
      admin_comment,
    } = this.state;
    const {expertApprovalObj} = this.props;

    const admincomment = admin_comment;
    // console.log("admin comment", expertApprovalObj);
    let status = expertApprovalObj.personal_info_status;

    status = isOkCkicked ? "accepted" : notokclicked ? "rejected" : null;

    expertApprovalObj.personal_info_status = status;

    if (status === "accepted" || status === "rejected") {
      const buffer = createBuffer({
        email,
        expert_id: expertID,
        expert_approve: expertApprovalObj,
      });
      const signature = generateSHA2(buffer, password);

      axios.post(
        `${apiURL}/expert/ExpertApproval`,
        {
          json_string: JSON.stringify({
            email,
            expert_id: expertID,
            expert_approve: expertApprovalObj,
          }),
        },
        {headers: {signature, email, channel: "web"}},
      );

      // axios
      //   .post(`${apiURL}/expert/ExpertApproval`, {
      //     expert_id: expertID,
      //     expert_approve: expertApprovalObj,
      //   })

      //   .then(response => {
      //     // console.log(response);
      //   });
    }
  };

  onApproveAllClicked = () => {
    this.setState({
      isApproveAllClicked: true,
    });
  };

  handleExpertApproval = () => {
    this.setState({
      isOkCkicked: true,
    });

    // this.setState({});

    setTimeout(() => {
      const {
        expertID,
        isOkCkicked,
        isNotOkClicked,
        admin_comment,
        isApproveAllClicked,
      } = this.state;
      const {expertApprovalObj} = this.props;

      let expertStatus = expertApprovalObj.personal_info_status;

      expertApprovalObj.personal_info_comment = admin_comment;

      // expertStatus = isOkCkicked
      //   ? "accepted"
      //   : isNotOkClicked
      //   ? "rejected"
      //   : null;
      if (isOkCkicked) {
        if (
          expertStatus === "submitted" ||
          expertStatus === "rejected" ||
          expertStatus === null
        ) {
          expertStatus = "accepted";
        }

        // console.log("Is Ok Clicked called", expertStatus);
      } else {
        // console.log("Is Ok Clicked called", isOkCkicked);
      }
      if (isNotOkClicked) {
        expertStatus = "rejected";
      }

      expertApprovalObj.personal_info_status = expertStatus;

      if (isApproveAllClicked) {
        expertStatus = "accepted";
        expertApprovalObj.personal_info_status = expertStatus;
        expertApprovalObj.experience_status = expertStatus;
        expertApprovalObj.education_status = expertStatus;
        expertApprovalObj.service_charges_status = expertStatus;
        expertApprovalObj.skills_status = expertStatus;
        expertApprovalObj.personal_info_comment = null;
        expertApprovalObj.experience_comment = null;
        expertApprovalObj.education_comment = null;
        expertApprovalObj.service_charges_comment = null;
        expertApprovalObj.skills_comment = null;
      }

      const buffer = createBuffer({
        email,
        expert_id: expertID,
        expert_approve: expertApprovalObj,
      });
      const signature = generateSHA2(buffer, password);

      axios
        .post(
          `${apiURL}/expert/ExpertApproval`,
          {
            json_string: JSON.stringify({
              email,
              expert_id: expertID,
              expert_approve: expertApprovalObj,
            }),
          },
          {headers: {signature, email, channel: "web"}},
        )

        .then(response => {
          this.getParticularExpertDetails();
          // toast.info("Personal Information Approved!");
          // console.log(response);
        });
    }, 2);
    // console.log("After Api Call", expertApprovalObj);
  };

  handleOptionSelectedChange = optionSelected => {
    this.setState({optionSelected});
  };

  handleDateChange = newDate => {
    this.setState({newDate});
  };

  onNotOkClicked = () => {
    this.setState({
      notokclicked: true,
    });
  };

  handleOnDateChange = e => {
    const {personal_info} = this.state;
  };

  handleOnNameChange = e => {
    const {personal_info} = this.state;
    const tempNameObj = personal_info;
  };

  handleOnChange = e => {
    const {personal_info} = this.state;

    const {name, value} = e.target;
    const tempObj = {
      [name]: value,
    };

    if (name === "email" && value !== personal_info.email) {
      this.setState({updatedEmail: value});
    }

    const personalInfoObj = personal_info;
    const copyobj = Object.assign(personalInfoObj, tempObj);

    this.setState({personal_info});
  };

  notOkClicked = () => {
    this.setState({
      isNotOkClicked: true,
    });
  };

  handleOnButtonClick = () => {
    this.setState({isFieldEnable: true, isFieldActive: true});
  };

  getValudFromKeys = (data, key1, key2) => {
    return data[key1] ? data[key1] : data[key2];
  }

  render() {
    const {
      newDate,
      isNotOkClicked,
      optionSelected,
      personal_info,
      isFieldEnable,
      isFieldActive,
      full_address,
      value,
      updatedEmail,
    } = this.state;
    const {
      values,
      touched,
      errors,
      setFieldValue,
      handleChange,
      handleBlur,
      handleSubmit,
      expertApprovalObj,
    } = this.props;

    return (
      <Form onSub>
        <FullWrap>
          <SmartToaster store={toast} lightBackground position="top_center" />
          <EditRemoveButtonWrap>
            <SaveButton
              onClick={() => {
                this.handleOnButtonClick();
              }}
            >
              Edit
            </SaveButton>

            <EditButton
              type="submit"
              onClick={() => {
                if (updatedEmail !== "") {
                  // console.log("updatedEmail IF", updatedEmail);
                  // this.updatePersonalInfo();
                  this.emailUpdate();
                } else {
                  // console.log("updatedEmail else", updatedEmail);
                  this.updatePersonalInfo();
                }

                toast.info("Personal Information Updated!");
              }}
            >
              Save
            </EditButton>
          </EditRemoveButtonWrap>

          <FormWrap>
            <LableInput>
              <Label> NAME</Label>

              <FormField
                placeholder="Write here..."
                type="text"
                name="name"
                value={personal_info.name}
                active={isFieldEnable ? isFieldActive : null}
                // value={personal_info.name}
                // onChange={e => {
                //   this.handleOnChange(e);
                // }}
                onChange={
                  isFieldEnable
                    ? e => {
                        this.handleOnChange(e);
                      }
                    : null
                }
                // value={firstname}
              />
            </LableInput>

            <LableInput>
              {/* <Label>LAST NAME</Label>
              <FormField
                placeholder="Write here..."
                type="text"
                name=""
                active={isFieldEnable ? isFieldActive : null}
                // value={personal_info.lastname}
                value={lname}
                // onChange={
                //   isFieldEnable
                //     ? e => {
                //         this.handleOnChange(e);
                //       }
                //     : null
                // }
              /> */}
            </LableInput>

            <LableInput>
              <Label>TITLE</Label>

              <FormField
                placeholder="Write here..."
                type="title"
                name="title"
                value={personal_info.personal_info_status == "submitted" ? personal_info.new_title : personal_info.title}
                active={isFieldEnable ? isFieldActive : null}
                onChange={
                  isFieldEnable
                    ? e => {
                        this.handleOnChange(e);
                      }
                    : null
                }
              />
            </LableInput>

            {/* <LableInput> */}
              {/* <Label>HEADLINE</Label>
              <FormField
                placeholder="Write here..."
                type="text"
                name="headline"
                value={personal_info.headline}
                active={isFieldEnable ? isFieldActive : null}
                onChange={
                  isFieldEnable
                    ? e => {
                        this.handleOnChange(e);
                      }
                    : null
                }
              /> */}
            {/* </LableInput> */}

            <LableInput>
              <Label>EMAIL ADDRESS</Label>
              <FormField
                placeholder="Write here..."
                // type="email"
                name="email"
                value={personal_info.email}
                active={isFieldEnable ? isFieldActive : null}
                onChange={
                  isFieldEnable
                    ? e => {
                        this.handleOnChange(e);
                      }
                    : null
                }
              />
            </LableInput>

            <LableInput>
              <Label>PHONE NUMBER</Label>
              <div>
                <NumberSpan>+966</NumberSpan>
                <PhoneNumField
                  type="text"
                  maxLength="9"
                  title="Please enter exactly 9 digits"
                  // pattern="[0-9]{1}[0-9]{8}"
                  placeholder="000000000"
                  name="phone_number"
                  active={isFieldEnable ? isFieldActive : null}
                  value={personal_info.personal_info_status == "submitted" ? personal_info.new_phone_number : personal_info.phone_number}
                  onChange={
                    isFieldEnable
                      ? e => {
                          this.handleOnChange(e);
                        }
                      : null
                  }
                />
              </div>
            </LableInput>
            {/* {errors.phone_number && touched.name && (
              <ErrorSpan>{errors.phone_number}</ErrorSpan>
            )} */}

            <LableInput>
              <Label> DATE OF BIRTH</Label>
              <FormField
                placeholder="Write here..."
                // type="email"
                name="dob"
                value={moment(personal_info.dob)
                  .local()
                  .format("DD-MM-YYYY")}
                active={isFieldEnable ? isFieldActive : null}
                onChange={
                  isFieldEnable
                    ? e => {
                        this.handleOnChange(e);
                      }
                    : null
                }
              />
            </LableInput>

            <LableInput>
              <Label>GENDER</Label>
              <GenderFieldWrap>
                <GenderField>
                  {/* <GenderFormField component="div" name="gender"> */}
                  <input
                    type="radio"
                    id="male"
                    name="gender"
                    // checked={personal_info.personal_info_status == "submitted" ? personal_info.new_gender.toLowerCase() === "male" : personal_info.gender.toLowerCase() === "male"}
                    checked={this.getValudFromKeys(personal_info, 'new_gender', 'gender').toLowerCase() === "male"}
                    value="male"
                    // active={isFieldEnable ? isFieldActive : null}
                    onChange={
                      isFieldEnable
                        ? e => {
                            this.onMaleRadioButtonSelected(e);
                          }
                        : null
                    }
                  />
                  <GenderFieldLegend>Male</GenderFieldLegend>
                  <input
                    type="radio"
                    id="female"
                    name="gender"
                    // checked={personal_info.personal_info_status == "submitted" ? personal_info.new_gender.toLowerCase() === "female" : personal_info.gender.toLowerCase() === "female"}
                    checked={this.getValudFromKeys(personal_info, 'new_gender', 'gender').toLowerCase() === "female"}
                    value="female"
                    onChange={
                      isFieldEnable
                        ? e => {
                            this.onFemaleRadioButtonSelected(e);
                          }
                        : null
                    }
                  />
                  <GenderFieldLegend>Female</GenderFieldLegend>
                  {/* </GenderFormField> */}
                </GenderField>
              </GenderFieldWrap>
            </LableInput>

            {/* <LableInput> */}
              {/* <Label>PREFERENCE LANGUAGE</Label>

              <Select
                active={isFieldEnable ? isFieldActive : null}
                name="language_preference"
                value={optionSelected}
                // value={personal_info.language_preference}
                // value={options.find(
                //   option => option.value === personal_info.language_preference,
                // )}
                // onChange={this.handleOptionSelectedChange}
                onChange={
                  isFieldEnable
                    ? e => {
                        this.handleOptionSelectedChange(e);
                      }
                    : null
                }
                options={options}
                styles={{
                  control: () => ({
                    marginTop: "10px",
                    display: "flex",
                    borderRadius: "4px",
                    border: "1px solid #e9eff4",
                  }),
                }}
              /> */}
            {/* </LableInput> */}

            <LableInput full>
              <Label>Education Details</Label>
              <FormField
                placeholder="Write here..."
                component="textarea"
                name="education_details"
                active={isFieldEnable ? isFieldActive : null}
                value={personal_info.personal_info_status == "submitted" ? personal_info.new_education_details : personal_info.education_details}
                // onChange={e => {
                //   this.handleOnChange(e);
                // }}
                onChange={
                  isFieldEnable
                    ? e => {
                        this.handleOnChange(e);
                      }
                    : null
                }
              />
              {/* {address} */}
            </LableInput>

            <LableInput full>
              <Label>About Expert</Label>
              <FormField
                placeholder="Write here..."
                component="textarea"
                name="about_yourself"
                active={isFieldEnable ? isFieldActive : null}
                value={personal_info.personal_info_status == "submitted" ? personal_info.new_about_yourself : personal_info.about_yourself}
                onChange={
                  isFieldEnable
                    ? e => {
                        this.handleOnChange(e);
                      }
                    : null
                }
              />
            </LableInput>
            {personal_info.personal_info_comment ? (
              <LableInput full>
                <Label>Not Approved Reason</Label>
                <FormField
                  placeholder="Write here..."
                  component="textarea"
                  name="not_approved_reason"
                  // active={isFieldEnable ? isFieldActive : null}
                  value={personal_info.personal_info_comment}
                  // onChange={
                  //   isFieldEnable
                  //     ? e => {
                  //         this.handleOnChange(e);
                  //       }
                  //     : null
                  // }
                />
              </LableInput>
            ) : null}

            {expertApprovalObj.personal_info_status === "submitted" ? (
              <LableInput>
                <Label>Status</Label>
                <FormField
                  type="text"
                  placeholder="Write here..."
                  value="Expert needs to be approved.."
                />
              </LableInput>
            ) : expertApprovalObj.personal_info_status === "rejected" ? (
              <LableInput>
                <Label>Status</Label>
                <FormField
                  type="text"
                  placeholder="Write here..."
                  value="Expert is not approved.."
                />
              </LableInput>
            ) : null}

            <ConfirmFullWrap>
              {expertApprovalObj.personal_info_status === "accepted" ? (
                <ApprovedMsgDiv>
                  <ApprovedMsg>Personal Info has been approved</ApprovedMsg>
                </ApprovedMsgDiv>
              ) : null}

              <ConfirmWrap>
                <ConfirmButton
                  type="button"
                  disabled={
                    expertApprovalObj.personal_info_status === "accepted"
                  }
                  onClick={this.handleExpertApproval}
                >
                  <Img src={approved} />
                  Approved
                </ConfirmButton>
                <ConfirmButton
                  red
                  notapproved
                  type="button"
                  onClick={this.notOkClicked}
                >
                  <Img src={notapproved} />
                  Not Approved
                </ConfirmButton>
                <ConfirmButton
                  red
                  notapproved
                  type="button"
                  disabled={
                    expertApprovalObj.personal_info_status === "accepted" &&
                    expertApprovalObj.experience_status === "accepted" &&
                    expertApprovalObj.education_status === "accepted" &&
                    expertApprovalObj.service_charges_status === "accepted" &&
                    expertApprovalObj.skills_status === "accepted"
                  }
                  onClick={() => {
                    this.onApproveAllClicked();
                    this.handleExpertApproval();
                  }}
                >
                  <ApproveAllImg src={Allapprove} />
                  Approve All
                </ConfirmButton>
              </ConfirmWrap>
            </ConfirmFullWrap>

            {isNotOkClicked ? (
              // <ConfirmButton underline>
              //   +Add Comment
              // </ConfirmButton>
              <div>
                <CommentTextBox
                  type="text"
                  onChange={e => this.handleOnAdminChange(e)}
                  placeholder="Add Comments Here.."
                />
                <DoneButton
                  type="button"
                  onClick={
                    () =>
                      // this.onNotOkClicked();
                      {
                        this.handleExpertApproval();
                        this.getParticularExpertDetails();
                      }
                    // toast.info(" Message sent to the expert!");
                  }
                >
                  Done!
                </DoneButton>
              </div>
            ) : null}
            <div />
          </FormWrap>
        </FullWrap>
      </Form>
    );
  }
}
const FormikFullForm = withFormik({
  mapPropsToValues: () => ({phone_number: ""}),
  validate: values => {
    // console.log("values", values);
    const errors = {};

    if (!values.phone_number) {
      errors.phone_number = "Required";
    }

    return errors;
  },
})(FullForm);

export default FormikFullForm;
