import React from "react";
import styled from "styled-components";
import DatePicker from "react-date-picker";
import axios from "axios";
// import DatePicker from "react-date-picker/dist/entry.nostyle";
import "../Common/datetimepicker.css";
import {withFormik, Form, Field, FieldArray, Formik} from "formik";

// import DatePicker from "react-date-picker/dist/entry.nostyle";

// api URL
import {apiURL, createBuffer, generateSHA2} from "../../utils/util";

// import "../../styles/global.css";

const FullWrap = styled.div`
  width: 950px;
  /* border: 1px solid black; */
  display: flex;
  /* flex-wrap: wrap; */
  margin-top: 2em;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.23);
  background-color: #ffffff;
  margin-left: 1em;
  border-radius: 6px;
  flex-direction: column;
`;
const TwoFieldWrap = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 700px;
`;
const ThreeFieldWrap = styled.div`
  display: flex;
`;

const LableInput = styled.div`
  margin: 20px;
  width: ${props => (props.full ? 640 : 270)}px;
`;

const Label = styled.label`
  font-size: 12px;
  color: #323c47;
`;

const FormField = styled(Field)`
  margin-top: 10px;
  position: ${props => (props.absolute ? "absolute" : "")};
  width: 100%;
  border: solid 1px #e9eff4;
  text-indent: 15px;
  padding: 10px;
  font-size: 17px;
  border-radius: 4px;
  background-color: #ffffff;
  opacity: 0.5;
  ::-webkit-input-placeholder {
    font-family: Ubuntu;
    /* font-size: 20px; */
    font-weight: 300;
    color: #0d3868;
    opacity: 0.5;
    padding: 15px;
    /* text-align: left; */
  }
`;
const email = window.sessionStorage.getItem("email");
const password = window.sessionStorage.getItem("token");

export default class FinancialForm extends React.Component {
  state = {
    expertID: sessionStorage.getItem("expertID"),
    financial: [],
  };

  componentDidMount() {
    this.getParticularExpertDetails();
  }

  getParticularExpertDetails = () => {
    const {expertID} = this.state;

    const buffer = createBuffer({email, expert_id: expertID});
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/expert/FinancialDetails`,
        {
          json_string: JSON.stringify({email, expert_id: expertID}),
        },
        {headers: {signature, email, channel: "web"}},
      )

      // axios
      //   .post(`${apiURL}/expert/FinancialDetails`, {
      //     expert_id: expertID,
      //   })
      .then(response => {
        if (response.status === 200 && response.data) {
          const {data} = response;

          this.setState({financial: data.content.bank_details});
          // console.log(data);
          // if (this.state.experience) {
          //   this.setState({apiLoading: false});
          // }
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    const {financial} = this.state;

    return (
      <div>
        {/* {console.log("financial info", financial)} */}

        <Formik
          enableReinitialize
          initialValues={{financialDetails: financial}}
          // initialValues={{
          //   experienceDetails: [
          //     {
          //       experience_id: "DRhwaP87qJcK37PsEppb",
          //       data: {
          //         company: "Saudi Electricity Co",
          //         fileList: [],
          //         from_year: "",
          //         location: "Haradh",
          //         title: "Android Developer",
          //         to_year: "",
          //       },
          //     },

          //     {
          //       experience_id: "LFOCp8ZznGQmQF9JpjFG",
          //       data: {
          //         company: "Saudi Telecom Co (STC)",
          //         fileList: [],
          //         from_year: "",
          //         location: "Granada Mall Parking",
          //         title: "Android Developer",
          //         to_year: "",
          //       },
          //     },
          //   ],
          // }}
          render={({values}) => (
            <Form>
              {financial ? (
                <FieldArray
                  name="financialDetails"
                  render={() => (
                    <React.Fragment>
                      {values.financialDetails.map((finDetail, index) => (
                        // <div key={expDetail.experience_id}>
                        // {console.log(values)}
                        <FullWrap key={finDetail.id}>
                          <TwoFieldWrap>
                            <LableInput>
                              <Label>FULL NAME</Label>
                              <FormField
                                type="text"
                                placeholder="Write here..."
                                // value={bank_details[index].full_name}
                                // name={`financialDetails[${index}].full_name`}
                                value={financial[index].full_name}
                                name={`financialDetails[${index}].full_name`}
                                data-name="full_name"
                              />
                            </LableInput>

                            <LableInput>
                              <Label>NICK NAME</Label>
                              <FormField
                                type="text"
                                placeholder="Write here..."
                                value={financial[index].nick_name}
                                name={`financialDetails[${index}].nick_name`}
                                data-name="nick_name"
                              />
                            </LableInput>
                          </TwoFieldWrap>

                          <ThreeFieldWrap>
                            <LableInput>
                              <Label>IBAN</Label>
                              <FormField
                                type="text"
                                placeholder="Write here..."
                                value={financial[index].iban}
                                name={`financialDetails[${index}].iban`}
                                data-name="iban"
                              />
                            </LableInput>

                            <LableInput>
                              <Label>BANK NAME</Label>
                              <FormField
                                type="text"
                                value={financial[index].bank_name}
                                name={`financialDetails[${index}].bank_name`}
                                data-name="bank_name"
                              />
                            </LableInput>
                          </ThreeFieldWrap>
                        </FullWrap>
                      ))}
                    </React.Fragment>
                  )}
                />
              ) : (
                "Financial Information Not Available"
              )}
              {/* <FullWrap> */}
              {console.log("Values are", values)}

              {/* </FullWrap> */}
            </Form>
          )}
        />
      </div>
    );
  }
}
