import React from "react";
import styled from "styled-components";

import Switch from "react-switch";
import filterimg from "./filter.png";

const FullTableWrap = styled.div`
  margin-left: 4em;
  margin-right: 4em;
  margin-top: 2em;
  max-height: 100%;

  background-color: #ffffff;
`;

const TitleDiv = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
`;
const FilterImage = styled.img``;

const Title = styled.label`
  color: #1a173b;
  font-size: 20px;
  padding-right: 10px;
`;
const TableWrap = styled.div`
  padding-top: 20px;
  /* padding-left: 30px; */
`;

const Table = styled.table`
  /* border: 1px solid black; */
  width: 100%;
  border-collapse: collapse;
`;
const TableHeader = styled.thead``;

const TableRowHead = styled.tr``;

const TableDataHead = styled.th`
  color: #b4bac6;
  font-size: 12px;
  padding-bottom: 20px;
  padding-left: 30px;
  text-align: ${props => (props.center ? "center" : "left")};
`;
const TableBody = styled.tbody``;

const ReviewButton = styled.button`
  width: 100px;
  height: 30px;
  background-color: #004389;
  border-radius: 4px;
  color: white;
  font-size: 15px;
`;

const ButtonLink = styled.a``;

const TableRow = styled.tr`
  /* width: 100%; */
`;

const TableData = styled.td`
  border-bottom: ${props => (props.none ? "none" : "1px solid #d7d3d3")};
  padding: 7px;
  font-size: 15px;
  color: ${props => (props.others ? "#8a96a0" : "#354052")};
  /* width: 21%; */
  text-align: ${props => (props.center ? "center" : "left")};
  padding-left: 30px;
`;
class ExpertTable extends React.Component {
  // constructor() {
  //   super(props);
  // }

  state = {checked: false};

  onSwitchHandleChange = this.onSwitchHandleChange.bind(this);

  componentDidMount() {
    this.getExpertDetails();
  }

  onSwitchHandleChange(checked) {
    this.setState({checked});
  }

  getExpertDetails = () => {};

  render() {
    const {handlereview, expertsList} = this.props;
    // console.log("Expert List", expertsList);
    const {checked} = this.state;
    return (
      <FullTableWrap>
        <TitleDiv>
          <Title>January 2018</Title>
          <FilterImage src={filterimg} />
        </TitleDiv>
        <TableWrap>
          <Table>
            <TableHeader>
              <TableRowHead>
                <TableDataHead>NAME</TableDataHead>
                <TableDataHead>INTEREST</TableDataHead>
                <TableDataHead>NUMBER OF ONLINE CHATS</TableDataHead>
                <TableDataHead>NUMBER OF OFFLINE CHATS</TableDataHead>
                <TableDataHead>NUMBER OF APPOINTMENTS</TableDataHead>
                <TableDataHead>LAST ONLINE TIME</TableDataHead>
                <TableDataHead center>STATUS</TableDataHead>
                <TableDataHead center>STATUS</TableDataHead>
              </TableRowHead>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableData none>Piku Vasandia</TableData>
                <TableData none others>
                  Home & Leisure
                </TableData>
                <TableData none others center>
                  20
                </TableData>
                <TableData none others center>
                  10
                </TableData>
                <TableData none others center>
                  15
                </TableData>
                <TableData none others center>
                  5:00 PM
                </TableData>
                <TableData none center>
                  {/* <ReviewButton>
                    Support
                    <ButtonLink />
                  </ReviewButton> */}
                  <Switch
                    onChange={this.onSwitchHandleChange}
                    checked={checked}
                    height={22}
                    width={48}
                    handleDiameter={26}
                    offColor="#F28F95"
                    offHandleColor="#E80000"
                    onColor="#A4F2BD"
                    onHandleColor="#00B936"
                    uncheckedIcon={false}
                    checkedIcon={false}
                  />
                </TableData>
                <TableData none center>
                  <ReviewButton onClick={handlereview}>
                    Review
                    <ButtonLink />
                  </ReviewButton>
                </TableData>
              </TableRow>
            </TableBody>
          </Table>
        </TableWrap>
      </FullTableWrap>
    );
  }
}

export default ExpertTable;
