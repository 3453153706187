import React from "react";
import styled from "styled-components";
import axios from "axios";
import NavigationBar from "../NavigationBar/NavigationBar";
import SideBar from "../NavigationBar/SideBar";

import AboutShawerSideBar from "./AboutShawerSideBar";

// api URL
import {apiURL} from "../../utils/util";

import ShawerCategories from "./ShawerCategories";
import Ratings from "./Ratings";
import ExpertProfileDetails from "./ExpertProfileDetails";
import ContactUs from "./ContactUs";
import TermsOfUse from "./TermsOfUse";
import PrivacyPolicy from "./PrivacyPolicy";
import NotAccessiblePNG from "../Common/NotAccessiblePNG.png";
import Transactions from "./Transaction";

const FullSection = styled.section`
  background-color: #fafbfc;
  display: grid;
  grid-template-rows: 1fr auto;
  max-height: 100%;

  /* grid-template-columns: auto auto; */
`;
const LabelInputWrap = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 4.5em;
  margin-left: 4.5em;
  margin-top: 0.5em;
`;

const ExpertListLabel = styled.h2`
  font-size: 17px;
  color: #1a173b;
  margin-left: 23px;
`;
const SearchInput = styled.input`
  padding: 10px;
  width: 18%;
  border-radius: 5px;
  background-color: #e8f0f6;
  text-align: center;
  border: 0;
  ::placeholder {
    color: #004389;
  }
`;
const BacktoExpertButton = styled.button`
  color: #ffffff;
  padding: 10px;
  width: 18%;
  border-radius: 5px;
  background-color: #e54666;
  text-align: center;
  border: 0;
`;
const PageOneContentWrap = styled.div``;
const PageTwoContentWrap = styled.div``;
const PageTwoContent = styled.div`
  display: flex;
  border-radius: 3.8px;
  border: solid 1px #ebedf8;
  background-color: #ffffff;
  margin: 0.5em 0 2em 6em;
  height: 100%;
`;
const FilterOption = styled.img`
  padding-right: 10px;
`;

const FilterOptionButton = styled.button`
  border: 0;
  background-color: #ffffff;
`;

const TitleLabel = styled.label`
  font-size: 15px;
  padding-right: 10px;
`;

const FullCardWrap = styled.div`
  display: flex;
  margin-left: 70px;
  justify-content: space-between;
  max-width: 750px;
`;

const CardContentWrap = styled.div`
  padding-top: 20px;
  padding-left: 20px;
`;
const LableWrap = styled.div`
  padding: 10px 20px 50px 10px;
`;
const NumberLabel = styled.label`
  /* padding-left: 10px; */
  padding-right: 5px;
  font-size: 18px;
  color: #4da1ff;
`;
const NumberLabelReview = styled(NumberLabel)`
  color: #ff6d4a;
`;

const MonthLabel = styled.label`
  font-size: 15px;
`;
const YearLabel = styled.label`
  font-size: 15px;
`;

const ActiveExpertsList = styled.li`
  list-style: none;
  color: #417505;
`;

const NotAccessibleDiv = styled.div`
  /* margin-left: 100px; */
  /* margin-top: 100px; */
  padding-top: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  height: 100%;
  width: 100%;
`;

const NotAccessibleImg = styled.img``;

const NotAccessibleLabel = styled.h2``;

// const docid = {
//   docid: this.state.docid,
// };
class AboutShawer extends React.Component {
  state = {
    // isPageLoad: true,
    // isOptionClicked: false,
    // expertsList: [],
    // particularExpertDetails: [],
    // expertID: sessionStorage.getItem("expertID"),
    typeofuser: ["superadmin", "superreader", "contentmanager"],
    isAuthourized: false,
    isTransactionsClicked: true,
    isTransactionsActive: true,
    isShawerCategoryDetailsClicked: false,
    isRatingDetailsClicked: false,
    isSExpertProfileDetailsClicked: false,
    isContactUsDetailsClicked: false,
    isTermsOfUseDetailsClicked: false,
    isPrivacyPolicyDetailsClicked: false,
    isShawerCategoryDetailsActive: false,
    isRatingDetailsActive: false,
    isSExpertProfileDetailsActive: false,
    isContactUsDetailsActive: false,
    isTermsOfUseDetailsActive: false,
    isPrivacyPolicyDetailsActive: false,
  };

  // lifecycle methods

  componentDidMount() {
    this.userData();
    this.getAboutShawerDetails();
  }

  // api calls

  getAboutShawerDetails = () => {};

  optionClicked = () => {
    this.setState({
      isOptionClicked: true,
    });
  };

  userData = () => {
    let {isAuthourized, typeofuser} = this.state;
    const role = sessionStorage.getItem("typeofuser");
    isAuthourized = typeofuser.includes(role);
    this.setState({isAuthourized});
  };

  transcationsClicked = () => {
    this.setState({
      isTransactionsClicked: true,
      isTransactionsActive: true,
      isShawerCategoryDetailsClicked: false,
      isShawerCategoryDetailsActive: false,
      isRatingDetailsActive: false,
      isSExpertProfileDetailsActive: false,
      isContactUsDetailsActive: false,
      isTermsOfUseDetailsActive: false,
      isPrivacyPolicyDetailsActive: false,
    });
  };

  shawerCategoriesDetailsClicked = () => {
    this.setState({
      isTransactionsClicked: false,
      isTransactionsActive: false,
      isShawerCategoryDetailsClicked: true,
      isShawerCategoryDetailsActive: true,
      isRatingDetailsActive: false,
      isSExpertProfileDetailsActive: false,
      isContactUsDetailsActive: false,
      isTermsOfUseDetailsActive: false,
      isPrivacyPolicyDetailsActive: false,
    });
  };

  ratingDetailsClicked = event => {
    this.setState({
      isTransactionsClicked: false,
      isTransactionsActive: false,
      isShawerCategoryDetailsClicked: false,
      isRatingDetailsClicked: true,
      isShawerCategoryDetailsActive: false,
      isRatingDetailsActive: true,
      isSExpertProfileDetailsActive: false,
      isContactUsDetailsActive: false,
      isTermsOfUseDetailsActive: false,
      isPrivacyPolicyDetailsActive: false,
    });
  };

  expertProfileDetailsClicked = () => {
    this.setState({
      isTransactionsClicked: false,
      isTransactionsActive: false,
      isShawerCategoryDetailsClicked: false,
      isRatingDetailsClicked: false,
      isSExpertProfileDetailsClicked: true,
      isShawerCategoryDetailsActive: false,
      isRatingDetailsActive: false,
      isSExpertProfileDetailsActive: true,
      isContactUsDetailsActive: false,
      isTermsOfUseDetailsActive: false,
      isPrivacyPolicyDetailsActive: false,
    });
  };

  contactUsDetailsDetailsClicked = () => {
    this.setState({
      isTransactionsClicked: false,
      isTransactionsActive: false,
      isShawerCategoryDetailsClicked: false,
      isRatingDetailsClicked: false,
      isSExpertProfileDetailsClicked: false,
      isContactUsDetailsClicked: true,
      isShawerCategoryDetailsActive: false,
      isRatingDetailsActive: false,
      isSExpertProfileDetailsActive: false,
      isContactUsDetailsActive: true,
      isTermsOfUseDetailsActive: false,
      isPrivacyPolicyDetailsActive: false,
    });
  };

  termsOfUseDetailsClicked = () => {
    this.setState({
      isTransactionsClicked: false,
      isTransactionsActive: false,
      isShawerCategoryDetailsClicked: false,
      isRatingDetailsClicked: false,
      isSExpertProfileDetailsClicked: false,
      isContactUsDetailsClicked: false,
      isTermsOfUseDetailsClicked: true,
      isShawerCategoryDetailsActive: false,
      isRatingDetailsActive: false,
      isSExpertProfileDetailsActive: false,
      isContactUsDetailsActive: false,
      isTermsOfUseDetailsActive: true,
      isPrivacyPolicyDetailsActive: false,
    });
  };

  privacyPolicyDetailsClicked = () => {
    this.setState({
      isTransactionsClicked: false,
      isTransactionsActive: false,
      isShawerCategoryDetailsClicked: false,
      isRatingDetailsClicked: false,
      isSExpertProfileDetailsClicked: false,
      isContactUsDetailsClicked: false,
      isTermsOfUseDetailsClicked: false,
      isPrivacyPolicyDetailsClicked: true,
      isShawerCategoryDetailsActive: false,
      isRatingDetailsActive: false,
      isSExpertProfileDetailsActive: false,
      isContactUsDetailsActive: false,
      isTermsOfUseDetailsActive: false,
      isPrivacyPolicyDetailsActive: true,
    });
  };

  render() {
    const {
      isPageLoad,
      isOptionClicked,
      expertsList,
      expertID,
      particularExpertDetails,
      isTransactionsClicked,
      isTransactionsActive,
      isShawerCategoryDetailsClicked,
      isRatingDetailsClicked,
      isSExpertProfileDetailsClicked,
      isContactUsDetailsClicked,
      isTermsOfUseDetailsClicked,
      isPrivacyPolicyDetailsClicked,
      isShawerCategoryDetailsActive,
      isRatingDetailsActive,
      isSExpertProfileDetailsActive,
      isContactUsDetailsActive,
      isTermsOfUseDetailsActive,
      isPrivacyPolicyDetailsActive,
      typeofuser,
      isAuthourized,
    } = this.state;
    const role = sessionStorage.getItem("typeofuser");
    // const {isPersonalInfoClicked, isEducationInfoClicked} = this.props;

    return (
      <FullSection>
        <NavigationBar />
        <SideBar />
        {/* <PageOneContentWrap>
          <LabelInputWrap>
            <ExpertListLabel>Experts Data Management</ExpertListLabel>
          </LabelInputWrap>
        </PageOneContentWrap> */}
        {isAuthourized ? (
          <PageTwoContentWrap>
            <LabelInputWrap>
              <ExpertListLabel>Content Management</ExpertListLabel>
              {/* <BacktoExpertButton>Back To Expert List</BacktoExpertButton> */}
            </LabelInputWrap>
            <PageTwoContent>
              <AboutShawerSideBar
                transactionsClicked={this.transcationsClicked}
                transactionsActive={isTransactionsActive}
                shawerCategoriesClicked={this.shawerCategoriesDetailsClicked}
                shawerCategoriesActive={isShawerCategoryDetailsActive}
                ratingsClicked={this.ratingDetailsClicked}
                ratingsActive={isRatingDetailsActive}
                expertProfileInfoClicked={this.expertProfileDetailsClicked}
                expertProfileInfoActive={isSExpertProfileDetailsActive}
                contactUsInfoClicked={this.contactUsDetailsDetailsClicked}
                contactUsInfoActive={isContactUsDetailsActive}
                termOfUseInfoClicked={this.termsOfUseDetailsClicked}
                termOfUseInfoActive={isTermsOfUseDetailsActive}
                privacyPolicyInfoClicked={this.privacyPolicyDetailsClicked}
                privacyPolicyInfoActive={isPrivacyPolicyDetailsActive}
              />
              {/* if(isPersonalDetailsClicked)
               {<FullForm onClick={this.personalDetailsClicked} />}
               else if(isEducationDetailsClicked)
               {
                 <QualificationDegreeForm
                   onClick={this.educationDetailsClicked}
                 />
               } */}
              {isTransactionsClicked ? (
                <Transactions />
              ) : isShawerCategoryDetailsClicked ? (
                // <ShawerCategories />
                <ShawerCategories />
              ) : isRatingDetailsClicked ? (
                <Ratings />
              ) : isSExpertProfileDetailsClicked ? (
                <ExpertProfileDetails />
              ) : isContactUsDetailsClicked ? (
                <ContactUs />
              ) : isTermsOfUseDetailsClicked ? (
                <TermsOfUse />
              ) : isPrivacyPolicyDetailsClicked ? (
                <PrivacyPolicy />
              ) : null}
            </PageTwoContent>
          </PageTwoContentWrap>
        ) : (
          <NotAccessibleDiv>
            <NotAccessibleLabel>
              Sorry you’re a{" "}
              {role === "approvalmanager"
                ? "Approval Manager"
                : role === "financialmanager"
                ? "Financial Manager"
                : null}
              . Please contact the Super Admin to access this area.
            </NotAccessibleLabel>
            <NotAccessibleImg alt="Not accessible" src={NotAccessiblePNG} />
          </NotAccessibleDiv>
        )}
      </FullSection>
    );
  }
}

export default AboutShawer;
