import React, {Component} from "react";
import styled from "styled-components";
import {Bar} from "react-chartjs-2";

const ChartWrap = styled.div`
  height: 100px;
  width: 400px;
`;

class DashboardChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: props.chartData,
    };
  }

  render() {
    const {chartData} = this.state;

    return (
      <ChartWrap>
        <Bar data={chartData} />
      </ChartWrap>
    );
  }
}

export default DashboardChart;
