import React from "react";
import styled from "styled-components";
import axios from "axios";
import {SmartToaster, toast} from "react-smart-toaster";
import {Form, Field, FieldArray, Formik} from "formik";
import Modal from "react-modal";
import "./reactpopup.css";
import closebtn from "./closebtn.png";

// api URL
import {apiURL, generateSHA2, createBuffer, Spinner} from "../../utils/util";

const FullWrap = styled.div`
  width: 850px;
  margin-left: 1.2em;
`;

const AddSubCategoryButton = styled.button`
  height: 25px;
  border-radius: 5px;
  background-color: #e54666;
  color: #fff;
  font-size: 12px;
  margin: 5px 0px 5px 700px;

  outline: 0;
  cursor: pointer;
`;

const CategoryListWrap = styled.div`
  background-color: #f5f5f5;
  //   margin-top: 20px;
`;

const CategoryInputWrap = styled.div`
  //   border-bottom: 1px solid #dddddd;
  margin: 0px 80px 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5;
`;

// const EditRemoveButtonWrap = styled.div`
//   width: 150px;
//   display: flex;
//   justify-content: space-between;
//   background-color: transparent;
// `;

const EditRemoveButtonWrap = styled.div`
  width: 150px;
  display: flex;
  justify-content: space-between;
  // background-color: none;
`;
const EditButton = styled.button`
  background-color: transparent;
  outline: 0;
  border: none;
  color: #39a1dd;
  font-size: 15px;
  cursor: pointer;
`;

const RemoveButton = styled.button`
  background-color: transparent;
  outline: 0;
  border: none;
  color: #d0021b;
  font-size: 15px;
  cursor: pointer;
`;

// popup css
const EditCategoryWrap = styled.div`
  /* margin: 20px; */
  /* padding: 20px; */
`;

const Categorytitle = styled.h2`
  color: #0052a9;
  margin-top: 0px;
`;

const CategoryPopupLabel = styled.label`
  /* margin-bottom: 15px; */
  margin-bottom: ${props => (props.small ? "0" : "15")}px;
`;

const ColumnWrap = styled.div`
  display: flex;
  width: 400px;
  justify-content: space-between;
`;

const ColumnOneWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0px 0px 0px;

  width: 350px;
`;

const ColumnTwoWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0px 0px 20px;
  width: 450px;
`;

const CloseButton = styled.button`
  outline: 0;
  border: none;
  cursor: pointer;
  margin-left: 350px;
  margin-top: -10px;
`;

const CloseButtonImg = styled.img`
  height: 30px;
  width: 30px;
`;

const UploadSVGFormatLabel = styled.label`
  color: #999999;
  font-size: 12px;
  margin-bottom: 15px;
`;

const RemoveButtonWrap = styled.div`
  width: 350px;
  width: ${props => (props.small ? "250" : "350")}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DoneButton = styled.button`
  height: 30px;
  margin: 20px 0px 20px 0px;
  width: 100px;
  border-radius: 4px;
  background-color: #085db0;
  color: #fff;

  outline: 0;
  cursor: pointer;
`;

const customStyles = {
  content: {
    height: "45%",
    width: "30%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const customStylesAlert = {
  content: {
    height: "25%",
    width: "27%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const CategoryEditInput = styled.input`
  height: 40px;
  width: 250px;
  border-radius: 5px;
  border: 1px solid #e9eff4;
  margin-bottom: 10px;
`;

const OkButton = styled.button`
  height: 30px;
  width: 100px;
  border-radius: 5px;
  background-color: #39a1dd;
  color: #fff;
  font-size: 13px;

  outline: 0;
  cursor: pointer;
`;

const EnablePopupWrap = styled.div`
  display: flex;
  flex-direction: column;
`;
const EnablePopupHeading = styled.h2``;
const EnablePopupLabel = styled.label``;
const EnablePopupOkButton = styled.button`
  border-radius: 5px;
  background-color: #39a1dd;
  color: #fff;
  height: 30px;
  width: 80px;
  margin-left: 250px;
  cursor: pointer;
  margin-top: 30px;
`;

const FormField = styled(Field)`
background-color: #f5f5f5;
  position: ${props => (props.absolute ? "absolute" : "")};
  width: 40%;
  /* border: solid 1px #d0d0d0; */
  border: none;
  text-indent: 15px;
  padding: 10px 10px 10px 5px;
  font-size: 15px;

  opacity: 0.5;
  outline: none;
  /* border-bottom: ${props => (props.isActive ? "2px solid #e54666" : "")}; */
  // border: ${props => (props.active ? "1px solid #ddd" : "")};
  text-decoration: ${props => (props.isactive ? "line-through" : "none")};
  ::-webkit-input-placeholder {
    font-family: Ubuntu;
    /* font-size: 20px; */
    font-weight: 300;
    color: #0d3868;
    opacity: 0.5;
    padding: 15px;
    /* text-align: left; */
    background-color: #f5f5f5;
  }
`;

const ArrowButton = styled.button``;

const email = window.sessionStorage.getItem("email");
const password = window.sessionStorage.getItem("token");

class SubCategories extends React.Component {
  state = {
    getCategoryList: [
      {
        arabic: "تطوير الذات والوظيفه",
        english: "Career and Self Development",
      },
      {
        arabic: "تطوير الذات والوظيفه",
        english: "Career and Self Development",
      },
    ],
    currentEditing: -1,
    isCategoryActive: false,
    isenabled: true,
    count: 0,
    openEnable: false,
    open: false,
    file: null,
    english_name: "",
    arabic_name: "",
    editedEnglishName: "",
    editedArabicName: "",
    getSubCategory: [],
    isLoading: false,
  };

  openPopup = this.openPopup.bind(this);

  closePopup = this.closePopup.bind(this);

  openEnablePopup = this.openEnablePopup.bind(this);

  closeEnablePopup = this.closeEnablePopup.bind(this);

  // lifecycle methods

  componentDidMount() {
    // this.getsubCategoryDetails();
  }

  // api calls

  getCategoriesDetails = () => {
    const {getCategoriesList} = this.props;
    getCategoriesList();
  };

  getsubCategoryDetails = () => {
    const buffer = createBuffer({email});
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/aboutshawer/GetCategoryList`,
        {
          json_string: JSON.stringify({email}),
        },
        {headers: {signature, email, channel: "web"}},
      )
      // axios
      //   .post(`${apiURL}/aboutshawer/GetCategoryList`, {})

      .then(response => {
        if (response.status === 200 && response.data) {
          const {data} = response;
          //   console.log("data", data);
          this.setState({getCategoryList: data.content}, () =>
            this.checkEnable(),
          );
        }
      })

      .catch(error => {
        // console.log(error);
      });
  };

  handleOnEditButtonClick = index => {
    this.setState({currentEditing: index, isCategoryActive: true});
  };

  updateCategoryInfo = (index, status) => {
    const {getCategoryList, getSubCategory} = this.state;
    const {categoryId, subCategories} = this.props;

    const buffer = createBuffer({
      email,
      sub_category: subCategories,
      category_id: categoryId,
    });
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/aboutshawer/UpdateSubCategory`,
        {
          json_string: JSON.stringify({
            email,
            sub_category: subCategories,
            category_id: categoryId,
          }),
        },
        {headers: {signature, email, channel: "web"}},
      )

      .then(response => {
        this.setState({currentEditing: -1});
      })
      .catch(error => {
        //  console.log(error);
      });
  };

  addSubCategoryDetails = () => {
    const {english_name, arabic_name, isLoading} = this.state;
    const {categoryId} = this.props;

    this.setState({isLoading: true});

    const buffer = createBuffer({
      email,
      english_name,
      arabic_name,
      category_id: categoryId,
    });
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/aboutshawer/AddSubCategory`,
        {
          json_string: JSON.stringify({
            email,
            english_name,
            arabic_name,
            category_id: categoryId,
          }),
        },
        {headers: {signature, email, channel: "web"}},
      )

      .then(response => {
        if (response.status === 200 && response.data) {
          this.closePopup();
          this.setState({isLoading: false});
        }
        // this.getCategoryDetails();
        // console.log(response);
      })

      .catch(error => {
        alert(error.response.data.content);
      });
  };

  updateEnableDisableCategoryInfo = (index, status) => {
    const {getCategoryList, count} = this.state;
    const {subCategories, categoryId} = this.props;
    subCategories[index].isEnable = status;

    const buffer = createBuffer({
      email,
      sub_category: subCategories,
      category_id: categoryId,
    });
    const signature = generateSHA2(buffer, password);

    axios
      .post(
        `${apiURL}/aboutshawer/UpdateSubCategory`,
        {
          json_string: JSON.stringify({
            email,
            sub_category: subCategories,
            category_id: categoryId,
          }),
        },
        {headers: {signature, email, channel: "web"}},
      )

      .then(response => {
        this.setState({currentEditing: -1});
        // this.getCategoriesDetails();z
      })
      .catch(error => {
        // console.log(error);
      });
  };

  handleOnArabicCategoryChange = (e, index) => {
    const {editedArabicName, getSubCategory} = this.state;
    const {subCategories} = this.props;

    const {value} = e.target;
    const {name} = e.target.dataset;
    subCategories[index].arabic_name = value;
    this.setState({
      subCategories: getSubCategory,
    });
  };

  handleOnEnglishCategoryChange = (e, index) => {
    const {editedEnglishName, getSubCategory} = this.state;
    const {subCategories} = this.props;

    const {value} = e.target;
    const {name} = e.target.dataset;

    subCategories[index].english_name = value;

    this.setState({
      subCategories: getSubCategory,
    });
  };

  onDisableClicked = index => {
    const {isenabled, count} = this.state;
    const status = false;

    this.setState(
      {
        currentEditing: index,
        isenabled: false,
      },
      () => this.updateEnableDisableCategoryInfo(index, status),
    );
  };

  onEnableClicked = index => {
    const {isenabled} = this.state;
    this.setState({
      currentEditing: -1,
      isenabled: true,
    });
  };

  handleOnCategoryName = e => {
    this.setState({english_name: e.target.value});
  };

  handleOnArabicCategoryName = e => {
    this.setState({arabic_name: e.target.value});
  };

  checkEnable = () => {
    let {getCategoryList, count} = this.state;

    count = 0;
    getCategoryList.forEach(cat => {
      if (cat.isEnable === true) {
        count += 1;
      }
    });
    this.setState({count});
    return count;
  };

  openPopup() {
    this.setState({open: true});
  }

  closePopup() {
    this.setState({open: false});
  }

  openEnablePopup() {
    this.setState({openEnable: true});
  }

  closeEnablePopup() {
    this.setState({openEnable: false});
  }

  render() {
    const {
      getCategoryList,
      currentEditing,
      isCategoryActive,
      open,
      openEnable,
    } = this.state;

    const {subCategories, categoryId, isLoading} = this.props;

    // console.log("subCategories", subCategories, categoryId);

    return (
      <FullWrap>
        <SmartToaster store={toast} lightBackground position="top_center" />

        <CategoryListWrap>
          <AddSubCategoryButton onClick={this.openPopup}>
            Add Sub Category
          </AddSubCategoryButton>
          {/* ---------Formik----------- */}
          <div>
            <Formik
              enableReinitialize
              initialValues={{subCategoryDetails: subCategories}}
              render={({values}) => (
                <Form>
                  {/* {console.log(
                    "values",
                    values,
                    "getCategoryList",
                    getCategoryList,
                  )} */}
                  <FieldArray
                    name="subCategoryDetails"
                    render={({arrayHelpers}) => (
                      <React.Fragment>
                        {values.subCategoryDetails.map(
                          (subCatDetail, index) => (
                            <CategoryInputWrap key={index}>
                              {/* {console.log(subCatDetail)} */}

                              <FormField
                                type="text"
                                name={`subCategoryDetails[${index}].english_name`}
                                data-name={subCatDetail.english_name}
                                data-index={index}
                                disabled={index !== currentEditing}
                                isactive={subCatDetail.isEnable === false}
                                active={
                                  index === currentEditing
                                    ? isCategoryActive
                                    : null
                                }
                                onChange={
                                  index === currentEditing
                                    ? e =>
                                        this.handleOnEnglishCategoryChange(
                                          e,
                                          index,
                                        )
                                    : null
                                }
                              />

                              <FormField
                                type="text"
                                name={`subCategoryDetails[${index}].arabic_name`}
                                data-name={subCatDetail.arabic_name}
                                data-index={index}
                                disabled={index !== currentEditing}
                                isactive={subCatDetail.isEnable === false}
                                active={
                                  index === currentEditing
                                    ? isCategoryActive
                                    : null
                                }
                                onChange={
                                  index === currentEditing
                                    ? e =>
                                        this.handleOnArabicCategoryChange(
                                          e,
                                          index,
                                        )
                                    : null
                                }
                              />

                              {subCatDetail.isEnable === false ? (
                                <EditRemoveButtonWrap>
                                  <RemoveButton
                                    type="button"
                                    data-index={index}
                                    onClick={() => {
                                      const status = true;
                                      const mode = "enable";
                                      this.onEnableClicked(index);
                                      this.updateEnableDisableCategoryInfo(
                                        index,
                                        status,
                                      );
                                    }}
                                  >
                                    Enable
                                  </RemoveButton>
                                </EditRemoveButtonWrap>
                              ) : (
                                <EditRemoveButtonWrap>
                                  <EditButton
                                    type="button"
                                    data-index={index}
                                    onClick={() => {
                                      this.handleOnEditButtonClick(index);
                                    }}
                                  >
                                    Edit
                                  </EditButton>
                                  <RemoveButton
                                    type="button"
                                    data-index={index}
                                    onClick={() => {
                                      const status = true;
                                      const mode = "edit";
                                      this.updateCategoryInfo(index, status);
                                      toast.info("Data Updated!");
                                    }}
                                  >
                                    Save
                                  </RemoveButton>

                                  <RemoveButton
                                    type="button"
                                    data-index={index}
                                    onClick={() => {
                                      const status = false;
                                      const mode = "disable";
                                      this.onDisableClicked(index);
                                    }}
                                  >
                                    Disable
                                  </RemoveButton>
                                </EditRemoveButtonWrap>
                              )}
                            </CategoryInputWrap>
                          ),
                        )}
                      </React.Fragment>
                    )}
                  />
                </Form>
              )}
            />
          </div>

          <Modal
            isOpen={openEnable}
            onRequestClose={this.closeEnablePopup}
            style={customStylesAlert}
            ariaHideApp={false}
          >
            <EnablePopupWrap>
              <EnablePopupHeading>Alert!!</EnablePopupHeading>

              <EnablePopupLabel>
                There should be minmum 3 Categories Enabled.
              </EnablePopupLabel>
              <EnablePopupOkButton onClick={this.closeEnablePopup}>
                OK
              </EnablePopupOkButton>
            </EnablePopupWrap>
          </Modal>

          <Modal
            isOpen={open}
            onRequestClose={this.closePopup}
            style={customStyles}
            ariaHideApp={false}
          >
            <EditCategoryWrap>
              <CloseButton onClick={this.closePopup}>
                <CloseButtonImg src={closebtn} />
              </CloseButton>

              <Categorytitle>Add Sub Category</Categorytitle>
              <ColumnWrap>
                <ColumnOneWrap>
                  <CategoryPopupLabel>
                    Sub Category Name(English)
                  </CategoryPopupLabel>

                  <RemoveButtonWrap>
                    <CategoryEditInput
                      // data-index={index}
                      // data-key={subcategory.key}
                      onChange={this.handleOnCategoryName}
                      // placeholder="C++"
                      // value={subcategory.value}
                    />

                    {/* <RemoveButton>Remove</RemoveButton> */}
                  </RemoveButtonWrap>

                  <CategoryPopupLabel>
                    Sub Category Name(Arabic)
                  </CategoryPopupLabel>

                  <RemoveButtonWrap>
                    <CategoryEditInput
                      // data-index={index}
                      // data-key={subcategory.key}
                      onChange={this.handleOnArabicCategoryName}
                      // placeholder="C++"
                      // value={subcategory.value}
                    />

                    {/* <RemoveButton>Remove</RemoveButton> */}
                  </RemoveButtonWrap>
                </ColumnOneWrap>
              </ColumnWrap>
              <DoneButton
                onClick={() => {
                  this.addSubCategoryDetails();

                  // this.closePopup();
                  // toast.info("Category has been added!");
                }}
              >
                Done {isLoading ? <Spinner /> : null}
              </DoneButton>
            </EditCategoryWrap>
          </Modal>
        </CategoryListWrap>
      </FullWrap>
    );
  }
}

export default SubCategories;
